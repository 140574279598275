import axios from "axios";
import { buildURL } from "@/Utils/Shared/buildUrl.ts";

const API_URL = import.meta.env.VITE_APP_API_URL;

const SAVE_FILTER: string = `${API_URL}/stocky/saved_filter/`;

export function CreateFilter(token: any, payload: any) {
  return axios.post<any>(`${SAVE_FILTER}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GetFilters(token: any, param: any) {
  const url = buildURL(param, SAVE_FILTER);
  return axios.get<any>(`${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
