import React, { lazy, Suspense } from "react";
import Spinner from "react-bootstrap/Spinner";

interface LazyLottieProps {
  animationData: unknown;
  maxWidth?: string;
}

const Lottie = lazy(() => import("lottie-react"));

function LazyLottie({ animationData, maxWidth = "25rem" }: LazyLottieProps) {
  return (
    <Suspense fallback={<Spinner animation={"grow"} />}>
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ maxWidth: maxWidth }}
      />
    </Suspense>
  );
}

export default LazyLottie;
