import React, { ReactElement } from "react";
import { ItemProps } from "./MetricsCard";
import CountUp from "react-countup";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import { Link } from "react-router-dom";

type ProgressCardProps = {
  icon?: ReactElement;
  heading: string | number | null;
  subText: string | number | null;
  itemsArray?: ItemProps[] | null;
  badgeIcon: ReactElement | null;
  badgeText: string | number | null;
  showBadge: boolean;
  progress: number | null;
  progressHeading: string | null;
  progressBg?: string | null;
  linkIcon: ReactElement;
  linkText: string | null;
  link: string | null;
};

function ProgressCard({
  icon,
  heading,
  subText,
  badgeText,
  badgeIcon,
  itemsArray,
  showBadge,
  progress,
  progressHeading,
  progressBg,
  linkIcon,
  linkText,
  link,
}: ProgressCardProps): React.JSX.Element {
  return (
    <>
      <div
        className="card card-flush h-100 shadow-xs bgi-no-repeat bgi-size-contain bgi-position-x-end overflow-hidden"
        style={{ minHeight: "307px" }}
      >
        <div className="card-body d-flex align-items-start flex-column">
          <div className="mb-4 d-flex align-items-center gap-5">
            <span
              className={"m-0 fw-semibold fs-2x text-gray-800 text-truncate"}
            >
              {heading}
            </span>
            {showBadge ? (
              <span className="badge badge-light-danger fs-base">
                {badgeIcon}
                {badgeText}
              </span>
            ) : null}
          </div>

          <div className={"d-flex flex-wrap gap-10"}>
            {itemsArray?.map(({ mainText, subText, itemIcon }, index) => {
              return (
                <div
                  className="d-flex flex-column my-5"
                  key={`${mainText}-${subText}-${index}`}
                >
                  {typeof mainText === "number" ? (
                    <div className={"d-flex align-items-center gap-2"}>
                      {itemIcon}
                      <CountUp
                        end={mainText}
                        enableScrollSpy
                        scrollSpyDelay={500}
                        scrollSpyOnce
                        className={"fw-semibold fs-3x text-gray-800 lh-1 ls-n2"}
                      />
                    </div>
                  ) : (
                    <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                      {DecorateNameTags(mainText ?? "")}
                    </span>
                  )}

                  <div className="m-0">
                    <div className="fw-semibold fs-6 text-gray-500">
                      {DecorateNameTags(subText ?? "")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={"d-flex flex-column gap-2 w-100 mt-7"}>
            <div className="d-flex justify-content-between w-100">
              <span className="fw-bolder fs-6 text-gray-900">
                {progressHeading}
              </span>
              <span className="fw-bold fs-6 text-gray-500">{progress}%</span>
            </div>

            <div
              className={`h-8px mb-5 w-100 bg-light-${
                progressBg ? progressBg : "primary"
              } rounded`}
            >
              <div
                className={`bg-${
                  progressBg ? progressBg : "primary"
                } rounded h-8px`}
                role="progressbar"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>

          {link ? (
            <div className={"mt-auto"}>
              <Link
                to={link}
                className="btn btn-link btn-color-gray-500 btn-active-color-primary d-flex align-items-center gap-1 fs-base hover-elevate-up"
              >
                {linkText}
                {linkIcon}
              </Link>
            </div>
          ) : null}

          {icon}
        </div>
      </div>
    </>
  );
}

export default ProgressCard;

ProgressCard.defaultProps = {
  heading: 1836,
  subText: "Total Sales",
  badgeText: "2.2%",
  badgeIcon: (
    <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  ),
  showBadge: false,
  progress: 62,
  progressHeading: "1,048 to Goal",
  linkIcon: (
    <i
      className="ki-duotone ki-arrow-up-right fs-3"
      style={{ color: "inherit" }}
    >
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  ),
  linkText: "View",
  link: null,
};
