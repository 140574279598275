import React from "react";
import { Placeholder } from "react-bootstrap";

function MetricsCardSkeleton(): React.JSX.Element {
  return (
    <div
      className={"card h-100 shadow-xs overflow-hidden"}
      style={{ minHeight: "307px" }}
    >
      <div className="card-body">
        <div className={"vstack justify-content-between h-100 py-4"}>
          <Placeholder as={"h1"} className={""} animation="glow">
            <Placeholder bg={"secondary"} className={"col-4"} />
          </Placeholder>

          <div className={""}>
            <Placeholder as={"div"} className={""} animation="glow">
              <Placeholder.Button bg={"secondary"} className={"col-8"} />
            </Placeholder>
          </div>

          <div className={""}>
            <Placeholder as={"h1"} className={""} animation="glow">
              <Placeholder bg={"secondary"} className={"col-12"} />
            </Placeholder>
          </div>

          <div className={""}>
            <Placeholder as={"span"} className={""} animation="glow">
              <Placeholder bg={"secondary"} className={"col-4"} />
            </Placeholder>
          </div>

          <div
            className={"position-absolute"}
            style={{ right: -15, bottom: -10 }}
          >
            <Placeholder as={"div"} className={""} animation="glow">
              <Placeholder.Button
                bg={"secondary"}
                className={"w-80px h-80px rounded-circle"}
              />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MetricsCardSkeleton;
