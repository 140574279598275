import { useEffect } from "react";
import { useThemeMode } from "@metronic/partials";

type BodyBackgroundProps = {
  urlLight: string;
  urlDark: string;
};

function useBodyBackground({ urlLight, urlDark }: BodyBackgroundProps) {
  const { mode } = useThemeMode();

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (body) {
      body.classList.add(
        "bgi-size-cover",
        "bgi-attachment-fixed",
        "bgi-position-center",
        "bgi-no-repeat"
      );
      body.style.backgroundImage =
        mode === "light"
          ? `url(${urlLight})`
          : mode === "dark"
          ? `url(${urlDark})`
          : `url(${urlLight})`;
    }

    return () => {
      if (body) {
        body.classList.remove(
          "bgi-size-cover",
          "bgi-attachment-fixed",
          "bgi-position-center",
          "bgi-no-repeat"
        );
        body.style.backgroundImage = "none";
      }
    };
  }, [mode, urlDark, urlLight]);

  return;
}

export default useBodyBackground;
