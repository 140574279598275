import axios from "axios";

const API_URL = import.meta.env.VITE_APP_API_URL;

const METRICS = `${API_URL}/core/dashboard/metrics`;

const GET_RENTAL_MONTHLY_STATS = `${API_URL}/rentals/monthly/stats`;
const GET_RENTAL_DAILY_MONTH_STATS = `${API_URL}/rentals/daily/month/stats`;

export function getMaticInformation(token: any) {
  return axios
    .get<any>(`${METRICS}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getMonthlyStatsByConnectId(token: any, connectId: any) {
  const url = `${GET_RENTAL_MONTHLY_STATS}/${connectId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getDailyMonthlyStatsByAccountId(token: any, accountId: number) {
  const url = `${GET_RENTAL_DAILY_MONTH_STATS}/${accountId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
