import axios from "axios";

import { buildURL } from "@/Utils/Shared/buildUrl.ts";
import { QRListParams } from "@app/pages/erp/QuoteRequest/QuoteList/_models.ts";

const API_URL = import.meta.env.VITE_APP_API_URL;
const QUOTES = `${API_URL}/stocky/quote/request/`;
const ADD_QUOTES: string = `${API_URL}/stocky/quote/`;
const ADD_QUOTES_STAGE: string = `${API_URL}/stocky/quote/request/stage/`;

export function getQuotesByToken(token: any, params: QRListParams) {
  const url = buildURL(params, QUOTES);
  return axios.get<any>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getQuoteRequestDetails(token: any, params: any) {
  return axios.get(`${QUOTES}${params}/`, {});
}

export function addQuotes(token: any, payload: any) {
  return axios.post(`${ADD_QUOTES}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function createQuoteStage(token: any, payload: any) {
  return axios.post(`${ADD_QUOTES_STAGE}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function quoteRequests(token: any, params: any) {
  return axios.get(`${ADD_QUOTES}?qr=${params}`, {
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  });
}
