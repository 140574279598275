import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import QuillTextEditor from "@components/helpers/ReactQuill/QuillTextEditor.tsx";
import { postComments } from "../_request";
import { useAuth } from "../../../../modules/auth";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import MultiLayeredModalDialog from "@components/UI/MultiLayeredModalDialog.tsx";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";

const CommentModel = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const [isSubbmitting, setIsSubbmitting] = useState(false);
  const { auth } = useAuth();
  const queryClient = useQueryClient();
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const hadelsubmit = () => {
    setIsSubbmitting(true);
    const payload = {
      comment: comment,
      task: data?.id,
    };
    // alert('subnittes')
    // @ts-ignore
    postComments(auth?.access, payload)
      .then(() => {
        toast.success("Posted", { autoClose: 1000 });
        setShowModal(false);
        queryClient.invalidateQueries(["taskComment", data?.id]);
        setComment("");
        setIsSubbmitting(false);
      })
      .catch(() => {
        toast.error("Can't create comment");
        setIsSubbmitting(false);
      });
  };

  return (
    <div>
      <Button
        variant=" btn-light-brand-primary btn-sm mt-5"
        onClick={handleModalOpen}
      >
        Comment
      </Button>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        size={"lg"}
        centered
        enforceFocus={false}
      >
        <MultiLayeredModalDialog>
          <Modal.Header closeButton>
            <Modal.Title>Client Conversation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QuillTextEditor
              setterFunction={(val) => setComment(val)}
              value={comment}
            />
            <div className="d-flex justify-content-end mt-6">
              <SubmitButton
                onClick={() => hadelsubmit()}
                disabled={isSubbmitting}
                loading={isSubbmitting}
                text="Comment"
              />
            </div>
          </Modal.Body>
        </MultiLayeredModalDialog>
      </Modal>
    </div>
  );
};

export default CommentModel;
