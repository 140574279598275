import React, { SVGProps } from "react";

interface RecordIconProps extends SVGProps<SVGSVGElement> {}

function IconRecord(props: RecordIconProps) {
  return (
    <>
      <svg
        fill="currentColor"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        {...props}
      >
        <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z" />
        <path
          fill={"var(--bs-brand-secondary)"}
          d="M11 8a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    </>
  );
}

export default IconRecord;
