import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiResponseProduct } from "./_models.ts";

const API_URL = import.meta.env.VITE_APP_API_URL;

const PRODUCT_CATEGORY_SEARCH_CONSTANT = `${API_URL}/stocky/search/category/`;

const PRODUCT_BRAND_CONSTANT = `${API_URL}/stocky/brand/`;

const PRODUCT_ITEM_CONSTANT = `${API_URL}/stocky/search/item/`;

const GENERATE_SERIAL_UUID = `${API_URL}/stocky/uuid/`;

const GET_PRODUCT_TAGS = `${API_URL}/stocky/search/tag/?`;

const STOCKY_CONNECT_SEARCH: string = `${API_URL}/stocky/search/connect/`;

const GET_USER_SEARCH = `${API_URL}/stocky/search/user/`;

const GET_COMPANY_SEARCH: string = `${API_URL}/core/company/`;

const GET_HNS_CODES_SEARCH: string = `${API_URL}/stocky/hsn_codes/`;

const SEARCH_BY_ACCOUNT: string = `${API_URL}/accounts/`;

const STOCKY_PRODUCT_SEARCH: string = `${API_URL}/accounts/rental/matrix/item/`;

const LEAD_SEARCH_TAGS: string = `${API_URL}/leads/search/tag/`;

const LEAD_RETRIVE = `${API_URL}/leads/retrieve/`;
const TEMPLATE = `${API_URL}/stocky/templates/?target__in=`;

const GET_FILTER = `${API_URL}/stocky/saved_filter/?search=`;

export function getProductCategorys(token: string, searchterm) {
  const url = `${PRODUCT_CATEGORY_SEARCH_CONSTANT}?search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        // The request was made and the server responded with a status code
        // other than 2xx (e.g., 404, 500).
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
}

export const getBrandDetails = (
  token: string | null | undefined,
  searchTerm
) => {
  const url = `${PRODUCT_BRAND_CONSTANT}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: searchTerm, // Include search term as query parameter
      },
    })
    .then((response: any) => {
      // Handle successful response
      console.log(response);
      return response?.data?.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        // The request was made and the server responded with a status code
        // other than 2xx (e.g., 404, 500).
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
};

export function getProductItem(token: string, searchterm) {
  const url = `${PRODUCT_ITEM_CONSTANT}?search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
}

export function getUuId(token: string) {
  const url = `${GENERATE_SERIAL_UUID}`;

  return axios.get<ApiResponseProduct>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getProductTag(token: string, searchterm) {
  const url = `${GET_PRODUCT_TAGS}&search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      throw error;
    });
}

export function getUserSearch(token: string | null | undefined, searchterm) {
  const url = `${GET_USER_SEARCH}?&search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
}

export interface ConnectSearchParams {
  search: string;
  is_rental_client?: boolean;
}

export function getConnectSearch(token: string, params: ConnectSearchParams) {
  const url = `${STOCKY_CONNECT_SEARCH}`;
  return axios
    .get<any>(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);
      throw error;
    });
}

export function getCompanySearch(token: string, searchterm: string) {
  const url: string = `${GET_COMPANY_SEARCH}?&search=${searchterm}`;
  return axios
    .get<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);
      throw error;
    });
}

export function getHnsCodesSearch(token: string, searchterm: string) {
  const url = `${GET_HNS_CODES_SEARCH}?search=${searchterm}`;
  return axios
    .get<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export interface SearchAccountParams {
  search: string;
  connect__is_rental_client?: boolean;
}

export function getStockySearchAccount(
  token: string,
  params: SearchAccountParams
) {
  const url = `${SEARCH_BY_ACCOUNT}`;
  return axios
    .get<any>(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export function getItemSearch(token: any, searchterm: string) {
  const url = `${STOCKY_PRODUCT_SEARCH}?search=${searchterm}`;
  return axios
    .get<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export function getSearchTags(token: any, searchterm: any) {
  const url = `${LEAD_SEARCH_TAGS}?search=${searchterm}`;
  return axios
    .get<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export function getleadInformation(token: any, leadslug: any) {
  const url = `${LEAD_RETRIVE}${leadslug}`;
  return axios
    .get<any>(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export function getTemplates(token, type) {
  const url = `${TEMPLATE}${type}`;
  return axios
    .get<any>(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export function getFilters(token, search, type) {
  const url = `${GET_FILTER}${search}&category=${type}`;
  return axios
    .get<any>(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export function generateUuId(token: string | null | undefined) {
  const url = `${GENERATE_SERIAL_UUID}`;

  return axios.get<ApiResponseProduct>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
