import React from "react";

type AssetVerificationSuccessProps = {
  successScreen: any;
};

function AssetVerificationSuccess({
  successScreen,
}: AssetVerificationSuccessProps): React.JSX.Element {
  return (
    <div className="card-body">
      <div className="row align-items-stretch" style={{ minHeight: "60dvh" }}>
        <div className="col text-center">
          <h3 className={"card-title my-10"}>
            Confirmation Id: # {successScreen?.id}
          </h3>

          <div
            className={
              "flex-grow-1 d-flex flex-column flex-center " +
              "position-md-absolute top-0 bottom-0 start-0 end-0 d-flex flex-center"
            }
          >
            <i
              className="bi bi-check-circle-fill text-success mb-3 "
              style={{
                fontSize: "150px",
                color: "#000",
              }}
            ></i>
            <h1>Success</h1>
            <button
              className="btn btn-light-brand mt-5"
              onClick={() => {
                window.location.reload();
              }}
            >
              Create New
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssetVerificationSuccess;
