import React from "react";
import {
  RENT_REQUEST_STAGE_COLORS,
  RENT_SHEET_STATUS_COLORS,
  RENTAL_REQUESTS_TYPES_COLORS,
} from "@Constants/Rental_Constants";
import {
  CONDITION_OF_ASSET_COLORS,
  OPS_LOG_OPS_TYPE_COLORS,
  REPLACEMENT_ASSET_STATUS_COLORS,
} from "@Constants/OPS_Constants";
import {
  ORDER_CATEGORY_COLORS,
  ORDER_STAGES_COLORS,
} from "@Constants/Order_Constants";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import {
  DC_LIST_STATUS_COLORS,
  LOGISTICS_OPS_TYPE_COLORS,
  logisticsPickupAttemptStatusesColor,
} from "@Constants/Logistic_Constants";
import {
  ASSET_MANAGEMENT_ALL_STAGES_COLOR,
  ASSET_VERIFICATION_SUBLIST_STATUS,
} from "@Constants/ASSET_MANEGMENT_Constant";
import { CRStageColor } from "@Constants/CR_Constant";
import {
  LEAD_STAGE_COLORS,
  QR_REQUEST_TYPE_COLORS,
  QUOTE_STAGE_COLORS,
} from "@Constants/Quote_Constants";
import { task_status_variant } from "@Constants/APP_Constants.ts";
import { All_Login_Type_Color } from "@Constants/Login_Constants.ts";
import { ColorsMain } from "@components/UI/uiTypes.ts";
import { Attendenct_ENUM } from "@app/pages/erp/Attendance/_modals.ts";

const badgeThemeObject = {
  ar_type: RENTAL_REQUESTS_TYPES_COLORS,
  ar_stage: RENT_REQUEST_STAGE_COLORS,
  ops_log_ops_type: OPS_LOG_OPS_TYPE_COLORS,
  condition_of_asset: CONDITION_OF_ASSET_COLORS,
  logistics_log_ops_type: LOGISTICS_OPS_TYPE_COLORS,
  odr_category: ORDER_CATEGORY_COLORS,
  odr_stage: ORDER_STAGES_COLORS,
  replacement_asset_status: REPLACEMENT_ASSET_STATUS_COLORS,
  dc_list_status: DC_LIST_STATUS_COLORS,
  rental_sheet_status: RENT_SHEET_STATUS_COLORS,
  asset_verification_status: ASSET_MANAGEMENT_ALL_STAGES_COLOR,
  asset_verification_sublist_status: ASSET_VERIFICATION_SUBLIST_STATUS,
  sr_stage: CRStageColor,
  qr_stage: QUOTE_STAGE_COLORS,
  qr_request_type: QR_REQUEST_TYPE_COLORS,
  lead_stage: LEAD_STAGE_COLORS,
  task_status: task_status_variant,
  login_status: All_Login_Type_Color,
  attendance_status: Attendenct_ENUM,
  pickup_attempt: logisticsPickupAttemptStatusesColor,
};

type CommonBadgeProps = {
  text: string;
  theme?:
    | "ar_type"
    | "ar_stage"
    | "ops_log_ops_type"
    | "condition_of_asset"
    | "fc_log_ops_type"
    | "logistics_log_ops_type"
    | "odr_category"
    | "odr_stage"
    | "replacement_asset_status"
    | "dc_list_status"
    | "rental_sheet_status"
    | "asset_verification_status"
    | "asset_verification_sublist_status"
    | "sr_stage"
    | "qr_stage"
    | "qr_request_type"
    | "lead_stage"
    | "task_status"
    | "login_status"
    | "attendance_status"
    | "pickup_attempt"
    | "default";
  size?: "sm" | "md" | "lg";
  fallback?: ColorsMain;
  action?: () => void | null;
};

function CommonBadge({
  text,
  theme = "default",
  size = "sm",
  fallback = "primary",
  action,
}: CommonBadgeProps) {
  return (
    <span
      className={`badge badge-outline badge-brand-secondary text-${
        badgeThemeObject[theme]
          ? badgeThemeObject[theme][text]
            ? badgeThemeObject[theme][text]
            : badgeThemeObject[theme]["default"]
          : fallback
      } badge-${size} rounded-pill  ${
        typeof action !== "undefined" && action !== null ? "cursor-pointer" : ""
      } `}
      style={{ width: "fit-content" }}
      onClick={() => {
        if (typeof action !== "undefined" && action !== null) {
          action();
        }
      }}
    >
      {DecorateNameTags(text ?? "")}
    </span>
  );
}

export default CommonBadge;
