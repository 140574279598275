import React, { useState } from "react";
import { buildURL } from "@/Utils/Shared/buildUrl.ts";
import { CreateFilter } from "./_requestes.ts";
import { useAuth } from "@app/modules/auth";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const SetFilterSetBtn = ({ object, name, catagory }) => {
  const { auth } = useAuth();
  const [Name, setName] = useState(name);
  const [Open, setOpen] = useState(false);

  function saveFilter(object, name, catagory) {
    const filter = buildURL(object);
    console.log(filter);
    CreateFilter(auth?.access, {
      name: name,
      category: catagory,
      filter_data: filter,
    })
      .then(() => {
        toast.success("Filter Saved");
      })
      .catch(() => {
        toast.error("Filter Error");
      });
  }

  return (
    <>
      <span
        className="badge badge-sm badge-dark rounded-pill cursor-pointer"
        onClick={() => setOpen(true)}
      >
        Save Filter
      </span>
      <Modal show={Open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Set Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col">
              <span className="badge badge-secondary rounded-pill ">
                {catagory}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label">Name </label>
              <input
                className="form-control mb-3"
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={Name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col text-end">
              <button
                className="btn btn-light-brand btn-sm me-2 ml-2"
                onClick={() => saveFilter(object, Name, catagory)}
              >
                Save Filters
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SetFilterSetBtn;
