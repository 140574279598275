import React, { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading: boolean;
  handleSearch: () => void;
  hasClearBtn: boolean;
  handleClear?: () => void;
}

function SearchInput({
  loading,
  handleSearch,
  hasClearBtn,
  handleClear,
  onKeyDown,
  ...rest
}: SearchInputProps): React.JSX.Element {
  // Ref
  const searchRef = useRef(null);

  // Effects
  useEffect(() => {}, []);

  function handleClick() {
    handleSearch();
  }

  function handleClearClick() {
    if (handleClear) {
      handleClear();
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  function handleOnInput(e: React.FormEvent<HTMLInputElement>) {
    if (!e.currentTarget.value && handleClear) {
      handleClear();
    }
  }

  return (
    <>
      <div className="input-group input-group-sm">
        <input
          className={"form-control form-control-sm"}
          onKeyDown={handleKeyDown}
          {...rest}
          ref={searchRef}
          onInput={handleOnInput}
        />
        <div className="z-index-0">
          <button
            type={"button"}
            className={"btn btn-sm btn-brand rounded-0 h-100"}
            disabled={loading}
            onClick={handleClick}
          >
            {loading ? (
              <>
                <Spinner size={"sm"} />
              </>
            ) : (
              <i className="bi bi-search p-0 text-white fs-5"></i>
            )}
          </button>
          {hasClearBtn ? (
            <button
              type={"button"}
              className={"btn btn-sm btn-danger rounded-0 h-100"}
              disabled={loading}
              onClick={handleClearClick}
            >
              <i className="bi bi-x-lg p-0 text-white fs-5"></i>
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default SearchInput;

SearchInput.defaultProps = {
  hasClearBtn: false,
};
