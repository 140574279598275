/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Order } from '../model';
// @ts-ignore
import type { OrderNotification } from '../model';
// @ts-ignore
import type { OrderNotify } from '../model';
// @ts-ignore
import type { OrderStage } from '../model';
// @ts-ignore
import type { OrdersList200Response } from '../model';
// @ts-ignore
import type { OrdersMetricsErpStageList200Response } from '../model';
// @ts-ignore
import type { OrdersNotificationsList200Response } from '../model';
// @ts-ignore
import type { OrdersPurchaseExportList200Response } from '../model';
// @ts-ignore
import type { OrdersPurchaseItemsList200Response } from '../model';
// @ts-ignore
import type { OrdersPurchaseList200Response } from '../model';
// @ts-ignore
import type { OrdersPurchaseNotificationsList200Response } from '../model';
// @ts-ignore
import type { OrdersPurchaseStageList200Response } from '../model';
// @ts-ignore
import type { OrdersRentalItemsList200Response } from '../model';
// @ts-ignore
import type { OrdersReportsList200Response } from '../model';
// @ts-ignore
import type { OrdersSalesItemsList200Response } from '../model';
// @ts-ignore
import type { OrdersServiceItemsList200Response } from '../model';
// @ts-ignore
import type { OrdersStageList200Response } from '../model';
// @ts-ignore
import type { ProcessedOrderLogisticsItems } from '../model';
// @ts-ignore
import type { ProcessedOrderOpsItems } from '../model';
// @ts-ignore
import type { ProcessedOrderServiceItems } from '../model';
// @ts-ignore
import type { PublicOrder } from '../model';
// @ts-ignore
import type { PublicPurchaseOrder } from '../model';
// @ts-ignore
import type { PurchaseOrder } from '../model';
// @ts-ignore
import type { PurchaseOrderItems } from '../model';
// @ts-ignore
import type { PurchaseOrderNotification } from '../model';
// @ts-ignore
import type { PurchaseOrderNotify } from '../model';
// @ts-ignore
import type { PurchaseOrderStage } from '../model';
// @ts-ignore
import type { RentalOrderItem } from '../model';
// @ts-ignore
import type { SalesOrderItem } from '../model';
// @ts-ignore
import type { ServiceOrderItem } from '../model';
/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreate: async (data: Order, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersCreate', 'data', data)
            const localVarPath = `/orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {string} [order_type] order_type
         * @param {string} [payment_terms] payment_terms
         * @param {string} [slug] slug
         * @param {string} [category] category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stage] stage
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {string} [request__slug] request__slug
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersList: async (connect?: string, order_type?: string, payment_terms?: string, slug?: string, category?: string, start_date?: string, end_date?: string, stage?: string, exclude_order_stage?: string, request__slug?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (order_type !== undefined) {
                localVarQueryParameter['order_type'] = order_type;
            }

            if (payment_terms !== undefined) {
                localVarQueryParameter['payment_terms'] = payment_terms;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (exclude_order_stage !== undefined) {
                localVarQueryParameter['exclude_order_stage'] = exclude_order_stage;
            }

            if (request__slug !== undefined) {
                localVarQueryParameter['request__slug'] = request__slug;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersMetricsErpStageList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/metrics/erp/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersMetricsErpTypeList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/metrics/erp/type/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderNotification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotificationsCreate: async (data: OrderNotification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersNotificationsCreate', 'data', data)
            const localVarPath = `/orders/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {OrdersNotificationsListType} [type] type
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotificationsList: async (order?: string, type?: OrdersNotificationsListType, stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderNotify} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotifyCreate: async (data: OrderNotify, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersNotifyCreate', 'data', data)
            const localVarPath = `/orders/notify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotifyRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersNotifyRead', 'id', id)
            const localVarPath = `/orders/notify/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} order_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotifyRead_1: async (order_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order_id' is not null or undefined
            assertParamExists('ordersNotifyRead_1', 'order_id', order_id)
            const localVarPath = `/orders/notify/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(order_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order.
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPartialUpdate: async (id: number, data: Order, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPartialUpdate', 'data', data)
            const localVarPath = `/orders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPoRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('ordersPoRead', 'slug', slug)
            const localVarPath = `/orders/po/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersProcessedLogisticsItemsRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('ordersProcessedLogisticsItemsRead', 'slug', slug)
            const localVarPath = `/orders/processed/logistics/items/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersProcessedOpsItemsRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('ordersProcessedOpsItemsRead', 'slug', slug)
            const localVarPath = `/orders/processed/ops/items/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersProcessedServiceItemsRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('ordersProcessedServiceItemsRead', 'slug', slug)
            const localVarPath = `/orders/processed/service/items/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPublicRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('ordersPublicRead', 'slug', slug)
            const localVarPath = `/orders/public/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseCreate: async (data: PurchaseOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchaseCreate', 'data', data)
            const localVarPath = `/orders/purchase/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [po_number] po_number
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stage] stage
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseExportList: async (connect?: string, category?: string, po_number?: string, slug?: string, start_date?: string, end_date?: string, stage?: string, exclude_order_stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/purchase/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (po_number !== undefined) {
                localVarQueryParameter['po_number'] = po_number;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (exclude_order_stage !== undefined) {
                localVarQueryParameter['exclude_order_stage'] = exclude_order_stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrderItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseItemsCreate: async (data: PurchaseOrderItems, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchaseItemsCreate', 'data', data)
            const localVarPath = `/orders/purchase/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseItemsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPurchaseItemsDelete', 'id', id)
            const localVarPath = `/orders/purchase/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseItemsList: async (order?: string, item_type?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/purchase/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (item_type !== undefined) {
                localVarQueryParameter['item_type'] = item_type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [po_number] po_number
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stage] stage
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseList: async (connect?: string, category?: string, po_number?: string, slug?: string, start_date?: string, end_date?: string, stage?: string, exclude_order_stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/purchase/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (po_number !== undefined) {
                localVarQueryParameter['po_number'] = po_number;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (exclude_order_stage !== undefined) {
                localVarQueryParameter['exclude_order_stage'] = exclude_order_stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrderNotification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotificationsCreate: async (data: PurchaseOrderNotification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchaseNotificationsCreate', 'data', data)
            const localVarPath = `/orders/purchase/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotificationsList: async (order?: string, stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/purchase/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrderNotify} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotifyCreate: async (data: PurchaseOrderNotify, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchaseNotifyCreate', 'data', data)
            const localVarPath = `/orders/purchase/notify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotifyRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPurchaseNotifyRead', 'id', id)
            const localVarPath = `/orders/purchase/notify/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {PurchaseOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchasePartialUpdate: async (id: number, data: PurchaseOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPurchasePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchasePartialUpdate', 'data', data)
            const localVarPath = `/orders/purchase/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchasePublicRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPurchasePublicRead', 'id', id)
            const localVarPath = `/orders/purchase/public/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPurchaseRead', 'id', id)
            const localVarPath = `/orders/purchase/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrderStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseStageCreate: async (data: PurchaseOrderStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchaseStageCreate', 'data', data)
            const localVarPath = `/orders/purchase/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseStageList: async (order?: string, stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/purchase/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {PurchaseOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseUpdate: async (id: number, data: PurchaseOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersPurchaseUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersPurchaseUpdate', 'data', data)
            const localVarPath = `/orders/purchase/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalOrderItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRentalItemsCreate: async (data: RentalOrderItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersRentalItemsCreate', 'data', data)
            const localVarPath = `/orders/rental/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order item rental.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRentalItemsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersRentalItemsDelete', 'id', id)
            const localVarPath = `/orders/rental/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRentalItemsList: async (order?: string, item_type?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/rental/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (item_type !== undefined) {
                localVarQueryParameter['item_type'] = item_type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fields] fields
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [connect] connect
         * @param {string} [stage] stage
         * @param {string} [category] category
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersReportsList: async (fields?: string, start_date?: string, end_date?: string, connect?: string, stage?: string, category?: string, exclude_order_stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (exclude_order_stage !== undefined) {
                localVarQueryParameter['exclude_order_stage'] = exclude_order_stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRetriveRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('ordersRetriveRead', 'slug', slug)
            const localVarPath = `/orders/retrive/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SalesOrderItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersSalesItemsCreate: async (data: SalesOrderItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersSalesItemsCreate', 'data', data)
            const localVarPath = `/orders/sales/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order item sales.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersSalesItemsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersSalesItemsDelete', 'id', id)
            const localVarPath = `/orders/sales/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersSalesItemsList: async (order?: string, item_type?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/sales/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (item_type !== undefined) {
                localVarQueryParameter['item_type'] = item_type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceOrderItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersServiceItemsCreate: async (data: ServiceOrderItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersServiceItemsCreate', 'data', data)
            const localVarPath = `/orders/service/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order item service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersServiceItemsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersServiceItemsDelete', 'id', id)
            const localVarPath = `/orders/service/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersServiceItemsList: async (order?: string, item_type?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/service/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (item_type !== undefined) {
                localVarQueryParameter['item_type'] = item_type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStageCreate: async (data: OrderStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersStageCreate', 'data', data)
            const localVarPath = `/orders/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStageList: async (order?: string, stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order.
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersUpdate: async (id: number, data: Order, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ordersUpdate', 'data', data)
            const localVarPath = `/orders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersCreate(data: Order, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {string} [order_type] order_type
         * @param {string} [payment_terms] payment_terms
         * @param {string} [slug] slug
         * @param {string} [category] category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stage] stage
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {string} [request__slug] request__slug
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersList(connect?: string, order_type?: string, payment_terms?: string, slug?: string, category?: string, start_date?: string, end_date?: string, stage?: string, exclude_order_stage?: string, request__slug?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersList(connect, order_type, payment_terms, slug, category, start_date, end_date, stage, exclude_order_stage, request__slug, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersMetricsErpStageList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersMetricsErpStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersMetricsErpStageList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersMetricsErpStageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersMetricsErpTypeList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersMetricsErpStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersMetricsErpTypeList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersMetricsErpTypeList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OrderNotification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersNotificationsCreate(data: OrderNotification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderNotification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersNotificationsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersNotificationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {OrdersNotificationsListType} [type] type
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersNotificationsList(order?: string, type?: OrdersNotificationsListType, stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersNotificationsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersNotificationsList(order, type, stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersNotificationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OrderNotify} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersNotifyCreate(data: OrderNotify, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderNotify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersNotifyCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersNotifyCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersNotifyRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderNotify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersNotifyRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersNotifyRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} order_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersNotifyRead_1(order_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderNotify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersNotifyRead_1(order_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersNotifyRead_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order.
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPartialUpdate(id: number, data: Order, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPoRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPoRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPoRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersProcessedLogisticsItemsRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedOrderLogisticsItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersProcessedLogisticsItemsRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersProcessedLogisticsItemsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersProcessedOpsItemsRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedOrderOpsItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersProcessedOpsItemsRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersProcessedOpsItemsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersProcessedServiceItemsRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedOrderServiceItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersProcessedServiceItemsRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersProcessedServiceItemsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPublicRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPublicRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPublicRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PurchaseOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseCreate(data: PurchaseOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [po_number] po_number
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stage] stage
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseExportList(connect?: string, category?: string, po_number?: string, slug?: string, start_date?: string, end_date?: string, stage?: string, exclude_order_stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersPurchaseExportList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseExportList(connect, category, po_number, slug, start_date, end_date, stage, exclude_order_stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PurchaseOrderItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseItemsCreate(data: PurchaseOrderItems, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseItemsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseItemsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseItemsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseItemsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseItemsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseItemsList(order?: string, item_type?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersPurchaseItemsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseItemsList(order, item_type, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseItemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [po_number] po_number
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stage] stage
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseList(connect?: string, category?: string, po_number?: string, slug?: string, start_date?: string, end_date?: string, stage?: string, exclude_order_stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersPurchaseList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseList(connect, category, po_number, slug, start_date, end_date, stage, exclude_order_stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PurchaseOrderNotification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseNotificationsCreate(data: PurchaseOrderNotification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderNotification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseNotificationsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseNotificationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseNotificationsList(order?: string, stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersPurchaseNotificationsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseNotificationsList(order, stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseNotificationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PurchaseOrderNotify} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseNotifyCreate(data: PurchaseOrderNotify, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderNotify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseNotifyCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseNotifyCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseNotifyRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderNotify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseNotifyRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseNotifyRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {PurchaseOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchasePartialUpdate(id: number, data: PurchaseOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchasePartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchasePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchasePublicRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicPurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchasePublicRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchasePublicRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PurchaseOrderStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseStageCreate(data: PurchaseOrderStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseStageCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseStageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseStageList(order?: string, stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersPurchaseStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseStageList(order, stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseStageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase order.
         * @param {PurchaseOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPurchaseUpdate(id: number, data: PurchaseOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPurchaseUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersPurchaseUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalOrderItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersRentalItemsCreate(data: RentalOrderItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalOrderItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersRentalItemsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersRentalItemsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order item rental.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersRentalItemsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersRentalItemsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersRentalItemsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersRentalItemsList(order?: string, item_type?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersRentalItemsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersRentalItemsList(order, item_type, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersRentalItemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fields] fields
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [connect] connect
         * @param {string} [stage] stage
         * @param {string} [category] category
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersReportsList(fields?: string, start_date?: string, end_date?: string, connect?: string, stage?: string, category?: string, exclude_order_stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersReportsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersReportsList(fields, start_date, end_date, connect, stage, category, exclude_order_stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersReportsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersRetriveRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersRetriveRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersRetriveRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SalesOrderItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersSalesItemsCreate(data: SalesOrderItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersSalesItemsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersSalesItemsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order item sales.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersSalesItemsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersSalesItemsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersSalesItemsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersSalesItemsList(order?: string, item_type?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersSalesItemsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersSalesItemsList(order, item_type, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersSalesItemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ServiceOrderItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersServiceItemsCreate(data: ServiceOrderItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceOrderItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersServiceItemsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersServiceItemsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order item service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersServiceItemsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersServiceItemsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersServiceItemsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [item_type] item_type
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersServiceItemsList(order?: string, item_type?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersServiceItemsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersServiceItemsList(order, item_type, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersServiceItemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OrderStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersStageCreate(data: OrderStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersStageCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersStageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [order] order
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersStageList(order?: string, stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersStageList(order, stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersStageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this order.
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersUpdate(id: number, data: Order, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.ordersUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {OrdersApiOrdersCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreate(requestParameters: OrdersApiOrdersCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.ordersCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersList(requestParameters: OrdersApiOrdersListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersList200Response> {
            return localVarFp.ordersList(requestParameters.connect, requestParameters.order_type, requestParameters.payment_terms, requestParameters.slug, requestParameters.category, requestParameters.start_date, requestParameters.end_date, requestParameters.stage, requestParameters.exclude_order_stage, requestParameters.request__slug, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersMetricsErpStageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersMetricsErpStageList(requestParameters: OrdersApiOrdersMetricsErpStageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersMetricsErpStageList200Response> {
            return localVarFp.ordersMetricsErpStageList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersMetricsErpTypeListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersMetricsErpTypeList(requestParameters: OrdersApiOrdersMetricsErpTypeListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersMetricsErpStageList200Response> {
            return localVarFp.ordersMetricsErpTypeList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersNotificationsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotificationsCreate(requestParameters: OrdersApiOrdersNotificationsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderNotification> {
            return localVarFp.ordersNotificationsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersNotificationsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotificationsList(requestParameters: OrdersApiOrdersNotificationsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersNotificationsList200Response> {
            return localVarFp.ordersNotificationsList(requestParameters.order, requestParameters.type, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersNotifyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotifyCreate(requestParameters: OrdersApiOrdersNotifyCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderNotify> {
            return localVarFp.ordersNotifyCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersNotifyReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotifyRead(requestParameters: OrdersApiOrdersNotifyReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderNotify> {
            return localVarFp.ordersNotifyRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersNotifyRead0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersNotifyRead_1(requestParameters: OrdersApiOrdersNotifyRead0Request, options?: RawAxiosRequestConfig): AxiosPromise<OrderNotify> {
            return localVarFp.ordersNotifyRead_1(requestParameters.order_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPartialUpdate(requestParameters: OrdersApiOrdersPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.ordersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPoReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPoRead(requestParameters: OrdersApiOrdersPoReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrder> {
            return localVarFp.ordersPoRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersProcessedLogisticsItemsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersProcessedLogisticsItemsRead(requestParameters: OrdersApiOrdersProcessedLogisticsItemsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcessedOrderLogisticsItems> {
            return localVarFp.ordersProcessedLogisticsItemsRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersProcessedOpsItemsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersProcessedOpsItemsRead(requestParameters: OrdersApiOrdersProcessedOpsItemsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcessedOrderOpsItems> {
            return localVarFp.ordersProcessedOpsItemsRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersProcessedServiceItemsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersProcessedServiceItemsRead(requestParameters: OrdersApiOrdersProcessedServiceItemsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcessedOrderServiceItems> {
            return localVarFp.ordersProcessedServiceItemsRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPublicReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPublicRead(requestParameters: OrdersApiOrdersPublicReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicOrder> {
            return localVarFp.ordersPublicRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseCreate(requestParameters: OrdersApiOrdersPurchaseCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrder> {
            return localVarFp.ordersPurchaseCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseExportList(requestParameters: OrdersApiOrdersPurchaseExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersPurchaseExportList200Response> {
            return localVarFp.ordersPurchaseExportList(requestParameters.connect, requestParameters.category, requestParameters.po_number, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stage, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseItemsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseItemsCreate(requestParameters: OrdersApiOrdersPurchaseItemsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrderItems> {
            return localVarFp.ordersPurchaseItemsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseItemsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseItemsDelete(requestParameters: OrdersApiOrdersPurchaseItemsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.ordersPurchaseItemsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseItemsList(requestParameters: OrdersApiOrdersPurchaseItemsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersPurchaseItemsList200Response> {
            return localVarFp.ordersPurchaseItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseList(requestParameters: OrdersApiOrdersPurchaseListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersPurchaseList200Response> {
            return localVarFp.ordersPurchaseList(requestParameters.connect, requestParameters.category, requestParameters.po_number, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stage, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseNotificationsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotificationsCreate(requestParameters: OrdersApiOrdersPurchaseNotificationsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrderNotification> {
            return localVarFp.ordersPurchaseNotificationsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseNotificationsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotificationsList(requestParameters: OrdersApiOrdersPurchaseNotificationsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersPurchaseNotificationsList200Response> {
            return localVarFp.ordersPurchaseNotificationsList(requestParameters.order, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseNotifyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotifyCreate(requestParameters: OrdersApiOrdersPurchaseNotifyCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrderNotify> {
            return localVarFp.ordersPurchaseNotifyCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseNotifyReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseNotifyRead(requestParameters: OrdersApiOrdersPurchaseNotifyReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrderNotify> {
            return localVarFp.ordersPurchaseNotifyRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchasePartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchasePartialUpdate(requestParameters: OrdersApiOrdersPurchasePartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrder> {
            return localVarFp.ordersPurchasePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchasePublicReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchasePublicRead(requestParameters: OrdersApiOrdersPurchasePublicReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicPurchaseOrder> {
            return localVarFp.ordersPurchasePublicRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseRead(requestParameters: OrdersApiOrdersPurchaseReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrder> {
            return localVarFp.ordersPurchaseRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseStageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseStageCreate(requestParameters: OrdersApiOrdersPurchaseStageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrderStage> {
            return localVarFp.ordersPurchaseStageCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseStageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseStageList(requestParameters: OrdersApiOrdersPurchaseStageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersPurchaseStageList200Response> {
            return localVarFp.ordersPurchaseStageList(requestParameters.order, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersPurchaseUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPurchaseUpdate(requestParameters: OrdersApiOrdersPurchaseUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseOrder> {
            return localVarFp.ordersPurchaseUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersRentalItemsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRentalItemsCreate(requestParameters: OrdersApiOrdersRentalItemsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalOrderItem> {
            return localVarFp.ordersRentalItemsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersRentalItemsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRentalItemsDelete(requestParameters: OrdersApiOrdersRentalItemsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.ordersRentalItemsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersRentalItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRentalItemsList(requestParameters: OrdersApiOrdersRentalItemsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersRentalItemsList200Response> {
            return localVarFp.ordersRentalItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersReportsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersReportsList(requestParameters: OrdersApiOrdersReportsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersReportsList200Response> {
            return localVarFp.ordersReportsList(requestParameters.fields, requestParameters.start_date, requestParameters.end_date, requestParameters.connect, requestParameters.stage, requestParameters.category, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersRetriveReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRetriveRead(requestParameters: OrdersApiOrdersRetriveReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.ordersRetriveRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersSalesItemsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersSalesItemsCreate(requestParameters: OrdersApiOrdersSalesItemsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SalesOrderItem> {
            return localVarFp.ordersSalesItemsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersSalesItemsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersSalesItemsDelete(requestParameters: OrdersApiOrdersSalesItemsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.ordersSalesItemsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersSalesItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersSalesItemsList(requestParameters: OrdersApiOrdersSalesItemsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersSalesItemsList200Response> {
            return localVarFp.ordersSalesItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersServiceItemsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersServiceItemsCreate(requestParameters: OrdersApiOrdersServiceItemsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ServiceOrderItem> {
            return localVarFp.ordersServiceItemsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersServiceItemsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersServiceItemsDelete(requestParameters: OrdersApiOrdersServiceItemsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.ordersServiceItemsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersServiceItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersServiceItemsList(requestParameters: OrdersApiOrdersServiceItemsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersServiceItemsList200Response> {
            return localVarFp.ordersServiceItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersStageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStageCreate(requestParameters: OrdersApiOrdersStageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderStage> {
            return localVarFp.ordersStageCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersStageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStageList(requestParameters: OrdersApiOrdersStageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrdersStageList200Response> {
            return localVarFp.ordersStageList(requestParameters.order, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.ordersTestRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrdersApiOrdersUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersUpdate(requestParameters: OrdersApiOrdersUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.ordersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ordersCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersCreateRequest
 */
export interface OrdersApiOrdersCreateRequest {
    /**
     * 
     * @type {Order}
     * @memberof OrdersApiOrdersCreate
     */
    readonly data: Order
}

/**
 * Request parameters for ordersList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersListRequest
 */
export interface OrdersApiOrdersListRequest {
    /**
     * connect
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly connect?: string

    /**
     * order_type
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly order_type?: string

    /**
     * payment_terms
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly payment_terms?: string

    /**
     * slug
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly slug?: string

    /**
     * category
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly category?: string

    /**
     * start_date
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly end_date?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly stage?: string

    /**
     * exclude_order_stage
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly exclude_order_stage?: string

    /**
     * request__slug
     * @type {string}
     * @memberof OrdersApiOrdersList
     */
    readonly request__slug?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersMetricsErpStageList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersMetricsErpStageListRequest
 */
export interface OrdersApiOrdersMetricsErpStageListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersMetricsErpStageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersMetricsErpStageList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersMetricsErpTypeList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersMetricsErpTypeListRequest
 */
export interface OrdersApiOrdersMetricsErpTypeListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersMetricsErpTypeList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersMetricsErpTypeList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersNotificationsCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersNotificationsCreateRequest
 */
export interface OrdersApiOrdersNotificationsCreateRequest {
    /**
     * 
     * @type {OrderNotification}
     * @memberof OrdersApiOrdersNotificationsCreate
     */
    readonly data: OrderNotification
}

/**
 * Request parameters for ordersNotificationsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersNotificationsListRequest
 */
export interface OrdersApiOrdersNotificationsListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersNotificationsList
     */
    readonly order?: string

    /**
     * type
     * @type {'1' | '2' | '3'}
     * @memberof OrdersApiOrdersNotificationsList
     */
    readonly type?: OrdersNotificationsListType

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersNotificationsList
     */
    readonly stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersNotificationsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersNotificationsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersNotifyCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersNotifyCreateRequest
 */
export interface OrdersApiOrdersNotifyCreateRequest {
    /**
     * 
     * @type {OrderNotify}
     * @memberof OrdersApiOrdersNotifyCreate
     */
    readonly data: OrderNotify
}

/**
 * Request parameters for ordersNotifyRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersNotifyReadRequest
 */
export interface OrdersApiOrdersNotifyReadRequest {
    /**
     * A unique integer value identifying this order notification.
     * @type {number}
     * @memberof OrdersApiOrdersNotifyRead
     */
    readonly id: number
}

/**
 * Request parameters for ordersNotifyRead_1 operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersNotifyRead0Request
 */
export interface OrdersApiOrdersNotifyRead0Request {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersNotifyRead0
     */
    readonly order_id: string
}

/**
 * Request parameters for ordersPartialUpdate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPartialUpdateRequest
 */
export interface OrdersApiOrdersPartialUpdateRequest {
    /**
     * A unique integer value identifying this order.
     * @type {number}
     * @memberof OrdersApiOrdersPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Order}
     * @memberof OrdersApiOrdersPartialUpdate
     */
    readonly data: Order
}

/**
 * Request parameters for ordersPoRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPoReadRequest
 */
export interface OrdersApiOrdersPoReadRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersPoRead
     */
    readonly slug: string
}

/**
 * Request parameters for ordersProcessedLogisticsItemsRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersProcessedLogisticsItemsReadRequest
 */
export interface OrdersApiOrdersProcessedLogisticsItemsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersProcessedLogisticsItemsRead
     */
    readonly slug: string
}

/**
 * Request parameters for ordersProcessedOpsItemsRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersProcessedOpsItemsReadRequest
 */
export interface OrdersApiOrdersProcessedOpsItemsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersProcessedOpsItemsRead
     */
    readonly slug: string
}

/**
 * Request parameters for ordersProcessedServiceItemsRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersProcessedServiceItemsReadRequest
 */
export interface OrdersApiOrdersProcessedServiceItemsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersProcessedServiceItemsRead
     */
    readonly slug: string
}

/**
 * Request parameters for ordersPublicRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPublicReadRequest
 */
export interface OrdersApiOrdersPublicReadRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersPublicRead
     */
    readonly slug: string
}

/**
 * Request parameters for ordersPurchaseCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseCreateRequest
 */
export interface OrdersApiOrdersPurchaseCreateRequest {
    /**
     * 
     * @type {PurchaseOrder}
     * @memberof OrdersApiOrdersPurchaseCreate
     */
    readonly data: PurchaseOrder
}

/**
 * Request parameters for ordersPurchaseExportList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseExportListRequest
 */
export interface OrdersApiOrdersPurchaseExportListRequest {
    /**
     * connect
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly connect?: string

    /**
     * category
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly category?: string

    /**
     * po_number
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly po_number?: string

    /**
     * slug
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly slug?: string

    /**
     * start_date
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly end_date?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly stage?: string

    /**
     * exclude_order_stage
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly exclude_order_stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersPurchaseItemsCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseItemsCreateRequest
 */
export interface OrdersApiOrdersPurchaseItemsCreateRequest {
    /**
     * 
     * @type {PurchaseOrderItems}
     * @memberof OrdersApiOrdersPurchaseItemsCreate
     */
    readonly data: PurchaseOrderItems
}

/**
 * Request parameters for ordersPurchaseItemsDelete operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseItemsDeleteRequest
 */
export interface OrdersApiOrdersPurchaseItemsDeleteRequest {
    /**
     * A unique integer value identifying this purchase order items.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseItemsDelete
     */
    readonly id: number
}

/**
 * Request parameters for ordersPurchaseItemsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseItemsListRequest
 */
export interface OrdersApiOrdersPurchaseItemsListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseItemsList
     */
    readonly order?: string

    /**
     * item_type
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseItemsList
     */
    readonly item_type?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseItemsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseItemsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersPurchaseList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseListRequest
 */
export interface OrdersApiOrdersPurchaseListRequest {
    /**
     * connect
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly connect?: string

    /**
     * category
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly category?: string

    /**
     * po_number
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly po_number?: string

    /**
     * slug
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly slug?: string

    /**
     * start_date
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly end_date?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly stage?: string

    /**
     * exclude_order_stage
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly exclude_order_stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersPurchaseNotificationsCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseNotificationsCreateRequest
 */
export interface OrdersApiOrdersPurchaseNotificationsCreateRequest {
    /**
     * 
     * @type {PurchaseOrderNotification}
     * @memberof OrdersApiOrdersPurchaseNotificationsCreate
     */
    readonly data: PurchaseOrderNotification
}

/**
 * Request parameters for ordersPurchaseNotificationsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseNotificationsListRequest
 */
export interface OrdersApiOrdersPurchaseNotificationsListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseNotificationsList
     */
    readonly order?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseNotificationsList
     */
    readonly stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseNotificationsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseNotificationsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersPurchaseNotifyCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseNotifyCreateRequest
 */
export interface OrdersApiOrdersPurchaseNotifyCreateRequest {
    /**
     * 
     * @type {PurchaseOrderNotify}
     * @memberof OrdersApiOrdersPurchaseNotifyCreate
     */
    readonly data: PurchaseOrderNotify
}

/**
 * Request parameters for ordersPurchaseNotifyRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseNotifyReadRequest
 */
export interface OrdersApiOrdersPurchaseNotifyReadRequest {
    /**
     * A unique integer value identifying this purchase order notification.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseNotifyRead
     */
    readonly id: number
}

/**
 * Request parameters for ordersPurchasePartialUpdate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchasePartialUpdateRequest
 */
export interface OrdersApiOrdersPurchasePartialUpdateRequest {
    /**
     * A unique integer value identifying this purchase order.
     * @type {number}
     * @memberof OrdersApiOrdersPurchasePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PurchaseOrder}
     * @memberof OrdersApiOrdersPurchasePartialUpdate
     */
    readonly data: PurchaseOrder
}

/**
 * Request parameters for ordersPurchasePublicRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchasePublicReadRequest
 */
export interface OrdersApiOrdersPurchasePublicReadRequest {
    /**
     * A unique integer value identifying this purchase order.
     * @type {number}
     * @memberof OrdersApiOrdersPurchasePublicRead
     */
    readonly id: number
}

/**
 * Request parameters for ordersPurchaseRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseReadRequest
 */
export interface OrdersApiOrdersPurchaseReadRequest {
    /**
     * A unique integer value identifying this purchase order.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseRead
     */
    readonly id: number
}

/**
 * Request parameters for ordersPurchaseStageCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseStageCreateRequest
 */
export interface OrdersApiOrdersPurchaseStageCreateRequest {
    /**
     * 
     * @type {PurchaseOrderStage}
     * @memberof OrdersApiOrdersPurchaseStageCreate
     */
    readonly data: PurchaseOrderStage
}

/**
 * Request parameters for ordersPurchaseStageList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseStageListRequest
 */
export interface OrdersApiOrdersPurchaseStageListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseStageList
     */
    readonly order?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersPurchaseStageList
     */
    readonly stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseStageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseStageList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersPurchaseUpdate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersPurchaseUpdateRequest
 */
export interface OrdersApiOrdersPurchaseUpdateRequest {
    /**
     * A unique integer value identifying this purchase order.
     * @type {number}
     * @memberof OrdersApiOrdersPurchaseUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PurchaseOrder}
     * @memberof OrdersApiOrdersPurchaseUpdate
     */
    readonly data: PurchaseOrder
}

/**
 * Request parameters for ordersRentalItemsCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersRentalItemsCreateRequest
 */
export interface OrdersApiOrdersRentalItemsCreateRequest {
    /**
     * 
     * @type {RentalOrderItem}
     * @memberof OrdersApiOrdersRentalItemsCreate
     */
    readonly data: RentalOrderItem
}

/**
 * Request parameters for ordersRentalItemsDelete operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersRentalItemsDeleteRequest
 */
export interface OrdersApiOrdersRentalItemsDeleteRequest {
    /**
     * A unique integer value identifying this order item rental.
     * @type {number}
     * @memberof OrdersApiOrdersRentalItemsDelete
     */
    readonly id: number
}

/**
 * Request parameters for ordersRentalItemsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersRentalItemsListRequest
 */
export interface OrdersApiOrdersRentalItemsListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersRentalItemsList
     */
    readonly order?: string

    /**
     * item_type
     * @type {string}
     * @memberof OrdersApiOrdersRentalItemsList
     */
    readonly item_type?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersRentalItemsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersRentalItemsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersReportsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersReportsListRequest
 */
export interface OrdersApiOrdersReportsListRequest {
    /**
     * fields
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly fields?: string

    /**
     * start_date
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly end_date?: string

    /**
     * connect
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly connect?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly stage?: string

    /**
     * category
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly category?: string

    /**
     * exclude_order_stage
     * @type {string}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly exclude_order_stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersReportsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersRetriveRead operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersRetriveReadRequest
 */
export interface OrdersApiOrdersRetriveReadRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiOrdersRetriveRead
     */
    readonly slug: string
}

/**
 * Request parameters for ordersSalesItemsCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersSalesItemsCreateRequest
 */
export interface OrdersApiOrdersSalesItemsCreateRequest {
    /**
     * 
     * @type {SalesOrderItem}
     * @memberof OrdersApiOrdersSalesItemsCreate
     */
    readonly data: SalesOrderItem
}

/**
 * Request parameters for ordersSalesItemsDelete operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersSalesItemsDeleteRequest
 */
export interface OrdersApiOrdersSalesItemsDeleteRequest {
    /**
     * A unique integer value identifying this order item sales.
     * @type {number}
     * @memberof OrdersApiOrdersSalesItemsDelete
     */
    readonly id: number
}

/**
 * Request parameters for ordersSalesItemsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersSalesItemsListRequest
 */
export interface OrdersApiOrdersSalesItemsListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersSalesItemsList
     */
    readonly order?: string

    /**
     * item_type
     * @type {string}
     * @memberof OrdersApiOrdersSalesItemsList
     */
    readonly item_type?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersSalesItemsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersSalesItemsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersServiceItemsCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersServiceItemsCreateRequest
 */
export interface OrdersApiOrdersServiceItemsCreateRequest {
    /**
     * 
     * @type {ServiceOrderItem}
     * @memberof OrdersApiOrdersServiceItemsCreate
     */
    readonly data: ServiceOrderItem
}

/**
 * Request parameters for ordersServiceItemsDelete operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersServiceItemsDeleteRequest
 */
export interface OrdersApiOrdersServiceItemsDeleteRequest {
    /**
     * A unique integer value identifying this order item service.
     * @type {number}
     * @memberof OrdersApiOrdersServiceItemsDelete
     */
    readonly id: number
}

/**
 * Request parameters for ordersServiceItemsList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersServiceItemsListRequest
 */
export interface OrdersApiOrdersServiceItemsListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersServiceItemsList
     */
    readonly order?: string

    /**
     * item_type
     * @type {string}
     * @memberof OrdersApiOrdersServiceItemsList
     */
    readonly item_type?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersServiceItemsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersServiceItemsList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersStageCreate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersStageCreateRequest
 */
export interface OrdersApiOrdersStageCreateRequest {
    /**
     * 
     * @type {OrderStage}
     * @memberof OrdersApiOrdersStageCreate
     */
    readonly data: OrderStage
}

/**
 * Request parameters for ordersStageList operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersStageListRequest
 */
export interface OrdersApiOrdersStageListRequest {
    /**
     * order
     * @type {string}
     * @memberof OrdersApiOrdersStageList
     */
    readonly order?: string

    /**
     * stage
     * @type {string}
     * @memberof OrdersApiOrdersStageList
     */
    readonly stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrdersApiOrdersStageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof OrdersApiOrdersStageList
     */
    readonly offset?: number
}

/**
 * Request parameters for ordersUpdate operation in OrdersApi.
 * @export
 * @interface OrdersApiOrdersUpdateRequest
 */
export interface OrdersApiOrdersUpdateRequest {
    /**
     * A unique integer value identifying this order.
     * @type {number}
     * @memberof OrdersApiOrdersUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Order}
     * @memberof OrdersApiOrdersUpdate
     */
    readonly data: Order
}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {OrdersApiOrdersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersCreate(requestParameters: OrdersApiOrdersCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersList(requestParameters: OrdersApiOrdersListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersList(requestParameters.connect, requestParameters.order_type, requestParameters.payment_terms, requestParameters.slug, requestParameters.category, requestParameters.start_date, requestParameters.end_date, requestParameters.stage, requestParameters.exclude_order_stage, requestParameters.request__slug, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersMetricsErpStageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersMetricsErpStageList(requestParameters: OrdersApiOrdersMetricsErpStageListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersMetricsErpStageList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersMetricsErpTypeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersMetricsErpTypeList(requestParameters: OrdersApiOrdersMetricsErpTypeListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersMetricsErpTypeList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersNotificationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersNotificationsCreate(requestParameters: OrdersApiOrdersNotificationsCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersNotificationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersNotificationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersNotificationsList(requestParameters: OrdersApiOrdersNotificationsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersNotificationsList(requestParameters.order, requestParameters.type, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersNotifyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersNotifyCreate(requestParameters: OrdersApiOrdersNotifyCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersNotifyCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersNotifyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersNotifyRead(requestParameters: OrdersApiOrdersNotifyReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersNotifyRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersNotifyRead0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersNotifyRead_1(requestParameters: OrdersApiOrdersNotifyRead0Request, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersNotifyRead_1(requestParameters.order_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPartialUpdate(requestParameters: OrdersApiOrdersPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPoReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPoRead(requestParameters: OrdersApiOrdersPoReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPoRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersProcessedLogisticsItemsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersProcessedLogisticsItemsRead(requestParameters: OrdersApiOrdersProcessedLogisticsItemsReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersProcessedLogisticsItemsRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersProcessedOpsItemsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersProcessedOpsItemsRead(requestParameters: OrdersApiOrdersProcessedOpsItemsReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersProcessedOpsItemsRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersProcessedServiceItemsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersProcessedServiceItemsRead(requestParameters: OrdersApiOrdersProcessedServiceItemsReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersProcessedServiceItemsRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPublicReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPublicRead(requestParameters: OrdersApiOrdersPublicReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPublicRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseCreate(requestParameters: OrdersApiOrdersPurchaseCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseExportList(requestParameters: OrdersApiOrdersPurchaseExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseExportList(requestParameters.connect, requestParameters.category, requestParameters.po_number, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stage, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseItemsCreate(requestParameters: OrdersApiOrdersPurchaseItemsCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseItemsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseItemsDelete(requestParameters: OrdersApiOrdersPurchaseItemsDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseItemsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseItemsList(requestParameters: OrdersApiOrdersPurchaseItemsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseList(requestParameters: OrdersApiOrdersPurchaseListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseList(requestParameters.connect, requestParameters.category, requestParameters.po_number, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stage, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseNotificationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseNotificationsCreate(requestParameters: OrdersApiOrdersPurchaseNotificationsCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseNotificationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseNotificationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseNotificationsList(requestParameters: OrdersApiOrdersPurchaseNotificationsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseNotificationsList(requestParameters.order, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseNotifyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseNotifyCreate(requestParameters: OrdersApiOrdersPurchaseNotifyCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseNotifyCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseNotifyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseNotifyRead(requestParameters: OrdersApiOrdersPurchaseNotifyReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseNotifyRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchasePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchasePartialUpdate(requestParameters: OrdersApiOrdersPurchasePartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchasePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchasePublicReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchasePublicRead(requestParameters: OrdersApiOrdersPurchasePublicReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchasePublicRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseRead(requestParameters: OrdersApiOrdersPurchaseReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseStageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseStageCreate(requestParameters: OrdersApiOrdersPurchaseStageCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseStageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseStageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseStageList(requestParameters: OrdersApiOrdersPurchaseStageListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseStageList(requestParameters.order, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersPurchaseUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPurchaseUpdate(requestParameters: OrdersApiOrdersPurchaseUpdateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPurchaseUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersRentalItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersRentalItemsCreate(requestParameters: OrdersApiOrdersRentalItemsCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersRentalItemsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersRentalItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersRentalItemsDelete(requestParameters: OrdersApiOrdersRentalItemsDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersRentalItemsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersRentalItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersRentalItemsList(requestParameters: OrdersApiOrdersRentalItemsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersRentalItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersReportsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersReportsList(requestParameters: OrdersApiOrdersReportsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersReportsList(requestParameters.fields, requestParameters.start_date, requestParameters.end_date, requestParameters.connect, requestParameters.stage, requestParameters.category, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersRetriveReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersRetriveRead(requestParameters: OrdersApiOrdersRetriveReadRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersRetriveRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersSalesItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersSalesItemsCreate(requestParameters: OrdersApiOrdersSalesItemsCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersSalesItemsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersSalesItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersSalesItemsDelete(requestParameters: OrdersApiOrdersSalesItemsDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersSalesItemsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersSalesItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersSalesItemsList(requestParameters: OrdersApiOrdersSalesItemsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersSalesItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersServiceItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersServiceItemsCreate(requestParameters: OrdersApiOrdersServiceItemsCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersServiceItemsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersServiceItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersServiceItemsDelete(requestParameters: OrdersApiOrdersServiceItemsDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersServiceItemsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersServiceItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersServiceItemsList(requestParameters: OrdersApiOrdersServiceItemsListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersServiceItemsList(requestParameters.order, requestParameters.item_type, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersStageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersStageCreate(requestParameters: OrdersApiOrdersStageCreateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersStageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersStageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersStageList(requestParameters: OrdersApiOrdersStageListRequest = {}, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersStageList(requestParameters.order, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersTestRead(options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersTestRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrdersApiOrdersUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersUpdate(requestParameters: OrdersApiOrdersUpdateRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const OrdersNotificationsListType = {
    _1: '1',
    _2: '2',
    _3: '3'
} as const;
export type OrdersNotificationsListType = typeof OrdersNotificationsListType[keyof typeof OrdersNotificationsListType];
