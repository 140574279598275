import React from "react";
import { Placeholder } from "react-bootstrap";

function CardHeaderSkeleton({
  hasEndContent = false,
}: {
  hasEndContent?: boolean;
}): React.JSX.Element {
  return (
    <div className="p-8">
      <>
        <>
          <Placeholder as={"span"} className={""} animation="glow">
            <Placeholder bg={"secondary"} className={"col-4"} />
          </Placeholder>
        </>

        {hasEndContent ? (
          <>
            <Placeholder as={"span"} className={"float-end"} animation="glow">
              <Placeholder bg={"secondary"} className={"col-4 min-w-250px"} />
            </Placeholder>
          </>
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default CardHeaderSkeleton;
