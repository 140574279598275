import {
  format,
  formatDistance,
  formatDistanceToNow,
  isThisYear,
  isToday,
  isValid,
  parse,
  parseISO,
} from "date-fns";
import moment, { Moment } from "moment";

export function formatDateString(inputDate) {
  // Parse the input date string into a Date object
  const date = parseISO(inputDate);

  if (!isValid(date)) {
    return "";
  }

  // Return the formatted string
  return `${timeAgo(date)} `;
}

export function formatDateFromCurrentDate(inputDate) {
  // Parse the input date string into a Date object
  const date = parseISO(inputDate);
  if (!isValid(date)) {
    return "";
  }
  // Return the formatted string
  return `${timeAgo(inputDate)} `;
}

export function convertMinuteCalculate(inputDate) {
  const date = parseISO(inputDate);
  if (!isValid(date)) {
    return "";
  }
  return `${timeAgo(inputDate)} `;
}

export function convertDateStringToDDMMYYYY(inputDate) {
  // Parse the input date string into a Date object
  const date = parseISO(inputDate);
  if (!isValid(date)) {
    return "inv"; // Return 'inv' for invalid time strings
  }

  // Format the date in "dd mm yyyy" format
  return justDate(inputDate); // 'MM' will give the full month name
}

export function convertDateStringToDDMMYYYY_HHMMSS(inputDate) {
  // Parse the input date string into a Date object
  const date = parseISO(inputDate);
  if (!isValid(date)) {
    return "inv"; // Return 'inv' for invalid time strings
  }

  // Format the date in "dd mm yyyy" format

  return localDateTime(inputDate); // 'MM' will give the full month name
}

export function convertDateStringToWeekMonthYear(inputDate) {
  // Parse the input date string into a Date object
  const date = parseISO(inputDate);
  if (!isValid(date)) {
    return ""; // Return 'inv' for invalid time strings
  }
  return format(date, "dd MMM yy");
}

export function calculateDaysAgo(targetDate: any) {
  const daysAgo = timeAgo(targetDate);

  return `${daysAgo}`;
}

export function getFulldate(inputDate: any) {
  // const parsedDate = parseISO(inputDate);
  // const formattedDate = format(parsedDate, "MMMM do, EEEE yyyy, h:mm:ss a");

  return longDate(inputDate);
}

export function getRelativeDate(inputDate) {
  const currentDate = new Date();
  const pastDate = new Date(inputDate);

  const distance = formatDistance(pastDate, currentDate, { addSuffix: true });
  return distance;
}

export function formatTimeAgo(inputDate) {
  const date = parseISO(inputDate);

  if (!isValid(date)) {
    return "";
  }
  return timeAgo(date);
}

export function DateAndTimeTODDMMYYHHSSA(dat, time) {
  // Check if either dat or time is empty or undefined
  if (!dat || !time) {
    return dat || "";
  }

  // Combine date and time strings into a single string
  const combinedDateTimeString = `${dat} ${time}`;

  // Parse the combined string into a Date object
  const combinedDateTime = parse(
    combinedDateTimeString,
    "yyyy-MM-dd HH:mm:ss.SSSSSS",
    new Date()
  );

  // Check if the parsing resulted in an invalid date
  if (isNaN(combinedDateTime.getTime())) {
    return dat; // Return just the date if the combined date/time is invalid
  }

  // Format the date according to the desired format
  const formattedDateTime = format(combinedDateTime, "dd-MM-yy hh:mm:aa");

  return formattedDateTime;
}

export const getAgeString = (timeString) => {
  const date = new Date(timeString);

  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const distanceString = formatDistanceToNow(date);

  if (distanceString === "less than a minute") {
    return "Just now";
  } else if (isToday(date)) {
    return `Today at ${new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(date)}`;
  } else if (isThisYear(date)) {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  } else {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  }
};

export const longDate = (longDate) => {
  return moment.utc(longDate).local().format("MMMM Do, dddd YYYY, h:mm:ss A");
};

export const localDateTime = (longDate) => {
  return moment.utc(longDate).local().format("Do MMM YYYY, hh:mm A");
};

// export const countDown = (date)=>{
//   return moment.utc(date).countdown().toString();
// }

export const shortDate = (shortDate) => {
  return moment.utc(shortDate).local().format("Do MMM");
};

export const justDate = (shortDate) => {
  return moment.utc(shortDate).local().format("DD-MM-YYYY");
};
export const justYear = (shortDate) => {
  return moment.utc(shortDate).local().format("YYYY-MM-DD");
};

export const getMonts = (shortDate) => {
  return moment.utc(shortDate).local().format("MMMM");
};

export const getYears = (shortDate) => {
  return moment.utc(shortDate).local().format("YYYY");
};

export const shortTime = (shortDate) => {
  return moment.utc(shortDate).local().format("hh:mm A");
};

export const dayMonYearDate = (dayMonYearDate) => {
  return moment.utc(dayMonYearDate).local().format("Do MMM YYYY");
};

export const timeAgo = (timeAgo) => {
  return moment.utc(timeAgo).local().fromNow();
};

export const onlyDateAndMonth = (date) => {
  return moment(date).format("Do ");
};

export const onlyDateAndMonths = (date) => {
  return moment(date).format("DD MMM");
};

export const convertTo12HourFormat = (timeString) => {
  const timeObj = moment(timeString, "HH:mm:ss.SSSSSS");
  if (timeObj.isValid()) {
    return timeObj.format("h:mm A");
  }

  if (timeString) {
    const [sHours, minutes] = timeString
      .match(/([0-9]{1,2}):([0-9]{2})/)
      .slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  }
  return "";
};

export const ToMins = (tatSeconds) => {
  var duration = moment.duration(tatSeconds, "seconds");
  // Convert to total days for easier comparisons for months and days
  var days = duration.asDays();
  // Define thresholds
  var minutesThreshold = 60; // 60 minutes in an hour
  var hoursThreshold = 24; // 24 hours in a day
  var daysThreshold = 30; // Approximate days in a month

  // Format based on the magnitude of the duration
  if (duration.asMinutes() < minutesThreshold) {
    return Math.floor(duration.asMinutes()) + " min";
  } else if (duration.asHours() < hoursThreshold) {
    return Math.floor(duration.asHours()) + " hr";
  } else if (days < daysThreshold) {
    return Math.floor(days) + "D";
  } else {
    // Rough approximation for months, assuming ~30.44 days in a month
    var months = days / 30.44;
    return Math.floor(months) + "M";
  }
};

export function getValidMonths(year: number | string | null | undefined) {
  if (!year) return [];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

  // If the provided year is the current year, return only the months that have passed or are currently running
  if (year === currentYear) {
    return Array.from({ length: currentMonth }, (_, i) => i + 1);
  }

  // For past years, return all months
  if (+year < currentYear) {
    return Array.from({ length: 12 }, (_, i) => i + 1);
  }

  // For future years, return an empty array
  return [];
}

/**
 * Enum representing possible date format strings.
 * @enum {string}
 */
export enum DateFormat {
  YYYY_MM_DD = "YYYY-MM-DD",
  DD_MM_YYYY = "DD-MM-YYYY",
  // Add more format strings as needed
}

/**
 * Formats the given Moment object using the specified format.
 * @param {Moment} date The Moment object to format.
 * @param {DateFormat} format The date format string.
 * @returns {string} The formatted date string.
 */
export function formatDateInPicker(date: Moment, format: DateFormat): string {
  return date.format(format);
}

export function getToday(format: DateFormat = DateFormat.YYYY_MM_DD) {
  return moment().format(format);
}

export function getDayOfTheWeek(dateStr: string) {
  return moment(dateStr).format("dddd");
}

export function getCountDown(timeInSeconds: number) {
  if (timeInSeconds < 60) {
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
    return `${seconds}`;
  }

  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
}

export function getRemainingTime(seconds: number) {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

const timeFormats = {
  "24": "24",
  "12": "12",
};

interface GetTime {
  date: Date;
  format: keyof typeof timeFormats;
}

export function getTime({ date, format }: GetTime): string {
  if (format == timeFormats["24"]) {
    return moment(date).format("HH:mm");
  }

  if (format == timeFormats["12"]) {
    return moment(date).format("HH:mm");
  }

  return moment(date).format("hh:mm A");
}

export function getIstHourFromUtc(dateStr: string): string {
  const utcDate = new Date(dateStr);

  return utcDate
    .toLocaleTimeString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      hour12: false,
    })
    .split(":")[0];
}
