import { LoginLog } from "@/generated-api";
import { All_Login_type } from "@Constants/Login_Constants.ts";

export function getErpLoginChartCategories({
  data,
}: {
  data: LoginLog[] | undefined;
}) {
  if (!data) {
    return undefined;
  }

  const chartData: Record<string, LoginLog[]> = {};

  data.forEach((item) => {
    if (item?.username && item.updated_at) {
      if (item.login_type === All_Login_type.LOGGED_IN) {
        if (!chartData[item.username]) {
          chartData[item.username] = [item];
        } else {
          chartData[item.username].push(item);
        }
      }
    }
  });

  return chartData;
}
