import React from "react";
import LottieError from "./Lottie/LottieError.tsx";

type Props = {
  message: string;
  maxWidth?: string;
  isAlert?: boolean;
};

function TableAlert({
  message,
  maxWidth,
  isAlert = true,
}: Props): React.JSX.Element {
  return (
    <>
      <div
        className={`${
          isAlert ? "alert bg-light-danger border-danger" : ""
        }  p-8 mb-0`}
      >
        <div className="d-flex flex-column flex-center gap-5 text-danger">
          <LottieError maxWidth={maxWidth} />
          <p className="m-0 fs-1 fw-semibold text-center">{message}</p>
        </div>
      </div>
    </>
  );
}

export default TableAlert;

TableAlert.defaultProps = {
  message: "Error fetching list",
};
