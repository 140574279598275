import React, { useState } from "react";
import { GetFilters } from "./_requestes.ts";
import { useAuth } from "@app/modules/auth";
import { Modal, Table } from "react-bootstrap";
import { useQuery } from "react-query";

const GetFilterSetBtn = ({ catagory, setFiletr = (e) => {} }) => {
  const { auth } = useAuth();
  const [Open, setOpen] = useState(false);

  const { data } = useQuery(
    ["getfilters", Open],
    () => {
      return GetFilters(auth?.access, { category: catagory });
    },
    {
      enabled: Open,
    }
  );

  function parseQueryString(queryString) {
    const parsedObject = {};

    // Removing leading "?" and splitting query string into key-value pairs
    const pairs = queryString.substring(1).split("&");

    for (const pair of pairs) {
      const [key, value] = pair.split("=");
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value.replace(/\+/g, " "));

      if (parsedObject[decodedKey] === undefined) {
        parsedObject[decodedKey] = decodedValue;
      } else {
        if (Array.isArray(parsedObject[decodedKey])) {
          parsedObject[decodedKey].push(decodedValue);
        } else {
          parsedObject[decodedKey] = [parsedObject[decodedKey], decodedValue];
        }
      }
    }

    return parsedObject;
  }

  const handelSeachFilter = (filterstring) => {
    const obj = parseQueryString(filterstring);
    console.log(obj);
    setFiletr((e) => {
      return { ...obj };
    });
  };

  return (
    <>
      <div
        className="badge badge-sm badge-info rounded-pill cursor-pointer"
        onClick={() => setOpen(true)}
      >
        Get Filter
      </div>
      <Modal show={Open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Get Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.results?.map((e) => (
                <tr
                  className="cursor-pointer"
                  onClick={() => handelSeachFilter(e?.filter_data)}
                >
                  <td>{e?.name}</td>
                  <td>{e?.category}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetFilterSetBtn;
