import {
  GENERATIONS as CPU_GEN,
  PROCESSORS as Pro_CPU_TYPE,
  StockFilterList,
} from "@Constants/Poduct_Constants";
import {
  AssetCurrentStatus,
  OpsStatusCategory,
} from "@Constants/OPS_Constants";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

export const CategoryStatus = Object.keys(OpsStatusCategory).map((key) => ({
  label: key,
  value: OpsStatusCategory[key],
}));

export const PROCESSORS = Pro_CPU_TYPE;

export const GENERATIONS = CPU_GEN;

export const STOCKFILTERDROPDOWN = Object.keys(StockFilterList).map((key) => ({
  label: key,
  value: StockFilterList[key],
}));

// Options for asset_status
const assetStatusKeys = [
  "OPERATIONS_IN",
  "OPERATIONS_OUT",
  "PHYSICALLY_MISSING",
];
export const ASSET_STATUS_OPTIONS = Object.entries(AssetCurrentStatus)
  .filter(([key]) => assetStatusKeys.includes(key))
  .map(([key, value]) => ({ label: DecorateNameTags(key), value: value }))
  .sort((a, b) => {
    const nameA = a.label;
    const nameB = b.label;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

// Constants for keyboard events
export const controlKeys = ["Shift", "Meta", "CapsLock", "Tab", "Backspace"];
