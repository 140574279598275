import React from "react";
import { Placeholder } from "react-bootstrap";

function CardWithTextSkeleton(): React.JSX.Element {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className={"card-title w-100"}>
            <Placeholder as={"h1"} className={"w-100"} animation="glow">
              <Placeholder bg={"secondary"} className={"col-4"} />
            </Placeholder>
          </div>
        </div>
        <div className="card-body">
          <Placeholder as={"p"} className={""} animation="glow">
            <Placeholder bg={"secondary"} className={"col-4"} />
          </Placeholder>
          <Placeholder as={"p"} className={""} animation="glow">
            <Placeholder bg={"secondary"} className={"col-8"} />
          </Placeholder>
          <Placeholder as={"p"} className={""} animation="glow">
            <Placeholder bg={"secondary"} className={"col-6"} />
          </Placeholder>
        </div>
      </div>
    </>
  );
}

export default CardWithTextSkeleton;
