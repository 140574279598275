import React, { ComponentProps } from "react";
import { useThemeMode } from "@metronic/partials";

interface StockyProLogoProps extends Omit<ComponentProps<"img">, "src"> {
  withText?: boolean;
  lightModeWhiteLogo?: boolean;
}

function StockyProLogo({
  withText = false,
  lightModeWhiteLogo = false,
  ...rest
}: StockyProLogoProps): React.JSX.Element {
  const { mode } = useThemeMode();

  const src = lightModeWhiteLogo
    ? "/media/stockyProLogos/whiteLogoIcon.png"
    : mode === "dark"
    ? "/media/stockyProLogos/whiteLogoIcon.png"
    : "/media/stockyProLogos/blackLogoIcon.png";

  const withTextSrc = lightModeWhiteLogo
    ? "/media/stockyProLogos/whiteLogoNoBg.png"
    : mode === "dark"
    ? "/media/stockyProLogos/whiteLogoNoBg.png"
    : "/media/stockyProLogos/blackLogoNoBg.png";

  return (
    <>
      <img src={withText ? withTextSrc : src} alt="Stocky Pro Logo" {...rest} />
    </>
  );
}

export default StockyProLogo;
