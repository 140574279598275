import { createRedirectObject } from "@Constants/RoutingConstants/constants.ts";
import { spModules } from "@Constants/RoutingConstants/stockyPro/SpModules.ts";

export const SP_PRODUCT_PATHS = createRedirectObject({
  CREATE: `/${spModules.product}/create`,
  LIST: `/${spModules.product}/list`,
});

export const SP_LIFE_CYCLE_PATHS = createRedirectObject({
  CREATE: `/${spModules.lifeCycle}/create`,
  LIST: `/${spModules.lifeCycle}/list`,
});

export const SP_HELP_DESK_PATHS = createRedirectObject({
  LIST: `/${spModules.helpDesk}/list`,
});

export const SP_CONSIGNEE_PATHS = createRedirectObject({
  LIST: `/${spModules.consignee}/list`,
  VIEW: `/${spModules.consignee}/`,
});

export const SP_USERS_PATHS = createRedirectObject({
  CREATE: `/${spModules.users}/create`,
  LIST: `/${spModules.users}/list`,
});

export const SP_ROLES_PATHS = createRedirectObject({
  CREATE: `${spModules.roles}/create`,
  MANAGE: `${spModules.roles}/manage`,
  LIST: `${spModules.roles}/list`,
});
