import axios from "axios";
import jwtDecode from "jwt-decode";
import { AuthModel, Decoded_Token, UserModel } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/`;
export const GET_USER_BY_PERMISSION_URL = `${API_URL}/users/permissions`;
// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/users/login`;
// export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/users/account/credentials/request/reset/`;
const GET_RESET_PASSWORD_VALIDITY_URL = `${API_URL}/users/account/credentials/reset`;
const SUBMIT_NEW_PASSWORD_URL = `${API_URL}/users/account/credentials/reset/`;
const ONBOARDING_SIGN_UP = `${API_URL}/accounts/onboarding/signup/`;

// Server should return AuthModel
export function login(email: string, password: string, captcha: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    captcha,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, captcha: string) {
  return axios.post<{ result: any }>(REQUEST_PASSWORD_URL, {
    email,
    captcha,
  });
}

export function getUserByToken(token: string) {
  const decodedToken: Decoded_Token = jwtDecode(token);
  const userId = decodedToken.user_id;
  const url = `${GET_USER_BY_ACCESSTOKEN_URL}${userId}/`;
  return axios.get<UserModel>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getPermissionByToken(token: string) {
  const url = `${GET_USER_BY_PERMISSION_URL}`;
  return axios.get<UserModel>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getResetPasswordKeyValidity(key: string) {
  return axios.get(`${GET_RESET_PASSWORD_VALIDITY_URL}/${key}`);
}

export function submitNewPassword(
  slug: string,
  password: string,
  captcha: string
) {
  return axios.post(`${SUBMIT_NEW_PASSWORD_URL}`, {
    slug,
    password,
    captcha,
  });
}

export interface onboardingSignUpPayloadInterface {
  first_name: string;
  last_name: string | null;
  account: string;
  email: string;
  mobile: number | string;
  captcha: string;
}

interface onboardingSignUpInterface {
  payload: onboardingSignUpPayloadInterface;
}

export function postOnboardingSignUp({ payload }: onboardingSignUpInterface) {
  return axios.post(ONBOARDING_SIGN_UP, payload);
}
