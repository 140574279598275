export const ASSET_MANEGMENT_ALL_STAGES = {
  ACTIVE: "ACTIVE",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VERIFIED_CLOSED: "VERIFIED_CLOSED",
};
export const ASSET_MANEGMENT_ALL_STAGES_COLOR = (status) => {
  switch (status) {
    case "IN_PROGRESS":
      return "warning";
    case "ACTIVE":
      return "success";
    case "COMPLETED":
      return "success";
    case "VERIFIED_CLOSED":
      return "danger";
    default:
      return "secondary";
  }
};

export const ASSET_MANAGEMENT_ALL_STAGES_COLOR = {
  IN_PROGRESS: "warning",
  ACTIVE: "success",
  COMPLETED: "success",
  VERIFIED_CLOSED: "danger",
  default: "secondary",
};

export const CRM_ASSET_STATUS_ENUM = {
  PENDING: "PENDING",
  VERIFIED: "VERIFIED",
  MISSMATCH: "MISSMATCH",
};
export const CRM_ASSET_STATUS_COLOR = (status) => {
  switch (status) {
    case "PENDING":
      return "warning";
    case "VERIFIED":
      return "success";
    case "MISSMATCH":
      return "danger";
    default:
      return "secondary";
  }
};

export const ASSET_VERIFICATION_SUBLIST_STATUS = {
  PENDING: "warning",
  VERIFIED: "success",
  MISSMATCH: "danger",
  default: "brand-secondary",
};

export const CRM_ASSET_MASTER_STATUS_COLOR = (status) => {
  switch (status) {
    case "PENDING":
      return "warning";
    case "ACTIVE":
      return "success";
    case "MISSMATCH":
      return "danger";
    default:
      return "secondary";
  }
};
export const CRM_WORK_PLACE = {
  OFFICE: "OFFICE",
  WORK_FROM_HOME: "WORK FROM HOME",
};
