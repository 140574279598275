import React from "react";
import { Placeholder } from "react-bootstrap";

type ColumnFormSkeletonProps = { count?: number };

function ColumnFormSkeleton({
  count = 3,
}: ColumnFormSkeletonProps): React.JSX.Element {
  return (
    <div>
      <div className="card mb-6">
        <div className="card-body p-8">
          <div className="row row-cols-1 gy-6">
            {Array.from({ length: count }).map((elem, index) => {
              return (
                <div className="col" key={index}>
                  <Placeholder as="div" animation="glow" className={""}>
                    <Placeholder
                      as={"span"}
                      className={"col-4 form-label bg-secondary"}
                    />
                    <div className={"d-grid"}>
                      <Placeholder.Button
                        bg={"secondary"}
                        className={"btn btn-lg"}
                      />
                    </div>
                  </Placeholder>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColumnFormSkeleton;
