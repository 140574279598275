export const supportDetails = [
  {
    role: { label: "Generic", name: "" },
    reach: { label: "Office" },
    contact: { phone: "9033-980-736", emails: ["info@"] },
  },
  {
    role: { label: "Orders", name: "Mohan Naik" },
    reach: { label: "Reconciliation & Compliance" },
    contact: { phone: "783-783-7676", emails: ["orders@"] },
  },
  {
    role: { label: "Client Account Manager", name: "Farzeen" },
    reach: { label: "Support & Client Requests" },
    contact: { phone: "783-783-8585", emails: ["hello@"] },
  },
  {
    role: { label: "Founder & Director", name: "Kathan" },
    reach: { label: "Further Escalation" },
    contact: {
      phone: "783-783-8484",
      emails: ["kathan@"],
    },
  },
  {
    role: { label: "BDM", name: "Pranam" },
    reach: { label: "BDM" },
    contact: { phone: "783-783-3434", emails: ["sales@"] },
  },
];

export const UpdatedsupportDetails = {
  Number: ["783-783-8484", "783-783-8585", "783-783-3434"],
  Email: ["info@spurge.rentals"],
};
export const WhatsAppLink = "https://wa.me/";
