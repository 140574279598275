export const OpsHandoverDepartment = {
  None: null,
  DIRECT_CLIENT: "DIRECT_CLIENT",
  OPERATIONS: "OPERATIONS",
  LOGISTICS: "LOGISTICS",
  FLOW_CAPTAIN: "FLOW_CAPTAIN",
  CRM: "CRM",
};
export const AssetCurrentStatus = {
  PHYSICALLY_MISSING: 0,
  OPERATIONS_IN: 1,
  OPERATIONS_OUT: 2,
  SENT_TO_SERVICE: 3,
  RETURN_FROM_SERVICE: 4,
  FLOW_CAPTAIN_INWARD: 5,
  FLOW_CAPTAIN_HANDOVER: 6,
  LOGISTICS_IN: 7,
  LOGISTICS_OUT: 8,
  QUALITY_CHECK_IN: 9,
  QUALITY_CHECK_OUT: 10,
};

export const ReplacementAssetStatus = {
  YET_TO_PICKUP: 1,
  PICKUP_REQUESTED: 2,
  LOGISTICS_RECEIVED: 3,
  CANCELED: 4,
  OPERATIONS_RECEIVED: 5,
};

export const OpsCategory = {
  LAPTOP: 0,
  DESKTOP: 1,
  ACCESSORY: 2,
  MOBILE: 3,
  TABLET: 4,
};

export const OperationsOutTypes = {
  RENT: "RENT",
  SELL: "SELL",
  RESEARCH_SELL: "RESEARCH SELL",
  RESEARCH_RENT: "RESEARCH RENT",
  RESEARCH_SELL_REPLACEMENT: "RESEARCH SELL REPLACEMENT",
  RESEARCH_RENT_REPLACEMENT: "RESEARCH RENT REPLACEMENT",
  RESEARCH_SELL_CANCEL: "RESEARCH SELL CANCEL",
  RESEARCH_RENT_CANCEL: "RESEARCH RENT CANCEL",
  SAMPLE: "SAMPLE",
  STAFF_USE: "STAFF USE",
  RENT_REPLACEMENT: "RENT REPLACEMENT",
  SELL_REPLACEMENT: "SELL REPLACEMENT",
  PURCHASE_REPAIR: "PURCHASE REPAIR",
  PURCHASE_CANCEL: "PURCHASE CANCEL",
  LOGISTIC: "LOGISTIC",
  RENTAL_SERVICE: "RENTAL SERVICE",
  SALES_SERVICE: "SALES SERVICE",
  THIRD_PARTY_SERVICE: "THIRD PARTY SERVICE",
  SPURGE_SERVICE: "SPURGE SERVICE",
  MISSING: "MISSING_UPDATE",
  FLOW_CAPTAIN: "FLOW_CAPTAIN",
  STOCK: "STOCK",
};

export const OperationsInTypes = {
  RENTAL_OVER: "RENTAL OVER",
  RENTAL_REPLACEMENT_RETURN: "RENTAL REPLACEMENT RETURN",
  SAMPLE_RETURN: "SAMPLE RETURN",
  SALES_CANCEL: "SALES CANCEL",
  RENTAL_CANCEL: "RENTAL CANCEL",
  SALES_REPLACEMENT_RETURN: "SALES REPLACEMENT RETURN",
  RESEARCH_SELL_CANCEL: "RESEARCH SELL CANCEL",
  PURCHASE: "PURCHASE",
  RESEARCH_RENT: "RESEARCH RENT",
  FROM_STAFF: "FROM STAFF",
  LOGISTIC: "LOGISTIC",
  RENTAL_SERVICE: "RENTAL SERVICE",
  SALES_SERVICE: "SALES SERVICE",
  PURCHASE_REPAIR_RETURN: "PURCHASE REPAIR RETURN",
  THIRD_PARTY_SERVICE_RETURN: "THIRD PARTY SERVICE RETURN",
  MISSING_FOUND: "MISSING FOUND",
  RENT_CANCEL: "RENT CANCEL",
  RETURN_FROM_QUALITY_CHECK: "RETURN FROM QUALITY CHECK",
  STOCK: "STOCK",
};

export const OpsUsageType = {
  SALES_CANCEL: "SALES CANCEL",
  RENTAL_OVER: "RENTAL OVER",
  FROM_STAFF: "FROM STAFF",
  LOGISTIC: "LOGISTIC",
  RESEARCH_RENT: "RESEARCH RENT",
  RESEARCH_SELL_CANCEL: "RESEARCH SELL CANCEL",
  PURCHASE_RETURN: "PURCHASE RETURN",
  SALES_REPLACEMENT_RETURN: "SALES REPLACEMENT RETURN",
  LOGISTIC_SUPPORT: "LOGISTIC SUPPORT",
  SAMPLE_RETURN: "SAMPLE RETURN",
  SERVICE: "SERVICE",
  PURCHASE: "PURCHASE",
  RENTAL_REPLACEMENT_RETURN: "RENTAL REPLACEMENT RETURN",
  STAFF_USE: "STAFF USE",
  SAMPLE: "SAMPLE",
  SELL: "SELL",
  RENT: "RENT",
  SELL_REPLACEMENT: "SELL REPLACEMENT",
  REPROGRAM: "REPROGRAM",
  RENT_CANCEL: "RENT CANCEL",
  RETURN_FROM_QUALITY_CHECK: "RETURN FROM QUALITY CHECK",
  STOCK: "STOCK",
};

export const OpsStatusCategory = {
  READY: "READY",
  SERVICE_PENDING: "SERVICE_PENDING",
  SALES: "SALES",
  QC_PENDING: "QC_PENDING",
  RETURN_FROM_RENT: "RETURN_FROM_RENT",
  SCRAP: "SCRAP",
  USE_FOR_PARTS: "USE_FOR_PARTS",
  NOT_OUR_COMPANY_ASSET: "NOT OUR COMPANY ASSET",
  WH_A: "WH_A",
  WH_B: "WH_B",
  ACCESSORY: "ACCESSORY",
  REPROGRAM: "REPROGRAM",
  PARTS_REQUIRED: "PARTS_REQUIRED",
  SCRAPED_AND_USED_FOR_PARTS: "SCRAPED_AND_USED_FOR_PARTS",
};

export const ReplacementAssetStatusColores = (status) => {
  switch (status) {
    case ReplacementAssetStatus.YET_TO_PICKUP:
      return "warning"; // Secondary
    case ReplacementAssetStatus.PICKUP_REQUESTED:
      return "primary"; // Primary
    case ReplacementAssetStatus.LOGISTICS_RECEIVED:
      return "info"; // Info
    case ReplacementAssetStatus.CANCELED:
      return "danger"; // Danger
    case ReplacementAssetStatus.OPERATIONS_RECEIVED:
      return "dark"; // Dark
    default:
      return "secondary"; // Secondary
  }
};

export const RequestType = {
  SALES: 0,
  RENTAL: 1,
  SERVICE: 2,
  SALES_RENTAL: 3,
};

export const OPS_LOG_OPS_TYPE_COLORS = {
  IN: "success",
  OUT: "dark",
  default: "secondary",
};

export const REPLACEMENT_ASSET_STATUS_COLORS = {
  YET_TO_PICKUP: "warning",
  PICKUP_REQUESTED: "primary",
  LOGISTICS_RECEIVED: "info",
  CANCELED: "danger",
  OPERATIONS_RECEIVED: "dark",
  default: "secondary",
};

export const CONDITION_OF_ASSET = {
  ALL_OK: "ALL_OK",
  DAMAGED: "DAMAGED",
  DEAD: "DEAD",
  MISSING_ITEMS: "MISSING ITEMS",
};

export const CONDITION_OF_ASSET_COLORS = {
  ALL_OK: "success",
  DAMAGED: "danger",
  DEAD: "brand-primary",
  MISSING_ITEMS: "warning",
};
