import { CrmSidebar } from "./CrmSidebar.tsx";
import { useAuth } from "@app/modules/auth";
import { ErpSidebar } from "@metronic/layout/components/sidebar/sidebar-menu/ErpSideBar.tsx";
import { STOCKY_PRO_SCOPES } from "@Constants/APP_Constants.ts";
import StockyProSidebar from "@metronic/layout/components/sidebar/sidebar-menu/StockyProSidebar.tsx";

const SidebarMenu = () => {
  const { currentUser } = useAuth();

  return (
    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention px-3"
          id="kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          {currentUser?.scope === STOCKY_PRO_SCOPES.ERP ? (
            <ErpSidebar />
          ) : currentUser?.scope === STOCKY_PRO_SCOPES.CRM ? (
            <CrmSidebar />
          ) : (
            <StockyProSidebar />
          )}
        </div>
      </div>
    </div>
  );
};

export { SidebarMenu };
