import React from "react";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import { getFulldate, ToMins } from "@/Utils/Shared/DateStringToDays.ts";
import { OrderStageColor, orderStageIcons } from "@Constants/Order_Constants";
import SeparatorY from "../Separators/SeparatorY";
import { TatHelperFunction } from "../../UI/TAT/TatHelperFun";
import { CRStageColor, CRStageIcon } from "@Constants/CR_Constant";
import {
  RAR_STAGES_ICONS,
  RENT_REQUEST_STAGE_COLORS,
} from "@Constants/Rental_Constants";
import HorizonatlTimelIne from "@components/helpers/HorizontalTimeLine/HorizonatlTimelIne.tsx";
import ProfileImage from "@components/helpers/ProfleImage/ProfileImage.tsx";

type stageAccordionProps = {
  StageData: any;
  paradimeKey: "ODR" | "RAR" | "CR";
  tat?: any | null;
  pfp: boolean;
};

const CollapseStageAccordion = ({
  StageData,
  paradimeKey,
  tat,
  pfp = false,
}: stageAccordionProps) => {
  return (
    <div className="accordion" id="accordionExample" key={Math.random()}>
      <div className="accordion-item rounded-0 border-gray-300">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed accordion-indicator-center"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <div className="d-flex w-100 justify-content-between">
              {Array.isArray(StageData) && (
                <StageItem
                  stage={StageData[0]}
                  index={0}
                  paradimekey={paradimeKey}
                  pfp={true}
                  tat={tat}
                />
              )}
            </div>
          </button>
        </h2>

        <div
          id="collapseOne"
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body p-6">
            {StageData?.length &&
              StageData?.map((stage, index) => {
                return index !== 0 ? (
                  <div
                    key={`${stage?.created_at}${index}`}
                    className={"position-relative"}
                  >
                    <StageItem
                      stage={stage}
                      index={index}
                      paradimekey={paradimeKey}
                      pfp={true}
                    />
                    {index !== StageData?.length - 1 ? (
                      <div
                        className={"h-40px my-2"}
                        style={{ marginInlineStart: "calc(45px / 2)" }}
                      >
                        <SeparatorY />
                      </div>
                    ) : null}
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CollapseStageAccordion;

export function StageItem({
  stage,
  index,
  paradimekey,
  pfp,
  tat,
}: {
  stage: any;
  index?: number | null | undefined;
  paradimekey: "ODR" | "RAR" | "CR";
  pfp?: boolean | null;
  tat?: any;
}) {
  const parardimeOBJ = {
    ODR: OrderStageColor,
    RAR: RENT_REQUEST_STAGE_COLORS,
    CR: CRStageColor,
  };

  const parardimeIconOBJ = {
    CR: CRStageIcon,
    RAR: RAR_STAGES_ICONS,
    ODR: orderStageIcons,
  };

  const ObjToColor = (obj, key) => {
    return Object.keys(obj).includes(key) ? obj[key] : "secondary";
  };

  return (
    <>
      <div
        key={index}
        className={`w-100`}
        style={{
          filter:
            index !== 0
              ? "brightness(80%) contrast(150%) grayscale(100%)"
              : "none",
          opacity: index !== 0 ? "0.50" : "",
        }}
      >
        <div className={`d-flex justify-content-between align-items-center`}>
          <div className="d-flex align-items-center gap-4">
            <div className="flex-shrink-0">
              <div
                className={`h-45px w-45px rounded-circle btn-outline btn btn-icon border-${ObjToColor(
                  parardimeOBJ[paradimekey],
                  stage?.stage
                )} btn-color-${ObjToColor(
                  parardimeOBJ[paradimekey],
                  stage?.stage
                )}`}
              >
                <i
                  className={`${
                    parardimeIconOBJ[paradimekey][stage?.stage]
                  } fs-2`}
                ></i>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className={"vstack gap-1"}>
                <strong className="fs-6">
                  {DecorateNameTags(stage?.stage ?? "")}
                </strong>
                <div className="text-muted fs-7 fw-semibold">
                  {getFulldate(stage?.created_at ?? "")}
                </div>
              </div>
            </div>
          </div>

          <div className={"d-flex flex-center gap-2"}>
            {tat?.stages?.length > 0 ? (
              <div className={"d-flex flex-column gap-2"}>
                <HorizonatlTimelIne
                  items={TatHelperFunction(tat?.stages, paradimekey)}
                />
                {tat?.total_tat && (
                  <div className="d-block small ">
                    {" "}
                    <strong> Total TAT : </strong>
                    {ToMins(tat?.total_tat)}
                  </div>
                )}
              </div>
            ) : null}
            {pfp && stage?.user?.avatar && (
              <div className="flex-shrink-0 d-flex align-items-center">
                <ProfileImage src={stage?.user?.avatar} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
