import React from "react";
import { StockyIconProps } from "@components/UI/Icons/IconTypes.ts";

function IconSend({ classNames, fontSize }: StockyIconProps) {
  return (
    <i
      className={`ki-duotone ki-send color-inherit fs-4 ${classNames} ${fontSize}`}
    >
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  );
}

export default IconSend;
