import React from "react";

type LogisticsMetricsDetailsProps = {
  data: any;
};

function LogisticsMetricsDetails({
  data,
}: LogisticsMetricsDetailsProps): React.JSX.Element {
  return (
    <div className="card shadow-xs">
      <div className="card-header ">
        <h4 className="card-title fw-bolder">Logistics</h4>
      </div>

      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span className="">Today's Delivery</span>
          <div>
            <span className="fw-bold text-danger opacity-75 me-2  ">
              {" "}
              <i className="bi bi-caret-left-fill  text-danger opacity-75 "></i>
              {data?.logistics?.metrics?.totals?.delivered}
            </span>
            <span className="fw-bold text-success">
              {data?.logistics?.metrics?.totals?.delivered}
              <i className="bi bi-caret-right-fill text-success"></i>
            </span>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span className="">Today's Delivery Signature</span>
          <div>
            <span className="fw-bold text-danger opacity-75 me-2  ">
              {" "}
              <i className="bi bi-caret-left-fill  text-danger opacity-75 "></i>
              {data?.logistics?.metrics?.totals?.delivery_not_signed}
            </span>
            <span className="fw-bold text-success">
              {data?.logistics?.metrics?.totals?.delivery_signed}
              <i className="bi bi-caret-right-fill text-success"></i>
            </span>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span className="">Total Delivery Pending</span>
          <div>
            <span className="fw-bold text-danger opacity-75">
              {data?.logistics?.metrics?.totals?.total_not_delivered}{" "}
            </span>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span className="">Total Delivery Signature Pending</span>
          <div>
            <span className="fw-bold text-danger opacity-75">
              {data?.logistics?.metrics?.totals?.total_delivery_not_signed}{" "}
            </span>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span>
            {data?.ops?.total_in_out?.use_type_counts &&
            data.ops?.total_in_out?.use_type_counts?.length > 0
              ? data?.ops.total_in_out.use_type_counts[0]?.use_type
              : ""}
          </span>
          <div>
            {/* @ts-ignore*/}
            <span className="fw-bold text-primary me-2  ">
              {" "}
              <i className="bi bi-caret-left-fill text-primary"></i>{" "}
              {data?.ops?.total_in_out?.use_type_counts &&
              data.ops.total_in_out.use_type_counts.length > 0
                ? data?.ops?.total_in_out?.use_type_counts[0]?.total_in
                : ""}
            </span>
            {/* @ts-ignore*/}
            <span className="fw-bold text-success ">
              {data?.ops?.total_in_out?.use_type_counts &&
              data?.ops?.total_in_out?.use_type_counts?.length > 0
                ? data?.ops?.total_in_out?.use_type_counts[0]?.total_out
                : ""}{" "}
              <i className="bi bi-caret-right-fill text-success"></i>
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default LogisticsMetricsDetails;
