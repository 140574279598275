import React from "react";
import { getStateName } from "@/Utils/CityAndStates/get_Cityname.ts";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";
import { LogisticsOpsType } from "@Constants/Logistic_Constants";
import { ORDER_TYPE } from "@Constants/Order_Constants";
import { dayMonYearDate } from "@/Utils/Shared/DateStringToDays.ts";

function TrackingDetailsSide({ data }) {
  const hasOrderDetails: boolean =
    !!data?.order_id ||
    !!data?.cost_to ||
    !!data?.order_date ||
    data?.order_category ||
    data?.order_stage;

  return (
    <div className="card border-0 h-100">
      <div className="card-body">
        {data?.serial || data?.model ? (
          <>
            <div className="card border-0 bg-light text-dark">
              <div className="card-body hstack gap-4">
                <div className="">
                  <div className="btn btn-icon btn-brand-primary">
                    <i className="ki-duotone ki-handcart fs-1 color-inherit"></i>
                  </div>
                </div>
                <div className="vstack justify-content-center gap-1">
                  <p className="m-0 fw-semibold">{data?.serial}</p>
                  <p className="m-0 fw-semibold">{data?.model}</p>
                </div>
              </div>
            </div>

            <div className="separator border-gray-300 my-6"></div>
          </>
        ) : null}

        {hasOrderDetails ? (
          <>
            <div className="card border-0">
              <div className="card-body px-4 py-0 vstack gap-4">
                <h2 className={"m-0"}>Order Details</h2>

                {data?.order_id ? (
                  <div className="hstack align-items-center gap-1">
                    <h6 className={"m-0"}>ID:</h6>
                    <div className="">{data?.order_id}</div>
                  </div>
                ) : null}

                {data?.cost_to ? (
                  <div className="hstack align-items-center gap-1">
                    <h6 className={"m-0"}>Logistics Cost To:</h6>
                    <div className="">{data?.cost_to}</div>
                  </div>
                ) : null}

                {data?.order_date ? (
                  <div className="hstack align-items-center gap-1">
                    <h6 className={"m-0"}>Order Placed on:</h6>
                    <div className="">{dayMonYearDate(data?.order_date)}</div>
                  </div>
                ) : null}

                {!!data?.order_category || !!data?.order_stage ? (
                  <div className="hstack align-items-center gap-4">
                    <CommonBadge
                      text={data?.order_category ?? ""}
                      theme={"odr_category"}
                    />

                    <CommonBadge
                      text={data?.order_stage ?? ""}
                      theme={"odr_stage"}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="separator border-gray-300 my-6"></div>
          </>
        ) : null}

        <div className="card card-flush border-0">
          <div className="card-body vstack gap-4 py-0 ps-4">
            <h2 className={"m-0"}>Logistics Details</h2>

            <div className="hstack align-items-center gap-4">
              <CommonBadge
                text={
                  Object.keys(LogisticsOpsType).find(
                    (key) => LogisticsOpsType[key] === data?.ops_type
                  ) ?? ""
                }
                theme={"logistics_log_ops_type"}
              />
              <CommonBadge
                text={
                  Object.keys(ORDER_TYPE)
                    .find((key) => ORDER_TYPE[key] === data?.order_type)
                    ?.toUpperCase() ?? ""
                }
                fallback={"primary"}
              />
            </div>
            {/* Address */}
            {data?.consignee_address ? (
              <div className="vstack gap-1">
                <h6 className={"m-0"}>
                  {data?.ops_type === LogisticsOpsType.OUTWARDS
                    ? "Delivery"
                    : "Pickup"}{" "}
                  Address:
                </h6>
                <div className="">{data?.consignee_address}</div>
              </div>
            ) : null}
            {/* City */}
            {data?.city ? (
              <div className="hstack align-items-center gap-1">
                <h6 className={"m-0"}>City:</h6>
                <div className="">{data?.city}</div>
              </div>
            ) : null}
            {/* State */}
            {data?.state ? (
              <div className="hstack align-items-center gap-1">
                <h6 className={"m-0"}>State:</h6>
                <div className="">{getStateName(parseInt(data?.state))}</div>
              </div>
            ) : null}
            {/* PIN code */}
            {data?.pincode ? (
              <div className="hstack align-items-center gap-1">
                <h6 className={"m-0"}>PIN Code:</h6>
                <div className="">{data?.pincode}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingDetailsSide;
