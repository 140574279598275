/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { AuthPage, Logout, useAuth } from "../modules/auth";
import { App } from "../App";
import CreateTicket from "../pages/erp/Create/CreateTicket/CreateTicket";
import TickitePublicList from "../pages/erp/Tickets/List";
import Tickiteview from "../pages/erp/Tickets/TickiteHelperComponent/tickiteview";
import ViewQuote from "../pages/erp/QuoteRequest/QuoteList/QuotesAction/ViewQuote";
import OrderDetailsView from "../pages/erp/Orders/OrderList/OrderHelper/OrderDetailsView";
import AssetVerification from "../pages/crm/AssetVerification/Create/CRMAssetVerification";
import StockySupport from "../pages/crm/StockySupport/StockySupport";
import ReAssignForm from "../pages/crm/Rentals/RentalReassign/ReAssignForm";
import RarViewPublicPage from "../pages/erp/Rentals/RentalAssetRequest/Components/RarViewPublicPage";
import AssignAsset from "@app/pages/erp/Rentals/Assign/AssignAsset/AssignAsset";
import TrackLogisticPage from "@app/pages/erp/Logistics/TrackLogistics/TrackLogisticPage.tsx";
import ExtendedProductViewPublicPage from "@app/pages/erp/products/ExtendedProductComponents/ExtendedProductViewPublicPage.tsx";
import PublicProductList from "@app/pages/erp/products/PublicProduct/PublicProductList.tsx";
import PrintPoPublicPage from "@app/pages/erp/Orders/PoList/PoActions/OrderStage/PrintPoPublicPage.tsx";
import { SentryRoutes } from "@/Utils/Sentry";
import ErrorBoundary from "@components/helpers/ErrorBoundary/ErrorBoundary.tsx";
import ErrorFallback from "@components/helpers/ErrorBoundary/ErrorFallback.tsx";

const { VITE_PUBLIC_URL } = import.meta.env;

const AppRoutes = () => {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter basename={VITE_PUBLIC_URL}>
      <SentryRoutes>
        <Route
          element={
            <ErrorBoundary
              fallback={(error) => <ErrorFallback error={error} />}
            >
              <App />
            </ErrorBoundary>
          }
        >
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />

          {/* Support Request */}
          <Route path="ticket/create/:connectSlug" element={<CreateTicket />} />
          <Route path="/tickets/:slug" element={<TickitePublicList />} />
          <Route path="/public/tickets/track/:slug" element={<Tickiteview />} />
          <Route
            path="/support-request/raise/:connectId"
            element={<StockySupport />}
          />

          {/* Asset Verification */}
          <Route
            path="/accounts/asset/verification/:slug"
            element={<AssetVerification />}
          />

          {/* Assign Asset */}
          <Route path="/assign/asset/:slug" element={<AssignAsset />} />
          <Route path="/rental/asset/assign/" element={<ReAssignForm />} />

          {/* Asset Request View */}
          <Route
            path="/asset/request/:rarSlug"
            element={<RarViewPublicPage />}
          />

          {/* QR View */}
          <Route path="/quote/request/view/:slug" element={<ViewQuote />} />

          {/* Order & PO View */}
          <Route path="orders/view/:slug" element={<OrderDetailsView />} />
          <Route
            path="/purchase/orders/view/:slug"
            element={<PrintPoPublicPage />}
          />

          {/* Track Logistics */}
          <Route
            path={"/logistics/track/:slug"}
            element={<TrackLogisticPage />}
          />

          {/* Product */}
          <Route
            path={"/public/product/list"}
            element={<PublicProductList />}
          />
          <Route
            path={"/public/product/view/:id"}
            element={<ExtendedProductViewPublicPage />}
          />

          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
};

export { AppRoutes };
