import React from "react";
import LottieAssetVerified from "@components/helpers/CustomAlerts/Lottie/LottieAssetVerified.tsx";

function AssetVerified(): React.JSX.Element {
  return (
    <div className={"py-10"}>
      <div className="d-flex flex-column flex-center gap-5">
        <LottieAssetVerified />
        <div className={"w-75 text-center pb-10 text-gray-600"}>
          <p className="m-0 fs-1 fw-semibold">
            {" "}
            Asset verification is complete.
          </p>
          <p className="m-0 fs-1 fw-semibold">
            {" "}
            Contact support for further clarification.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AssetVerified;
