import React, { useState } from "react";
import clsx from "clsx";
import { BorderRadii, ColorsMain } from "@components/UI/uiTypes.ts";

type ProfileImageProps = {
  src?: string | null;
  alt?: string;
  size?: number;
  name?: string;
  letterColor?: ColorsMain;
  letterSize?: string;
  rounded?: BorderRadii;
};

const ProfileImage = ({
  src,
  alt = "Profile",
  size = 35,
  name = "",
  letterColor = "brand-primary",
  letterSize = "3",
  rounded = "rounded-circle",
}: ProfileImageProps) => {
  const [imageSrc, setImageSrc] = useState(() => src || null);

  const handleImageError = () => {
    setImageSrc(null);
  };

  return (
    <div className={`symbol symbol-${size}px`}>
      {imageSrc ? (
        <div className="symbol-label bg-transparent">
          <img
            src={imageSrc}
            alt={alt}
            width={size}
            height={size}
            className={`${rounded} object-fit-cover`}
            onError={handleImageError}
            style={{ filter: "grayscale(100%)" }}
          />
        </div>
      ) : name ? (
        <div
          className={clsx(
            `symbol-label fs-${letterSize} ${rounded}`,
            `bg-light-${letterColor} `,
            `text-${letterColor ? letterColor : "info"} fs-2x`
          )}
        >
          {name[0]}
        </div>
      ) : (
        <div className="symbol-label bg-transparent">
          <img
            src={"/media/avatars/blank.png"}
            alt={alt}
            width={size}
            height={size}
            className={`${rounded} object-fit-cover`}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileImage;
