import React from "react";
import { Placeholder } from "react-bootstrap";

function RowCardsSkeleton(): React.JSX.Element {
  return (
    <section className={"mb-6"}>
      <div className="row">
        <div className="col-md-6">
          <div className={"card"}>
            <div className={"card-body"}>
              <Placeholder as="span" animation="glow" className={"me-4"}>
                <Placeholder.Button
                  bg={"secondary"}
                  className={"btn btn-icon"}
                />
              </Placeholder>
              <Placeholder as="span" animation="glow">
                <Placeholder.Button bg={"secondary"} xs={4} />
              </Placeholder>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className={"card"}>
            <div className={"card-body"}>
              <Placeholder as="span" animation="glow" className={"me-4"}>
                <Placeholder.Button
                  bg={"secondary"}
                  className={"btn btn-icon"}
                />
              </Placeholder>
              <Placeholder as="span" animation="glow">
                <Placeholder.Button bg={"secondary"} xs={4} />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RowCardsSkeleton;
