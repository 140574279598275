import React from "react";
import { Spinner } from "react-bootstrap";

export interface RefreshButtonProps {
  callback: () => void;
  loading: boolean;
  title?: string;
  disabled?: boolean;
}

function RefreshButton({
  callback,
  loading,
  title = "Refresh",
  disabled,
}: RefreshButtonProps): React.JSX.Element {
  function handleClick() {
    if (callback) {
      callback();
    }
  }

  return (
    <button
      onClick={handleClick}
      className="btn btn-light-brand-primary btn-sm d-flex flex-center gap-2"
      type="button"
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <Spinner size={"sm"} />
        </>
      ) : (
        <>
          <i className="bi bi-arrow-clockwise fs-4 p-0 color-inherit"></i>
        </>
      )}
      {title}
    </button>
  );
}

export default RefreshButton;
