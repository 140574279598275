import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UseErrorRedirection = (response) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (response) {
      if (response.status === 404) {
        navigate("/error/404", { replace: true }); // Redirect to /400 on bad request
      } else if (response.status === 500) {
        navigate("/error/500", { replace: true }); // Redirect to /500 on internal server error
      }
    }
  }, [response, navigate]); // Dependency array includes both response and navigate
};

export default UseErrorRedirection;
