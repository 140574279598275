import React from "react";
import Select, { GroupBase, Props } from "react-select";
import { statesOptions } from "@/Utils/CityAndStates/StateByGSTCode.ts";

type optionDefault = { value: string; label: string };

function StateSelector<
  IsMulti extends boolean = false,
  Group extends GroupBase<optionDefault> = GroupBase<optionDefault>
>({
  options = statesOptions,
  ...rest
}: Props<optionDefault, IsMulti, Group>): React.JSX.Element {
  return (
    <>
      <Select
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        isSearchable
        isClearable
        openMenuOnFocus
        options={options}
        {...rest}
      />
    </>
  );
}

export default StateSelector;
