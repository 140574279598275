import SPURGEIT_LOGO from "@metronic/assets/CompanyName/SPUREIT.png";
import SPURGE_LOGO from "@metronic/assets/CompanyName/SpurgeLogo.png";
import RENTECH_LOGO from "@metronic/assets/CompanyName/renttechlogo.png";
import STOCKY_PRO_LOGO from "/media/stockyProLogos/blackLogoIcon.png";

export function generateDataURIFromImage(imageSrc: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext("2d");
      if (!context) {
        reject("Failed to get canvas context");
        return;
      }

      context.drawImage(image, 0, 0);
      const dataURI = canvas.toDataURL();
      resolve(dataURI);
    };

    image.onerror = (error) => {
      reject("Failed to load image: " + error);
    };
  });
}

export function getCompanyImageSrc(id: number | undefined) {
  if (!id) {
    return null;
  }

  if (id === 1) {
    return SPURGEIT_LOGO;
  } else if (id === 2) {
    return SPURGE_LOGO;
  } else if (id === 3) {
    return RENTECH_LOGO;
  } else if (id === 4) {
    return STOCKY_PRO_LOGO;
  }
  // Handle the case when id does not match any condition
  return null; // Or provide a default value
}
