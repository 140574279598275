import { useCallback, useState } from "react";

function useToggle() {
  const [isOn, setIsOn] = useState(false);

  const setToggleOn = useCallback(() => {
    setIsOn(true);
  }, [setIsOn]);

  const setToggleOff = useCallback(() => {
    setIsOn(false);
  }, [setIsOn]);

  const toggle = useCallback(() => {
    setIsOn((prev) => !prev);
  }, [setIsOn]);

  return { isOn, toggle, setToggleOn, setToggleOff } as const;
}

export default useToggle;
