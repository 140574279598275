import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "@metronic/layout/core";
import { useAuth } from "@app/modules/auth";
import { useQuery } from "react-query";
import { getMonthlyStatsByConnectId } from "./_requestes";
import ChartLoader from "../erp/Reports/ReportsHelper/MonthlyStatsReport/ChartLoader";
import CrmOrderStatistics from "../crm/DashboardHelpers/Chart/CrmOrderStatistics";
import MessageBoard from "../crm/DashboardHelpers/Messages/MessageBoard";
import QuickLinksBoard from "../crm/DashboardHelpers/QuickLinks/QuickLinksBoard";
import { getCrmDashboardMetrics } from "../crm/DashboardHelpers/_requests";

const DashboardPage: FC = () => {
  const { auth, currentUser } = useAuth();
  const isRmsAdmin = currentUser?.groups
    ?.map((group) => group.name)
    .includes("rms_admin");

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["rental_monthly_stats"],
    queryFn: () =>
      getMonthlyStatsByConnectId(auth?.access, currentUser?.account?.id).then(
        (res) => res.data
      ),
    enabled: !!(currentUser?.account?.id && isRmsAdmin),
    retry: false,
    cacheTime: 1,
  });

  const { data: metricsData } = useQuery({
    queryKey: ["crm_metrics"],
    queryFn: () => {
      return getCrmDashboardMetrics(auth?.access).then((res) => res.data);
    },
    retry: false,
    cacheTime: 1,
  });

  return (
    <>
      <div className="container-fluid">
        <div className={"row mb-6 gy-6"}>
          <div className="col-12 col-lg-6 col-xl-5 col-xxl-5 col-3xl-4">
            <QuickLinksBoard metricsData={metricsData} />
          </div>
          <div className="col-12 col-lg-6 col-xl-7 col-xxl-7 col-3xl-8">
            <MessageBoard genericMessage={metricsData?.dashboard_message} />
          </div>
        </div>

        {isRmsAdmin ? (
          <div className="card shadow-xs mb-6">
            <div className="card-header">
              <h1 className="card-title fs-1 m-0">Order Statistics</h1>
            </div>
            <div className={"card-body"}>
              {isLoading || isFetching ? (
                <ChartLoader />
              ) : (
                <CrmOrderStatistics
                  chartData={data}
                  connectInfo={currentUser?.account?.connect}
                  title={""}
                  showToolbar={false}
                />
              )}
            </div>
          </div>
        ) : null}

        {/*{!isRmsAdmin ? (*/}
        {/*  <div className="row row-gap-6">*/}
        {/*    {DASHBOARD_CARDS.map((card, index) => {*/}
        {/*      return (*/}
        {/*        Allowed &&*/}
        {/*        Allowed?.includes(card?.permission) && (*/}
        {/*          <div*/}
        {/*            className="col-sm-12 col-md-6 col-lg-4"*/}
        {/*            key={`${card.heading}-${index}`}*/}
        {/*          >*/}
        {/*            <CRMCard {...card} />*/}
        {/*          </div>*/}
        {/*        )*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </div>
    </>
  );
};

const DashboardWrapperCRM: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapperCRM };
