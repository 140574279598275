import React from "react";
import AssetVerified from "@metronic/assets/lottie-assets/AssetVerified2.json";
import LazyLottie from "@components/helpers/CustomAlerts/Lottie/LazyLottie.tsx";

function LottieAssetVerified() {
  return (
    <>
      <LazyLottie animationData={AssetVerified} />
    </>
  );
}

export default LottieAssetVerified;
