import { ERP_ALL_PERMISSIONS } from "./Permissions";

export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_100 = 100;

export const STOCKY_PRO_SCOPES = {
  ERP: "erp",
  CRM: "crm",
  STOCKY_PRO: "stocky_pro",
};

export const task_status_variant = {
  CANCEL: "secondary",
  TODO: "primary",
  BLOCKED: "danger",
  PROGRESS: "info",
  DONE: "success",
  REVIEW: "teal",
};

export const MONTHS = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
] as const;

export const Sound_Types = {
  BaseNotify: "/media/sounds/notification_sound.mp3",
  Buzzer: "/media/sounds/buzzer.mp3",
  SoftAlert: "/media/sounds/CrmError.mp3",
  Delete: "/media/sounds/delete.mp3",
};
//APP SCOPE
export const FILTER_SAVE = {
  OPERATIONS: "OPERATIONS",
  LOGISTICS: "LOGISTICS",
  DELIVERY_CHALLAN: "DELIVERY_CHALLAN",
  SERVICE: "SERVICE",
  QUALITY_CHECK: "QUALITY_CHECK",
  ORDERS: "ORDERS",
  SUPPORT: "SUPPORT",
  SALES: "SALES",
  COMPANY_INTRO: "COMPANY_INTRO",
  GENERAL: "GENERAL",
};
export const FileUploadStatus = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export const MediaCategory = {
  LOGISTICS_OUTWARDS: "LOGISTICS_OUTWARDS",
  LOGISTICS_INWARD: "LOGISTICS_INWARD",
  LOGISTICS_PICKUP: "LOGISTICS_PICKUP",
  LOGISTICS_DELIVERY: "LOGISTICS_DELIVERY",

  CONSIGNEE_MEDIA: "CONSIGNEE_MEDIA",
} as const;

export const TAGS_CATEGORY = {
  PRODUCT: 1,
  OPERATIONS: 2,
  USER_MASTER: 3,
};

export const ERP_TAGS_CATEGORIES = {
  PRODUCT: 1,
  OPERATIONS: 2,
  FLOW_CAPTAIN: 3,
  SERVICE: 4,
  QUALITY_CHECK: 5,
  LOGISTICS: 6,
  RENTAL: 7,
  MEDIA_FILE: 8,
};

export const DYNAMIC_IMPORT_ERROR_MESSAGE =
  "Failed to fetch dynamically imported module";

export const PageTitles = {
  CREATE_PRODUCT: "Product Create",
  LIST_PRODUCTS: "Product List",
  VIEW_PRODUCTS: "Product Info",
  CREATE_OPS: "Create Operations",
  VIEW_OPS: "Operations Info",
  LIST_OPS: "Operations Log",
  CREATE_QC: "Create Quality Check",
  LIST_QC: " Quality Check Log",
  CREATE_FLOW_CAPTION: "Create Flow Captain",
  LIST_FLOW_CAPTION: "Flow Captain Log",
  CREATE_SERVICE: "Create Service",
  LIST_SERVICE: "Service Log",
  CREATE_SERVICE_TRACKOR: "Create Service Track",
  LIST_SERVICE_TRACKOR: "Service Track Log ",
  CREATE_GROUP: "Create Group",
  LIST_GROUP: "Groups List",
  LIST_ASSET_TRAIL: "Asset Trail",
  LIST_DC: "DC List",
  CREATE_DC: "DC Create",
  CREATE_LOGISTIC: "Logistic Create",
  LIST_LOGISTIC: "Logistic Log",
  LIST_PICKUP_ATTEMPT: " Pickup Attempt Log",
  CREATE_ODR: " Order Create ",
  List_ODR: " Order List ",
  CREATE_PO: "PO Create ",
  List_PO: " PO List ",
  LIST_AR: "List Asset Request",
  LIST_RENT: "Rental Sheet",
  LIST_RENT_RATE: "Rental Rate",
  LIST_RENT_YIELD: "Rental Yield",
  LIST_RENT_PRODUCT: "Rental Products",
  LIST_RENT_LOGISTIC_RATE: "Rental Logistic Rate",
  CREATE_ASSET_VERIFICATION: "Asset Verification Create",
  LIST_ASSET_VERIFICATION: "Asset Verification Create",

  //   Connect
  CONNECT_CREATE: "Connect Create",
  CONNECT_LIST: "Connect List",
  RENTAL_LOGISTIC_RATE_LIST: "Logistic Rate List",
  //  Support Request
  SUPPORT_REQUEST_LIST: "Support Request",
  SUPPORT_REQUEST_VIEW: "Support Request View",
  //  Report
  REPORT: "Report",
  //  Business Development
  //   Quote Request
  QR_CREATE: "Create Quote Request",
  QR_LIST: "Quote Request List",
  //  Lead
  LEAD_CREATE: "Create Lead",
  LEAD_LIST: "Leads List",
  LEAD_VIEW: "Lead View",
  //   Visits
  VISIT_LIST: "Visit List",
  VISIT_VIEW: "Visit View",
  VISIT_REPORT_LIST: "Visit Report",
  //   Enquiry
  ENQUIRY_LIST: "Website Enquiry",
  ENQUIRY_VIEW: "Website Enquiry View",
  //   Reach-out
  REACHOUT_CREATE: "Reach Out",
  //  Vaults
  VAULT_CREATE: "Create Vault",
  VAULT_LIST: "Vault List",
  //   Onboarding
  ONBOARDING_LIST: "Onboarding Entity List",
  ONBOARDING_VIEW: "Onboarding Entity Verification List",
  //   User Management
  ACCOUNT_LIST: "Accounts List",
  ACCOUNT_CREATE: "Create Account",
  USER_LIST: "User List",
  ROLE_LIST: "Roles ",
  CONSIGNEE_LIST: "Consignee List",
  //   Attendance
  ATTENDANCE_LIST: "Attendance List",
  LOGIN_LOG_LIST: "Login Log List",
};

export const ErpPath = {
  LIST_PRODUCTS: "products-list",
  CREATE_PRODUCT: "products-create",
  VIEW_PRODUCTS: "products/:id",
  OPS_CREATE: "operations",
  OPS_VIEW: "/operations/view/:Id",
  OPS_LIST: "logs/operations",
  QC_CREATE: "quality-check",
  FC_CREATE: "create-flowcaption",
  FC_LIST: "logs/flowcaptain-list",
  SERVICE_CREATE: "service",
  SERVICE_TRAC_CREATE: "servicetracker/create",
  SERVICE_TRAC_LIST: "servicetracker/list/",
  SERVICE_LIST: "logs/service",
  ASSET_TRAIL_LIST: "logs/asset_trail",
  GROUP_LIST: "groups/list",
  //  Connect
  CONNECT_CREATE: "connect-create/",
  CONNECT_LIST: "connet-list/",
  RENTAL_LOGISTIC_RATE_LIST: "rental-logistic-rate-list/",
  //  Support Request
  SUPPORT_REQUEST_LIST: "client_requests",
  SUPPORT_REQUEST_VIEW: "client_requests/:taskid",
  //  Report
  REPORT: "report",
  //  Business Development
  //   Quote Request
  QR_CREATE: "quote-request/quote-request-create/",
  QR_LIST: "quote-request/quote-list/",
  //  Lead
  LEAD_CREATE: "leads/create",
  LEAD_LIST: "leads/list",
  LEAD_VIEW: "leads/view/:id",
  //   Visits
  VISIT_LIST: "visits/list/",
  VISIT_VIEW: "visits/list/details/:id",
  VISIT_REPORT_LIST: "visits/report/list/",
  //   Enquiry
  ENQUIRY_LIST: "website/enquiry/",
  ENQUIRY_VIEW: "website/enquiry/:id",
  //   Reach-out
  REACHOUT_CREATE: "introduction/",
  //  Vaults
  VAULT_CREATE: "vault/create/",
  VAULT_LIST: "vault/list/",
  //   Onboarding
  ONBOARDING_LIST: "onboarding",
  ONBOARDING_VIEW: "onboarding-verifiaction/:EntitiId",
  //   User Management
  ACCOUNT_CREATE: "user/managmet/create",
  ACCOUNT_LIST: "user/managmet/accounts/",
  USER_LIST: "/user/managmet/list/:id",
  CONSIGNEE_LIST: "user/consignee/list",
  ROLES_LIST: "user/roles",
  //   Attendance
  ATTENDANCE_LIST: "attendance",
  LOGIN_LOG_LIST: "login/log/list",
};

interface ErpPageObjectInterface {
  path: string;
  title: string;
  permissions?: string;
}

export const ErpPageObject: { [key: string]: ErpPageObjectInterface } = {
  // product start
  PRODUCT_LIST: {
    path: ErpPath?.LIST_PRODUCTS,
    title: PageTitles?.LIST_PRODUCTS,
    permissions: "user.view_product",
  },
  PRODUCT_CREATE: {
    path: ErpPath?.CREATE_PRODUCT,
    title: PageTitles?.CREATE_PRODUCT,
    permissions: "user.view_product",
  },
  PRODUCT_VIEW: {
    path: ErpPath?.VIEW_PRODUCTS,
    title: PageTitles?.VIEW_PRODUCTS,
    permissions: "user.view_product",
  },
  // product end
  OPS_CREATE: {
    path: ErpPath?.OPS_CREATE,
    title: PageTitles?.CREATE_OPS,
    permissions: "user.create_ops",
  },
  OPS_VIEW: {
    path: ErpPath?.OPS_VIEW,
    title: PageTitles?.VIEW_OPS,
    permissions: "user.create_ops",
  },
  OPS_LIST: {
    path: ErpPath?.OPS_LIST,
    title: PageTitles?.LIST_OPS,
    permissions: "user.list_ops",
  },
  QC_CREATE: {
    path: ErpPath?.QC_CREATE,
    title: PageTitles?.CREATE_QC,
    permissions: "user.list_qc",
  },
  FC_CREATE: {
    path: ErpPath?.FC_CREATE,
    title: PageTitles?.CREATE_FLOW_CAPTION,
    permissions: "user.create_flowcaptain",
  },
  FC_LIST: {
    path: ErpPath?.FC_LIST,
    title: PageTitles?.LIST_FLOW_CAPTION,
    permissions: "user.list_flowcaptain",
  },
  SERVICE_CREATE: {
    path: ErpPath?.SERVICE_CREATE,
    title: PageTitles?.CREATE_SERVICE,
    permissions: "user.list_sent_to_service",
  },
  SERVICE_TRAC_CREATE: {
    path: ErpPath?.SERVICE_TRAC_CREATE,
    title: PageTitles?.CREATE_SERVICE_TRACKOR,
    permissions: "user.list_sent_to_service",
  },
  SERVICE_TRAC_LIST: {
    path: ErpPath?.SERVICE_TRAC_LIST,
    title: PageTitles?.LIST_SERVICE_TRACKOR,
    permissions: "user.list_sent_to_service",
  },
  SERVICE_LIST: {
    path: ErpPath?.SERVICE_LIST,
    title: PageTitles?.LIST_SERVICE,
    permissions: "user.list_sent_to_service",
  },
  ASSET_TRAIL_LIST: {
    path: ErpPath?.ASSET_TRAIL_LIST,
    title: PageTitles?.LIST_ASSET_TRAIL,
    permissions: "user.list_asset_trail",
  },
  GROUP_LIST: {
    path: ErpPath?.GROUP_LIST,
    title: PageTitles?.LIST_GROUP,
    permissions: "user.view_group",
  },
  //   Connect
  CONNECT_CREATE: {
    path: ErpPath?.CONNECT_CREATE,
    title: PageTitles?.CONNECT_CREATE,
    permissions: `user.${ERP_ALL_PERMISSIONS.create_connect}`,
  },
  CONNECT_LIST: {
    path: ErpPath?.CONNECT_LIST,
    title: PageTitles?.CONNECT_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_connect}`,
  },
  RENTAL_LOGISTIC_RATE_LIST: {
    path: ErpPath?.RENTAL_LOGISTIC_RATE_LIST,
    title: PageTitles?.RENTAL_LOGISTIC_RATE_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_logistics}`,
  },
  //  Support Request
  SUPPORT_REQUEST_LIST: {
    path: ErpPath?.SUPPORT_REQUEST_LIST,
    title: PageTitles?.SUPPORT_REQUEST_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_support_request}`,
  },
  SUPPORT_REQUEST_VIEW: {
    path: ErpPath?.SUPPORT_REQUEST_VIEW,
    title: PageTitles?.SUPPORT_REQUEST_VIEW,
    permissions: `user.${ERP_ALL_PERMISSIONS.view_support_request}`,
  },
  //  Report
  REPORT: {
    path: ErpPath?.REPORT,
    title: PageTitles?.REPORT,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_reports}`,
  },
  //  Business Development
  //  Quote Request
  QR_CREATE: {
    path: ErpPath?.QR_CREATE,
    title: PageTitles?.QR_CREATE,
    permissions: `user.${ERP_ALL_PERMISSIONS.create_quote_request}`,
  },
  QR_LIST: {
    path: ErpPath?.QR_LIST,
    title: PageTitles?.QR_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_quote_request}`,
  },
  //  Lead
  LEAD_CREATE: {
    path: ErpPath?.LEAD_CREATE,
    title: PageTitles?.LEAD_CREATE,
    permissions: `user.${ERP_ALL_PERMISSIONS.create_leads}`,
  },
  LEAD_LIST: {
    path: ErpPath?.LEAD_LIST,
    title: PageTitles?.LEAD_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_leads}`,
  },
  LEAD_VIEW: {
    path: ErpPath?.LEAD_VIEW,
    title: PageTitles?.LEAD_VIEW,
    permissions: `user.${ERP_ALL_PERMISSIONS.view_leads}`,
  },
  //   Visits
  VISIT_LIST: {
    path: ErpPath?.VISIT_LIST,
    title: PageTitles?.VISIT_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_visits}`,
  },
  VISIT_VIEW: {
    path: ErpPath?.VISIT_VIEW,
    title: PageTitles?.VISIT_VIEW,
    permissions: `user.${ERP_ALL_PERMISSIONS.view_visits}`,
  },
  VISIT_REPORT_LIST: {
    path: ErpPath?.VISIT_REPORT_LIST,
    title: PageTitles?.VISIT_REPORT_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_visits}`,
  },
  //   Enquiry
  ENQUIRY_LIST: {
    path: ErpPath?.ENQUIRY_LIST,
    title: PageTitles?.ENQUIRY_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_enquiry}`,
  },
  ENQUIRY_VIEW: {
    path: ErpPath?.ENQUIRY_VIEW,
    title: PageTitles?.ENQUIRY_VIEW,
    permissions: `user.${ERP_ALL_PERMISSIONS.view_enquiry}`,
  },
  //   Reach-out
  REACHOUT_CREATE: {
    path: ErpPath?.REACHOUT_CREATE,
    title: PageTitles?.REACHOUT_CREATE,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_rentals}`,
  },
  // Vault
  VAULT_CREATE: {
    path: ErpPath?.VAULT_CREATE,
    title: PageTitles?.VAULT_CREATE,
    permissions: `user.${ERP_ALL_PERMISSIONS.create_vault}`,
  },
  VAULT_LIST: {
    path: ErpPath?.VAULT_LIST,
    title: PageTitles?.VAULT_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_vault}`,
  },
  //   Onboarding
  ONBOARDING_LIST: {
    path: ErpPath?.ONBOARDING_LIST,
    title: PageTitles?.ONBOARDING_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.list_onboarding}`,
  },
  ONBOARDING_VIEW: {
    path: ErpPath?.ONBOARDING_VIEW,
    title: PageTitles?.ONBOARDING_VIEW,
    permissions: `user.${ERP_ALL_PERMISSIONS.update_onboarding}`,
  },
  //   User Management
  ACCOUNT_CREATE: {
    path: ErpPath?.ACCOUNT_CREATE,
    title: PageTitles?.ACCOUNT_CREATE,
    permissions: `user.${ERP_ALL_PERMISSIONS.user_create}`,
  },
  ACCOUNT_LIST: {
    path: ErpPath?.ACCOUNT_LIST,
    title: PageTitles?.ACCOUNT_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.user_list}`,
  },
  USER_LIST: {
    path: ErpPath?.USER_LIST,
    title: PageTitles?.USER_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.user_list}`,
  },
  ROLE_LIST: {
    path: ErpPath?.ROLES_LIST,
    title: PageTitles?.ROLE_LIST,
    // todo add permissions of permissions shoud be getting fr backedn
    permissions: `user.${ERP_ALL_PERMISSIONS.user_list}`,
  },
  CONSIGNEE_LIST: {
    path: ErpPath?.CONSIGNEE_LIST,
    title: PageTitles?.CONSIGNEE_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.user_list}`,
  },
  //   Attendance
  ATTENDANCE_LIST: {
    path: ErpPath?.ATTENDANCE_LIST,
    title: PageTitles?.ATTENDANCE_LIST,
    permissions: `user.${ERP_ALL_PERMISSIONS.user_list}`,
  },
  //   Login Log list |
  LOGIN_LOG_LIST: {
    path: ErpPath?.LOGIN_LOG_LIST,
    title: PageTitles?.LOGIN_LOG_LIST,
    // todo: add login log list permission
    permissions: `user.${ERP_ALL_PERMISSIONS.user_list}`,
  },
};
