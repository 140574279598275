import React from "react";
import {
  dayMonYearDate,
  shortTime,
} from "@/Utils/Shared/DateStringToDays.ts";
import TrackBadge from "@components/helpers/CustomBadges/TrackBadge.tsx";
import SeparatorY from "@components/helpers/Separators/SeparatorY.tsx";
import { useThemeMode } from "@metronic/partials";

function TimelineItem({ details, index }) {
  const { mode } = useThemeMode();

  return (
    <div className="d-flex align-items-center gap-8">
      <div className="d-flex flex-column justify-content-center gap-1 w-100px h-50px">
        {details?.updated_at ? (
          <>
            <p className="m-0 fw-bold">{dayMonYearDate(details?.updated_at)}</p>
            <p className="m-0">At {shortTime(details?.updated_at)}</p>
          </>
        ) : null}
      </div>

      <div className="w-50px h-50px d-flex flex-center position-relative">
        <div
          className={`${
            mode === "dark" ? "bg-gray-100" : "bg-white"
          } position-relative z-index-1`}
        >
          {index !== 0 ? (
            <i className={`bi bi-record-circle fs-2 p-0 text-muted`}></i>
          ) : (
            <TrackBadge />
          )}
        </div>
        {index !== 0 ? (
          <div
            className="position-absolute start-0 end-0 z-index-0"
            style={{
              bottom: "50%",
              height: `calc(2rem + 50px)`,
            }}
          >
            <div className="mx-auto h-100" style={{ width: "fit-content" }}>
              <SeparatorY
                borderColor={"gray-400 border-1"}
                borderType={"dashed"}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="d-flex flex-column justify-content-center gap-1">
        <p className="m-0 fw-bold text-capitalize">{details?.action}</p>
        {details?.origin ? <p className="m-0">at {details?.origin}</p> : null}
      </div>
    </div>
  );
}

export default TimelineItem;
