import React from "react";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import { UserMaster } from "@/generated-api";

function ConsigneeInformation({
  userData,
}: {
  userData: UserMaster | undefined;
}) {
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-xxl-4 px-4 border-end">
          <div className="d-flex align-items-center gap-6">
            <div className="rounded-3 bg-secondary w-100px h-100px d-flex flex-center">
              <span className={"fs-4x"}>{userData?.name?.charAt(0)}</span>
            </div>

            <div className="d-flex flex-column gap-2 align-self-stretch">
              <div className="d-flex gap-1">
                {userData?.tag?.map((e) => (
                  <CommonBadge text={e.name} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xxl-4 px-4 border-end">
          <div className="vstack gap-3">
            {userData?.auto_id ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">ID</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.auto_id}
                </div>
              </div>
            ) : null}

            {userData?.employee_id ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">
                  Employee ID
                </div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.employee_id}
                </div>
              </div>
            ) : null}

            {userData?.name ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">Name</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {DecorateNameTags(userData?.name)}
                </div>
              </div>
            ) : null}

            {userData?.phone ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">Contact</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.phone}
                </div>
              </div>
            ) : null}

            {userData?.email ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">Email</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.email}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-md-6 col-xxl-4 px-4">
          <div className="vstack gap-3">
            {userData?.city ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">City</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.city}
                </div>
              </div>
            ) : null}

            {userData?.state ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">State</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.state}
                </div>
              </div>
            ) : null}

            {userData?.pincode ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">PIN Code</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.pincode}
                </div>
              </div>
            ) : null}

            {userData?.address ? (
              <div className="row">
                <div className="col-6 col-xl-2 col-xxl-3 fw-bold">Address</div>
                <div className="col-6 col-xl-10 col-xxl-9">
                  {userData?.address}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ConsigneeInformation;
