import React from "react";
import ClientUserCardSm from "../../helpers/CrmHelpers/Ui/ClientUserCardSm";
import { ToInr } from "@/Utils/Shared/CurrencyConverter.ts";

type orderProductTableProps = {
  productItems: any | null;
  subTotal: number;
  grandTotal: number;
  isSales: boolean;
};

const MAX_COLS = 5;

export default function OrderProductTable({
  productItems,
  subTotal,
  grandTotal,
  isSales,
}: orderProductTableProps): React.JSX.Element {
  return (
    <div className="table-responsive">
      <table className="table align-middle table-row-bordered table-row-gray-300 fs-6 gy-5 mb-0">
        <thead className={"bg-transparent"}>
          <tr className="text-start text-black fw-bold text-uppercase gs-0">
            <th className="col-3">Product</th>
            <th className="col-3">POC</th>
            <th className="col-1 text-end">Qty</th>
            {isSales ? null : (
              <th className="col-3 text-end">Refundable Deposit</th>
            )}
            <th className="col-2 text-end">{`${
              isSales ? "" : "Monthly"
            } Rate`}</th>
            <th className="col-2 text-end">Total</th>
          </tr>
        </thead>
        <tbody className="fw-semibold">
          {productItems?.length > 0 &&
            productItems?.map((obj, index) => {
              return (
                <tr key={`${index}`}>
                  {/* Product */}
                  <td className={""}>
                    <div className="d-flex flex-column">
                      <div className={"fw-bold"}>{obj?.item?.item ?? ""}</div>
                      <div className={"fs-7"}>{obj?.config ?? ""}</div>
                    </div>
                  </td>

                  {/* POC */}
                  <td className={""}>
                    <div className={"vstack gap-1"}>
                      <ClientUserCardSm user={obj?.consignee_details} />
                      <div className="d-flex flex-column fs-7">
                        {obj?.delivery_poc_address ? (
                          <span>{obj?.delivery_poc_address ?? ""}</span>
                        ) : null}
                        {obj?.consignee_poc_address ? (
                          <span>{obj?.consignee_poc_address ?? ""}</span>
                        ) : null}
                      </div>
                    </div>
                  </td>

                  {/* Qty */}
                  <td className={"text-end"}>
                    <div>{obj?.qty ?? ""}</div>
                  </td>

                  {/* Refundable Deposit */}
                  {isSales ? null : (
                    <td className={"text-end"}>
                      <div>{ToInr(obj?.deposit ?? 0)}</div>
                    </td>
                  )}

                  {/* Monthly Rate */}
                  <td className={"text-end"}>
                    <div>{ToInr(obj?.rate ?? 0)}</div>
                  </td>

                  {/* Total */}
                  <td className={"text-end"}>{ToInr(obj?.qty * obj?.rate)}</td>
                </tr>
              );
            })}

          <tr>
            <td
              colSpan={isSales ? MAX_COLS - 1 : MAX_COLS}
              className="text-end"
            >
              Subtotal
            </td>
            <td className="text-end">{ToInr(subTotal ?? 0)}</td>
          </tr>
          <tr>
            <td
              colSpan={isSales ? MAX_COLS - 1 : MAX_COLS}
              className="text-end"
            >
              GST (18%)
            </td>
            <td className="text-end">{ToInr(grandTotal - subTotal ?? 0)}</td>
          </tr>

          <tr>
            <td
              colSpan={isSales ? MAX_COLS - 1 : MAX_COLS}
              className="fs-3 text-black text-end"
            >
              Grand Total
            </td>
            <td className="text-black fs-3 fw-bolder text-end">
              {ToInr(grandTotal ?? 0)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
