import React, { FC } from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive, KTIcon, WithChildren } from "@metronic/helpers";
import { useLayout } from "../../../core";
import { useAuth } from "@app/modules/auth";
import { useACLHook } from "@Hooks/useACLHook.tsx";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  permission?: any;
  newTab?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  permission,
  newTab = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const { permissions: Allowed } = useAuth();
  const hasPermission = useACLHook([permission ?? ""], Allowed);

  return permission && hasPermission ? (
    <div className="menu-item">
      <Link
        className={clsx("menu-link without-sub", { active: isActive })}
        to={to}
        target={`${newTab ? "_blank" : "_self"}`}
        rel={`${newTab ? "noreferrer noopener" : ""}`}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            {" "}
            <KTIcon iconName={icon} className="fs-2" />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  ) : null;
};

export { SidebarMenuItem };
