import { CityOptions } from "@components/helpers/CustomSelectors/CitySelector.tsx";

interface City {
  id: number;
  name: string;
  state: string;
}

export const getCityName = (id: number): string | undefined => {
  const city: City | undefined = CityOptions.find((city) => city.id === id);
  return city ? city.name : "City not found";
};

export const getCities = () => {
  return CityOptions.map((item) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });
};

export const getStateName = (id: number): string | undefined => {
  const data: City | undefined = CityOptions.find((item) => item.id === id);
  return data ? data.state : "State not found";
};
