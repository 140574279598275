import { Sound_Types } from "@Constants/APP_Constants.ts";

type SoundKey = "BaseNotify" | "Buzzer" | "Delete" | "SoftAlert";

export const playSound = (key: SoundKey) => {
  const currentUserScope = localStorage.getItem("scope");
  const soundtype =
    currentUserScope === "crm" && key === "Buzzer" ? "SoftAlert" : key;
  const audio = new Audio(Sound_Types[soundtype]);
  audio.play();
};
