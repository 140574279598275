import axios from "axios";
import { buildURL } from "@/Utils/Shared/buildUrl.ts";

const API_URL = import.meta.env.VITE_APP_API_URL;

const ASSETVERIFICATION = `${API_URL}/accounts/asset/verification/`;
const ASSET_VERIFICATION_DATA = `${API_URL}/accounts/asset/verification/account/`;
const ASSET_SERIAL_DATA = `${API_URL}/accounts/public/asset/`;
const ASSET_VARIFICATION_MASTER = `${API_URL}/accounts/asset/verification/master/`;
const ASSET_VARIFICATION_SUBLIST = `${API_URL}/accounts/asset/verification/`;

export function getAssetVerificationID(token: any, id: any) {
  return axios
    .get<any>(`${ASSET_VARIFICATION_MASTER}${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAccountData(slug: any) {
  return axios.get<any>(`${ASSET_VERIFICATION_DATA}${slug}`);
}

export function getSerialData(serial: any) {
  return axios.get<any>(`${ASSET_SERIAL_DATA}${serial}`);
}

export function postAssetVerification(payload: any) {
  return axios.post<any>(`${ASSETVERIFICATION}`, payload, {});
}

export function getAssetVarificationMaster(token: any, param) {
  const url = buildURL(param, `${ASSET_VARIFICATION_MASTER}`);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// export function getClientAssetVerification(){
//     const url = buildURL(param,)
// }

export function getAssetVerificationSub(token: any, param) {
  const url = buildURL(param, `${ASSET_VARIFICATION_SUBLIST}`);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAssetVerficationById(token, id) {
  const url = `${ASSET_VARIFICATION_SUBLIST}${id}/`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postAssetVarificationMaster(token: any, payload) {
  const url = `${ASSET_VARIFICATION_MASTER}`;

  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
