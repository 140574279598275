import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "@metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getPermissionByToken, getUserByToken } from "./_requests";
import { WithChildren } from "@metronic/helpers";
import jwtDecode from "jwt-decode";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  setPermissions: any;
  permissions: any;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  setPermissions: () => {},
  permissions: undefined,
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  const AuthDetails = useContext(AuthContext);
  if (AuthDetails?.auth?.access) {
    const DecodedToken = jwtDecode(AuthDetails?.auth?.access);
    // @ts-ignore
    const expirationTime = DecodedToken?.exp;
    const expirationMoment = moment.unix(expirationTime);
    const currentTime = moment();

    if (currentTime.isBefore(expirationMoment)) {
      return AuthDetails;
    } else {
      authHelper.removeAuth();
      // eslint-disable-next-line
      location.reload();
    }
  }
  return AuthDetails;
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(
    undefined
  );
  const [permissions, setPermissions] = useState<string[] | null | undefined>(
    undefined
  );
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);

    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    localStorage.setItem("scope", "");
    setCurrentUser(undefined);
    setPermissions(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setPermissions } = useAuth();

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        const { data: userModel } = await getUserByToken(apiToken);
        const decodedToken: { scope: string; is_account_admin?: boolean } =
          jwtDecode(apiToken);
        if (userModel) {
          localStorage.setItem("scope", decodedToken?.scope);
          setCurrentUser(() => {
            localStorage.setItem("scope", decodedToken?.scope);
            return {
              ...userModel,
              scope: decodedToken?.scope,
              is_account_admin: decodedToken?.is_account_admin,
            };
          });

          // Set user to sentry
          Sentry.setUser({ id: userModel.id, username: userModel.username });
        }

        const { data: UserPermissions } = await getPermissionByToken(apiToken);
        if (UserPermissions) {
          setPermissions(UserPermissions);
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
      return () => (didRequest.current = true);
    };
    if (auth && auth.access) {
      requestUser(auth.access);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
