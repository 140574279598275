import React from "react";
import { Carousel } from "react-bootstrap";

function ProductImageCarousel({ data, height }) {
  return (
    <>
      <Carousel slide indicators={false}>
        {data?.images?.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div className="">
                <img
                  className={"w-100 object-fit-contain"}
                  src={item?.src}
                  alt={"Dummy"}
                  style={{ height: `${height}` }}
                />
              </div>
            </Carousel.Item>
          );
        })}

        {Array.from({ length: 3 }).map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div className="">
                <div
                  className={"d-flex flex-center"}
                  style={{ height: `${height}` }}
                >
                  <div className="d-flex flex-column flex-center gap-2">
                    <i
                      className="ki-duotone ki-laptop text-brand"
                      style={{ fontSize: "12rem" }}
                    >
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <p className="m-0 fs-4 text-muted">
                      Upload Product Images to view in this slideshow
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}

export default ProductImageCarousel;
