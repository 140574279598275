import axios from "axios";

const API_URL = import.meta.env.VITE_APP_API_URL;

const CREATE_TASK_API_URL = `${API_URL}/stocky/tasks/`;

const GET_CONNECT_INFO: string = `${API_URL}/leads/retrieve/`;
const GET_STOCKY_TASKS: string = `${API_URL}/stocky/tasks/`;
const ADD_TICKET_COMMENT: string = `${API_URL}/stocky/task/comments/`;
const GET_TICKET_COMMENTS: string = `${API_URL}/stocky/task/comments/`;

export function getConnectInformation(token: any, payload: string) {
  return axios
    .get<any>(`${GET_CONNECT_INFO}${payload}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error?.response;
    });
}

export function addTaskDetails(token: any, payload: any) {
  return axios.post<any>(`${CREATE_TASK_API_URL}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateTaskDetails(token: any, taskId: any, payload: any) {
  return axios.patch<any>(`${CREATE_TASK_API_URL}${taskId}/`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getStockyTaskInfo(token: any, param: any) {
  return axios.get(`${GET_STOCKY_TASKS}${param}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addTicketComment(token: any, payload: any) {
  return axios
    .post<any>(ADD_TICKET_COMMENT, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export function getComments(token: any, params: any) {
  return axios
    .get<any>(`${GET_TICKET_COMMENTS}?task_id=${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.results;
    })
    .catch((err) => {
      return err;
    });
}

export function deleteTask(token: any, params: any) {
  return axios.delete<any>(`${GET_STOCKY_TASKS}${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
