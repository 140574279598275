import { QueryClient } from "react-query";

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: "always",
        keepPreviousData: true,
        retry: false,
      },
    },
  });
}
