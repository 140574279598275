import privateInstance from "@/api/private/axios.ts";
import { RentalsApi } from "@/generated-api/server/rentals-api.ts";
import { LogisticsApi } from "@/generated-api/server/logistics-api.ts";
import { StockyApi } from "@/generated-api/server/stocky-api.ts";
import { ClientApi } from "@/generated-api/server/client-api.ts";
import { StorageApi } from "@/generated-api/server/storage-api.ts";
import { CoreApi } from "@/generated-api/server/core-api.ts";
import { OrdersApi } from "@/generated-api/server/orders-api.ts";
import { AccountsApi } from "@/generated-api/server/accounts-api.ts";
import { UsersApi } from "@/generated-api/server/users-api.ts";
import { AclApi } from "@/generated-api";

const rentalsApi = new RentalsApi(undefined, undefined, privateInstance);
const logisticsApi = new LogisticsApi(undefined, undefined, privateInstance);
const stockyApi = new StockyApi(undefined, undefined, privateInstance);
const clientApi = new ClientApi(undefined, undefined, privateInstance);
const storageApi = new StorageApi(undefined, undefined, privateInstance);
const coreApi = new CoreApi(undefined, undefined, privateInstance);
const ordersApi = new OrdersApi(undefined, undefined, privateInstance);
const accountsApi = new AccountsApi(undefined, undefined, privateInstance);
const usersApi = new UsersApi(undefined, undefined, privateInstance);
const aclApi = new AclApi(undefined, undefined, privateInstance);

export {
  rentalsApi,
  logisticsApi,
  stockyApi,
  clientApi,
  storageApi,
  coreApi,
  ordersApi,
  accountsApi,
  usersApi,
  aclApi,
};
