import { selectionStatus } from "./_models";
import { SUPPORT_REQUEST_CATEGORY } from "@Constants/CR_Constant";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

export const CLIENT_SELECTION_STATUS: {
  ASSIGNED: selectionStatus;
  NOT_ASSIGNED: selectionStatus;
  UNSELECTED: selectionStatus;
} = {
  ASSIGNED: "ASSIGNED",
  NOT_ASSIGNED: "NOT_ASSIGNED",
  UNSELECTED: "UNSELECTED",
};

export const supportRequestCategoryOptions = Object.entries(
  SUPPORT_REQUEST_CATEGORY
).map(([key, value]) => ({
  label: DecorateNameTags(key),
  value,
}));
