import React from "react";
import { Modal } from "react-bootstrap";
import { UserReferences } from "@/generated-api";
import IconLink from "@components/UI/Icons/IconLink.tsx";
import { CONSIGNEE_PATHS } from "@Constants/RoutingConstants/erp/RedirectRouteConstants.ts";
import ConsigneeReference from "@app/pages/erp/Consignee/ConsigneeHelpers/ConsigneeReference.tsx";
import ConsigneeInformation from "@app/pages/erp/Consignee/ConsigneeHelpers/ConsigneeInformation.tsx";
import { useAuth } from "@app/modules/auth";
import { CRM_MEMBERS_PATHS } from "@Constants/RoutingConstants/crm/CrmRedirectRouteConstants.ts";
import { STOCKY_PRO_SCOPES } from "@Constants/APP_Constants.ts";

type ConsigneeViewModal = {
  user: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  referencePayload: UserReferences;
};

export function ConsigneeViewModal({
  user,
  open,
  setOpen,
  referencePayload,
}: ConsigneeViewModal) {
  const { currentUser } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={handleClose} size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title>
          Consignee Information{" "}
          <a
            href={`${window.location.origin}${
              currentUser?.scope === STOCKY_PRO_SCOPES.ERP
                ? CONSIGNEE_PATHS.VIEW
                : CRM_MEMBERS_PATHS.VIEW
            }${user?.id}`}
            rel={"noreferrer noopener"}
            target={"_blank"}
          >
            <IconLink />
          </a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body onDoubleClick={(e) => e.stopPropagation()}>
        <div className="vstack gap-8">
          <ConsigneeInformation userData={user} />
          {currentUser?.scope === STOCKY_PRO_SCOPES.ERP ? (
            <ConsigneeReference
              referencePayload={referencePayload}
              open={open}
            />
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}
