import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import TaskEdit from "../../Tasks/Create/TaskEdit";
import MultiLayeredModalDialog from "@components/UI/MultiLayeredModalDialog.tsx";

const TaskCreateMOdel = ({ data }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Button
        variant=" btn-sm btn-light-brand-primary"
        onClick={handleModalOpen}
      >
        Create Internal Task
      </Button>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        size={"lg"}
        enforceFocus={false}
      >
        <MultiLayeredModalDialog>
          <Modal.Header closeButton>
            <Modal.Title>Create Internal Task</Modal.Title>
          </Modal.Header>

          <TaskEdit obj={data} closeModle={handleModalClose} />

          <Modal.Footer>
            <Button variant="light-brand-secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </MultiLayeredModalDialog>
      </Modal>
    </div>
  );
};

export default TaskCreateMOdel;
