export const crmModules = {
  ar: "ar",
  assets: "assets",
  assetVerification: "asset-verification",
  members: "members",
  logistics: "logistics",
  manageAssets: "manage-assets",
  order: "order",
  rentals: "rentals",
  supportRequest: "support-request",
  users: "users",
  onboarding: "onboarding",
  profile: "profile",
};
