import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ExtendedProductPublicView from "@app/pages/erp/products/ExtendedProductComponents/ExtendedProductPublicView.tsx";

function PublicProductListRow({ data }) {
  const [open, setOpen] = useState(false);

  function handleDoubleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <tr onDoubleClick={handleDoubleClick}>
        {/*  type  */}
        <td>
          <div className="table-cell-col">
            <p className="p-0 m-0 ">{data?.category_details?.display_name}</p>
          </div>
        </td>

        {/*  brand  */}
        <td>
          <div className="table-cell-col">
            <p className="p-0 m-0 ">{data?.brand_details?.name}</p>
          </div>
        </td>

        {/*  product  */}
        <td>
          <div className="table-cell-col">
            <p className="p-0 m-0 "> {data?.item_details?.name}</p>
            <p className="p-0 m-0 ">SI#:&nbsp;{data?.serial}</p>
          </div>
        </td>

        {/*  config  */}
        <td className="mw-250px" style={{ maxWidth: "250px" }}>
          <div className="table-cell-col">
            <div className="">{data?.computer?.cpuversion || data?.config}</div>
            {data?.computer?.ram ? (
              <div>
                {data?.computer?.ram && <span>{data?.computer?.ram} GB </span>}
                {data?.computer?.ram && data?.computer?.storage?.length > 0 ? (
                  <span>| </span>
                ) : null}
                {data?.computer?.storage?.length > 0
                  ? data?.computer?.storage?.map((item, index) => {
                      return (
                        <span key={index}>
                          {item?.hddsize}
                          {index !== data?.computer?.storage?.length - 1
                            ? ", "
                            : ""}
                        </span>
                      );
                    })
                  : null}
              </div>
            ) : null}

            {data?.computer?.graphics ? (
              <span className={"text-muted"}>
                <span className={"fw-bold"}> Graphics: </span>{" "}
                <span>{data?.computer?.graphics} </span>
              </span>
            ) : null}
          </div>
        </td>

        {/*  actions  */}
        <td></td>
      </tr>

      <Modal show={open} onHide={handleClose} size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExtendedProductPublicView data={data} height={"400px"} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PublicProductListRow;
