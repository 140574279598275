export const LogisticsProductType = {
  Laptop_ADP: "Laptop+ADP",
  Laptop: "Laptop",
  Phone: "Phone",
  Tablet: "Tablet",
  Accessory: "Accessory",
  Other: "Other",
};

export const LogisticsCostTo = {
  SPURGE: "SPURGE",
  CLIENT: "CLIENT",
  NOT_APPLICABLE: "NOT APPLICABLE",
};

export const Purpose = {
  RENTAL: "RENTAL",
  SELL: "SELL",
  SERVICE: "SERVICE",
  SAMPLE: "SAMPLE",
  LOGISTICS_SUPPORT: "LOGISTICS SUPPORT",
};
export const DeliveryType = {
  DELIVERED: "DELIVERED",
  NOT_DELIVERED: "NOT_DELIVERED",
  DELIVERY_SIGNED: "DELIVERY_SIGNED",
  DELIVERY_NOT_SIGNED: "DELIVERY_NOT_SIGNED",
  RETURNED: "RETURNED",
  CANCELLED: "CANCELLED",
  REJECTED: "REJECTED",
};
export const DeliveredStatus = {
  DELIVERED: "DELIVERED",
  RETURNED: "RETURNED",
  CANCELLED: "CANCELLED",
  REJECTED: "REJECTED",
};

export const DeliveryStatusColor = {
  DELIVERED: "success",
  RETURNED: "warning",
  CANCELLED: "danger",
  REJECTED: "danger",
};
export const LogisticsOpsType = {
  INWARDS: 1,
  OUTWARDS: 2,
  PICKUPS: 3,
};

export const LOGISTICS_OPS_TYPE_COLORS = {
  INWARDS: "success",
  OUTWARDS: "dark",
  PICKUPS: "info",
  default: "brand-secondary",
};

export const LogisticsInType = {
  RENTAL_RETURN: "RENTAL OVER",
  RENTAL_REPLACEMENT_RETURN: "RENTAL REPLACEMENT RETURN",
  SAMPLE_RETURN: "SAMPLE RETURN",
  SALES_CANCEL: "SALES CANCEL",
  RENTAL_CANCEL: "RENTAL CANCEL",
  SALES_REPLACEMENT_RETURN: "SALES REPLACEMENT RETURN",
  PURCHASE: "PURCHASE",
  RESEARCH_SELL: "RESEARCH SELL",
  RESEARCH_RENT: "RESEARCH RENT",
  RESEARCH_SELL_REPLACEMENT: "RESEARCH SELL REPLACEMENT",
  RESEARCH_RENT_REPLACEMENT: "RESEARCH RENT REPLACEMENT",
  RESEARCH_SELL_CANCEL: "RESEARCH SELL CANCEL",
  RESEARCH_RENT_CANCEL: "RESEARCH RENT CANCEL",
  FROM_STAFF: "FROM STAFF",
  LOGISTICS: "LOGISTICS",
  RENTAL_SERVICE: "RENTAL SERVICE",
  SALES_SERVICE: "SALES SERVICE",
  NON_SPURGE_ASSET_SERVICE: "NON SPURGE ASSET SERVICE",
  PURCHASE_REPAIR_RETURN: "PURCHASE REPAIR RETURN",
  THIRD_PARTY_SERVICE_RETURN: "THIRD PARTY SERVICE RETURN",
  MISSING_FOUND: "MISSING FOUND",
  REPROGRAM: "REPROGRAM",
};

export const LogisticsOutType = {
  RENT: "RENT",
  SELL: "SELL",
  RESEARCH_SELL: "RESEARCH SELL",
  RESEARCH_RENT: "RESEARCH RENT",
  RESEARCH_SELL_REPLACEMENT: "RESEARCH SELL REPLACEMENT",
  RESEARCH_RENT_REPLACEMENT: "RESEARCH RENT REPLACEMENT",
  RESEARCH_SELL_CANCEL: "RESEARCH SELL CANCEL",
  RESEARCH_RENT_CANCEL: "RESEARCH RENT CANCEL",
  SAMPLE: "SAMPLE",
  STAFF_USE: "STAFF USE",
  RENT_REPLACEMENT: "RENT REPLACEMENT",
  SELL_REPLACEMENT: "SELL REPLACEMENT",
  PURCHASE_REPAIR: "PURCHASE REPAIR",
  PURCHASE_CANCEL: "PURCHASE CANCEL",
  LOGISTIC: "LOGISTIC",
  RENTAL_SERVICE: "RENTAL SERVICE",
  SALES_SERVICE: "SALES SERVICE",
  NON_SPURGE_ASSET_SERVICE: "NON SPURGE ASSET SERVICE",
  THIRD_PARTY_SERVICE: "THIRD PARTY SERVICE",
  SPURGE_SERVICE: "SPURGE SERVICE",
  LOGISTICS: "LOGISTICS",
  MISSING: "MISSING_UPDATE",
};

export const LogisticPartners = {
  PROF_COURIER: "PROF_COURIER",
  TIRUPATI: "TIRUPATI",
  DTDC: "DTDC",
  UBER: "UBER",
  DUNZO: "DUNZO",
  PORTER: "PORTER",
  DELHIVERY: "DELHIVERY",
  AIRWAYS: "AIRWAYS",
  SPURGE_EXECUTIVE: "SPURGE_EXECUTIVE",
  OTHER: "OTHER",
};

export const logisticsPickupAttemptStatuses = {
  CANCELLED: 0,
  CREATED: 1,
  RECEIVED: 2,
};
export const logisticsPickupAttemptStatusesColor = {
  CANCELLED: "danger",
  CREATED: "primary",
  RECEIVED: "success",
};
export const LogisticPhone = "6361 509 286";

export const DC_LIST_STATUS_COLORS = {
  CREATED: "success",
  LOCKED: "dark",
  CANCELLED: "danger",
  default: "warning",
};

export const TRACKING_STATUSES = {
  IN_TRANSIT: "In Transit",
  DELIVERED: "Delivered",
};
