import React from "react";

type CommonPageTextHeaderProps = {
  heading: string;
};

function CommonPageTextHeader({
  heading,
}: CommonPageTextHeaderProps): React.JSX.Element {
  return (
    <div className="position-relative mb-3 pb-3 border-bottom border-gray-300">
      <h1 className="m-0">{heading}</h1>
    </div>
  );
}

export default CommonPageTextHeader;
