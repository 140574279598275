import React from "react";

export type RouteFields = {
  path: string;
  title: string;
  permissions: string | string[];
  element: React.ReactElement;
  redirectPath?: string;
};

export type ModuleRouteObject = Record<string, RouteFields>;

export function createRoutesObject<T extends ModuleRouteObject>(routes: T): T {
  return routes;
}

export type RedirectPathObject = Record<string, string>;

export function createRedirectObject<T extends RedirectPathObject>(
  routes: T
): T {
  return routes;
}
