import axios from "axios";
import { LOCK_KEY } from "@/api/private/axios.ts";

const basePath = import.meta.env.VITE_APP_API_URL;

const publicInstance = axios.create({ baseURL: basePath });

publicInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 429) {
      localStorage.setItem(
        LOCK_KEY,
        JSON.stringify(error.response.data?.available_in_seconds ?? 0)
      );
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export default publicInstance;
