import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

type countersObject = {
  [key: string]: number;
};

export type quickLinkCardPropsType = {
  iconColor?: string;
  icon: ReactElement;
  text: string;
  link: string;
  counters?: countersObject;
  counterIconArray?: ReactElement[];
};

const quickLinkCardDefaultProps: quickLinkCardPropsType = {
  iconColor: "text-gray-800",
  icon: (
    <i
      className={`bi bi-lightning-charge-fill p-0 fs-5x`}
      style={{ color: "inherit" }}
    ></i>
  ),
  text: "Quick link",
  link: "",
};

export default function QuickLinkCard({
  iconColor,
  icon,
  text,
  link,
  counters,
  counterIconArray,
}: quickLinkCardPropsType): React.JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  // Methods
  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  return (
    <Link
      to={`${link}`}
      className={""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="card h-100 rounded-3 text-dark overflow-hidden border-0"
        style={{
          boxShadow: `${
            isHovered
              ? "0 4px 12px 0px rgba(0, 0, 0, 0.2)"
              : "0px 4px 12px 10px rgba(0, 0, 0, 0.03)"
          }`,
          transition: "box-shadow 0.3s ease",
        }}
      >
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div
            className={`${
              isHovered ? "text-brand-primary" : iconColor
            } position-absolute end-0 bottom-0 p-2`}
            style={{
              transition: "color 0.3s ease",
            }}
          >
            {icon}
          </div>

          <div className="d-flex flex-column mb-10">
            <span className="fw-semibold fs-1 text-gray-800 pe-2 ls-n1">
              {text}
            </span>
          </div>

          <div className="hstack gap-4 mt-auto">
            {counters
              ? Object.entries(counters).map(([key, value], index) => {
                  return (
                    <div
                      className={`badge badge-light-dark fs-2`}
                      key={`${key}-${index}`}
                    >
                      <span className={"me-1"}>
                        {counterIconArray?.[index]}
                      </span>
                      <CountUp
                        end={value}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={200}
                        className={""}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </Link>
  );
}

QuickLinkCard.defaultProps = quickLinkCardDefaultProps;
