import React from "react";
import Logistics from "@metronic/assets/lottie-assets/Logistics.json";
import LazyLottie from "@components/helpers/CustomAlerts/Lottie/LazyLottie.tsx";

function LottieLogistics() {
  return (
    <>
      <LazyLottie animationData={Logistics} maxWidth={"25rem"} />
    </>
  );
}

export default LottieLogistics;
