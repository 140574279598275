import React from 'react'
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const HorizonatlTimelIne = ({items}) => {
   // const items= [
   //     { label: 'Item 1' , color :'primary' },
   //     { label: 'Item 2',color :'success' },
   //     { label: 'Item 3' }];

    const timelineStyles = {
        container: {
            display: 'flex',
            flexDirection: 'row', // Vertical layout
            alignItems: 'center',

        },
        itemContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // alignItems: 'center',
            // marginBottom: '1.5rem',
        },
            line: {
                // position: 'relative',
                 // Adjust line width
                // height: '3px',
                // backgroundColor: '',
                // margin: '0',
            },


            textContainer: {
                backgroundColor: '#fff',
                padding: "2px", // Make the background behind the text transparent
            },
        radioButton: {
            margin: '0',
        },
        label: {
            textAlign: 'center',
        },
    };
    return (
        // @ts-ignore
        <div style={timelineStyles.container}>
            {items.map((item, index) => (
                <div key={index} style={{...timelineStyles.itemContainer, display:"flex"}}>
                    {/* <input type="radio" name="timeline" style={timelineStyles.radioButton} /> */}
                    <OverlayTrigger
                        placement="top"

                        overlay={
                            <Tooltip id={`tooltip-${index}   `} className='small'>
                                {item.ToolTip ? item.ToolTip : ''}
                            </Tooltip>
                        }
                    >

                        <i className={`fa fa-genderless text-${item?.color ??"warning"} fs-3  `} id={`timeline-radio-${index}`}></i>

                    </OverlayTrigger>
                    {/*@ts-ignore*/}
                    {item?.label && <div style={{...timelineStyles.line, display: 'flex', justifyContent: 'center', alignItems: 'center' , }}>

                        {/*{item?.label}*/}
                        <div className='bg-secondary' style={{width:`${10}px` ,backgroundColor: '#ccc', height:'3px'}}></div>
                        { item?.label && <div className='small bg-transparent px-1' >
                            {item?.label}
                        </div>}
                        <div className='bg-secondary' style={{width:`${10}px` ,backgroundColor: '#ccc', height:'3px'}}></div>

                    </div>}



                </div>
            ))}
        </div>
    )
}
export default HorizonatlTimelIne
