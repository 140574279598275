import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "@metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import { ThemeModeProvider } from "@metronic/partials";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AppLock from "@components/helpers/AppLock/AppLock.tsx";

const App = () => {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <ToastContainer />

                <AppLock>
                  <Outlet />
                </AppLock>

                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  );
};

export { App };
