import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import BackgroundWrapper from "@components/UI/Wrappers/BackgroundWrapper.tsx";

const ReAssignForm = () => {
  // Add appropriate 404 and 500

  return (
    <BackgroundWrapper>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 offset-3">
            <div className="card shadow">
              <div className="card-header ">
                <h2 className="card-title my-0 fs-1">Assign/Reassign Asset</h2>
              </div>
              <div className="card-body p-6">
                <form>
                  <div className="row mb-6">
                    <div className="col">
                      <label className="form-label" htmlFor="serail">
                        Serail :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="serail"
                        id="serail"
                      />
                    </div>
                    <div className="col">
                      <label className="form-label" htmlFor="product">
                        Product :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="product"
                        disabled={true}
                        id="serail"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col">
                      <label className="form-label" htmlFor="consignee_name">
                        Consignee Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="consignee_name"
                        id="consignee_name"
                      />
                    </div>
                    <div className="col">
                      <label className="form-label" htmlFor="consignee_email">
                        Consignee Email:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="consignee_email"
                        id="consignee_email"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-6">
                      <label className="form-label" htmlFor="consignee_number">
                        Consignee Number:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="consignee_number"
                        id="consignee_number"
                      />
                    </div>
                  </div>
                  <div className="row mb-6 ">
                    <div className="col-6">
                      <label className="form-label">&nbsp;</label>
                      <ReCAPTCHA
                        sitekey={import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY}
                      />
                    </div>
                    <div className="col text-end align-self-end">
                      <button className="btn btn-success">Submit</button>
                    </div>
                  </div>
                  <div className="row"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundWrapper>
  );
};
export default ReAssignForm;
