import React from "react";
import { AsyncUserSelector } from "@components/helpers/CustomSelectors/AsyncSelectors/Selector's";
import { format } from "date-fns";
import Flatpickr from "react-flatpickr";
import { useAuth } from "@app/modules/auth";
import { toast } from "react-toastify";
import { addTaskDetails } from "./_requests";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQueryClient } from "react-query";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import { playSound } from "@components/helpers/Sounds/PlaySound.ts";
import QuillTextEditor from "@components/helpers/ReactQuill/QuillTextEditor.tsx";

const TaskEdit = ({ obj, closeModle }) => {
  const { auth } = useAuth();

  const queryClient = useQueryClient();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitForm,
    handleBlur,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      assigned: "",
      client_request: obj?.id,
      deadline: "",
      description: null,
      priority: obj?.priority,
      contact: obj?.contact,
      serial_number: obj?.serial_number ?? "",
      title: obj?.title || "",
      connect: obj?.connect_info?.id,
    },
    validationSchema: yup.object().shape({
      assigned: yup.string().required("Assigned is required"),
      client_request: yup.string().required("Client is required"),
      deadline: yup.string().required("Deadline is required"),
      description: yup.string().nullable(),
      priority: yup.string().required("Priority is required"),
      serial_number: yup
        .string()
        .required("At least one string in the array is required"),
      title: yup.string().required("Title is required"),
      connect: yup.string().nullable("Connect"),
    }),
    onSubmit: (values, action) => {
      addTaskDetails(auth?.access, values)
        .then(() => {
          action.resetForm();
          toast.success("task created successfully");
          playSound("BaseNotify");
          queryClient.invalidateQueries("taskDetaisl");
          closeModle();
          // navigate(`/erp/tasks/${res.id}`);
        })
        .catch(() => {
          toast.error("Error occoured while creating task.try again!", {
            autoClose: 1000,
          });
          playSound("Buzzer");
        });
    },
  });

  return (
    <div className="modal-body">
      <div className="row mb-6">
        <div className="col-6">
          <label className="form-label"></label>
          <div className=" d-flex align-items-center">
            <div className="flex-shrink-0">
              <div className="btn btn-icon btn-brand-primary">
                <i className="bi bi-hand-thumbs-up-fill fs-2"></i>
              </div>
            </div>
            <div className="flex-grow-1 ms-3 ">
              <span className="fw-bold fs-4 d-block">
                {obj?.connect_info?.name ?? ""}
              </span>
              <span>{obj?.connect_info?.slug ?? ""}</span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <label className="form-label fw-bold">Title</label>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Title"
            name="title"
            value={values?.title}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors?.title && touched?.title && (
            <p className="text-danger">{errors?.title as string}</p>
          )}
        </div>
      </div>

      <div className="row gy-6 mb-6">
        <div className="col-6">
          <label className="form-label fw-bold">Assign</label>
          <AsyncUserSelector
            setterFunction={(val) => {
              setFieldValue("assigned", val);
            }}
          />
          {errors?.assigned && touched?.assigned ? (
            <p className="text-danger">{errors?.assigned}</p>
          ) : null}
        </div>
        <div className="col-6 mb-3">
          <label className="form-label fw-bold">Priority</label>
          <RadioButtons
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            defaultValue={`${obj?.priority}`}
          />
        </div>
        <div className="col-6">
          <label className="form-label fw-bold">Deadline</label>
          <Flatpickr
            className="form-control form-control-sm"
            placeholder="Deadline Date"
            onChange={([val]) => {
              setFieldValue("deadline", format(new Date(val), "yyyy-MM-dd"));
            }}
            value={values.deadline}
          />
          {errors?.deadline && touched?.deadline ? (
            <p className="text-danger">{errors?.deadline}</p>
          ) : null}
        </div>
        <div className="col-6">
          <label className="form-label fw-bold">Serial Number</label>

          <input
            type="text"
            name="serial_number"
            className="form-control form-control-sm"
            aria-label="serial_number"
            value={values?.serial_number}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          {errors.serial_number && touched.serial_number ? (
            <p className="text-danger">
              {JSON.stringify(errors.serial_number)}
            </p>
          ) : null}
        </div>
      </div>

      <div className="row mb-6">
        <div className="col-12">
          <label className="form-label fw-bold">Description</label>
          <div>
            <QuillTextEditor
              setterFunction={(val: string) =>
                setFieldValue("description", val)
              }
              value={values.description}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <SubmitButton
          type="button"
          onClick={submitForm}
          disabled={isSubmitting}
          loading={isSubmitting}
          icon={<i className="bi bi-plus fs-3 p-0 color-inherit"></i>}
        />
      </div>
    </div>
  );
};
export default TaskEdit;

function RadioButtons({ setFieldValue, handleBlur, defaultValue }) {
  return (
    <div className="d-flex gap-10">
      <div className="form-check form-check-custom form-check-solid form-check-sm form-check-brand-primary">
        <input
          className="form-check-input"
          type="radio"
          value="1"
          id="highest"
          name="priority"
          onChange={(e) => setFieldValue("priority", e.target.value)}
          onBlur={handleBlur}
          defaultChecked={defaultValue === "1"}
        />
        <label className="form-check-label text-dark" htmlFor="highest">
          Highest
        </label>
      </div>
      <div className="form-check form-check-custom form-check-solid form-check-sm form-check-brand-primary">
        <input
          className="form-check-input"
          type="radio"
          value="2"
          id="high"
          name="priority"
          onChange={(e) => setFieldValue("priority", e.target.value)}
          onBlur={handleBlur}
          defaultChecked={defaultValue === "2"}
        />
        <label className="form-check-label text-dark" htmlFor="high">
          High
        </label>
      </div>
      <div className="form-check form-check-custom form-check-solid form-check-sm form-check-brand-primary">
        <input
          className="form-check-input"
          type="radio"
          value="3"
          id="medium"
          name="priority"
          onChange={(e) => setFieldValue("priority", e.target.value)}
          onBlur={handleBlur}
          defaultChecked={defaultValue === "3"}
        />
        <label className="form-check-label text-dark" htmlFor="medium">
          Medium
        </label>
      </div>
      <div className="form-check form-check-custom form-check-solid form-check-sm form-check-brand-primary">
        <input
          className="form-check-input"
          type="radio"
          value="4"
          id="low"
          name="priority"
          onChange={(e) => setFieldValue("priority", e.target.value)}
          onBlur={handleBlur}
          defaultChecked={defaultValue === "4"}
        />
        <label className="form-check-label text-dark" htmlFor="low">
          Low
        </label>
      </div>
    </div>
  );
}
