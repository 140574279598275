import React from "react";
import MetricsCard, {
  ItemProps,
} from "../../../../../components/UI/Cards/MetricsCard";
import ProgressCard from "../../../../../components/UI/Cards/ProgressCard";
import {
  LOGISTICS_PATHS,
  OPERATIONS_PATHS,
  ORDER_PATHS,
  SUPPORT_REQUEST_PATHS,
} from "@Constants/RoutingConstants/erp/RedirectRouteConstants.ts";
import MetricsCardSkeleton from "@components/helpers/Skeletons/SkeletonsByPage/MetricsCardSkeleton.tsx";

function MetricsRow({ data, loading }): React.JSX.Element {
  const opsItems: ItemProps[] = [
    {
      mainText: data?.ops?.total_in_out?.totals?.total_in ?? 0,
      subText: "IN",
      itemIcon: <i className="bi bi-caret-up-fill fs-3"></i>,
    },
    {
      mainText: data?.ops?.total_in_out?.totals?.total_out ?? 0,
      subText: "OUT",
      itemIcon: <i className="bi bi-caret-right-fill fs-3"></i>,
    },
  ];

  const logisticsItems: ItemProps[] = [
    {
      mainText: data?.logistics?.metrics?.totals?.total_in ?? 0,
      subText: "IN",
      itemIcon: <i className="bi bi-caret-up-fill fs-3"></i>,
    },
    {
      mainText: data?.logistics?.metrics?.totals?.total_out ?? 0,
      subText: "OUT",
      itemIcon: <i className="bi bi-caret-right-fill fs-3"></i>,
    },
    {
      mainText: data?.logistics?.metrics?.totals?.pickup ?? 0,
      subText: "PICKUP",
      itemIcon: <i className="bi bi-caret-left-fill fs-3"></i>,
    },
  ];

  const orderItems: ItemProps[] = [
    {
      mainText: data?.order?.order_stage?.[0]?.count ?? 0,
      itemIcon: <i className="bi bi-bullseye fs-3 text-primary"></i>,
    },
    {
      mainText: data?.order?.order_stage?.[1]?.count ?? 0,
      itemIcon: <i className="fa fa-flag-checkered fs-2 text-dark-50"></i>,
    },
    {
      mainText: data?.order?.order_stage?.[2]?.count ?? 0,
      itemIcon: <i className="bi bi-check-circle-fill fs-2 text-success"></i>,
    },
  ];

  const supportItems: ItemProps[] = [
    {
      mainText: data?.client_request?.today?.status_list?.DONE ?? 0,
      itemIcon: (
        <i className="ki-duotone ki-double-check-circle fs-2x text-success">
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
        </i>
      ),
    },
    {
      mainText: data?.client_request?.today?.total ?? 0,
      itemIcon: (
        <i className="ki-duotone ki-entrance-left fs-2x text-primary">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      ),
    },
  ];

  return (
    <>
      <div className="row gy-5 gy-xxl-10 gx-xl-4 mb-8">
        {/* Operations */}
        <div className={"col-sm-6 col-xxl-3"}>
          {loading ? (
            <MetricsCardSkeleton />
          ) : (
            <MetricsCard
              heading={"Operations"}
              itemsArray={opsItems}
              extraHeading={
                data?.ops?.replacement_asset?.replacement[0]?.status ?? ""
              }
              extraValue={
                data?.ops?.replacement_asset?.replacement[0]?.count ?? 0
              }
              icon={
                <i
                  className="ki-duotone ki-gear fs-7x text-primary position-absolute"
                  style={{ right: -20, bottom: -20 }}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              }
              link={`${OPERATIONS_PATHS.LIST}`}
              linkText={"View Operations"}
            />
          )}
        </div>

        {/* Logistics */}
        <div className={"col-sm-6 col-xxl-3"}>
          {loading ? (
            <MetricsCardSkeleton />
          ) : (
            <MetricsCard
              heading={"Logistics"}
              itemsArray={logisticsItems}
              extraHeading={
                data?.logistics?.replacement_asset?.replacement[0]?.status ?? ""
              }
              extraValue={
                data?.logistics?.replacement_asset?.replacement[0]?.count ?? 0
              }
              icon={
                <i
                  className="ki-duotone ki-truck fs-7x text-warning position-absolute"
                  style={{ transform: "scaleX(-1)", right: -10, bottom: -20 }}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
              }
              link={`${LOGISTICS_PATHS.LIST}`}
              linkText={"View Logistics"}
            />
          )}
        </div>

        {/* Orders  */}
        <div className={"col-sm-6 col-xxl-3"}>
          {loading ? (
            <MetricsCardSkeleton />
          ) : (
            <ProgressCard
              icon={
                <i
                  className="ki-duotone ki-purchase fs-7x text-gray-800 position-absolute"
                  style={{ right: -18, bottom: -10 }}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              }
              heading={"Orders"}
              subText={""}
              itemsArray={orderItems}
              progressHeading={"Completion"}
              link={`${ORDER_PATHS.LIST}`}
              linkText={"View Orders"}
              progress={
                data?.order?.order_stage?.[2]?.count
                  ? (data?.order?.order_stage?.[0]?.count ??
                      0 / data?.order?.order_stage?.[2]?.count) * 100
                  : 0
              }
            />
          )}
        </div>

        {/* Support Requests */}
        <div className={"col-sm-6 col-xxl-3"}>
          {loading ? (
            <MetricsCardSkeleton />
          ) : (
            <ProgressCard
              icon={
                <i
                  className="ki-duotone ki-question fs-7x text-info position-absolute"
                  style={{ right: -18, bottom: -18 }}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              }
              heading={"Support Requests"}
              subText={""}
              itemsArray={supportItems}
              progressHeading={"Completion"}
              progressBg={"success"}
              progress={
                data?.client_request?.today
                  ? isNaN(
                      (data?.client_request?.today?.status_list?.DONE ?? 0) /
                        (data?.client_request?.today?.total ?? 0)
                    )
                    ? 0
                    : ((data?.client_request?.today?.status_list?.DONE ?? 0) /
                        (data?.client_request?.today?.total ?? 0)) *
                      100
                  : 0
              }
              link={`${SUPPORT_REQUEST_PATHS.LIST}`}
              linkText={"View Support Requests"}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MetricsRow;
