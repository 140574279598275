import React, { ReactElement } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

export type ItemProps = {
  mainText?: string | number | null;
  subText?: string | number | null;
  itemIcon?: ReactElement;
};

type MetricsCardProps = {
  icon: ReactElement;
  heading: string | null;
  itemsArray: ItemProps[] | null;
  linkIcon: ReactElement;
  linkText: string | null;
  link: string | null;
  extraHeading?: string | number | null;
  extraValue?: string | number | null;
};

function MetricsCard({
  icon,
  heading,
  itemsArray,
  linkIcon,
  linkText,
  link,
  extraHeading,
  extraValue,
}: MetricsCardProps): React.JSX.Element {
  return (
    <>
      <div
        className="card shadow-xs h-100 bgi-no-repeat bgi-size-contain bgi-position-x-end overflow-hidden"
        style={{ minHeight: "307px" }}
      >
        <div className="card-body d-flex align-items-start flex-column">
          <div className="mb-4 d-flex align-items-center gap-5">
            <span
              className={"m-0 fw-semibold fs-2x text-gray-800 text-truncate"}
            >
              {heading}
            </span>
          </div>

          <div className={"d-flex flex-wrap gap-10"}>
            {itemsArray?.map(({ mainText, subText, itemIcon }, index) => {
              return (
                <div
                  className="d-flex flex-column my-5"
                  key={`${mainText}-${subText}-${index}`}
                >
                  {typeof mainText === "number" ? (
                    <div className={"d-flex align-items-center gap-2"}>
                      {itemIcon}
                      <CountUp
                        end={mainText}
                        enableScrollSpy
                        scrollSpyDelay={500}
                        scrollSpyOnce
                        className={"fw-semibold fs-3x text-gray-800 lh-1 ls-n2"}
                      />
                    </div>
                  ) : (
                    <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                      {DecorateNameTags(mainText ?? "")}
                    </span>
                  )}

                  <div className="m-0">
                    <div className="fw-semibold fs-6 text-gray-500">
                      {DecorateNameTags(subText ?? "")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="my-5">
            <span className={"fw-semibold fs-1 text-gray-800"}>
              {DecorateNameTags(extraHeading ?? "")} :{" "}
            </span>
            {typeof extraValue === "number" ? (
              <CountUp
                end={extraValue}
                enableScrollSpy
                scrollSpyDelay={500}
                scrollSpyOnce
                className={"fw-semibold fs-1 text-gray-800"}
              />
            ) : (
              <span className={"fw-semibold fs-1 text-gray-800"}>
                {DecorateNameTags(extraValue ?? "")}
              </span>
            )}
          </div>

          {link ? (
            <div className={"mt-auto"}>
              <Link
                to={link}
                className="btn btn-link btn-color-gray-500 btn-active-color-primary d-flex align-items-center gap-1 fs-base hover-elevate-up"
              >
                {linkText}
                {linkIcon}
              </Link>
            </div>
          ) : null}

          {icon}
        </div>
      </div>
    </>
  );
}

export default MetricsCard;

MetricsCard.defaultProps = {
  icon: (
    <i className="ki-duotone ki-compass fs-2hx text-gray-600">
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  ),
  heading: "Heading",
  itemsArray: [
    { mainText: 327, subText: "Projects" },
    { mainText: 327, subText: "Projects" },
    { mainText: 327, subText: "Projects" },
  ],
  linkIcon: (
    <i
      className="ki-duotone ki-arrow-up-right fs-3"
      style={{ color: "inherit" }}
    >
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  ),
  linkText: "View",
  link: null,
};
