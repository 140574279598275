const FINAL_YEAR = 2020;

type YearOption = {
  label: string;
  value: number;
};
export function getReportYearOptions() {
  let currentYear = new Date().getFullYear();
  let yearOptions: YearOption[] = [];

  while (currentYear >= FINAL_YEAR) {
    yearOptions.push({
      label: `${currentYear}`,
      value: currentYear,
    });
    currentYear -= 1;
  }

  return yearOptions;
}
