import React, { useState } from "react";
import { TickiteParams } from "./_models";
import { useQuery } from "react-query";
import { getTickitByCaptcha } from "./_request";
import { useParams } from "react-router-dom";
import CompanyImage from "@components/helpers/CompalyImage/CompanyImage";
import { Spinner, Table } from "react-bootstrap";
import TicketeRow from "./TickiteHelperComponent/Ticketerow";
import Select from "react-select";
import { Department, TaskStatus } from "@Constants/CR_Constant";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import DateRangePicker from "@components/helpers/DateRangePicker/DateRangePicker.tsx";
import Pagination from "@components/helpers/Pagination";

function TickitePublicList() {
  const [urlparma, setUrlparma] = useState<TickiteParams>({
    limit: undefined,
    offset: undefined,
    status: undefined,
    department: undefined,
    connect: undefined,
    client: undefined,
    slug: undefined,
    start_date: undefined,
    end_date: undefined,
    status_exclude: undefined,
  });
  // const quaryParam= useParams()
  const { slug: slugFromParams } = useParams<{ slug: string }>();
  const { isLoading: isTickiteLodaing, data: tickiteDetails } = useQuery(
    ["tickiteDetatils", slugFromParams, urlparma],
    () => getTickitByCaptcha({ ...urlparma, slug: slugFromParams })
  );
  UseErrorRedirection(tickiteDetails);
  const options = Object.keys(Department).map((key) => ({
    value: Department[key],
    label: key,
  }));

  const handleChange = (selectedOption) => {
    // setSelectedDepartment(selectedOption.value);
    setUrlparma((prev) => {
      return {
        ...prev,
        department: selectedOption ? selectedOption.value : null,
      };
    });
  };
  const taskstatusOptions = Object.keys(TaskStatus).map((key) => ({
    value: TaskStatus[key],
    label: key,
  }));
  const handleStatusChange = (selectedOption) => {
    setUrlparma((prev) => {
      return { ...prev, status: selectedOption ? selectedOption.value : null };
    });
  };

  const stagesArray = Object.entries(TaskStatus).map(([label, value]) => ({
    label,
    value,
  }));

  return (
    <div className="container">
      <div className="bd-content">
        <div className="row">
          <div className="col">
            <div className={"card"}>
              <div className="card-header">
                <div className="card-title">
                  <CompanyImage id={2} width={40} alt="SPURGE LOGO" /> SPURGE
                  SUPPORT REQUESTS
                </div>
              </div>
              <div className="card-body">
                <div className={"row"}>
                  <div className={"col-4"}>
                    <label className="col-form-label fw-bold">Department</label>
                    <Select
                      id="departmentSelector"
                      options={options}
                      onChange={handleChange}
                      value={options.find(
                        (option) => option.value === urlparma?.department
                      )}
                      isClearable
                    />
                  </div>
                  <div className={"col-4"}>
                    <label className="col-form-label fw-bold">Staus</label>
                    <Select
                      id="stausSelector"
                      options={taskstatusOptions}
                      onChange={handleStatusChange}
                      value={taskstatusOptions.find(
                        (option) => option.value === urlparma?.status
                      )}
                      isClearable
                    />
                  </div>
                  <div className="col-4">
                    <label className=" col-form-label fw-bold ">
                      Date Time
                    </label>
                    <DateRangePicker setUrlParams={setUrlparma} />
                  </div>
                  <div className="col-4">
                    <label className="col-form-label"> Exclude Status</label>
                    <Select
                      options={stagesArray}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "43px",
                        }),
                      }}
                      isMulti={true}
                      onChange={(val) => {
                        const selectedValues = val.map(
                          (option: any) => option.value
                        );
                        setUrlparma((prev: any) => {
                          return {
                            ...prev,
                            status_exclude: selectedValues.join(","),
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12  mt-5">
                    <div className="d-flex">
                      <Pagination
                        totalCount={tickiteDetails?.data?.count}
                        updateState={setUrlparma}
                        urlParam={urlparma}
                      />
                      <div className="btn btn-sm btn-outline border-0">
                        Visible : {tickiteDetails?.data?.results?.length}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                {!isTickiteLodaing ? (
                  <Table responsive bordered striped>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Title</th>
                        <th>Connect</th>
                        <th>Date</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickiteDetails?.data?.results?.map((tickite) => (
                        <TicketeRow data={tickite} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="d-flex justify-content-center align-items-center p-10">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TickitePublicList;
