import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Popover } from "react-bootstrap";
import { UpdatedsupportDetails, WhatsAppLink } from "./_constants";
import useToggle from "@Hooks/useToggle.tsx";

type SupportProps = {
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
  positionHeader?: boolean;
  domain?: string;
};

export default function SupportPopover({
  placement = "top",
  positionHeader = false,
  domain = "spurge.rentals",
}: SupportProps) {
  const { isOn, toggle, setToggleOff } = useToggle();

  function handleClick() {
    toggle();
  }

  return (
    <>
      <div
        className={`${
          positionHeader ? "" : "position-fixed"
        } d-flex flex-center pulse pulse-light`}
        style={{
          bottom: `${positionHeader ? "" : "2rem"}`,
          right: `${positionHeader ? "" : "2rem"}`,
        }}
      >
        <div className="">
          <OverlayTrigger
            rootClose={true}
            onToggle={setToggleOff}
            trigger={"click"}
            placement={placement}
            overlay={
              <Popover
                id="support_popover"
                className={"rounded-0"}
                style={{ maxWidth: "fit-content" }}
              >
                <Popover.Body>
                  <SupportTable domain={domain} />
                  {/*<div className="card">*/}
                  {/*  <div className="card-body">*/}
                  {/*    <div className="d-flex align-items-center">*/}
                  {/*      <div className="flex-shrink-0">*/}
                  {/*        <i className="ki-duotone ki-whatsapp fs-2">*/}
                  {/*          <span className="path1"></span>*/}
                  {/*          <span className="path2"></span>*/}
                  {/*        </i>*/}
                  {/*      </div>*/}
                  {/*      <div className="flex-grow-1 ms-3">*/}
                  {/*        This is some content from a media component. You can*/}
                  {/*        replace this with any content and adjust it as needed.*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type={"button"}
              className={`btn btn-icon rounded-circle ${isOn ? "" : ""} `}
              onClick={handleClick}
              style={{
                backgroundColor: `${
                  isOn ? "var(--bs-secondary)" : "var(--bs-gray-800)"
                }`,
                color: `${isOn ? "var(--bs-dark)" : "var(--bs-light)"}`,
              }}
            >
              <i
                className="bi bi-person-raised-hand fs-1"
                style={{ color: "inherit" }}
              ></i>
              <span className="pulse-ring"></span>
            </button>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
}

function SupportTable({ domain }) {
  return (
    <>
      <SupportCard />
    </>
  );
}

export function SupportCard() {
  return (
    <div className="d-flex  gap-2  justify-content-evenly flex-column">
      <SupportSubCardComponent
        textLines={UpdatedsupportDetails?.Number}
        icon={
          <i
            className="ki-duotone ki-whatsapp text-brnad-primary "
            style={{ fontSize: "3rem" }}
          >
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        }
        isClickable={true}
      />
      <SupportSubCardComponent
        textLines={UpdatedsupportDetails?.Email}
        icon={
          <i className="ki-duotone ki-sms   " style={{ fontSize: "3rem" }}>
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        }
      />
      <SupportSubCardComponent
        textLines={UpdatedsupportDetails?.Number}
        icon={
          <i
            className="bi bi-telephone-fill"
            style={{ fontSize: "2.5rem" }}
          ></i>
        }
      />
    </div>
  );
}

const SupportSubCardComponent = ({ icon, textLines, isClickable = false }) => {
  return (
    <div className="d-flex align-items-center p-3 border rounded">
      <div className="me-3">{icon}</div>
      <div>
        {textLines.map((line, index) =>
          !isClickable ? (
            <p key={index} className="mb-1">
              {line}
            </p>
          ) : (
            <a
              key={index}
              href={`${WhatsAppLink}${line.replace(/\D/g, "")}`}
              className="mb-1 d-block"
              rel="nofollow"
              target="_blank"
            >
              {line}
            </a>
          )
        )}
      </div>
    </div>
  );
};

// export  SupportSubCardComponent;
