import React from "react";

type SeparatorYProps = {
  borderColor: string;
  borderType: "dashed" | "dotted" | null;
};

export default function SeparatorY({
  borderColor,
  borderType,
}: SeparatorYProps): React.JSX.Element {
  return (
    <div
      className={`border-start${
        borderType ? `-${borderType}` : ""
      } h-100 border-${borderColor} `}
      style={{ content: " " }}
    ></div>
  );
}

SeparatorY.defaultProps = {
  borderColor: "gray-300",
  borderType: null,
};
