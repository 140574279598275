import ReactGA from "react-ga4";

export function initializeGoogleAnalytics() {
  if (import.meta.env.VITE_APP_SITE_TYPE === "production") {
    ReactGA.initialize(`${import.meta.env.VITE_APP_GA_MEASUREMENT_ID}`);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }
}
