import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getTickitBytaskid } from "../_request";
import { getComments } from "../_request";
import { timeAgo } from "@/Utils/Shared/DateStringToDays.ts";
import CommentModel from "./CommentModel";
import CommonPageHeader from "@components/helpers/CommonPageHeader/CommonPageHeader";
import {
  CrComponetID,
  StageUI,
} from "../../Client_requests/ticket_helper_comp/UITickite";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import CardHeaderSkeleton from "@components/helpers/Skeletons/CardHeaderSkeleton.tsx";
import ListSkeleton from "@components/helpers/Skeletons/ListSkeleton.tsx";
import ProfileImage from "@components/helpers/ProfleImage/ProfileImage.tsx";

interface ticketViewProps {
  ticketId?: string | number | null;
  isModal?: boolean;
}

function Tickiteview({ ticketId, isModal = false }: ticketViewProps) {
  const { slug: slugFromParams } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const ticketSlug = ticketId || slugFromParams;

  const {
    data: tickiteData,
    isLoading,
    isFetching,
  } = useQuery(
    ["tickited_task_id_detaitls", ticketSlug],
    () =>
      getTickitBytaskid(ticketSlug).catch(() => {
        navigate("/error/404", { replace: true });
      }),
    { enabled: !!ticketSlug }
  );
  UseErrorRedirection(ticketSlug);
  const {
    data: comments,
    isLoading: isCommentsLoading,
    isFetching: isCommentsFetching,
  } = useQuery<any>(
    ["taskComment", tickiteData?.data?.id],
    // @ts-ignore
    () => {
      return getComments(tickiteData?.data?.id);
    },
    {
      enabled: !!tickiteData?.data?.id,
    }
  );

  return (
    <div className="container my-8">
      {!isModal ? <CommonPageHeader id={2} heading={"Support Request"} /> : ""}

      <div className="row">
        <div className={`col ${isModal ? "px-0" : ""}`}>
          <div className={`card ${isModal ? "border-0" : ""}`}>
            {isLoading || isFetching ? (
              <>
                <CardHeaderSkeleton hasEndContent={true} />
              </>
            ) : (
              <>
                <div className="card-header p-6">
                  <div className="card-title">
                    {tickiteData?.data?.connect_info?.name}
                  </div>

                  {isModal ? (
                    <a
                      href={`${window.location.origin}/public/tickets/track/${tickiteData?.data?.task_id}`}
                      target={"_blank"}
                      rel={"noreferrer noopener"}
                      className={"card-title text-primary"}
                    >
                      SPG-{tickiteData?.data?.task_id}
                    </a>
                  ) : (
                    <div className="card-title">
                      SPG-{tickiteData?.data?.task_id}
                    </div>
                  )}
                </div>
                <div className="progress h-8px w-100 rounded-0 ">
                  <div
                    className={`progress-bar progress-bar-striped progress-bar-animated bg-brand-primary`}
                    role="progressbar"
                    style={{ width: `${tickiteData?.data?.status * 20}%` }}
                  ></div>
                </div>
              </>
            )}
            <div className="card-body p-6">
              <div className={"vstack gap-6"}>
                <CrComponetID
                  item={tickiteData?.data}
                  isTickitLoading={isLoading}
                />

                <StageUI TickiteDetails={tickiteData?.data} />
              </div>
              <div className={"card mt-6"}>
                <div className={"card-header px-8"}>
                  <div className={"card-title"}>Conversation</div>
                  <CommentModel data={tickiteData?.data} />
                </div>

                <div className="card-body p-6">
                  <div className={"vstack gap-6"}>
                    {isCommentsLoading || isCommentsFetching ? (
                      <ListSkeleton flush={true} />
                    ) : (
                      comments?.data?.results &&
                      comments?.data?.results?.map((comment) => (
                        <div className="d-flex mb-3">
                          {/*<img className="mr-3" src={comment?.user_info?.avatar} alt="Generic placeholder image"/>*/}
                          <div className="flex-shrink-0">
                            <ProfileImage src={comment?.user_info?.avatar} />
                          </div>

                          <div className="flex-grow-1 ms-3">
                            <div className="d-flex justify-content-between">
                              <h5 className="mt-0 mb-1">
                                {comment?.user_info?.display_name}
                              </h5>
                              <span className="fw-normal">
                                {timeAgo(comment?.created_at)}
                              </span>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: comment?.comment,
                              }}
                            ></div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-10">
        <div className={`col `}></div>
      </div>
    </div>
  );
}

export default Tickiteview;
