import React, { useState } from "react";
import { Filter } from "@components/UI/HOC/ListFilter/Filter.tsx";
import { useQuery } from "react-query";
import { publicStockyApi } from "@/api/public";
import {
  publicProductFiltersInitial,
  publicProductListParamsInitial,
} from "@app/pages/erp/products/PublicProduct/_constants.ts";
import { Table } from "react-bootstrap";
import BackgroundWrapper from "@components/UI/Wrappers/BackgroundWrapper.tsx";
import TableAlert from "@components/helpers/CustomAlerts/TableAlert.tsx";
import PublicProductListRow from "@app/pages/erp/products/PublicProduct/PublicProductListRow.tsx";
import PublicProductListFilters from "@app/pages/erp/products/PublicProduct/PublicProductListFilters.tsx";

function PublicProductList() {
  //   local states
  const [urlParams, setUrlParams] = useState(publicProductListParamsInitial);

  const { data, isLoading, isFetching, isError, refetch } = useQuery({
    queryKey: ["public_product_list", urlParams],
    queryFn: () => {
      return publicStockyApi
        .stockyProductsList(urlParams)
        .then((res) => res.data);
    },
    keepPreviousData: true,
    retry: false,
    enabled: false,
  });

  return (
    <>
      <BackgroundWrapper>
        <div className={"container"}>
          <div className="d-flex flex-column gap-6 my-10">
            <div className="shadow">
              <div className="card card-flush border-bottom-0">
                <div className="card-header">
                  <div className="card-title">
                    <h1>Product List</h1>
                  </div>
                </div>
              </div>
              <Filter
                filtersInitial={publicProductFiltersInitial}
                urlParams={urlParams}
                setUrlParams={setUrlParams}
              >
                <Filter.Fields>
                  <PublicProductListFilters />
                </Filter.Fields>
                <Filter.Extras
                  loading={isLoading || isFetching}
                  callback={() => refetch()}
                  apiData={data}
                ></Filter.Extras>
              </Filter>
            </div>

            <div className="card border-0 shadow">
              {isError ? (
                <TableAlert />
              ) : (
                <Table
                  striped
                  bordered
                  responsive
                  hover
                  className={`${
                    isFetching || isLoading ? "visually-hidden" : ""
                  }`}
                >
                  <thead>
                    <tr className="">
                      <th className="col-1">Type</th>
                      <th className="col-1">Brand</th>
                      <th className="col-2">Product</th>
                      <th className="col-2">Config</th>
                      <th className="col-1">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.results?.map((item) => {
                      return (
                        <PublicProductListRow data={item} key={item?.id} />
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </BackgroundWrapper>
    </>
  );
}

export default PublicProductList;
