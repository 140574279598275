import React from "react";
import { ColorsMain } from "@components/UI/uiTypes.ts";
import IconRecord from "@components/UI/Icons/IconRecord.tsx";

type TrackBadgeProps = {
  size?: string;
  color?: ColorsMain;
};

function TrackBadge({ size = "1.5rem", color = "brand" }: TrackBadgeProps) {
  return (
    <>
      <IconRecord height={size} width={size} className={`text-${color}`} />
    </>
  );
}

export default TrackBadge;
