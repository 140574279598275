import React, { ReactNode, useCallback, useState } from "react";
import CustomFilterBtn from "@components/UI/Buttons/CustomFilterBtn.tsx";
import CommonPagination, {
  CommonPaginationProps,
} from "@components/UI/Pagination/CommonPagination.tsx";
import { FilterContextProvider } from "@components/UI/HOC/ListFilter/FilterContext.tsx";
import RefreshButton, {
  RefreshButtonProps,
} from "@components/UI/Buttons/RefreshButton.tsx";
import SetFilterSetBtn from "@components/helpers/SaveFilterSet/SetFilterSetBtn.tsx";
import { FILTER_SAVE } from "@Constants/APP_Constants.ts";
import GetFilterSetBtn from "@components/helpers/SaveFilterSet/GetFilters.tsx";
import ClearFilterSetBtn from "@components/helpers/SaveFilterSet/ClearFilter.tsx";
import { useFilterContext } from "@components/UI/HOC/ListFilter/useFilterContext.tsx";

export interface FilterObject {
  key: string;
  label: string;
  isVisible: boolean;
}

export interface FiltersType {
  [key: string]: FilterObject;
}

type FilterProps<F extends FiltersType, U> = {
  filtersInitial: F;
  urlParams: U;
  setUrlParams: React.Dispatch<React.SetStateAction<U>>;
  showFilterButtons?: boolean;
  filterCategory?: keyof typeof FILTER_SAVE;
  children?: ReactNode;
};

type FieldsProps = {
  children?: ReactNode;
};

interface ExtrasProps extends RefreshButtonProps {
  children?: ReactNode;
  apiData?: any;
}

function Filter<F extends FiltersType, U>({
  filtersInitial,
  urlParams,
  setUrlParams,
  showFilterButtons = true,
  filterCategory,
  children,
}: FilterProps<F, U>) {
  // local states
  const [filters, setFilters] = useState<F>(filtersInitial);

  // Methods
  const handleParamChange = useCallback(
    (key: string | undefined, value: any) => {
      if (!key) {
        return;
      }
      setUrlParams((prev) => {
        return { ...prev, [key]: value, limit: null, offset: null };
      });
    },
    [setUrlParams]
  );

  function handleFilterClick(key: string) {
    //   if toggling off update urlParam to null
    if (filters[key].isVisible) {
      handleParamChange(filters[key].key!, null);
    }

    //   Toggle filter visibility
    setFilters((prev) => {
      const copy = { ...prev };
      copy[key].isVisible = !copy[key].isVisible;
      return copy;
    });
  }

  function handlePaginationClick(limit: number, offset: number) {
    setUrlParams((prev) => {
      return { ...prev, limit, offset };
    });
  }

  function handleDateRangeChange(
    startDate: string | null,
    endDate: string | null
  ) {
    setUrlParams((prev) => {
      return {
        ...prev,
        start_date: startDate,
        end_date: endDate,
        limit: null,
        offset: null,
      };
    });
  }

  return (
    <FilterContextProvider
      value={{
        filters,
        urlParams,
        setUrlParams,
        handleParamChange,
        handleDateRangeChange,
        handlePaginationClick,
      }}
    >
      <div className={"card"}>
        <div className="card-body vstack gap-6 ribbon ribbon-top">
          {/* filter buttons */}
          {showFilterButtons ? (
            <div className={"hstack flex-wrap gap-3 row-gap-6"}>
              {Object.entries(filters).map(([key, value], index) => {
                return (
                  <CustomFilterBtn
                    action={() => handleFilterClick(key)}
                    isActive={value.isVisible}
                    label={value.label}
                    key={`${index}-${key}`}
                  />
                );
              })}

              {filterCategory ? (
                <>
                  <SetFilterSetBtn
                    object={urlParams}
                    name=""
                    catagory={filterCategory}
                  />
                  <GetFilterSetBtn
                    catagory={filterCategory}
                    setFiletr={(e) => setUrlParams(e)}
                  />
                  <ClearFilterSetBtn setFiletr={(e) => setUrlParams(e)} />
                </>
              ) : null}
            </div>
          ) : null}

          {children}
        </div>
      </div>
    </FilterContextProvider>
  );
}

function Fields<F extends FiltersType, U>({ children }: FieldsProps) {
  const { filters } = useFilterContext<F, U>();

  const isAnyFilterVisible = Object.entries(filters).some(
    ([_, value]) => value.isVisible
  );

  return isAnyFilterVisible ? (
    <div className={"row gy-6"}>{children}</div>
  ) : (
    <></>
  );
}

function Extras({
  children,
  callback,
  loading,
  title,
  disabled,
  apiData,
}: ExtrasProps) {
  const { handlePaginationClick } = useFilterContext();

  const paginationData: CommonPaginationProps = {
    prev: apiData?.previous,
    next: apiData?.next,
    count: apiData?.count,
    handlePaginationClick: handlePaginationClick,
  };

  return (
    <div className={"d-flex align-items-center gap-4"}>
      {apiData ? <CommonPagination {...paginationData} /> : null}

      <RefreshButton
        callback={callback}
        loading={loading}
        title={title}
        disabled={disabled}
      />

      {children}
    </div>
  );
}

Filter.Fields = Fields;
Filter.Extras = Extras;

export { Filter };
