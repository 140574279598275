import {
  PublicPurchaseOrder,
  PurchaseOrder,
  PurchaseOrderItems,
} from "@/generated-api";
import { IndianRupeeFormat } from "@/Utils/Shared/CurrencyConverter.ts";
import { Content, TableCell, TDocumentDefinitions } from "pdfmake/interfaces";
import { dayMonYearDate } from "@/Utils/Shared/DateStringToDays.ts";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import htmlToPdfmake from "html-to-pdfmake";
import { commonTableLayout } from "@/Utils/pdfmake/_constants.ts";

const border =
  "------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------";

function poDetails({ order }: { order: PurchaseOrder | PublicPurchaseOrder }) {
  return [
    {
      layout: "noBorders",
      style: "sectionGap",
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              stack: [
                {
                  text: "Buyer (Invoice To):",
                  style: ["normalParagraph", "header"],
                },
                {
                  text: order?.company_info?.name ?? "",
                  style: "normalParagraph",
                },
                {
                  text: order?.company_info?.address ?? "",
                  style: "normalParagraph",
                },
                {
                  text: `CIN: ${order?.company_info?.cin ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `GSTIN: ${order?.company_info?.gst_number ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `State: ${order?.company_info?.state ?? ""} (${
                    order?.company_info?.state_code
                  })`,
                  style: "normalParagraph",
                },
              ],
            },
            {
              layout: "noBorders",
              alignment: "right",
              table: {
                widths: ["*", "auto"],
                body: [
                  [
                    {
                      text: "Dated:",
                    },
                    {
                      text: dayMonYearDate(order?.created_at) ?? "",
                    },
                  ],
                  [
                    {
                      text: "Voucher No.:",
                    },
                    {
                      text: order?.po_number ?? "",
                    },
                  ],
                  [
                    {
                      text: "Mode/Terms of Payment:",
                    },
                    {
                      text: DecorateNameTags(order?.payment_terms),
                    },
                  ],
                  [
                    {
                      text: "Reference No. & Date:",
                    },
                    {
                      text: order?.po_number ?? "",
                    },
                  ],
                  [
                    {
                      text: "Other References:",
                    },
                    {
                      text: "",
                    },
                  ],
                  [
                    {
                      text: "Dispatched through:",
                    },
                    { text: "" },
                  ],
                  [
                    {
                      text: "Destination:",
                    },
                    { text: "" },
                  ],
                ],
              },
            },
          ],
        ],
      },
    },
  ] as Content;
}

function poShipmentDetails({
  order,
}: {
  order: PurchaseOrder | PublicPurchaseOrder;
}) {
  return [
    {
      style: "sectionGap",
      layout: commonTableLayout,
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              text: "Consignee (Ship to)",
              style: ["tableHeader"],
            },
            {
              text: "Supplier (From)",
              style: ["tableHeader"],
            },
          ],
          [
            {
              stack: [
                {
                  text: order?.company_info?.name ?? "",
                  style: "normalParagraph",
                },
                {
                  text: order?.company_info?.address ?? "",
                  style: "normalParagraph",
                },
                {
                  text: `CIN: ${order?.company_info?.cin ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `GSTIN: ${order?.company_info?.gst_number ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `State: ${order?.company_info?.state ?? ""} (${
                    order?.company_info?.state_code
                  })`,
                  style: "normalParagraph",
                },
              ],
            },
            {
              stack: [
                {
                  text: DecorateNameTags(order?.connect_info?.name ?? ""),
                  style: "normalParagraph",
                },
                {
                  text: order?.connect_info?.points?.[0]?.address ?? "",
                  style: "normalParagraph",
                },
                {
                  text: `GSTIN: ${order?.connect_info?.gst_number ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `State: ${
                    // @ts-ignore
                    order?.connect_info?.points?.[0].city_details?.state ?? ""
                  }`,
                  style: "normalParagraph",
                },
                {
                  text: `Name: ${order?.poc_name ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `Phone Number: ${order?.poc_contact ?? ""}`,
                  style: "normalParagraph",
                },
                {
                  text: `Address: ${order?.from_address}`,
                  style: "normalParagraph",
                },
              ],
            },
          ],
        ],
      },
    },
  ] as Content;
}

function poTermsAndCost({
  order,
}: {
  order: PurchaseOrder | PublicPurchaseOrder;
}) {
  const richText = htmlToPdfmake(`${order?.terms ?? ""}`);

  return [
    {
      style: "sectionGap",
      columns: [
        {
          stack: [{ text: "Terms of Delivery:" }, richText],
        },
        {
          text: [
            { text: "Logistics Cost:\t" },
            { text: order?.logistics_cost_to ?? "" },
          ],
        },
      ],
    },
  ] as Content;
}

function poProductRows({ itemsList }: { itemsList: PurchaseOrderItems[] }) {
  const rows: TableCell[] = itemsList.map((itemData, index) => {
    return [
      {
        text: index + 1,
      },
      {
        stack: [
          {
            text: itemData?.item?.item,
          },
          {
            text: itemData?.item_type,
          },
          {
            text: itemData?.config,
          },
        ],
      },
      {
        text: `${itemData?.qty} Nos`,
      },
      {
        text: IndianRupeeFormat(itemData?.rate),
      },
      {
        text: "Nos",
      },
      {
        text: IndianRupeeFormat(itemData?.rate * (itemData?.qty ?? 1)),
      },
    ];
  });

  return rows;
}

function poProductList({
  order,
  itemsList,
}: {
  order: PurchaseOrder | PublicPurchaseOrder;
  itemsList: PurchaseOrderItems[];
}) {
  return [
    {
      layout: commonTableLayout,
      style: ["sectionGap"],
      table: {
        headerRows: 1,
        dontBreakRows: true,
        widths: ["auto", "*", "auto", "auto", "auto", "auto"],
        body: [
          [
            {
              text: "SI NO.",
              style: ["tableHeader"],
            },
            {
              text: "Description of Goods",
              style: ["tableHeader"],
            },
            {
              text: "Qty",
              style: ["tableHeader"],
            },
            {
              text: "Rate",
              style: ["tableHeader"],
            },
            {
              text: "Per",
              style: ["tableHeader"],
            },
            {
              text: "Amount",
              style: ["tableHeader"],
            },
          ],
          ...poProductRows({ itemsList: itemsList }),
          [
            {},
            { text: "Total", alignment: "right" },
            {},
            {},
            {},
            // @ts-ignore
            { text: IndianRupeeFormat(order?.totals?.total_value) },
          ],
          [
            {},
            { text: "GST", alignment: "right" },
            {},
            {},
            {},
            {
              text:
                IndianRupeeFormat(
                  // @ts-ignore
                  order?.totals?.total_with_gst - order?.totals?.total_value
                ) ?? "",
            },
          ],
          [
            {},
            {
              text: "Grand Total",
              alignment: "right",
              fontSize: 12,
            },
            {
              // @ts-ignore
              text: `${order?.totals?.total_qty} Nos`,
              fontSize: 12,
            },
            {},
            {},
            {
              // @ts-ignore
              text: IndianRupeeFormat(order?.totals?.total_with_gst) ?? "",
              fontSize: 12,
            },
          ],
        ],
      },
    },
  ] as Content;
}

function poLastPage({ order }: { order: PurchaseOrder | PublicPurchaseOrder }) {
  const poLastPage: Content = [
    {
      text: border,
      decoration: "underline",
      decorationColor: "#999",
      decorationStyle: "dashed",
      color: "#fff",
      style: "sectionGap",
    },

    {
      columns: [
        { text: `Buyer: ${order.company_info?.name}` },
        { text: `Supplier: ${DecorateNameTags(order.connect_info?.name)}` },
      ],
    },

    {
      text: border,
      decoration: "underline",
      decorationColor: "#999",
      decorationStyle: "dashed",
      color: "#fff",
      style: "sectionGap",
    },

    {
      headlineLevel: 1,
      stack: [
        { text: "Disclaimer:", style: "normalParagraph" },
        {
          text: "Goods and deal must be as per item details mentioned in this PO. Buyer reserves right to return if its defected, not as per quality standard, delayed or wrong quantity or price.",
          style: "normalParagraph",
        },
        {
          text: "Until goods are delivered and accepted, buyer doesn't take any responsibility including force majeure.",
          style: "normalParagraph",
        },
        {
          text: "It is 100% seller's responsibility and title.",
          style: "normalParagraph",
        },
        {
          text: "Unauthorized use, reproduction, or distribution of the order details or any information contained therein is strictly prohibited and may be subject to legal action.",
          style: "normalParagraph",
        },
      ],
    },

    {
      text: border,
      decoration: "underline",
      decorationColor: "#999",
      decorationStyle: "dashed",
      color: "#fff",
      style: "sectionGap",
    },
  ];
  return poLastPage;
}

export function generatePoDocDefinition({
  order,
  itemsList,
  imageData,
}: {
  order: PurchaseOrder | PublicPurchaseOrder;
  itemsList: PurchaseOrderItems[];
  imageData: string;
}) {
  const poDocDefinition: TDocumentDefinitions = {
    info: {
      title: order?.po_number ?? "",
    },
    content: [
      {
        image: "company",
        fit: [30, 30],
        width: 30,
        absolutePosition: { x: 40, y: 35 },
      },
      {
        text: "PURCHASE ORDER",
        alignment: "center",
        fontSize: 20,
        characterSpacing: 3,
        style: [""],
      },

      {
        text: border,
        decoration: "underline",
        decorationColor: "#999",
        decorationStyle: "dashed",
        color: "#fff",
        style: "sectionGap",
      },

      poDetails({ order: order }),
      poShipmentDetails({ order: order }),
      poTermsAndCost({ order: order }),
      poProductList({ order: order, itemsList: itemsList }),
      poLastPage({ order: order }),
    ],
    styles: {
      tableHeader: {
        bold: true,
        color: "white",
        fillColor: "#555",
      },
      header: {
        bold: true,
        fontSize: 12,
      },
      sectionGap: {
        marginBottom: 16,
      },
      normalParagraph: {
        marginBottom: 8,
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 10,
    },
    images: {
      company: imageData,
    },
    pageSize: "A4",
    pageMargins: 40,
    // pageBreakBefore: function (currentNode, followingNodesOnPage) {
    //   return (
    //     currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
    //   );
    // },
    footer: (currentPage, pageCount) => {
      return {
        stack: [
          {
            text: "This is a Computer Generated Document",
            alignment: "center",
          },
          {
            text: `${currentPage} / ${pageCount}`,
            absolutePosition: { x: 550, y: 0 },
          },
        ],
      };
    },
  };

  return poDocDefinition;
}
