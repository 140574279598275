import CompanyImage from "@components/helpers/CompalyImage/CompanyImage.tsx";
import React from "react";

type CommonPageHeaderProps = {
  id: number | null | undefined;
  heading: string;
};

export default function CommonPageHeader({
  id,
  heading,
}: CommonPageHeaderProps) {
  return (
    <div className="position-relative mb-3 pb-3 border-bottom border-gray-300">
      <CompanyImage id={id ?? 2} alt={"company"} width={40} />
      <div
        className={
          "position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center"
        }
      >
        <h1 className="m-0 text-capitalize">{heading}</h1>
      </div>
    </div>
  );
}
