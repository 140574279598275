import { FC } from "react";
import { useAuth } from "@app/modules/auth";
import { Link } from "react-router-dom";
import { WHITELIST_IP_PATHS } from "@Constants/RoutingConstants/erp/RedirectRouteConstants.ts";
import ProfileImage from "@components/helpers/ProfleImage/ProfileImage.tsx";
import { STOCKY_PRO_SCOPES } from "@Constants/APP_Constants.ts";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5 align-self-start">
            {/*<img alt='Logo' src={currentUser?.avatar} />*/}
            <ProfileImage
              src={currentUser?.avatar}
              name={currentUser?.first_name}
              size={45}
            />
          </div>

          <div className="d-flex flex-column gap-4">
            <div className="">
              <div className="fw-bolder d-flex align-items-center fs-5 text-wrap">
                {currentUser?.first_name} {currentUser?.last_name}
              </div>

              <a
                href="#"
                className="fw-bold text-muted text-hover-primary fs-7"
              >
                {currentUser?.email}
              </a>
            </div>

            <div className="row">
              <div className="col">
                <div className="d-flex flex-wrap gap-1 ">
                  {currentUser?.groups.map((e, k) => (
                    <div key={k}>
                      <span
                        key={k}
                        className="badge badge-light-success fw-bolder      "
                      >
                        {e?.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="separator my-2"></div>

      {currentUser?.scope === STOCKY_PRO_SCOPES.CRM && (
        <div className="menu-item px-5 my-1">
          <Link to="/crm/profile/edit" className="menu-link px-5">
            Edit Profile
          </Link>
        </div>
      )}

      {currentUser?.scope === STOCKY_PRO_SCOPES.ERP && (
        <div className="menu-item px-5 my-1">
          <Link to={`${WHITELIST_IP_PATHS.LIST}`} className="menu-link px-5">
            List IP
          </Link>
        </div>
      )}
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
