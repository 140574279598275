import React, { lazy, Suspense } from "react";
import "./ckeditor.css";
import CkEditorSkeleton from "../Skeletons/CKEditorSkeleton";
const CkEditorLazyComp = lazy(() => import("./CkEditorLazyComp"));

const QuillTextEditor = ({
  value,
  setterFunction,
  blrfuncton = () => {},
  flagState = null,
}: {
  value: any;
  setterFunction: any;
  blrfuncton?: any;
  flagState?: any;
}) => {
  return (
    <>
      <Suspense fallback={<CkEditorSkeleton />}>
        <CkEditorLazyComp
          value={value}
          setterFunction={setterFunction}
          blrfuncton={blrfuncton}
          flagState={flagState}
        />
      </Suspense>
    </>
  );
};
export default QuillTextEditor;
