/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AllowedIP } from '../model';
// @ts-ignore
import type { Company } from '../model';
// @ts-ignore
import type { CoreCompanyList200Response } from '../model';
// @ts-ignore
import type { CoreGenericConfigList200Response } from '../model';
// @ts-ignore
import type { CoreRolesList200Response } from '../model';
// @ts-ignore
import type { CoreRolesPermissionsList200Response } from '../model';
// @ts-ignore
import type { CoreSecretList200Response } from '../model';
// @ts-ignore
import type { CoreSenderList200Response } from '../model';
// @ts-ignore
import type { CoreWhitelistList200Response } from '../model';
// @ts-ignore
import type { GenericConfig } from '../model';
// @ts-ignore
import type { Sender } from '../model';
// @ts-ignore
import type { StockyPermissions } from '../model';
// @ts-ignore
import type { StockyRoles } from '../model';
// @ts-ignore
import type { VaultManager } from '../model';
/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCacheClearIpRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/cache/clear/ip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [gst_number] gst_number
         * @param {string} [domain] domain
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyList: async (gst_number?: string, domain?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (gst_number !== undefined) {
                localVarQueryParameter['gst_number'] = gst_number;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this company.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyPartialUpdate: async (id: number, data: Company, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreCompanyPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreCompanyPartialUpdate', 'data', data)
            const localVarPath = `/core/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreCompanyRead', 'id', id)
            const localVarPath = `/core/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this company.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyUpdate: async (id: number, data: Company, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreCompanyUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreCompanyUpdate', 'data', data)
            const localVarPath = `/core/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCrmDashboardMetricsRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/crm/dashboard/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreDashboardMetricsRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/dashboard/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [name] name
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreGenericConfigList: async (category?: string, name?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/generic_config/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreGenericConfigRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreGenericConfigRead', 'id', id)
            const localVarPath = `/core/generic_config/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreHealthRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockyRoles} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesCreate: async (data: StockyRoles, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreRolesCreate', 'data', data)
            const localVarPath = `/core/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CoreRolesListCategory} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesList: async (category?: CoreRolesListCategory, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky role permissions.
         * @param {StockyRoles} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPartialUpdate: async (id: number, data: StockyRoles, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreRolesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreRolesPartialUpdate', 'data', data)
            const localVarPath = `/core/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockyPermissions} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsCreate: async (data: StockyPermissions, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreRolesPermissionsCreate', 'data', data)
            const localVarPath = `/core/roles/permissions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] name
         * @param {CoreRolesPermissionsListCategory} [category] category
         * @param {string} [stockyrolepermissions] stockyrolepermissions
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsList: async (name?: string, category?: CoreRolesPermissionsListCategory, stockyrolepermissions?: string, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/roles/permissions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (stockyrolepermissions !== undefined) {
                localVarQueryParameter['stockyrolepermissions'] = stockyrolepermissions;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky permissions.
         * @param {StockyPermissions} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsPartialUpdate: async (id: number, data: StockyPermissions, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreRolesPermissionsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreRolesPermissionsPartialUpdate', 'data', data)
            const localVarPath = `/core/roles/permissions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky permissions.
         * @param {StockyPermissions} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsUpdate: async (id: number, data: StockyPermissions, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreRolesPermissionsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreRolesPermissionsUpdate', 'data', data)
            const localVarPath = `/core/roles/permissions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky role permissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreRolesRead', 'id', id)
            const localVarPath = `/core/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky role permissions.
         * @param {StockyRoles} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesUpdate: async (id: number, data: StockyRoles, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreRolesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreRolesUpdate', 'data', data)
            const localVarPath = `/core/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VaultManager} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretCreate: async (data: VaultManager, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreSecretCreate', 'data', data)
            const localVarPath = `/core/secret/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretDecodeRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSecretDecodeRead', 'id', id)
            const localVarPath = `/core/secret/decode/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSecretDelete', 'id', id)
            const localVarPath = `/core/secret/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [username] username
         * @param {string} [name] name
         * @param {string} [otp_number] otp_number
         * @param {string} [otp_email] otp_email
         * @param {string} [users] users
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretList: async (category?: string, username?: string, name?: string, otp_number?: string, otp_email?: string, users?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/secret/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (otp_number !== undefined) {
                localVarQueryParameter['otp_number'] = otp_number;
            }

            if (otp_email !== undefined) {
                localVarQueryParameter['otp_email'] = otp_email;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {VaultManager} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretPartialUpdate: async (id: number, data: VaultManager, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSecretPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreSecretPartialUpdate', 'data', data)
            const localVarPath = `/core/secret/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSecretRead', 'id', id)
            const localVarPath = `/core/secret/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {VaultManager} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretUpdate: async (id: number, data: VaultManager, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSecretUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreSecretUpdate', 'data', data)
            const localVarPath = `/core/secret/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Sender} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderCreate: async (data: Sender, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreSenderCreate', 'data', data)
            const localVarPath = `/core/sender/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/sender/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this sender.
         * @param {Sender} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderPartialUpdate: async (id: number, data: Sender, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSenderPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreSenderPartialUpdate', 'data', data)
            const localVarPath = `/core/sender/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this sender.
         * @param {Sender} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderUpdate: async (id: number, data: Sender, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreSenderUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreSenderUpdate', 'data', data)
            const localVarPath = `/core/sender/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllowedIP} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistCreate: async (data: AllowedIP, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('coreWhitelistCreate', 'data', data)
            const localVarPath = `/core/whitelist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this allowed ip.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreWhitelistDelete', 'id', id)
            const localVarPath = `/core/whitelist/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/whitelist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this allowed ip.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coreWhitelistRead', 'id', id)
            const localVarPath = `/core/whitelist/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCacheClearIpRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCacheClearIpRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCacheClearIpRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [gst_number] gst_number
         * @param {string} [domain] domain
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCompanyList(gst_number?: string, domain?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreCompanyList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCompanyList(gst_number, domain, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCompanyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this company.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCompanyPartialUpdate(id: number, data: Company, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCompanyPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCompanyPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCompanyRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCompanyRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCompanyRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this company.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCompanyUpdate(id: number, data: Company, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCompanyUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCompanyUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCrmDashboardMetricsRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCrmDashboardMetricsRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCrmDashboardMetricsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreDashboardMetricsRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreDashboardMetricsRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreDashboardMetricsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [name] name
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreGenericConfigList(category?: string, name?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreGenericConfigList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreGenericConfigList(category, name, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreGenericConfigList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreGenericConfigRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreGenericConfigRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreGenericConfigRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreHealthRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreHealthRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreHealthRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StockyRoles} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesCreate(data: StockyRoles, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyRoles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CoreRolesListCategory} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesList(category?: CoreRolesListCategory, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreRolesList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesList(category, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky role permissions.
         * @param {StockyRoles} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesPartialUpdate(id: number, data: StockyRoles, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyRoles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StockyPermissions} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesPermissionsCreate(data: StockyPermissions, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesPermissionsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesPermissionsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] name
         * @param {CoreRolesPermissionsListCategory} [category] category
         * @param {string} [stockyrolepermissions] stockyrolepermissions
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesPermissionsList(name?: string, category?: CoreRolesPermissionsListCategory, stockyrolepermissions?: string, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreRolesPermissionsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesPermissionsList(name, category, stockyrolepermissions, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesPermissionsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky permissions.
         * @param {StockyPermissions} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesPermissionsPartialUpdate(id: number, data: StockyPermissions, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesPermissionsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesPermissionsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky permissions.
         * @param {StockyPermissions} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesPermissionsUpdate(id: number, data: StockyPermissions, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesPermissionsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesPermissionsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky role permissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyRoles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this stocky role permissions.
         * @param {StockyRoles} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreRolesUpdate(id: number, data: StockyRoles, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyRoles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreRolesUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreRolesUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VaultManager} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretCreate(data: VaultManager, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VaultManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretDecodeRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VaultManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretDecodeRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretDecodeRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [username] username
         * @param {string} [name] name
         * @param {string} [otp_number] otp_number
         * @param {string} [otp_email] otp_email
         * @param {string} [users] users
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretList(category?: string, username?: string, name?: string, otp_number?: string, otp_email?: string, users?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSecretList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretList(category, username, name, otp_number, otp_email, users, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {VaultManager} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretPartialUpdate(id: number, data: VaultManager, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VaultManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VaultManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this vault manager.
         * @param {VaultManager} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSecretUpdate(id: number, data: VaultManager, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VaultManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSecretUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSecretUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Sender} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSenderCreate(data: Sender, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSenderCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSenderCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSenderList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSenderList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSenderList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSenderList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this sender.
         * @param {Sender} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSenderPartialUpdate(id: number, data: Sender, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSenderPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSenderPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this sender.
         * @param {Sender} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSenderUpdate(id: number, data: Sender, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreSenderUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreSenderUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AllowedIP} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreWhitelistCreate(data: AllowedIP, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedIP>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreWhitelistCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreWhitelistCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this allowed ip.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreWhitelistDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreWhitelistDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreWhitelistDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreWhitelistList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreWhitelistList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreWhitelistList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreWhitelistList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this allowed ip.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreWhitelistRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedIP>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreWhitelistRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreWhitelistRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCacheClearIpRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.coreCacheClearIpRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreCompanyListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyList(requestParameters: CoreApiCoreCompanyListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreCompanyList200Response> {
            return localVarFp.coreCompanyList(requestParameters.gst_number, requestParameters.domain, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreCompanyPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyPartialUpdate(requestParameters: CoreApiCoreCompanyPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.coreCompanyPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreCompanyReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyRead(requestParameters: CoreApiCoreCompanyReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.coreCompanyRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreCompanyUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCompanyUpdate(requestParameters: CoreApiCoreCompanyUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.coreCompanyUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCrmDashboardMetricsRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.coreCrmDashboardMetricsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreDashboardMetricsRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.coreDashboardMetricsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreGenericConfigListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreGenericConfigList(requestParameters: CoreApiCoreGenericConfigListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreGenericConfigList200Response> {
            return localVarFp.coreGenericConfigList(requestParameters.category, requestParameters.name, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreGenericConfigReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreGenericConfigRead(requestParameters: CoreApiCoreGenericConfigReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericConfig> {
            return localVarFp.coreGenericConfigRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreHealthRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.coreHealthRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesCreate(requestParameters: CoreApiCoreRolesCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyRoles> {
            return localVarFp.coreRolesCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesList(requestParameters: CoreApiCoreRolesListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreRolesList200Response> {
            return localVarFp.coreRolesList(requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPartialUpdate(requestParameters: CoreApiCoreRolesPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyRoles> {
            return localVarFp.coreRolesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesPermissionsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsCreate(requestParameters: CoreApiCoreRolesPermissionsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyPermissions> {
            return localVarFp.coreRolesPermissionsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesPermissionsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsList(requestParameters: CoreApiCoreRolesPermissionsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreRolesPermissionsList200Response> {
            return localVarFp.coreRolesPermissionsList(requestParameters.name, requestParameters.category, requestParameters.stockyrolepermissions, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesPermissionsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsPartialUpdate(requestParameters: CoreApiCoreRolesPermissionsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyPermissions> {
            return localVarFp.coreRolesPermissionsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesPermissionsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesPermissionsUpdate(requestParameters: CoreApiCoreRolesPermissionsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyPermissions> {
            return localVarFp.coreRolesPermissionsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesRead(requestParameters: CoreApiCoreRolesReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyRoles> {
            return localVarFp.coreRolesRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreRolesUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreRolesUpdate(requestParameters: CoreApiCoreRolesUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyRoles> {
            return localVarFp.coreRolesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretCreate(requestParameters: CoreApiCoreSecretCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<VaultManager> {
            return localVarFp.coreSecretCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretDecodeReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretDecodeRead(requestParameters: CoreApiCoreSecretDecodeReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<VaultManager> {
            return localVarFp.coreSecretDecodeRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretDelete(requestParameters: CoreApiCoreSecretDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.coreSecretDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretList(requestParameters: CoreApiCoreSecretListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreSecretList200Response> {
            return localVarFp.coreSecretList(requestParameters.category, requestParameters.username, requestParameters.name, requestParameters.otp_number, requestParameters.otp_email, requestParameters.users, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretPartialUpdate(requestParameters: CoreApiCoreSecretPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<VaultManager> {
            return localVarFp.coreSecretPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretRead(requestParameters: CoreApiCoreSecretReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<VaultManager> {
            return localVarFp.coreSecretRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSecretUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSecretUpdate(requestParameters: CoreApiCoreSecretUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<VaultManager> {
            return localVarFp.coreSecretUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSenderCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderCreate(requestParameters: CoreApiCoreSenderCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sender> {
            return localVarFp.coreSenderCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSenderListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderList(requestParameters: CoreApiCoreSenderListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreSenderList200Response> {
            return localVarFp.coreSenderList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSenderPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderPartialUpdate(requestParameters: CoreApiCoreSenderPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sender> {
            return localVarFp.coreSenderPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreSenderUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSenderUpdate(requestParameters: CoreApiCoreSenderUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sender> {
            return localVarFp.coreSenderUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.coreTestRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreWhitelistCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistCreate(requestParameters: CoreApiCoreWhitelistCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AllowedIP> {
            return localVarFp.coreWhitelistCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreWhitelistDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistDelete(requestParameters: CoreApiCoreWhitelistDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.coreWhitelistDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreWhitelistListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistList(requestParameters: CoreApiCoreWhitelistListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CoreWhitelistList200Response> {
            return localVarFp.coreWhitelistList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoreApiCoreWhitelistReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreWhitelistRead(requestParameters: CoreApiCoreWhitelistReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<AllowedIP> {
            return localVarFp.coreWhitelistRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for coreCompanyList operation in CoreApi.
 * @export
 * @interface CoreApiCoreCompanyListRequest
 */
export interface CoreApiCoreCompanyListRequest {
    /**
     * gst_number
     * @type {string}
     * @memberof CoreApiCoreCompanyList
     */
    readonly gst_number?: string

    /**
     * domain
     * @type {string}
     * @memberof CoreApiCoreCompanyList
     */
    readonly domain?: string

    /**
     * A search term.
     * @type {string}
     * @memberof CoreApiCoreCompanyList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreCompanyList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreCompanyList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreCompanyPartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreCompanyPartialUpdateRequest
 */
export interface CoreApiCoreCompanyPartialUpdateRequest {
    /**
     * A unique integer value identifying this company.
     * @type {number}
     * @memberof CoreApiCoreCompanyPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Company}
     * @memberof CoreApiCoreCompanyPartialUpdate
     */
    readonly data: Company
}

/**
 * Request parameters for coreCompanyRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreCompanyReadRequest
 */
export interface CoreApiCoreCompanyReadRequest {
    /**
     * A unique integer value identifying this company.
     * @type {number}
     * @memberof CoreApiCoreCompanyRead
     */
    readonly id: number
}

/**
 * Request parameters for coreCompanyUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreCompanyUpdateRequest
 */
export interface CoreApiCoreCompanyUpdateRequest {
    /**
     * A unique integer value identifying this company.
     * @type {number}
     * @memberof CoreApiCoreCompanyUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Company}
     * @memberof CoreApiCoreCompanyUpdate
     */
    readonly data: Company
}

/**
 * Request parameters for coreGenericConfigList operation in CoreApi.
 * @export
 * @interface CoreApiCoreGenericConfigListRequest
 */
export interface CoreApiCoreGenericConfigListRequest {
    /**
     * category
     * @type {string}
     * @memberof CoreApiCoreGenericConfigList
     */
    readonly category?: string

    /**
     * name
     * @type {string}
     * @memberof CoreApiCoreGenericConfigList
     */
    readonly name?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreGenericConfigList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreGenericConfigList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreGenericConfigRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreGenericConfigReadRequest
 */
export interface CoreApiCoreGenericConfigReadRequest {
    /**
     * A unique integer value identifying this generic config.
     * @type {number}
     * @memberof CoreApiCoreGenericConfigRead
     */
    readonly id: number
}

/**
 * Request parameters for coreRolesCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesCreateRequest
 */
export interface CoreApiCoreRolesCreateRequest {
    /**
     * 
     * @type {StockyRoles}
     * @memberof CoreApiCoreRolesCreate
     */
    readonly data: StockyRoles
}

/**
 * Request parameters for coreRolesList operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesListRequest
 */
export interface CoreApiCoreRolesListRequest {
    /**
     * category
     * @type {'1' | '2'}
     * @memberof CoreApiCoreRolesList
     */
    readonly category?: CoreRolesListCategory

    /**
     * A search term.
     * @type {string}
     * @memberof CoreApiCoreRolesList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreRolesList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreRolesList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreRolesPartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesPartialUpdateRequest
 */
export interface CoreApiCoreRolesPartialUpdateRequest {
    /**
     * A unique integer value identifying this stocky role permissions.
     * @type {number}
     * @memberof CoreApiCoreRolesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StockyRoles}
     * @memberof CoreApiCoreRolesPartialUpdate
     */
    readonly data: StockyRoles
}

/**
 * Request parameters for coreRolesPermissionsCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesPermissionsCreateRequest
 */
export interface CoreApiCoreRolesPermissionsCreateRequest {
    /**
     * 
     * @type {StockyPermissions}
     * @memberof CoreApiCoreRolesPermissionsCreate
     */
    readonly data: StockyPermissions
}

/**
 * Request parameters for coreRolesPermissionsList operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesPermissionsListRequest
 */
export interface CoreApiCoreRolesPermissionsListRequest {
    /**
     * name
     * @type {string}
     * @memberof CoreApiCoreRolesPermissionsList
     */
    readonly name?: string

    /**
     * category
     * @type {'1' | '2'}
     * @memberof CoreApiCoreRolesPermissionsList
     */
    readonly category?: CoreRolesPermissionsListCategory

    /**
     * stockyrolepermissions
     * @type {string}
     * @memberof CoreApiCoreRolesPermissionsList
     */
    readonly stockyrolepermissions?: string

    /**
     * Pagination
     * @type {string}
     * @memberof CoreApiCoreRolesPermissionsList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreRolesPermissionsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreRolesPermissionsList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreRolesPermissionsPartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesPermissionsPartialUpdateRequest
 */
export interface CoreApiCoreRolesPermissionsPartialUpdateRequest {
    /**
     * A unique integer value identifying this stocky permissions.
     * @type {number}
     * @memberof CoreApiCoreRolesPermissionsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StockyPermissions}
     * @memberof CoreApiCoreRolesPermissionsPartialUpdate
     */
    readonly data: StockyPermissions
}

/**
 * Request parameters for coreRolesPermissionsUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesPermissionsUpdateRequest
 */
export interface CoreApiCoreRolesPermissionsUpdateRequest {
    /**
     * A unique integer value identifying this stocky permissions.
     * @type {number}
     * @memberof CoreApiCoreRolesPermissionsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StockyPermissions}
     * @memberof CoreApiCoreRolesPermissionsUpdate
     */
    readonly data: StockyPermissions
}

/**
 * Request parameters for coreRolesRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesReadRequest
 */
export interface CoreApiCoreRolesReadRequest {
    /**
     * A unique integer value identifying this stocky role permissions.
     * @type {number}
     * @memberof CoreApiCoreRolesRead
     */
    readonly id: number
}

/**
 * Request parameters for coreRolesUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreRolesUpdateRequest
 */
export interface CoreApiCoreRolesUpdateRequest {
    /**
     * A unique integer value identifying this stocky role permissions.
     * @type {number}
     * @memberof CoreApiCoreRolesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StockyRoles}
     * @memberof CoreApiCoreRolesUpdate
     */
    readonly data: StockyRoles
}

/**
 * Request parameters for coreSecretCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretCreateRequest
 */
export interface CoreApiCoreSecretCreateRequest {
    /**
     * 
     * @type {VaultManager}
     * @memberof CoreApiCoreSecretCreate
     */
    readonly data: VaultManager
}

/**
 * Request parameters for coreSecretDecodeRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretDecodeReadRequest
 */
export interface CoreApiCoreSecretDecodeReadRequest {
    /**
     * A unique integer value identifying this vault manager.
     * @type {number}
     * @memberof CoreApiCoreSecretDecodeRead
     */
    readonly id: number
}

/**
 * Request parameters for coreSecretDelete operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretDeleteRequest
 */
export interface CoreApiCoreSecretDeleteRequest {
    /**
     * A unique integer value identifying this vault manager.
     * @type {number}
     * @memberof CoreApiCoreSecretDelete
     */
    readonly id: number
}

/**
 * Request parameters for coreSecretList operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretListRequest
 */
export interface CoreApiCoreSecretListRequest {
    /**
     * category
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly category?: string

    /**
     * username
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly username?: string

    /**
     * name
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly name?: string

    /**
     * otp_number
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly otp_number?: string

    /**
     * otp_email
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly otp_email?: string

    /**
     * users
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly users?: string

    /**
     * A search term.
     * @type {string}
     * @memberof CoreApiCoreSecretList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreSecretList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreSecretList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreSecretPartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretPartialUpdateRequest
 */
export interface CoreApiCoreSecretPartialUpdateRequest {
    /**
     * A unique integer value identifying this vault manager.
     * @type {number}
     * @memberof CoreApiCoreSecretPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {VaultManager}
     * @memberof CoreApiCoreSecretPartialUpdate
     */
    readonly data: VaultManager
}

/**
 * Request parameters for coreSecretRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretReadRequest
 */
export interface CoreApiCoreSecretReadRequest {
    /**
     * A unique integer value identifying this vault manager.
     * @type {number}
     * @memberof CoreApiCoreSecretRead
     */
    readonly id: number
}

/**
 * Request parameters for coreSecretUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreSecretUpdateRequest
 */
export interface CoreApiCoreSecretUpdateRequest {
    /**
     * A unique integer value identifying this vault manager.
     * @type {number}
     * @memberof CoreApiCoreSecretUpdate
     */
    readonly id: number

    /**
     * 
     * @type {VaultManager}
     * @memberof CoreApiCoreSecretUpdate
     */
    readonly data: VaultManager
}

/**
 * Request parameters for coreSenderCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreSenderCreateRequest
 */
export interface CoreApiCoreSenderCreateRequest {
    /**
     * 
     * @type {Sender}
     * @memberof CoreApiCoreSenderCreate
     */
    readonly data: Sender
}

/**
 * Request parameters for coreSenderList operation in CoreApi.
 * @export
 * @interface CoreApiCoreSenderListRequest
 */
export interface CoreApiCoreSenderListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreSenderList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreSenderList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreSenderPartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreSenderPartialUpdateRequest
 */
export interface CoreApiCoreSenderPartialUpdateRequest {
    /**
     * A unique integer value identifying this sender.
     * @type {number}
     * @memberof CoreApiCoreSenderPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Sender}
     * @memberof CoreApiCoreSenderPartialUpdate
     */
    readonly data: Sender
}

/**
 * Request parameters for coreSenderUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreSenderUpdateRequest
 */
export interface CoreApiCoreSenderUpdateRequest {
    /**
     * A unique integer value identifying this sender.
     * @type {number}
     * @memberof CoreApiCoreSenderUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Sender}
     * @memberof CoreApiCoreSenderUpdate
     */
    readonly data: Sender
}

/**
 * Request parameters for coreWhitelistCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreWhitelistCreateRequest
 */
export interface CoreApiCoreWhitelistCreateRequest {
    /**
     * 
     * @type {AllowedIP}
     * @memberof CoreApiCoreWhitelistCreate
     */
    readonly data: AllowedIP
}

/**
 * Request parameters for coreWhitelistDelete operation in CoreApi.
 * @export
 * @interface CoreApiCoreWhitelistDeleteRequest
 */
export interface CoreApiCoreWhitelistDeleteRequest {
    /**
     * A unique integer value identifying this allowed ip.
     * @type {number}
     * @memberof CoreApiCoreWhitelistDelete
     */
    readonly id: number
}

/**
 * Request parameters for coreWhitelistList operation in CoreApi.
 * @export
 * @interface CoreApiCoreWhitelistListRequest
 */
export interface CoreApiCoreWhitelistListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreWhitelistList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof CoreApiCoreWhitelistList
     */
    readonly offset?: number
}

/**
 * Request parameters for coreWhitelistRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreWhitelistReadRequest
 */
export interface CoreApiCoreWhitelistReadRequest {
    /**
     * A unique integer value identifying this allowed ip.
     * @type {number}
     * @memberof CoreApiCoreWhitelistRead
     */
    readonly id: number
}

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCacheClearIpRead(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCacheClearIpRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreCompanyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCompanyList(requestParameters: CoreApiCoreCompanyListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCompanyList(requestParameters.gst_number, requestParameters.domain, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreCompanyPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCompanyPartialUpdate(requestParameters: CoreApiCoreCompanyPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCompanyPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreCompanyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCompanyRead(requestParameters: CoreApiCoreCompanyReadRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCompanyRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreCompanyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCompanyUpdate(requestParameters: CoreApiCoreCompanyUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCompanyUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCrmDashboardMetricsRead(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCrmDashboardMetricsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreDashboardMetricsRead(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreDashboardMetricsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreGenericConfigListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreGenericConfigList(requestParameters: CoreApiCoreGenericConfigListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreGenericConfigList(requestParameters.category, requestParameters.name, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreGenericConfigReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreGenericConfigRead(requestParameters: CoreApiCoreGenericConfigReadRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreGenericConfigRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreHealthRead(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreHealthRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesCreate(requestParameters: CoreApiCoreRolesCreateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesList(requestParameters: CoreApiCoreRolesListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesList(requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesPartialUpdate(requestParameters: CoreApiCoreRolesPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesPermissionsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesPermissionsCreate(requestParameters: CoreApiCoreRolesPermissionsCreateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesPermissionsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesPermissionsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesPermissionsList(requestParameters: CoreApiCoreRolesPermissionsListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesPermissionsList(requestParameters.name, requestParameters.category, requestParameters.stockyrolepermissions, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesPermissionsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesPermissionsPartialUpdate(requestParameters: CoreApiCoreRolesPermissionsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesPermissionsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesPermissionsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesPermissionsUpdate(requestParameters: CoreApiCoreRolesPermissionsUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesPermissionsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesRead(requestParameters: CoreApiCoreRolesReadRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreRolesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreRolesUpdate(requestParameters: CoreApiCoreRolesUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreRolesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretCreate(requestParameters: CoreApiCoreSecretCreateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretDecodeReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretDecodeRead(requestParameters: CoreApiCoreSecretDecodeReadRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretDecodeRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretDelete(requestParameters: CoreApiCoreSecretDeleteRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretList(requestParameters: CoreApiCoreSecretListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretList(requestParameters.category, requestParameters.username, requestParameters.name, requestParameters.otp_number, requestParameters.otp_email, requestParameters.users, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretPartialUpdate(requestParameters: CoreApiCoreSecretPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretRead(requestParameters: CoreApiCoreSecretReadRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSecretUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSecretUpdate(requestParameters: CoreApiCoreSecretUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSecretUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSenderCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSenderCreate(requestParameters: CoreApiCoreSenderCreateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSenderCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSenderListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSenderList(requestParameters: CoreApiCoreSenderListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSenderList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSenderPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSenderPartialUpdate(requestParameters: CoreApiCoreSenderPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSenderPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreSenderUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSenderUpdate(requestParameters: CoreApiCoreSenderUpdateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreSenderUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreTestRead(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreTestRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreWhitelistCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreWhitelistCreate(requestParameters: CoreApiCoreWhitelistCreateRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreWhitelistCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreWhitelistDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreWhitelistDelete(requestParameters: CoreApiCoreWhitelistDeleteRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreWhitelistDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreWhitelistListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreWhitelistList(requestParameters: CoreApiCoreWhitelistListRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreWhitelistList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreWhitelistReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreWhitelistRead(requestParameters: CoreApiCoreWhitelistReadRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreWhitelistRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CoreRolesListCategory = {
    _1: '1',
    _2: '2'
} as const;
export type CoreRolesListCategory = typeof CoreRolesListCategory[keyof typeof CoreRolesListCategory];
/**
 * @export
 */
export const CoreRolesPermissionsListCategory = {
    _1: '1',
    _2: '2'
} as const;
export type CoreRolesPermissionsListCategory = typeof CoreRolesPermissionsListCategory[keyof typeof CoreRolesPermissionsListCategory];
