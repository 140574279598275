import React, { useState } from "react";
import { useAuth } from "@app/modules/auth";
import {
  orderTypeChartParamsInitial,
  orderTypeChartParamsInterface,
} from "./_models";
import { useQuery } from "react-query";
import { getErpOrderMetricsType } from "../_requests";
import { getValidMonths } from "@/Utils/Shared/DateStringToDays.ts";
import { MONTHS } from "@Constants/APP_Constants.ts";
import Select from "react-select";
import { chartYearOptions } from "../OrderStageChart/_constants";
import moment from "moment/moment";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import ChartLoader from "../../../Reports/ReportsHelper/MonthlyStatsReport/ChartLoader";
import StatsAreaChart from "../../../../../../components/UI/Cards/ChartWithStatsCard/StatsAreaChart";

function OrderTypeChartContainer(): React.JSX.Element {
  const { auth } = useAuth();

  // local states
  const [urlParams, setUrlParams] = useState<orderTypeChartParamsInterface>(
    orderTypeChartParamsInitial
  );

  // Queries
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["erp_order_metrics_type", urlParams],
    queryFn: () => {
      return getErpOrderMetricsType(auth?.access, urlParams).then(
        (res) => res.data
      );
    },
    enabled: true,
    retry: false,
    cacheTime: 1,
  });

  // Methods
  function handleParamChange(key, value) {
    setUrlParams((prev) => ({ ...prev, [key]: value }));
  }

  // Derived constants
  const seriesKeys =
    data && Object.keys(data).length > 0
      ? Object.keys(data[Object.keys(data)[0]])
      : null;

  const categoriesData = data
    ? Object.keys(data).map((date) => moment(date).format("DD MMM"))
    : null;

  const seriesData =
    data && seriesKeys
      ? seriesKeys?.map((key) => {
          return {
            name: DecorateNameTags(key),
            data: Object.values(data).map((value: any) =>
              value?.[key] ? value?.[key] : 0
            ),
          };
        })
      : null;

  const chartMonthOptions = getValidMonths(urlParams.year).map((month) => ({
    label: MONTHS[month - 1],
    value: month,
  }));

  return (
    <div className={"vstack gap-6"}>
      <div className="row justify-content-end">
        <div className="col-md-6 col-lg-3 col-xl-2">
          <Select
            className="react-select-styled react-select-solid react-select-sm"
            classNamePrefix="react-select"
            placeholder={"Select Year"}
            openMenuOnFocus
            options={chartYearOptions}
            onChange={(option) => {
              handleParamChange("year", option?.value);
            }}
            value={
              chartYearOptions.find(
                (option) => option.value === urlParams?.year
              ) ?? null
            }
          />
        </div>
        <div className="col-md-6 col-lg-3 col-xl-2">
          <Select
            className="react-select-styled react-select-solid react-select-sm"
            classNamePrefix="react-select"
            placeholder={"Select Month"}
            openMenuOnFocus
            isClearable
            options={chartMonthOptions}
            onChange={(option) => {
              handleParamChange("month", option?.value);
            }}
            value={
              chartMonthOptions.find(
                (option) => option.value === urlParams?.month
              ) ?? null
            }
          />
        </div>
      </div>

      {isLoading || isFetching || !data ? (
        <ChartLoader height={515} />
      ) : (
        <div className={"z-index-0"}>
          <StatsAreaChart
            categoriesData={categoriesData}
            seriesData={seriesData}
            height={500}
          />
        </div>
      )}
    </div>
  );
}

export default OrderTypeChartContainer;
