import axios from "axios";
import { getPublicSerialInfo } from "@Hooks/_requestees";

const API_URL = import.meta.env.VITE_APP_API_URL;

const CONNECT_INORMATION_BY_SLUG = `${API_URL}/stocky/public/ticket/connect/`;

const ADD_CLIENT_REQUEST_DETAILS = `${API_URL}/stocky/client_requests/`;
const GET_CONSIGNEE_BY_SLUG_AND_SERIAL = `${API_URL}/rentals/assigned/serial`;

export function getConnectionInformation(slug: any) {
  return axios.get<any>(`${CONNECT_INORMATION_BY_SLUG}${slug}/`);
}

export function addClientRequestDetails(token: any, payload: any) {
  return axios.post<any>(`${ADD_CLIENT_REQUEST_DETAILS}`, payload, {});
}

export function getConsigneeBySlugAndSerial(
  slug: string | undefined,
  serial: string | undefined
) {
  return axios
    .get(`${GET_CONSIGNEE_BY_SLUG_AND_SERIAL}/${slug}/${serial}`)
    .then((res) => res.data);
}

export function getSerialData(serial: string) {
  return getPublicSerialInfo(serial).then((res) => res.data);
}
