import React from "react";
const ClearFilterSetBtn = ({ setFiletr = (e) => {} }) => {
  const HandleFilterCler = () => {
    setFiletr((e) => {
      return {
        limit: 15,
        offset: 0,
      };
    });
  };

  return (
    <>
      <div
        className="badge badge-sm badge-danger rounded-pill cursor-pointer"
        onClick={() => HandleFilterCler()}
      >
        Clear Filter
      </div>
    </>
  );
};

export default ClearFilterSetBtn;
