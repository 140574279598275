import StatesByGST from "./StateByGSTCode.json";

export function getStateByGSTCode(code: number) {
  for (const item of StatesByGST) {
    if (item.code == code) {
      return item.state;
    }
  }
}

export const statesOptions = StatesByGST.map((item) => {
  return { label: item.state, value: item.state };
});
