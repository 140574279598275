/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SupportPopover from "../../pages/crm/CRMPageWrapper/SupportPopover";
import useBodyBackground from "@Hooks/useBodyBackground.tsx";
import StockyProLogo from "@components/helpers/CustomImage/StockyProLogo.tsx";

const AuthLayout = () => {
  useBodyBackground({
    urlLight: "/media/auth/bg4.jpg",
    urlDark: "/media/auth/bg4-dark.jpg",
  });

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-column-fluid flex-lg-row overlow-hidden">
        {/* Aside */}
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <div className="d-flex flex-center flex-lg-center flex-column">
            <StockyProLogo
              lightModeWhiteLogo={true}
              className="h-100px h-lg-150px"
            />
            <h2 className="text-white text-center lh-base fw-normal">
              Request, Manage, Track & Support.
            </h2>
            <h2 className="text-white text-center lh-base fw-normal m-0">
              Your Business, Simplified
            </h2>
          </div>
        </div>

        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end align-items-lg-center p-8 px-lg-20">
          <div className="w-375px w-md-600px">
            <div className="card card-flush shadow-sm rounded-4 min-h-600px">
              <div className="card-body p-20 d-flex flex-column">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportPopover />
    </div>
  );
};

export { AuthLayout };
