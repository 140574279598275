export const All_Login_type = {
  LOGGED_IN: 1,
  FAILED: 2,
  LOGGED_OUT: 3,
};
export const All_Login_Type_Color = {
  LOGGED_IN: "success",
  FAILED: "danger",
  LOGGED_OUT: "warning",
};
