import React, { ReactElement } from "react";
import { ColorsMain, Positions } from "@components/UI/uiTypes.ts";

type xProps = {
  text: string | ReactElement;
  textClassNames?: string;
  borderColor?: ColorsMain;
  position?: Positions;
};

function SeparatorX({
  textClassNames,
  text,
  borderColor,
  position = "50",
}: xProps): React.JSX.Element {
  return (
    <div className={"d-flex align-items-center position-relative"}>
      <span
        className={`flex-grow-1 border-bottom border-${borderColor}`}
      ></span>
      <span
        className={`fs-5 px-4 position-absolute ${textClassNames} start-${position} translate-middle-x`}
      >
        {text}
      </span>
      <span
        className={`flex-grow-1 border-bottom border-${borderColor}`}
      ></span>
    </div>
  );
}

export default SeparatorX;
