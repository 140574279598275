import React from "react";
import TrackingDetails from "@app/pages/erp/Logistics/TrackLogistics/Components/TrackingDetails.tsx";
import BackgroundWrapper from "@components/UI/Wrappers/BackgroundWrapper.tsx";

function TrackLogisticPage() {
  return (
    <BackgroundWrapper>
      <div className="container">
        <div className="min-vh-100 row flex-center">
          <div className="col">
            <div className="card shadow">
              <div className="card-body px-0">
                <TrackingDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundWrapper>
  );
}
export default TrackLogisticPage;
