import React, { useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { DateFormat } from "@/Utils/Shared/DateStringToDays.ts";

const DateRangePicker = ({ setUrlParams }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const flatpickrRef = useRef(null);

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    if (dates.length === 2) {
      // setUrlParams((prev) => ({
      //   ...prev,
      //   start_date: format(dates[0], "yyyy-MM-dd"),
      //   end_date: format(dates[1], "yyyy-MM-dd"),
      //   limit: null,
      //   offset: null,
      // }));
      setUrlParams(
        moment(dates[0]).format(DateFormat.YYYY_MM_DD),
        moment(dates[1]).format(DateFormat.YYYY_MM_DD)
      );
    }
  };

  const clearDates = () => {
    setSelectedDates([]);
    // setUrlParams((prev) => ({
    //   ...prev,
    //   start_date: null,
    //   end_date: null,
    //   limit: null,
    //   offset: null,
    // }));
    setUrlParams(null, null);
    // @ts-ignore
    if (flatpickrRef?.current && flatpickrRef?.current?.flatpickr) {
      // @ts-ignore
      flatpickrRef?.current?.flatpickr.clear();
    }
  };

  return (
    <div>
      <div>
        <div className={"input-group"}>
          <Flatpickr
            ref={flatpickrRef}
            placeholder="Date"
            value={selectedDates}
            options={{
              mode: "range",
              dateFormat: "d-m-Y",

              onClose: handleDateChange,
            }}
            className="form-control form-control-sm"
          />
          {selectedDates.length === 2 && (
            <i
              className=" input-group-text cursor-pointer bi bi-x fs-2"
              onClick={clearDates}
            >
              {" "}
            </i>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
