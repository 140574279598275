import React from "react";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "@app/modules/auth";
import {
  CRM_AR_PATHS,
  CRM_ASSET_VERIFICATION_PATHS,
  CRM_ASSETS_PATHS,
  CRM_LOGISTICS_PATHS,
  CRM_MANAGE_ASSETS_PATHS,
  CRM_MEMBERS_PATHS,
  CRM_ORDER_PATHS,
  CRM_RENTALS_PATHS,
  CRM_SUPPORT_REQUEST_PATHS,
  CRM_USER_PATHS,
} from "@Constants/RoutingConstants/crm/CrmRedirectRouteConstants.ts";

const CrmSidebar = () => {
  // const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      <div className="menu-item ">
        <div className="menu-content">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {currentUser?.account?.connect?.company_display_name}
          </span>
        </div>
      </div>

      {/* AR */}
      <SidebarMenuItemWithSub
        title="Asset Request"
        to={"/crm/ar"}
        icon={"bi bi-lightning-charge-fill text-gold"}
        fontIcon={"bi bi-lightning-charge-fill"}
        permission={[
          "user.list_rental_asset_request",
          "user.view_rental_asset_request",
          "user.create_rental_asset_request",
          "user.update_rental_asset_request",
          "user.delete_rental_asset_request",
        ]}
      >
        <SidebarMenuItem
          to={CRM_AR_PATHS.CREATE}
          title="Create"
          hasBullet={true}
          permission={"user.create_rental_asset_request"}
        />
        <SidebarMenuItem
          to={CRM_AR_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission={"user.view_rental_asset_request"}
        />
      </SidebarMenuItemWithSub>

      {/* Rentals */}
      <SidebarMenuItemWithSub
        to="/crm/rentals"
        icon="bi bi-caret-down-square-fill"
        // title={intl.formatMessage({id: 'On Boarding'})}
        title="Rentals"
        fontIcon="bi bi-caret-down-square-fill"
        permission={[
          "user.view_rentals",
          "user.list_rental_summary",
          "user.view_rental_summary",
          "user.list_rental_rates",
          "user.view_rental_rates",
          "user.list_rentals",
          "user.view_rentals",
          "user.list_consolidated_rentals_rate",
          "user.view_consolidated_rentals_rate",
        ]}
      >
        <SidebarMenuItem
          to={CRM_RENTALS_PATHS.SHEET}
          title="Sheet"
          hasBullet={true}
          permission="user.list_rentals"
        />
        <SidebarMenuItem
          to={CRM_RENTALS_PATHS.LIVE_RATE}
          title="Live Rate"
          hasBullet={true}
          permission="user.list_consolidated_rentals_rate"
        />
        <SidebarMenuItem
          to={CRM_RENTALS_PATHS.ASSIGN}
          title="Assign"
          hasBullet={true}
          permission="user.list_rental_asset_request"
        />
        <SidebarMenuItem
          to={CRM_RENTALS_PATHS.ASSIGNED}
          title="Assigned"
          hasBullet={true}
          permission="user.list_rental_asset_request"
        />
      </SidebarMenuItemWithSub>

      {/* Support */}
      <SidebarMenuItemWithSub
        to="/crm/"
        icon="bi bi-bookmark-heart-fill"
        // title={intl.formatMessage({id: 'On Boarding'})}
        title="Support"
        fontIcon="bi bi-bookmark-heart-fill"
        permission={["user.list_support_request"]}
      >
        <SidebarMenuItem
          to={CRM_SUPPORT_REQUEST_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission="user.list_support_request"
        />
        <SidebarMenuItem
          to={`/support-request/raise/${currentUser?.account?.connect?.slug}`}
          title="Print QR"
          hasBullet={true}
          permission="user.create_support_request"
          newTab={true}
        />
      </SidebarMenuItemWithSub>

      {/* Orders */}
      <SidebarMenuItem
        to={CRM_ORDER_PATHS.LIST}
        icon="bi bi-x-diamond-fill"
        // title={intl.formatMessage({id: 'On Boarding'})}
        title="Orders"
        fontIcon="bi bi-x-diamond-fill"
        permission={"user.list_orders"}
      />

      {/* Logistics */}
      <SidebarMenuItemWithSub
        to="/crm/logistics"
        icon="bi bi-card-list"
        // title={intl.formatMessage({id: 'On Boarding'})}
        title="Logistics"
        fontIcon="bi bi-card-list"
        permission={["user.list_support_request"]}
      >
        <SidebarMenuItem
          to={CRM_LOGISTICS_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission="user.list_logistics"
        />
        <SidebarMenuItem
          to={CRM_LOGISTICS_PATHS.RATE}
          title="Rate"
          hasBullet={true}
          permission="user.list_logistics"
        />
      </SidebarMenuItemWithSub>

      {/* Asset Verification */}
      <SidebarMenuItemWithSub
        to="/crm/asset-verification"
        icon="bi bi-database-fill-check"
        // title={intl.formatMessage({id: 'On Boarding'})}
        title="Asset Verification"
        fontIcon="bi bi-database-fill-check"
        permission={[
          "user.list_asset_verification_master",
          "user.create_asset_verification_master",
        ]}
      >
        {/*<SidebarMenuItem to='/crm/asset-verification/create' title='Create' hasBullet={true} />*/}
        <SidebarMenuItem
          to={CRM_ASSET_VERIFICATION_PATHS.CREATE}
          title="Create"
          hasBullet={true}
          permission="user.create_asset_verification_master"
        />
        <SidebarMenuItem
          to={CRM_ASSET_VERIFICATION_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission="user.list_asset_verification_master"
        />
      </SidebarMenuItemWithSub>

      {/* Secondary Menu */}
      <>
        <div className="menu-item mt-20">
          <div className="menu-content">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              {currentUser?.account?.connect?.name}{" "}
            </span>
          </div>
        </div>

        {/* Asset */}
        <SidebarMenuItemWithSub
          to="/crm/assets"
          icon="bi bi-laptop"
          // title={intl.formatMessage({id: 'On Boarding'})}
          title="Assets"
          fontIcon="bi bi-laptop"
          permission={[
            "user.list_asset_verification_master",
            "user.view_asset_verification_master",
            "user.create_asset_verification_master",
            "user.update_asset_verification_master",
          ]}
        >
          <SidebarMenuItem
            to={CRM_ASSETS_PATHS.CREATE}
            title="Create"
            hasBullet={true}
            permission="user.create_asset_verification_master"
          />
          <SidebarMenuItem
            to={CRM_ASSETS_PATHS.LIST}
            title="List"
            hasBullet={true}
            permission="user.list_asset_verification_master"
          />
        </SidebarMenuItemWithSub>

        {/* Manage Asset */}
        <SidebarMenuItemWithSub
          to="/crm/manage-assets"
          icon="bi bi-list-check"
          // title={intl.formatMessage({id: 'On Boarding'})}
          title="Manage Assets"
          fontIcon="bi bi-list-check"
          permission={[
            "user.list_asset_verification_master",
            "user.view_asset_verification_master",
          ]}
        >
          <SidebarMenuItem
            to={CRM_MANAGE_ASSETS_PATHS.TRANSACT}
            title="Transact"
            hasBullet={true}
            permission="user.create_asset_verification_master"
          />
          <SidebarMenuItem
            to={CRM_MANAGE_ASSETS_PATHS.LIST}
            title="List"
            hasBullet={true}
            permission="user.list_asset_verification_master"
          />
        </SidebarMenuItemWithSub>

        {currentUser?.groups?.find((group) => group.name === "rms_admin") ? (
          <SidebarMenuItemWithSub
            to={"/crm/members"}
            icon={"bi bi-people-fill"}
            title={"Members"}
            fontIcon={"bi bi-people-fill"}
            permission={["user.list_support_request"]}
          >
            <SidebarMenuItem
              to={CRM_MEMBERS_PATHS.LIST}
              title="List"
              hasBullet={true}
              permission="user.list_support_request"
            />
          </SidebarMenuItemWithSub>
        ) : null}

        {currentUser?.groups?.find((e) => e.name === "rms_admin") && (
          <SidebarMenuItemWithSub
            to="/crm/user"
            icon="bi bi-person-vcard-fill"
            // title={intl.formatMessage({id: 'On Boarding'})}
            title="Users"
            fontIcon="bi bi-person-vcard-fill"
            permission={["user.list_support_request"]}
          >
            <SidebarMenuItem
              to={CRM_USER_PATHS.LIST}
              title="List"
              hasBullet={true}
              permission="user.list_support_request"
            />
          </SidebarMenuItemWithSub>
        )}

        <SidebarMenuItem
          to="/crm/onboarding"
          icon="bi bi-person-fill-up"
          // title={intl.formatMessage({id: 'On Boarding'})}
          title="On Boarding "
          fontIcon="bi bi-person-fill-up"
        />
      </>
    </>
  );
};

export { CrmSidebar };
