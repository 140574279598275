import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CommonPageHeader from "@components/helpers/CommonPageHeader/CommonPageHeader";
import RarDisclaimer from "./RarDisclaimer";
import RarPublicView from "./RarPublicView";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import { publicRentalsApi } from "@/api/public";

function RarViewPublicPage(): React.JSX.Element {
  const { rarSlug } = useParams();
  const slug = rarSlug ?? "";

  //   Queries
  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ["rar_request_public"],
    queryFn: () => {
      // return getPublicAssetRequestBySlug(slug).then((res) => res.data);
      return publicRentalsApi
        .rentalsPublicRequestRead({ slug: parseInt(slug) })
        .then((res) => res.data);
    },
    enabled: !!slug,
    retry: false,
  });
  // @ts-ignore
  UseErrorRedirection(error?.response);

  return (
    <div className={"container"}>
      <div className={"vstack mt-10"}>
        <CommonPageHeader
          id={data?.connect?.company}
          heading={`Asset Request`}
        />
        <div>
          <div className={"card"}>
            <div className={"card-body"}>
              <RarPublicView data={data} loading={isLoading || isFetching} />
            </div>
          </div>
          <RarDisclaimer />
        </div>
      </div>
    </div>
  );
}

export default RarViewPublicPage;
