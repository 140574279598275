export const DecorateNameTags = (emailStr) => {
  if (!emailStr) {
    return null;
  }

  // Replace underscores with space
  let formattedStr = emailStr?.replace(/_+/g, " ");

  // Capitalize first letter of each word
  formattedStr = formattedStr?.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  // Remove extra spaces
  formattedStr = formattedStr?.replace(/\s+/g, " ");

  return formattedStr;
};
