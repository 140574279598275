import React from "react";

function RarDisclaimer(): React.JSX.Element {
  return (
    <div className="card border-0 bg-light">
      <div className="card-body">
        <h4 className="">Disclaimer</h4>
        <p className="">
          The Asset request (AR) details contain confidential information
          pertaining to the business relationship between the Company and its
          clients. By accessing the link, you agree to respect the privacy and
          confidentiality of this information. You agree not to disclose,
          disseminate, or use the information contained within the AR details
          for any purpose that is not explicitly authorized by the Company.
        </p>
        <p className={""}>
          Unauthorized use, reproduction, or distribution of the AR details or
          any information contained therein is strictly prohibited and may be
          subject to legal action.
        </p>
        <p className={"m-0"}>
          The Company reserves the right to track, restrict or revoke access to
          the AR Details Link at any time and for any reason, at its sole
          discretion.
        </p>
      </div>
    </div>
  );
}

export default RarDisclaimer;
