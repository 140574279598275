import React from "react";
import { Placeholder } from "react-bootstrap";

function TextLinesSkeleton({
  count = 3,
}: {
  count?: number;
}): React.JSX.Element {
  return (
    <section className="mb-6">
      {Array.from({ length: count }).map((elem, index) => {
        return (
          <div className={"row"} key={index}>
            <div className={"col"}>
              <Placeholder as="span" animation="glow">
                <Placeholder bg={"secondary"} className={"col-6"} />
              </Placeholder>
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default TextLinesSkeleton;
