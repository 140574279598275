import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getTrackingDetails } from "@app/pages/erp/Logistics/TrackLogistics/_requests.ts";
import TrackingDetailsMain from "@app/pages/erp/Logistics/TrackLogistics/Components/TrackingDetailsMain.tsx";
import TrackingDetailsSide from "@app/pages/erp/Logistics/TrackLogistics/Components/TrackingDetailsSide.tsx";
import LottieLogistics from "@components/helpers/CustomAlerts/Lottie/LottieLogistics.tsx";
import useErrorRedirection from "@Hooks/useErrorRedirection.tsx";

function TrackingDetails() {
  const { slug } = useParams();

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey: ["logistics_track", slug],
    queryFn: () => {
      return getTrackingDetails(slug!).then((res) => res.data);
    },
    enabled: !!slug,
    retry: false,
  });
  // @ts-ignore
  useErrorRedirection(error?.response);

  return (
    <>
      {isLoading || isFetching ? (
        <>
          <div className="d-flex flex-center" style={{ minHeight: "60dvh" }}>
            <LottieLogistics />
          </div>
        </>
      ) : (
        <div className="row gy-lg-8 gx-0 animate__animated animate__fadeIn">
          <>
            <div className="col-12 col-lg-5 col-xl-4">
              <TrackingDetailsSide data={data} />
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <TrackingDetailsMain data={data} />
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default TrackingDetails;
