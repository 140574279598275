import React from "react";
import Error from "@metronic/assets/lottie-assets/TomatoError.json";
import LazyLottie from "@components/helpers/CustomAlerts/Lottie/LazyLottie.tsx";

function LottieError({ maxWidth }: { maxWidth?: string }): React.JSX.Element {
  return (
    <>
      <LazyLottie animationData={Error} maxWidth={maxWidth} />
    </>
  );
}

export default LottieError;
