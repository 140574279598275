import React from "react";
import { chartColors } from "./_constants";
import ApexChart, { ApexChartOptions } from "../../Charts/ApexChart";

export interface StatsAreaChartProps {
  categoriesData: any | null;
  seriesData: any | null;
  showToolbar: boolean;
  height: number;
}

export default function StatsAreaChart({
  categoriesData,
  seriesData,
  showToolbar,
  height,
}: StatsAreaChartProps): React.JSX.Element {
  const options: ApexChartOptions = {
    chart: {
      toolbar: {
        show: showToolbar,
      },
      type: "area",
      id: "chart_stage",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.9,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
      colors: chartColors,
    },
    colors: chartColors,
    xaxis: {
      categories: categoriesData
        ? categoriesData
        : [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20,
          ],
      tooltip: { enabled: false },
      labels: {
        offsetX: 1,
        rotate: -70,
        style: {
          fontSize: "1rem",
          colors: "#999",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter(val: number, opts?: any): string | string[] {
          return `${val}`;
        },
        style: {
          fontSize: "1rem",
          colors: "#999",
        },
      },
      tickAmount: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    legend: { itemMargin: { horizontal: 20, vertical: 20 }, position: "top" },
    grid: {
      strokeDashArray: 6,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "var(--bs-brand-primary)",
        fontSize: "20px",
      },
    },
  };

  const series = seriesData
    ? seriesData
    : [
        {
          name: "No Data",
          data: [],
        },
      ];

  return (
    <>
      <ApexChart
        options={options}
        series={series}
        type="area"
        height={`${height}`}
      />
    </>
  );
}

StatsAreaChart.defaultProps = {
  categoriesData: null,
  seriesData: null,
  showToolbar: true,
  height: 400,
};
