import React from "react";
import {
  longDate,
  timeAgo,
} from "@/Utils/Shared/DateStringToDays.ts";
import { task_status_variant } from "@Constants/APP_Constants";
// import {useNavigate} from "react-router-dom";

const TicketeRow = ({ data }) => {
  const truncatedDescription = data?.description
    ? `${data?.description.slice(0, 100)}${
        data?.description.length > 100 ? "..." : ""
      }`
    : "";
  // const navigation = useNavigate();
  const handleRowDoubleClick = () => {
    const route = `/public/tickets/track/${data?.task_id}`;
    window.open(route);
    // navigation(route);
  };

  return (
    <tr onDoubleClick={handleRowDoubleClick}>
      <td>
        <span
          className={`badge badge-${task_status_variant[data?.status_label]}`}
        >
          {data?.status_label}
        </span>
        <p className="p-0 m-0 fw-bold">{data?.department_name}</p>
      </td>
      <td>{data?.title}</td>
      <td>
        {data?.client_info?.name}
        <p className="p-0 m-0">
          {data?.client_info?.number} | {data?.client_info?.email}
        </p>
      </td>
      <td>
        <p className="p-0 m-0 ">
          <b>Created: </b> {timeAgo(data?.created_at)}
        </p>
        <p className="p-0 m-0 ">
          <b>Updated: </b>
          {timeAgo(data?.updated_at)}
        </p>
        <p className="p-0 m-0 ">
          <b className={"text-danger"}>Deadline: </b>
          {longDate(data?.deadline)}
        </p>
      </td>
      <td className="w-ma">
        <div
          className="max-w-50px"
          dangerouslySetInnerHTML={{ __html: truncatedDescription }}
        />
      </td>
    </tr>
  );
};
export default TicketeRow;
