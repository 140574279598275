import React, { useEffect, useState } from "react";
import { getRemainingTime } from "@/Utils/Shared/DateStringToDays.ts";

type CountdownTimerProps = {
  initial: number;
  onComplete?: () => void;
};

const COUNTER_STATUS = {
  IDLE: "IDLE",
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
} as const;

type CounterStatus = keyof typeof COUNTER_STATUS;

function CountdownTimer({ initial, onComplete }: CountdownTimerProps) {
  const [countSeconds, setCountSeconds] = useState(0);
  const [status, setStatus] = useState<CounterStatus>(COUNTER_STATUS.IDLE);

  // Effect for re-initializing counter
  useEffect(() => {
    setCountSeconds(initial);
    setStatus(COUNTER_STATUS.RUNNING);
  }, [initial]);

  // Effect for count down
  useEffect(() => {
    const timer = setInterval(() => {
      if (countSeconds !== 0) {
        setCountSeconds(countSeconds - 1);
      }
    }, 1000);

    if (countSeconds === 0 && status === COUNTER_STATUS.RUNNING) {
      setStatus(COUNTER_STATUS.COMPLETED);
      if (onComplete) {
        onComplete();
      }
    }

    return () => clearInterval(timer);
  }, [countSeconds, onComplete, status]);

  const { days, hours, minutes, seconds } = getRemainingTime(countSeconds);

  return (
    <>
      <div className={"fs-3x"} style={{ fontFamily: "monospace, Courier" }}>
        {days > 0 && <span>{days} days</span>}
        {hours > 0 && <span>{hours.toString().padStart(2, "0")}:</span>}
        <span>{minutes.toString().padStart(2, "0")}:</span>
        <span>{seconds.toString().padStart(2, "0")}</span>
      </div>
    </>
  );
}

export default CountdownTimer;
