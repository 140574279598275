import React, { useState } from "react";
import { useFormik } from "formik";
import { publicAssetAssignedSchema } from "./_models.ts";
import { getAssignedAssetData, postPublicAssetAssign } from "./_requests.ts";
import { toast } from "react-toastify";
import ProductCardSm from "@components/helpers/CrmHelpers/Ui/ProductCardSm.tsx";
import QuillTextEditor from "@components/helpers/ReactQuill/QuillTextEditor.tsx";
import {
  handleNumberInputKeyDown,
  handleNumberInputWheel,
} from "@/Utils/FormHelpers/FormHelpers.ts";
import ClientUserCardSm from "@components/helpers/CrmHelpers/Ui/ClientUserCardSm.tsx";
import { CLIENT_SELECTION_STATUS } from "../../../Create/CreateTicket/_constants.ts";
import { useQuery } from "react-query";
import useDocumentTitle from "@Hooks/useDocumentTitle.tsx";
import SearchInput from "@components/UI/Inputs/SearchInput.tsx";
import CreatableCitySelector from "@components/helpers/CustomSelectors/CreatableCitySelector.tsx";
import StateSelector from "@components/helpers/CustomSelectors/StateSelector.tsx";
import CreatableSelect from "react-select/creatable";
import { TAGS_CATEGORY } from "@Constants/APP_Constants.ts";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import IconSend from "@components/UI/Icons/IconSend.tsx";
import { TableSuccess } from "@components/helpers/CustomAlerts/TableSuccess.tsx";

type PublicAssignedAssetFormProps = {
  accountData: any;
};

function PublicAssignAssetForm({
  accountData,
}: PublicAssignedAssetFormProps): React.JSX.Element {
  useDocumentTitle({ title: "Assign Asset" });

  //   Local states
  const [assignedData, setAssignedData] = useState<any>(null);
  const [serial, setSerial] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [selection, setSelection] = useState(
    CLIENT_SELECTION_STATUS.UNSELECTED
  );

  const formik = useFormik({
    initialValues: {
      connect_slug: `${accountData?.slug}`,
      name: null,
      number: null,
      email: null,
      rental: null,
      note: null,
      city: null,
      state: null,
      pincode: null,
      tag: null,
    },
    validationSchema: publicAssetAssignedSchema,
    onSubmit: (values, { setSubmitting }) => {
      const formattedValues = {
        connect_slug: values.connect_slug,
        assigned_info: {
          name: values.name,
          number: values.number,
          email: values.email,
        },
        rental: values.rental,
        note: values.note,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
        tag: values.tag,
      };

      postPublicAssetAssign(formattedValues)
        .then(() => {
          toast.success("Assigned successfully.");
          setIsSuccess(true);
        })
        .catch(() => {
          toast.error("Error assigning asset");
          setIsSuccess(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  // Query
  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ["serial_data", serial],
    queryFn: () => {
      return getAssignedAssetData(accountData?.slug, serial).then(
        (res) => res.data
      );
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setAssignedData(data);
      formik.setFieldValue("rental", data?.id, true);
    },
    onError: () => {
      setAssignedData(null);
      toast.error("Serial not found. Please try again.");
      setSelection(CLIENT_SELECTION_STATUS.UNSELECTED);
    },
  });

  // Methods
  function handleSerialChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSerial(e.target.value);
  }

  function handleCreateClick() {
    formik.resetForm();
    setSerial("");
    setAssignedData(null);
    setIsSuccess(false);
    setSelection(CLIENT_SELECTION_STATUS.UNSELECTED);
  }

  function handleYesClick() {
    setSelection(CLIENT_SELECTION_STATUS.ASSIGNED);
  }

  function handleNoClick() {
    setSelection(CLIENT_SELECTION_STATUS.NOT_ASSIGNED);
    formik.resetForm({
      values: {
        connect_slug: `${accountData?.slug}`,
        name: null,
        number: null,
        email: null,
        rental: formik.values.rental,
        note: null,
        city: null,
        state: null,
        pincode: null,
        tag: null,
      },
    });
  }

  // Derived
  const productData = {
    serial: assignedData?.product_details?.computer?.serial,
    config: assignedData?.product_details?.computer?.cpuversion,
    model: assignedData?.product_details?.computer?.item_name,
  };

  return (
    <div>
      <>
        {!isSuccess ? (
          <form onSubmit={formik.handleSubmit}>
            <div className="card bg-light mb-6">
              <div className="card-body vstack gap-6">
                <div className="row align-items-end gy-6">
                  <div className="col-12 col-xl-6">
                    <label
                      htmlFor={"assigned_asset_serial"}
                      className={"form-label fw-bold"}
                    >
                      Serial
                    </label>
                    <SearchInput
                      id={"assigned_asset_serial"}
                      className="form-control form-control-sm"
                      name="serial"
                      onChange={handleSerialChange}
                      autoFocus
                      handleSearch={refetch}
                      loading={isLoading || isFetching}
                    />
                  </div>

                  {assignedData?.product_details ? (
                    <div className="col-12 col-xl-6">
                      <ProductCardSm data={productData} />
                    </div>
                  ) : null}
                </div>

                {assignedData ? (
                  <div className="separator border-secondary my-6"></div>
                ) : null}

                {assignedData?.assigned_details ? (
                  <div className="row gy-6">
                    <div className="col-xl-6">
                      <div className="card">
                        <div className="card-body">
                          <ClientUserCardSm
                            user={assignedData?.assigned_details}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-xl-6">
                      <div className="vstack gap-6">
                        <div
                          className={
                            "separator separator-content border-secondary"
                          }
                        >
                          <span className={"w-200px"}>
                            {assignedData?.assigned_details?.name}?
                          </span>
                        </div>

                        <div className="vstack gap-3">
                          <button
                            type={"button"}
                            className={`btn btn-sm btn-outline ${
                              selection === CLIENT_SELECTION_STATUS.ASSIGNED
                                ? "btn-bg-success border-success text-white"
                                : "text-success"
                            }`}
                            onClick={handleYesClick}
                          >
                            Yes, I am
                          </button>
                          <button
                            type={"button"}
                            className={`btn btn-sm btn-outline ${
                              selection === CLIENT_SELECTION_STATUS.NOT_ASSIGNED
                                ? "btn-bg-warning border-warning text-white"
                                : "text-warning"
                            }`}
                            onClick={handleNoClick}
                          >
                            No, I am not
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {selection === CLIENT_SELECTION_STATUS.NOT_ASSIGNED ? (
                  <div className="row gy-6">
                    <div className="col-12 col-xl-6">
                      <label
                        htmlFor={"assigned_asset_name"}
                        className={"form-label fw-bold"}
                      >
                        Name <span className={"text-danger"}>*</span>
                      </label>
                      <input
                        type={"text"}
                        id={"assigned_asset_name"}
                        className="form-control form-control-sm"
                        name={"name"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <span className={"text-danger"}>
                          {formik.errors.name}
                        </span>
                      ) : null}
                    </div>

                    <div className="col-12 col-xl-6">
                      <label
                        htmlFor={"assigned_asset_number"}
                        className={"form-label fw-bold"}
                      >
                        Number <span className={"text-danger"}>*</span>
                      </label>
                      <input
                        type={"number"}
                        id={"assigned_asset_number"}
                        className="form-control form-control-sm"
                        name={"number"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onWheel={handleNumberInputWheel}
                        onKeyDown={handleNumberInputKeyDown}
                      />
                      {formik.touched.number && formik.errors.number ? (
                        <span className={"text-danger"}>
                          {formik.errors.number}
                        </span>
                      ) : null}
                    </div>

                    <div className="col-12 col-xl-6">
                      <label
                        htmlFor={"assigned_asset_email"}
                        className={"form-label fw-bold"}
                      >
                        Email <span className={"text-danger"}>*</span>
                      </label>
                      <input
                        type={"email"}
                        id={"assigned_asset_email"}
                        className="form-control form-control-sm"
                        name={"email"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className={"text-danger"}>
                          {formik.errors.email}
                        </span>
                      ) : null}
                    </div>

                    <div className="col-12 col-xl-6">
                      <label
                        className="form-label"
                        htmlFor="create_user_form_city"
                      >
                        City <span className={"text-danger"}>*</span>
                      </label>
                      <CreatableCitySelector
                        placeholder={"Select City"}
                        inputId={"create_user_form_city"}
                        onChange={(option) => {
                          formik.setFieldValue("city", option?.name);
                        }}
                        onBlur={() => {
                          formik.setFieldTouched("city", true);
                        }}
                      />
                      {formik.errors?.city && formik?.touched?.city && (
                        <div className="text-danger">{formik.errors?.city}</div>
                      )}
                    </div>

                    <div className="col-12 col-xl-6">
                      <label
                        className="form-label"
                        htmlFor="create_user_form_state"
                      >
                        State <span className="text-danger">*</span>
                      </label>
                      <StateSelector
                        placeholder={"Select State"}
                        inputId={"create_user_form_state"}
                        onChange={(option) => {
                          formik.setFieldValue("state", option?.value);
                        }}
                        onBlur={() => {
                          formik.setFieldTouched("state", true);
                        }}
                      />
                      {formik.errors?.state && formik?.touched?.state && (
                        <div className="text-danger">
                          {formik.errors?.state}
                        </div>
                      )}
                    </div>

                    <div className="col-12 col-xl-6">
                      <label
                        className="form-label"
                        htmlFor="create_user_form_pincode"
                      >
                        PIN Code <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        id={"create_user_form_pincode"}
                        name={"pincode"}
                        className={"form-control form-control-sm"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onWheel={handleNumberInputWheel}
                        onKeyDown={handleNumberInputKeyDown}
                      />
                      {formik.errors?.pincode && formik?.touched?.pincode && (
                        <div className="text-danger">
                          {formik.errors?.pincode}
                        </div>
                      )}
                    </div>

                    <div className="col-12 col-xl-6">
                      <label
                        className="form-label"
                        htmlFor="create_user_form_tag"
                      >
                        Tag
                      </label>
                      <CreatableSelect
                        // components={{ Menu: () => <></> }}
                        className="react-select-styled react-select-sm"
                        classNamePrefix="react-select"
                        inputId={"create_user_form_tag"}
                        placeholder="Enter Tags"
                        isMulti
                        isClearable
                        onChange={(options) => {
                          const values = options?.map((option: any) => {
                            return {
                              name: option?.value,
                              category: TAGS_CATEGORY.USER_MASTER,
                            };
                          });
                          formik.setFieldValue("tag", values);
                        }}
                        onBlur={() => {
                          formik.setFieldTouched("tag", true);
                        }}
                        formatCreateLabel={(inputValue) => (
                          <span className={"text-dark"}>
                            Add -{" "}
                            <span className={"text-primary fw-bold"}>
                              {inputValue}
                            </span>
                          </span>
                        )}
                      />
                      {formik.errors?.tag && formik?.touched?.tag && (
                        <div className="text-danger">
                          {formik.errors?.tag ? "Tag name is required" : null}
                        </div>
                      )}
                    </div>

                    <div className="col-12 z-index-0">
                      <label
                        htmlFor={"assigned_asset_note"}
                        className={"form-label fw-bold"}
                      >
                        Note
                      </label>
                      <QuillTextEditor
                        value={formik.values.note}
                        setterFunction={(data) =>
                          formik.setFieldValue("note", data)
                        }
                        blrfuncton={() => formik.setFieldTouched("note", true)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {selection !== CLIENT_SELECTION_STATUS.UNSELECTED ? (
              selection === CLIENT_SELECTION_STATUS.ASSIGNED ? (
                <>
                  <div className="alert bg-light-success d-flex flex-center gap-2">
                    <span className={"text-success-emphasis fw-bold fs-5"}>
                      You are all set!
                    </span>
                    <div>
                      <i className="ki-duotone ki-double-check-circle fs-2x text-success-emphasis">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    </div>
                  </div>
                </>
              ) : (
                <div className="card-footer pb-0">
                  <div className="d-flex flex-center">
                    <SubmitButton
                      type={"submit"}
                      disabled={formik.isSubmitting}
                      loading={formik.isSubmitting}
                      text={"Assign"}
                      icon={<IconSend />}
                    />
                  </div>
                </div>
              )
            ) : null}
          </form>
        ) : (
          <>
            <div className="vstack gap-6">
              <TableSuccess />
              <button
                type={"button"}
                className={"btn btn-light-brand align-self-center"}
                onClick={handleCreateClick}
              >
                New Assign
              </button>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default PublicAssignAssetForm;
