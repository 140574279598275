import React from "react";
import QuickLinkCard from "./QuickLinkCard";
import {
  CRM_AR_PATHS,
  CRM_LOGISTICS_PATHS,
  CRM_MEMBERS_PATHS,
  CRM_ORDER_PATHS,
  CRM_RENTALS_PATHS,
  CRM_SUPPORT_REQUEST_PATHS,
} from "@Constants/RoutingConstants/crm/CrmRedirectRouteConstants.ts";

type QuickLinksBoardProps = {
  metricsData: any;
};

function QuickLinksBoard({
  metricsData,
}: QuickLinksBoardProps): React.JSX.Element {
  return (
    <div className={"row g-6"}>
      {/* Asset Request */}
      <div className={"col-sm-6"}>
        <QuickLinkCard
          icon={
            <i
              className={`bi bi-lightning-charge-fill p-0 fs-4x color-inherit`}
              style={{
                bottom: "0rem",
                right: "0rem",
              }}
            ></i>
          }
          link={CRM_AR_PATHS.CREATE}
          text={"Asset Requests"}
          counters={metricsData?.counters?.rar ?? { count: 0 }}
          counterIconArray={[
            <i className="bi bi-lightning-charge fs-3 p-0"></i>,
          ]}
          iconColor={"text-gold"}
        />
      </div>

      {/* Rental Sheet */}
      <div className={"col-sm-6"}>
        <QuickLinkCard
          icon={
            <i className="ki-duotone ki-tablet-text-down p-0 fs-4x color-inherit">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </i>
          }
          link={CRM_RENTALS_PATHS.SHEET}
          text={"Rental Sheet"}
          iconColor={"text-primary"}
        />
      </div>

      {/* Support request */}
      <div className={"col-sm-6"}>
        <QuickLinkCard
          icon={
            <i className="ki-duotone ki-question p-0 fs-4x color-inherit">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
          }
          link={CRM_SUPPORT_REQUEST_PATHS.LIST}
          text={"Support request"}
          counters={metricsData?.counters?.support ?? { count: 0 }}
          counterIconArray={[
            <i className={"bi bi-person-raised-hand p-0 fs-3"}></i>,
            <i className="bi bi-person-walking p-0 fs-3"></i>,
          ]}
          iconColor={"text-info"}
        />
      </div>

      {/* Orders */}
      <div className={"col-sm-6"}>
        <QuickLinkCard
          icon={
            <i className="ki-duotone ki-purchase p-0 fs-4x color-inherit">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          }
          link={CRM_ORDER_PATHS.LIST}
          text={"Orders"}
        />
      </div>

      {/* Consignee */}
      <div className={"col-sm-6"}>
        <QuickLinkCard
          icon={
            <i className="ki-duotone ki-profile-user p-0 fs-4x color-inherit">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </i>
          }
          link={CRM_MEMBERS_PATHS.LIST}
          text={"Consignee"}
          iconColor={"text-success"}
        />
      </div>

      {/* Logistics */}
      <div className={"col-sm-6"}>
        <QuickLinkCard
          icon={
            <i className="ki-duotone ki-truck p-0 fs-4x color-inherit">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
            </i>
          }
          link={CRM_LOGISTICS_PATHS.LIST}
          text={"Logistics"}
          iconColor={"text-warning"}
        />
      </div>
    </div>
  );
}

export default QuickLinksBoard;
