import React from "react";
import LottieChart from "@components/helpers/CustomAlerts/Lottie/LottieChart.tsx";

type chartLoaderProps = {
  height?: number;
};

function ChartLoader({ height }: chartLoaderProps): React.JSX.Element {
  return (
    <div className={`d-flex flex-center h-${height}px`}>
      <LottieChart />
    </div>
  );
}

export default ChartLoader;

ChartLoader.defaultProps = {
  height: 500,
};
