import React, { useState } from "react";
import { getResetPasswordKeyValidity } from "../core/_requests";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import SupportPopover from "../../../pages/crm/CRMPageWrapper/SupportPopover";
import ResetPasswordForm from "./HelperComponents/ResetPasswordForm";

const InitialSubmitState = {
  error: false,
  success: false,
};

export default function ResetPassword() {
  const { resetKey } = useParams<{ resetKey: string }>();

  const slug = resetKey || "";

  // Local states
  const [submitState, setSubmitState] = useState(InitialSubmitState);

  const { data, isError, isLoading, isFetching } = useQuery(
    ["resetPasswordValidation", resetKey],
    () => getResetPasswordKeyValidity(slug),
    { enabled: !!resetKey, retry: false }
  );

  return (
    <>
      <div className={"flex-column-fluid d-flex flex-column flex-center"}>
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Setup New Password</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            Have you already reset the password ?{" "}
            <a href="/auth/login" className="link-primary fw-bold">
              Sign in
            </a>
          </div>
        </div>

        {isLoading || isFetching ? (
          <div className={"d-flex flex-center"}>
            <Spinner variant={"dark"} />
          </div>
        ) : (
          <>
            {data?.status !== 200 || isError ? (
              <div className="card mb-5 mb-lg-10 rounded-3 shadow-xs">
                <div className={"card-body"}>
                  <div className="text-danger text-center">
                    Link to Reset Password has expired. Go to{" "}
                    <Link
                      className={"text-danger text-hover-info fw-bold"}
                      to={"/auth/login"}
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {submitState.error ? (
              <div className="card mb-5 mb-lg-10 rounded-3 shadow-xs">
                <div className={"card-body"}>
                  <div className="text-danger">
                    Sorry, looks like there was an error resetting the password.
                    Please try again.
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {!submitState.error && submitState.success ? (
              <div className="card mb-5 mb-lg-10 rounded-3 shadow-xs">
                <div className={"card-body"}>
                  <div className="text-success text-center">
                    Password was reset successfully. Go to{" "}
                    <Link
                      className={"text-hover-primary text-success fw-bold"}
                      to={"/auth/login"}
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {!isError && !submitState.success ? (
              <ResetPasswordForm slug={slug} setSubmitState={setSubmitState} />
            ) : (
              ""
            )}
          </>
        )}
      </div>

      <SupportPopover />
    </>
  );
}
