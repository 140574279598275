import axios from "axios";

const API_URL = import.meta.env.VITE_APP_API_URL;
export const SERIAL_NUMBER = `${API_URL}/stocky/filter/product/serial/`;
export const ORDER_RETRIVE = `${API_URL}/orders/retrive/`;
export const ACCOUT_RETRIVE = `${API_URL}/accounts/`;

export const PUBLIC_SERIAL_INFO = `${API_URL}/stocky/public/product/serial/`;
export const getSerialInfo = (toekn, tearm) => {
  if (tearm) {
    return axios.get(`${SERIAL_NUMBER}`, {
      headers: {
        Authorization: `Bearer ${toekn}`,
      },
      params: {
        serial: tearm,
      },
    });
  }
  return null;
};

export const getPublicSerialInfo = (tearm) => {
  return axios.get(`${PUBLIC_SERIAL_INFO}${tearm}`);
};

export const getAccoutInfo = (toekn, id) => {
  return axios.get(`${ACCOUT_RETRIVE}${id}/`, {
    headers: {
      Authorization: `Bearer ${toekn}`,
    },
  });
};

export const getOrderInfo = (toekn, slug) => {
  return axios.get(`${ORDER_RETRIVE}${slug}`, {
    headers: {
      Authorization: `Bearer ${toekn}`,
    },
  });
};
