export const useACLHook = (permissions, userPermissions) => {
  // Assuming you have some way to get user permissions, for example, from context or props
  // const userPermissions = ['view_product', 'update_product', 'create_product', 'list_product', 'delete_product'];
  const checkPermission = (permission) => {
    if (userPermissions) {
      if (Array.isArray(permission) && permission[0].length) {
        // ion is included in userPermissions
        return permission.some((p) => userPermissions.includes(p));
      }
    }
    return false; // Assuming that if permission is not an array, it's not allowed
  };

  // const hasPermission = permissions.every(checkPermission);
  if (Array.isArray(permissions)) {
    const hasPermission = checkPermission(permissions);
    return hasPermission;
  }

  return false; // Assuming that if permission is not an array, it's not'
};
