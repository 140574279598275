import React from "react";
import { Table } from "react-bootstrap";
import ClientUserCardSm from "../../../../../../components/helpers/CrmHelpers/Ui/ClientUserCardSm";
import { StageItem } from "@components/helpers/StagesAccorion/CollapseStageAccordion.tsx";
import { TatHelperFunction } from "@components/UI/TAT/TatHelperFun.ts";
import {
  dayMonYearDate,
  shortTime,
  ToMins,
} from "@/Utils/Shared/DateStringToDays.ts";
import { RENTAL_REQUESTS_FULL_REQUEST_TYPES } from "@Constants/Rental_Constants";
import CellRichTextWrapper from "../../../../../../components/helpers/TableComponents/CellRichTextWrapper";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";
import RowCardsSkeleton from "@components/helpers/Skeletons/RowCardsSkeleton.tsx";
import HorizonatlTimelIne from "@components/helpers/HorizontalTimeLine/HorizonatlTimelIne.tsx";
import TableSkeleton from "@components/helpers/Skeletons/TableSkeleton.tsx";
import TextLinesSkeleton from "@components/helpers/Skeletons/TextLinesSkeleton.tsx";

function RarPublicView({ data, loading }): React.JSX.Element {
  return (
    <>
      <div className={"vstack gap-6"}>
        {loading ? (
          <RowCardsSkeleton />
        ) : (
          <div className="row">
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body p-6">
                  <div className="d-flex align-items-center gap-4">
                    <div className="btn btn-icon btn-brand-primary">
                      <i className="bi bi-cart-fill fs-3"></i>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column justify-content-center gap-1">
                      <div className={"d-flex gap-2"}>
                        <CommonBadge
                          text={data?.current_stage?.stage}
                          theme={"ar_stage"}
                        />
                      </div>
                      <strong className="card-title fw-bold fs-7 m-0">
                        {data?.connect?.name ?? ""}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body p-6">
                  <div className={"d-flex align-items-center gap-4"}>
                    <div className={"d-flex flex-column gap-2"}>
                      <CommonBadge
                        text={data?.request_type}
                        theme={"ar_type"}
                        size={"lg"}
                      />
                      <div className="">
                        {dayMonYearDate(data?.created_at)}{" "}
                        {shortTime(data?.created_at)}
                      </div>
                    </div>

                    {data?.calculate_stage_tats_and_total?.stages?.length >
                    0 ? (
                      <div className={"d-flex flex-column gap-2"}>
                        <HorizonatlTimelIne
                          items={TatHelperFunction(
                            data?.calculate_stage_tats_and_total?.stages,
                            "RAR"
                          )}
                        />
                        {data?.calculate_stage_tats_and_total?.total_tat && (
                          <div className="d-block small ">
                            {" "}
                            <strong>Total TAT : </strong>
                            {ToMins(
                              data?.calculate_stage_tats_and_total?.total_tat
                            )}
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <TableSkeleton col={5} row={5} lines={2} />
        ) : (
          <Table bordered striped responsive className={`m-0 `}>
            <thead>
              <tr>
                <th className={"col-3 text-capitalize"}>Product</th>
                <th className={"col-3 text-capitalize"}>config</th>
                <th className={"col-3 text-capitalize"}>assignee</th>
                {data?.request_type ===
                RENTAL_REQUESTS_FULL_REQUEST_TYPES.NEW_ORDER ? (
                  <th className={"col-1 text-capitalize"}>Qty</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {data?.request_type ===
              RENTAL_REQUESTS_FULL_REQUEST_TYPES.NEW_ORDER ? (
                <>
                  {data?.product_details?.map((details) => {
                    return (
                      <tr key={details?.id}>
                        {/* Product */}
                        <td>{details?.product_details?.name}</td>
                        {/* Config */}
                        <td className={""}>
                          <div className="vstack gap-2">
                            <div className="mw-300px text-truncate">
                              {details?.config_details?.config}
                            </div>
                            {details?.note ? (
                              <CellRichTextWrapper richText={details?.note} />
                            ) : null}
                          </div>
                        </td>
                        {/* Assignee */}
                        <td>
                          <ClientUserCardSm user={details?.assigned_details} />
                        </td>
                        {/* Qty */}
                        <td>{details?.qty}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  <tr>
                    {/* Product */}
                    <td className={"mw-150px text-truncate"}>
                      {
                        data?.rental_details?.product_details?.computer
                          ?.item_name
                      }
                    </td>
                    {/* Config */}
                    <td className={"mw-150px text-truncate"}>
                      {
                        data?.rental_details?.product_details?.computer
                          ?.cpuversion
                      }
                    </td>
                    {/* Assignee */}
                    <td>
                      <ClientUserCardSm
                        user={data?.rental_details?.assigned_details}
                      />
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        )}

        {/*  Description  */}
        {loading ? (
          <TextLinesSkeleton />
        ) : data?.description ? (
          <div className="">
            <span className={"fw-bold"}>Description</span>
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        ) : null}

        {/*  Current Stage  */}
        <div className={"card"}>
          <div className={"card-body p-6"}>
            <StageItem
              stage={data?.current_stage}
              index={0}
              paradimekey={"RAR"}
              pfp={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RarPublicView;
