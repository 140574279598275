import axios from "axios";
import { getAuth } from "@app/modules/auth";

export const LOCK_KEY = "STOCKY_LOCK";

const basePath = import.meta.env.VITE_APP_API_URL;

const privateInstance = axios.create({
  baseURL: basePath,
});

privateInstance.defaults.headers.Accept = "application/json";
privateInstance.interceptors.request.use(
  (config) => {
    const auth = getAuth();

    if (auth && auth.access) {
      config.headers.Authorization = `Bearer ${auth.access}`;
    }

    return config;
  },
  (err: any) => Promise.reject(err)
);

// Handle api Throttle error
privateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 429) {
      localStorage.setItem(
        LOCK_KEY,
        JSON.stringify(error.response.data?.available_in_seconds ?? 0)
      );
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export default privateInstance;
