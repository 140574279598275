import React, { useRef, useState } from "react";
import ClientUserCardSm from "../../../../../../components/helpers/CrmHelpers/Ui/ClientUserCardSm";
import { selectionStatus } from "../_models";
import { CLIENT_SELECTION_STATUS } from "../_constants";
import SeparatorX from "../../../../../../components/helpers/Separators/SeparatorX";
import { Collapse } from "react-bootstrap";

function ClientInfoSelection({
  serialData,
  updateClientInfo,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldTouched,
  fieldNames = { name: "name", number: "number", email: "email" },
}): React.JSX.Element {
  const { assignedData } = serialData;

  // local states
  const [open, setOpen] = useState(true);
  const [selection, setSelection] = useState<selectionStatus>(
    CLIENT_SELECTION_STATUS.UNSELECTED
  );
  // Refs
  const nameRef = useRef<any>(null);
  const numberRef = useRef<any>(null);
  const emailRef = useRef<any>(null);

  // Methods
  function handleAssignedSelect() {
    updateClientInfo({
      name: assignedData?.name,
      number: assignedData?.phone,
      email: assignedData?.email,
      consignee: assignedData?.id,
    });
    setSelection(CLIENT_SELECTION_STATUS.ASSIGNED);

    setFieldTouched("name", false);
    setFieldTouched("number", false);
    setFieldTouched("email", false);

    if (!assignedData?.name) {
      nameRef?.current?.focus();
    } else if (!assignedData?.number) {
      numberRef?.current?.focus();
    } else if (!assignedData?.email) {
      emailRef?.current?.focus();
    }
  }

  function handleClear() {
    updateClientInfo({
      name: null,
      number: null,
      email: null,
      consignee: null,
    });
    setSelection(CLIENT_SELECTION_STATUS.NOT_ASSIGNED);
    nameRef?.current?.focus();
    setFieldTouched("name", false);
    setFieldTouched("number", false);
    setFieldTouched("email", false);
  }

  return (
    <>
      <div className="card shadow-sm mb-6">
        <div className="card-body">
          <div className={"vstack gap-6"}>
            {!open ? (
              <button
                type={"button"}
                className={"btn btn-sm btn-light-dark align-self-end"}
                onClick={() => {
                  setOpen(true);
                  handleAssignedSelect();
                }}
              >
                {assignedData?.name}
              </button>
            ) : null}

            <>
              {assignedData ? (
                <Collapse in={open}>
                  <div className={"m-0 p-0"}>
                    <div className={"vstack gap-6"}>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div
                            className={`card border-brand-secondary ${
                              selection === CLIENT_SELECTION_STATUS.ASSIGNED
                                ? "bg-light"
                                : ""
                            }`}
                          >
                            <div className="card-body p-6">
                              <div className={"d-flex align-items-center"}>
                                <div className="flex-grow-1">
                                  <ClientUserCardSm user={assignedData} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="vstack gap-2">
                            <button
                              type={"button"}
                              className={`btn btn-sm btn-active-success btn-active-color-white ${
                                selection === CLIENT_SELECTION_STATUS.ASSIGNED
                                  ? "btn-success"
                                  : "btn-outline btn-color-success"
                              }`}
                              onClick={handleAssignedSelect}
                            >
                              Yes, I am
                            </button>
                            <button
                              type={"button"}
                              className={`btn btn-sm btn-outline btn-color-warning btn-active-warning btn-active-color-white`}
                              onClick={() => {
                                handleClear();
                                setOpen(false);
                              }}
                            >
                              No, I am not
                            </button>
                          </div>
                        </div>
                      </div>
                      {selection !== CLIENT_SELECTION_STATUS.UNSELECTED ? (
                        <SeparatorX
                          text={
                            assignedData &&
                            selection === CLIENT_SELECTION_STATUS.ASSIGNED
                              ? "Please fill any missing details or update below if required"
                              : `Not ${assignedData?.name} ?`
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </Collapse>
              ) : null}
            </>

            {selection !== CLIENT_SELECTION_STATUS.UNSELECTED ? (
              <div className={`card`}>
                <div className="card-body p-6">
                  <div className="row row-gap-6">
                    <div className="col-md-6">
                      <label
                        htmlFor={"public_create_ticket_contactName"}
                        className="form-label fw-bold"
                      >
                        Contact Name
                      </label>
                      <input
                        id={"public_create_ticket_contactName"}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Contact Name"
                        name={fieldNames.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.[fieldNames.name] ?? ""}
                        ref={nameRef}
                      />
                      {errors?.[fieldNames.name] &&
                        touched?.[fieldNames.name] && (
                          <p className="text-danger m-0">
                            {errors?.[fieldNames.name]}
                          </p>
                        )}
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor={"public_create_ticket_contactNumber"}
                        className="form-label fw-bold"
                      >
                        Contact Number
                      </label>
                      <input
                        id={"public_create_ticket_contactNumber"}
                        type="tel"
                        className="form-control form-control-sm"
                        placeholder="Contact Number"
                        name={fieldNames.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.[fieldNames.number] ?? ""}
                        ref={numberRef}
                      />
                      {errors?.[fieldNames.number] &&
                        touched?.[fieldNames.number] && (
                          <p className="text-danger m-0">
                            {errors?.[fieldNames.number]}
                          </p>
                        )}
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor={"public_create_ticket_contactEmail"}
                        className="form-label fw-bold"
                      >
                        Contact Email
                      </label>
                      <input
                        id={"public_create_ticket_contactEmail"}
                        type="email"
                        className="form-control form-control-sm"
                        placeholder="Contact Email"
                        name={fieldNames.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.[fieldNames.email] ?? ""}
                        ref={emailRef}
                      />
                      {errors?.[fieldNames.email] &&
                        touched?.[fieldNames.email] && (
                          <p className="text-danger m-0">
                            {errors?.[fieldNames.email]}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientInfoSelection;
