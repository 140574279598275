import React, { FC, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import {
  ConnectSearchParams,
  getBrandDetails,
  getCompanySearch,
  getConnectSearch,
  getFilters,
  getHnsCodesSearch,
  getItemSearch,
  getleadInformation,
  getProductItem,
  getProductTag,
  getSearchTags,
  getStockySearchAccount,
  getTemplates,
  getUserSearch,
  SearchAccountParams,
} from "./_requestes.ts";

import { useQuery, useQueryClient } from "react-query";
import {
  getClientTags,
  getProductCategorys,
} from "@app/pages/erp/products/_requests.ts";
import { GroupBase, Props } from "react-select";
import { useAuth } from "@app/modules/auth";

interface AsynSelectorProps {
  setterFunction: (value: any) => void;
  defaultVal?: any;
  fieldstyle?: any;
  getValue?: any;
  key?: any;
  blrFn?: any;
  placeholder?: string | null | undefined;
  type?: string;
  fieldValue?: string | number | null | undefined;
  option?: any;
}

export const AsyncTagSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
}) => {
  const auth = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      //@ts-ignore
      loadOptions={(search) => getProductTag(auth?.access, search)}
      //@ts-ignore
      getOptionLabel={(option) => [option?.slug] ?? ""}
      //@ts-ignore
      getOptionValue={(option) => option?.id ?? ""}
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      id="tags"
      name="tags"
      isMultiple={true}
      isClearable={true}
      placeholder={"Select Tags"}
      defaultValue={defaultVal}
      onChange={(selectedOption) => {
        // @ts-ignore
        setterFunction(selectedOption?.id);
      }}
    />
  );
};

export const AsyncBrandSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  blrFn = () => {},
}) => {
  const { auth } = useAuth();

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={(search) => getBrandDetails(auth?.access, search)}
      getOptionLabel={(option) => option?.name ?? ""}
      getOptionValue={(option) => option?.id ?? ""}
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      id="Brand"
      name="brand"
      isClearable={true}
      placeholder={"Select Tags"}
      defaultValue={defaultVal}
      onChange={(selectedOption) => {
        // @ts-ignore
        setterFunction(selectedOption?.id);
      }}
      onBlur={() => {
        blrFn();
      }}
    />
  );
};

export const AsyncUserSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  fieldstyle = null,
  fieldValue,
  getValue = () => {},
  blrFn = () => {},
}) => {
  const { auth } = useAuth();
  const [value, setValue] = useState<any>(defaultVal);

  useEffect(() => {
    if (fieldValue) {
      setValue(defaultVal);
    }
    return () => {};
  }, [fieldValue, defaultVal]);

  return (
    <>
      <AsyncSelect
        key={fieldValue}
        cacheOptions
        defaultOptions
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        loadOptions={async (search) => {
          if (search) {
            try {
              return await getUserSearch(auth?.access, search);
            } catch (error) {
              return [];
            }
          }
          return [];
        }}
        //@ts-ignore
        getOptionLabel={(option) =>
          [
            <div key={`${option?.id} ${option?.first_name} `}>
              {`${option?.first_name ?? ""} ${option?.last_name ?? ""}`}
              <br />
              {`${option?.email ?? ""}`}
            </div>,
          ] ?? ""
        }
        //@ts-ignore
        getOptionValue={(option) => option?.id ?? ""}
        id="tags"
        name="tags"
        isMultiple={true}
        isClearable={true}
        onChange={(selectedOption) => {
          setValue(selectedOption);
          getValue(selectedOption);
          setterFunction(selectedOption?.id);
        }}
        onBlur={() => blrFn()}
        value={value}
      />
    </>
  );
};

export const AsyncItemSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
}) => {
  const auth = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={(search) => getProductItem(auth?.access, search)}
      //@ts-ignore
      getOptionLabel={(option) => [option?.name] ?? ""}
      //@ts-ignore
      defaultValue={defaultVal}
      getOptionValue={(option) => option?.id ?? ""}
      id="tags"
      name="tags"
      placeholder={"Select Item"}
      isMultiple={true}
      isClearable={true}
      onChange={(selectedOption) => {
        // @ts-ignore
        setterFunction(selectedOption?.id);
      }}
    />
  );
};

interface AdditionalConnectSelectorProps extends AsynSelectorProps {
  showRentalOnly?: boolean;
}

export const AsyncConnectSelector: FC<AdditionalConnectSelectorProps> = ({
  setterFunction,
  defaultVal,
  getValue = () => {},
  blrFn = () => {},
  placeholder = "",
  fieldValue,
  showRentalOnly,
}) => {
  // Directly using defaultVal to initialize companyName
  const [companyName, setCompanyName] = useState<any>(defaultVal);
  const { auth }: any = useAuth();

  useEffect(() => {
    if (fieldValue) {
      setCompanyName((prev) => {
        return {
          ...prev,
          ...defaultVal,
          company_info:
            fieldValue === defaultVal?.id ? defaultVal?.company_info : null,
        };
      });
    } else {
      setCompanyName(null);
    }
  }, [fieldValue, defaultVal]);

  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        //@ts-ignore
        loadOptions={async (search) => {
          // loadOptions logic
          if (search) {
            let params: ConnectSearchParams = { search: search };
            if (showRentalOnly) {
              params.is_rental_client = true;
            }

            try {
              const data = await getConnectSearch(auth?.access, params);
              return data;
            } catch (err) {
              return [];
            }
          }
        }}
        getOptionLabel={(option: any) => option?.display_name ?? ""}
        getOptionValue={(option: any) => option?.id ?? ""}
        id="connect"
        name="connect"
        onChange={(selectedOption) => {
          // onChange logic
          // @ts-ignore
          setterFunction(selectedOption?.id);
          getValue(selectedOption);
          setCompanyName(selectedOption);
        }}
        onBlur={() => blrFn()}
        placeholder={placeholder}
        value={companyName}
        isClearable={true}
      />
      {(companyName?.company_info?.name || companyName?.company_name) && (
        <p className="d-block m-0">
          <b>Belonging Company: </b>
          {companyName?.company_info?.name || companyName?.company_name}
        </p>
      )}
    </>
  );
};

export const AsyncCompanySelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  getValue = () => {},
  blrFn = () => {},
  option,
  fieldValue,
}) => {
  const { auth }: any = useAuth();

  const [company, setCompany] = useState<any>(defaultVal);

  useEffect(() => {
    if (fieldValue) {
      setCompany(defaultVal);
    } else {
      setCompany(null);
    }
  }, [defaultVal, fieldValue]);

  return (
    <>
      <AsyncSelect
        cacheOptions
        key={defaultVal}
        defaultOptions
        value={company}
        {...option}
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        onBlur={() => blrFn()}
        //@ts-ignore
        loadOptions={async (search) => {
          if (search) {
            try {
              const data = await getCompanySearch(auth?.access, search);
              return data;
            } catch (err) {
              console.error(err);
              return [];
            }
          }
        }}
        //@ts-ignore
        getOptionLabel={(option: any) => option?.name ?? ""}
        //@ts-ignore
        getOptionValue={(option: any) => option?.id ?? ""}
        id="company"
        name="company"
        onChange={(selectedOption) => {
          setCompany(selectedOption);
          // @ts-ignore
          setterFunction(selectedOption?.id);
          getValue(selectedOption);
        }}
        isClearable={true}
      />
    </>
  );
};

export const AsyncHnsCodeSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  getValue = () => {},
  blrFn = () => {},
}) => {
  const { auth }: any = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      defaultValue={defaultVal}
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={async (search) => {
        if (search) {
          try {
            const data = await getHnsCodesSearch(auth?.access, search);
            return data;
          } catch (err) {
            console.error(err);
            return [];
          }
        }
      }}
      getOptionLabel={(option: any) => option?.name + "\n" + option?.code ?? ""}
      getOptionValue={(option: any) => option?.name + "\n" + option?.code ?? ""}
      id="hns_code"
      name="hns_code"
      onChange={(selectedOption) => {
        setterFunction(selectedOption?.code);
        getValue(selectedOption);
      }}
      onBlur={() => blrFn()}
    />
  );
};

export function CustomAsyncHnsSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>): React.JSX.Element {
  const { auth }: any = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={async (search) => {
        if (search) {
          try {
            const data = await getHnsCodesSearch(auth?.access, search);
            return data;
          } catch (err) {
            console.error(err);
            return [];
          }
        }
      }}
      getOptionLabel={(option: any) => option?.name + "\n" + option?.code ?? ""}
      getOptionValue={(option: any) => option?.name + "\n" + option?.code ?? ""}
      {...props}
    />
  );
}

interface OptionalAccountSelectorProps extends AsynSelectorProps {
  showRentalOnly?: boolean;
}

export const AsyncAccountSelector: FC<OptionalAccountSelectorProps> = ({
  setterFunction,
  defaultVal,
  getValue = () => {},
  blrFn = () => {},
  showRentalOnly,
}) => {
  const { auth } = useAuth();

  return (
    <AsyncSelect
      isClearable={true}
      // cacheOptions
      defaultOptions
      defaultValue={defaultVal}
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={async (search) => {
        if (search) {
          let params: SearchAccountParams = { search: search };
          if (showRentalOnly) {
            params.connect__is_rental_client = true;
          }
          try {
            const data = await getStockySearchAccount(auth?.access!, params);
            return data;
          } catch (err) {
            console.error(err);
            return [];
          }
        }
      }}
      //@ts-ignore
      getOptionLabel={(option: any) => option?.name ?? ""}
      //@ts-ignore
      getOptionValue={(option: any) => option?.id + "\n" + option?.id ?? ""}
      id="account"
      name="account"
      onChange={(selectedOption) => {
        // @ts-ignore
        setterFunction(selectedOption?.id);
        getValue(selectedOption);
      }}
      onBlur={() => blrFn()}
    />
  );
};

export const AsyncProductItemSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
}) => {
  const { auth }: any = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      defaultValue={defaultVal}
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      isClearable
      //@ts-ignore
      loadOptions={async (search) => {
        if (search) {
          try {
            const data = await getItemSearch(auth?.access, search);
            return data;
          } catch (err) {
            console.error(err);
            return [];
          }
        }
      }}
      //@ts-ignore
      getOptionLabel={(option: any) => option?.name ?? ""}
      //@ts-ignore
      getOptionValue={(option: any) => option?.id + "\n" + option?.id ?? ""}
      id="account"
      name="account"
      onChange={(selectedOption) => {
        // @ts-ignore
        setterFunction(selectedOption?.id);
      }}
    />
  );
};

export const AsyncSearchTagsSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal = {},
}) => {
  const { auth }: any = useAuth();
  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        //@ts-ignore
        loadOptions={async (search) => {
          if (search) {
            try {
              const data = await getSearchTags(auth?.access, search);
              return data;
            } catch (err) {
              return [];
            }
          }
        }}
        //@ts-ignore
        getOptionLabel={(option: any) => option?.slug ?? ""}
        //@ts-ignore
        getOptionValue={(option: any) => option?.id ?? ""}
        //@ts-ignore
        defaultValue={defaultVal}
        id="tag__id"
        name="tag__id"
        onChange={(selectedOption) => {
          // @ts-ignore
          setterFunction(selectedOption?.id);
        }}
      />
    </>
  );
};

export const AsyncExcludeTagsSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal = [],
}) => {
  const { auth }: any = useAuth();
  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        //@ts-ignore
        loadOptions={async (search) => {
          if (search) {
            try {
              const data = await getSearchTags(auth?.access, search);
              return data;
            } catch (err) {
              return [];
            }
          }
        }}
        //@ts-ignore
        getOptionLabel={(option: any) => option?.slug ?? undefined}
        //@ts-ignore
        getOptionValue={(option: any) => option?.id ?? undefined}
        //@ts-ignore
        defaultValue={defaultVal}
        id="tag_id_exclude"
        name="tag_id_exclude"
        onChange={(selectedOption) => {
          // @ts-ignore
          setterFunction(selectedOption);
        }}
        isMulti={true}
      />
    </>
  );
};

export const AsyncMultiUserSelector: any = ({
  setterFunction,
  defaultVal,
  getValue,
}) => {
  const { auth } = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      defaultValue={defaultVal}
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={(search) => getUserSearch(auth?.access, search)}
      //@ts-ignore
      getOptionLabel={(option) =>
        [
          <p className="p-0 m-0">
            {`${option?.first_name ?? ""} ${option?.last_name ?? ""}`}
            <br />
          </p>,
        ] ?? ""
      }
      getOptionValue={(option) => option?.id ?? ""}
      id="tags"
      name="tags"
      isMultiple={true}
      isClearable={true}
      //@ts-ignore
      onChange={(selectedOption: any) => {
        getValue(selectedOption);
        const options = selectedOption.map((obj: any) => obj.id);
        setterFunction(options);
      }}
      isMulti={true}
    />
  );
};

export const AsyncLeadSelector: any = ({
  setterFunction,
  defaultVal,
  getValue,
}) => {
  const { auth } = useAuth();
  const [LeadSlug, setLeadSlug] = useState("");

  const quarry = useQuery(
    ["extractLead"],
    async () => {
      return getleadInformation(auth?.access, LeadSlug).then((data) => {
        setterFunction(data);
      });
    },
    {
      enabled: false, // Default value for enabled
    }
  );

  const handleSearch = () => {
    quarry.refetch();
  };

  const queryClient = useQueryClient();

  const Hnadleremove = () => {
    setLeadSlug("");
    setterFunction({ connect: [], id: "", lead_name: "" });
    queryClient.setQueryData("extractLead", {});
    queryClient.removeQueries("extractLead");
  };
  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control form-control-sm"
        placeholder=""
        aria-label="Example text with button addon"
        aria-describedby="button-addon1"
        onChange={(e) => {
          setLeadSlug(e?.target?.value);
        }}
        value={LeadSlug ?? ""}
      />
      <div className="">
        <div
          className="btn btn-sm btn-brand h-100 rounded-0"
          id="button-addon1"
          onClick={() => {
            handleSearch();
          }}
        >
          {" "}
          {quarry?.isFetching ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <i className="bi bi-search fs-5"></i>
          )}{" "}
        </div>
      </div>
      <div className="">
        <div
          className="btn btn-sm btn-danger h-100 rounded-0"
          id="button-addon1"
          onClick={() => {
            Hnadleremove();
          }}
        >
          <i className="bi bi-x-lg"></i>
        </div>
      </div>
    </div>
  );
};

export const AsyncTemplatetSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  type,
}) => {
  const auth = useAuth();
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={(search) => getTemplates(auth?.access, type, search)}
      //@ts-ignore
      getOptionLabel={(option) => [option?.slug] ?? ""}
      //@ts-ignore
      defaultValue={defaultVal}
      getOptionValue={(option) => option?.id ?? ""}
      id="tags"
      name="tags"
      placeholder={"Select Item"}
      isMultiple={true}
      isClearable={true}
      onChange={(selectedOption) => {
        // @ts-ignore
        setterFunction(selectedOption?.id);
      }}
    />
  );
};

export const AsyncFilterSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  type,
}) => {
  const auth = useAuth();
  function parseQueryString(queryString) {
    console.log(queryString);
    const parsedObject = {};
    // Removing leading "?" and splitting query string into key-value pairs
    if (queryString) {
      const pairs = queryString.substring(1).split("&");
      for (const pair of pairs) {
        const [key, value] = pair.split("=");
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value.replace(/\+/g, " "));

        if (parsedObject[decodedKey] === undefined) {
          parsedObject[decodedKey] = decodedValue;
        } else {
          if (Array.isArray(parsedObject[decodedKey])) {
            parsedObject[decodedKey].push(decodedValue);
          } else {
            parsedObject[decodedKey] = [parsedObject[decodedKey], decodedValue];
          }
        }
      }
    }

    return parsedObject;
  }

  const handelSeachFilter = (filterstring) => {
    const obj = parseQueryString(filterstring);
    setterFunction((e) => {
      return { ...obj };
    });
  };
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      className="react-select-styled react-select-sm"
      classNamePrefix="react-select"
      //@ts-ignore
      loadOptions={(search) => getFilters(auth?.access, search, type)}
      //@ts-ignore
      getOptionLabel={(option) => [option?.name] ?? ""}
      //@ts-ignore
      defaultValue={defaultVal}
      getOptionValue={(option) => option?.filter_data ?? ""}
      id="tags"
      name="tags"
      placeholder={"Select Item"}
      isMultiple={true}
      isClearable={true}
      onChange={(selectedOption) => {
        console.log("selected option", selectedOption);

        // @ts-ignore
        handelSeachFilter(selectedOption?.filter_data ?? "");
      }}
    />
  );
};

export const AsyncCatagorySelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  getValue = () => {},
  blrFn = () => {},
  placeholder = "",
}) => {
  // Directly using defaultVal to initialize companyName
  const [companyName, setCompanyName] = useState<any>(defaultVal);
  const { auth }: any = useAuth();

  useEffect(() => {
    console.log("Rendered");
    if (defaultVal) {
      setCompanyName(defaultVal);
    }
  }, [defaultVal]);

  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        //@ts-ignore
        loadOptions={async (search) => {
          // loadOptions logic
          if (search) {
            try {
              const data = await getProductCategorys(auth?.access, search);
              return data;
            } catch (err) {
              return [];
            }
          }
        }}
        getOptionLabel={(option: any) => option?.display_name ?? ""}
        getOptionValue={(option: any) => option?.id ?? ""}
        id="connect"
        name="connect"
        onChange={(selectedOption) => {
          // onChange logic
          // @ts-ignore
          setterFunction(selectedOption?.id);
          getValue(selectedOption);
          setCompanyName(selectedOption);
        }}
        onBlur={() => blrFn()}
        placeholder={placeholder}
        value={companyName}
        isClearable={true}
      />
      {companyName?.company_info?.name && (
        <p className="d-block">
          <b>Belonging Company: </b>
          {companyName?.company_info?.name}
        </p>
      )}
    </>
  );
};

export const AsyncClientTagsSelector: FC<AsynSelectorProps> = ({
  setterFunction,
  defaultVal,
  getValue = () => {},
  blrFn = () => {},
  placeholder = "",
  type = null,
}) => {
  // Directly using defaultVal to initialize companyName
  const [companyName, setCompanyName] = useState<any>(defaultVal);
  const { auth }: any = useAuth();

  useEffect(() => {
    if (defaultVal) {
      setCompanyName(defaultVal);
    }
  }, [defaultVal]);

  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        isMulti={true}
        //@ts-ignore
        loadOptions={async (search) => {
          // loadOptions logic
          if (search) {
            try {
              const data = await getClientTags(auth?.access, search, type);
              console.log(data?.data?.results);
              // @ts-ignore
              return data?.data?.results;
            } catch (err) {
              return [];
            }
          }
        }}
        getOptionLabel={(option: any) => option?.name ?? ""}
        getOptionValue={(option: any) => option?.id ?? ""}
        id="connect"
        name="connect"
        onChange={(selectedOption) => {
          // onChange logic
          console.log(selectedOption);
          // @ts-ignore

          setterFunction([...selectedOption?.map((e) => e?.id)]);
          getValue(selectedOption);
          setCompanyName(selectedOption);
        }}
        onBlur={() => blrFn()}
        placeholder={placeholder}
        value={companyName}
        isClearable={true}
      />
    </>
  );
};
