import React from "react";
import useBodyBackground from "@Hooks/useBodyBackground.tsx";

type BackgroundWrapperProps = {
  children: React.ReactNode;
  urlLight?: string;
  urlDark?: string;
};

function BackgroundWrapper({
  children,
  urlLight = "/media/auth/public-bg.webp",
  urlDark = "/media/auth/bg4-dark.jpg",
}: BackgroundWrapperProps): React.JSX.Element {
  useBodyBackground({
    urlLight: urlLight,
    urlDark: urlDark,
  });

  return <>{children}</>;
}

export default BackgroundWrapper;
