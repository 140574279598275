import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getQuoteRequestDetails, quoteRequests } from "../_request";
import { useAuth } from "@app/modules/auth";
import { format } from "date-fns";
import CompanyImage from "@components/helpers/CompalyImage/CompanyImage";
import CommonPageTextHeader from "@components/helpers/CommonPageHeader/CommonPageTextHeader.tsx";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";

const ViewQuote = () => {
  const { slug } = useParams();
  const { auth } = useAuth();
  const [quoteItemsTotal, setQuoteItemstotal] = useState([]);

  const { data: quoteData, isLoading } = useQuery(
    ["quote-details", slug],
    () => {
      return getQuoteRequestDetails(auth?.access, slug)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    }
  );
  UseErrorRedirection(quoteData?.response);

  const { data: quotes } = useQuery(["quotes", slug], () => {
    return quoteRequests(auth?.access, slug)
      .then((response) => {
        setQuoteItemstotal(
          response?.data?.results.map((quote) => {
            const total = quote.quote_items.reduce(
              (acc, item) => acc + item.qty * item.rate,
              0
            );
            return { id: quote.id, total };
          })
        );
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container my-10">
      <CommonPageTextHeader heading={"Quote Request"} />
      <div className="row mb-5">
        <div className="col">
          <div className="card">
            <div className="card-body p-4 p-md-8">
              <div className="row  ">
                <div className="col-12">
                  <div className="row mb-6 row-gap-4">
                    <div className="col col-md-8">
                      <div className="card">
                        <div className="card-body p-4 p-md-8">
                          <div className="d-flex flex-column gap-4 flex-md-row gap-md-3 align-items-center">
                            <div className="flex-shrink-0">
                              <CompanyImage
                                width={45}
                                id={quoteData?.company_info?.id}
                                alt={"not"}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <span className="fw-bold d-block">
                                {quoteData?.company_info?.name ?? ""}
                              </span>
                              <span>
                                {quoteData?.company_info?.address ?? ""}
                              </span>
                              <span className="d-block">
                                {" "}
                                <span className="fw-bold ">CIN:</span>{" "}
                                {quoteData?.company_info?.cin ?? ""}
                              </span>
                              <span className={"d-block"}>
                                <span className="fw-bold   ">GSTIN:</span>{" "}
                                {quoteData?.company_info?.gst_number ?? ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="card">
                        <div className="card-body p-4 p-md-8">
                          <span className="d-block fw-bold">
                            Date:{" "}
                            {quoteData?.created_at &&
                              format(
                                new Date(quoteData?.created_at ?? ""),
                                "dd-MM-yyyy"
                              )}
                          </span>
                          <span className="d-block fw-bold">
                            Quote#: {quoteData?.slug ?? ""}
                          </span>
                          <span className="d-block fw-bold">
                            Request Type: {quoteData?.request_type_label ?? ""}
                          </span>
                          <span className="d-block fw-bold">
                            Usage Type: {quoteData?.usage_type_label ?? ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6 ">
                    <div className="col">
                      <div className="card">
                        <div className="card-body p-4 p-md-8">
                          <div className="row row-gap-4">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="btn btn-icon btn-brand-primary">
                                    <i className="bi bi-building-fill fs-4"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="d-flex flex-column gap-1">
                                    {quoteData?.lead_detail ? (
                                      <strong className="fs-5">
                                        {quoteData?.lead_detail?.lead_name ??
                                          ""}
                                      </strong>
                                    ) : (
                                      quoteData?.connect_detail?.name ?? ""
                                    )}
                                    {quoteData?.lead_detail?.lead_id && (
                                      <span>
                                        <strong className="">Lead ID#:</strong>{" "}
                                        {quoteData?.lead_detail?.lead_id ?? ""}
                                      </span>
                                    )}
                                    {quoteData?.connect_detail?.slug && (
                                      <span>
                                        <strong className="">
                                          Connect ID#:
                                        </strong>{" "}
                                        {quoteData?.connect_detail?.slug ?? ""}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Table responsive striped bordered className={"m-0"}>
                    <thead>
                      <tr>
                        <th>Product Model</th>
                        <th>Qty</th>
                        <th>Config</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quoteData?.item_details?.length > 0 &&
                        quoteData?.item_details?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="vstack gap-1">
                                  <span>{item?.product_type_label ?? ""}</span>
                                  <span>{item?.product_model ?? ""}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <span>{item?.order_qty ?? ""} Units</span>
                                  {item?.tenure && (
                                    <div>
                                      <span className={"d-block  "}>
                                        Lock-in: {item?.tenure ?? ""}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="vstack gap-1">
                                  <span>{item?.config ?? ""}</span>
                                  <span>{item?.note ?? ""}</span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                {quoteData?.note && (
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <span className="fw-bold">Notes:</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: quoteData?.note ?? "",
                        }}
                      ></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {quotes?.results?.length === 0 ? (
            <div className="card mb-10">
              <div className="card-body p-4 p-md-8 text-center vstack gap-5">
                <div className="w-75 m-auto">
                  <h2 className="text-center lh-lg">
                    We're almost there! Your quote is on its way and will soon
                    arrive in the same inbox you used for the request. Stay
                    tuned!
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body p-4 p-md-8">
                <h1>Quotes</h1>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {quotes?.results?.map((accordion, index) => {
                    const collapseId = `flush-collapse-${index}`;
                    const isFirstAccordion = index === 0;
                    const isActiveAccordion = "0" === collapseId;
                    return (
                      <div
                        className={`accordion-item my-5 ${
                          isActiveAccordion ? "show" : ""
                        }`}
                        key={index}
                      >
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button gap-10 fw-bold collapsed ${
                              isActiveAccordion
                                ? "bg-brand-primary"
                                : "bg-light-brand-primary"
                            }  border border-brand-secondary `}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${collapseId}`}
                            aria-expanded={isActiveAccordion ? "true" : "false"}
                            aria-controls={collapseId}
                          >
                            <span>
                              {isFirstAccordion
                                ? `Latest Quote:# ${accordion?.slug}`
                                : `Previous Quote:# ${accordion?.slug}`}
                            </span>
                            <span>
                              {accordion?.created_at &&
                                format(
                                  new Date(accordion?.created_at),
                                  "MMMM d, yyyy h:mm:ss a"
                                )}
                            </span>
                          </button>
                        </h2>
                        <div
                          id={collapseId}
                          className={`accordion-collapse collapse ${
                            isActiveAccordion
                              ? "show"
                              : index === 0
                              ? "show"
                              : ""
                          }`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body border border-brand-secondary border-top-0">
                            <div className="row flex-column-reverse flex-md-row row-gap-4">
                              <div className="col-12 col-md-8">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: accordion?.greetings ?? "",
                                  }}
                                ></p>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="border border-1 card-body p-4 p-md-8">
                                  <h6>
                                    {accordion?.user_details?.first_name}{" "}
                                    {accordion?.user_details?.last_name}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <Table
                              responsive
                              striped
                              bordered
                              className={"mb-6"}
                            >
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Config</th>
                                  <th>Qty</th>
                                  <th>Rate</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {accordion?.quote_items?.map(
                                  (quoteItem, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div className="d-flex flex-column">
                                            <span>
                                              {quoteItem?.product ?? ""}
                                            </span>
                                            <span>
                                              Lock-In:{" "}
                                              {quoteItem?.lock_in ?? ""}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex flex-column">
                                            <span>
                                              {quoteItem?.config ?? ""}
                                            </span>
                                            <span>{quoteItem?.note ?? ""}</span>
                                          </div>
                                        </td>
                                        <td>{quoteItem?.qty ?? ""}</td>
                                        <td>₹ {quoteItem?.rate ?? ""}</td>
                                        <td>
                                          ₹{" "}
                                          {Number(quoteItem?.qty) *
                                            Number(quoteItem?.rate)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                            <div className="row ">
                              <div className="col-6">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: accordion?.terms,
                                  }}
                                />
                              </div>
                              <div className="col-6 ">
                                <Table
                                  responsive
                                  striped
                                  bordered
                                  className={"m-0"}
                                >
                                  <thead>
                                    <tr>
                                      <th>Total</th>
                                      <th>Tax</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        ₹ {quoteItemsTotal[index]["total"]}
                                      </td>
                                      <td>+18% GST</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        ₹ {quoteItemsTotal[index]["total"]} +
                                      </td>
                                      <td>
                                        ₹{" "}
                                        {(
                                          quoteItemsTotal[index]["total"] * 0.18
                                        ).toFixed(2)}{" "}
                                        GST Tax
                                      </td>
                                    </tr>
                                  </tbody>
                                  <thead>
                                    <tr>
                                      <th>
                                        ₹{" "}
                                        {(
                                          quoteItemsTotal[index]["total"] +
                                          quoteItemsTotal[index]["total"] * 0.18
                                        ).toFixed(2)}
                                      </th>
                                      <th>Inc of 18% GST</th>
                                    </tr>
                                  </thead>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ViewQuote;
