/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AlbumMedia } from '../model';
// @ts-ignore
import type { AttachMedia } from '../model';
// @ts-ignore
import type { MediaMeta } from '../model';
// @ts-ignore
import type { StorageAlbumsList200Response } from '../model';
// @ts-ignore
import type { StorageMediaCategoryList200Response } from '../model';
// @ts-ignore
import type { StorageMediaList200Response } from '../model';
// @ts-ignore
import type { StorageMetaList200Response } from '../model';
// @ts-ignore
import type { UploadObject } from '../model';
/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageAlbumsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/albums/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this album media model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageAlbumsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageAlbumsRead', 'id', id)
            const localVarPath = `/storage/albums/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finalize uploaded object and attach file to an asset. with media category
         * @param {AttachMedia} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageAttachCreate: async (data: AttachMedia, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('storageAttachCreate', 'data', data)
            const localVarPath = `/storage/attach/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMediaCategoryList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/media_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMediaList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/media/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this file media model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageMetaDelete', 'id', id)
            const localVarPath = `/storage/meta/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/meta/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this file media model.
         * @param {MediaMeta} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaPartialUpdate: async (id: number, data: MediaMeta, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageMetaPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('storageMetaPartialUpdate', 'data', data)
            const localVarPath = `/storage/meta/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this file media model.
         * @param {MediaMeta} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaUpdate: async (id: number, data: MediaMeta, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageMetaUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('storageMetaUpdate', 'data', data)
            const localVarPath = `/storage/meta/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Signed Upload URL Get Signed URL for Upload Media to temp path, attach to asset later.
         * @param {UploadObject} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCreate: async (data: UploadObject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('storageUploadCreate', 'data', data)
            const localVarPath = `/storage/upload/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageAlbumsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageAlbumsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageAlbumsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageAlbumsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this album media model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageAlbumsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlbumMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageAlbumsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageAlbumsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Finalize uploaded object and attach file to an asset. with media category
         * @param {AttachMedia} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageAttachCreate(data: AttachMedia, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageAttachCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageAttachCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageMediaCategoryList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageMediaCategoryList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageMediaCategoryList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageMediaCategoryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageMediaList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageMediaList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageMediaList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageMediaList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this file media model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageMetaDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageMetaDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageMetaDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageMetaList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageMetaList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageMetaList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageMetaList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this file media model.
         * @param {MediaMeta} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageMetaPartialUpdate(id: number, data: MediaMeta, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageMetaPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageMetaPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this file media model.
         * @param {MediaMeta} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageMetaUpdate(id: number, data: MediaMeta, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageMetaUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageMetaUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Signed Upload URL Get Signed URL for Upload Media to temp path, attach to asset later.
         * @param {UploadObject} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadCreate(data: UploadObject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageApi.storageUploadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @param {StorageApiStorageAlbumsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageAlbumsList(requestParameters: StorageApiStorageAlbumsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StorageAlbumsList200Response> {
            return localVarFp.storageAlbumsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageAlbumsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageAlbumsRead(requestParameters: StorageApiStorageAlbumsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<AlbumMedia> {
            return localVarFp.storageAlbumsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Finalize uploaded object and attach file to an asset. with media category
         * @param {StorageApiStorageAttachCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageAttachCreate(requestParameters: StorageApiStorageAttachCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AttachMedia> {
            return localVarFp.storageAttachCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageMediaCategoryListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMediaCategoryList(requestParameters: StorageApiStorageMediaCategoryListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StorageMediaCategoryList200Response> {
            return localVarFp.storageMediaCategoryList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageMediaListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMediaList(requestParameters: StorageApiStorageMediaListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StorageMediaList200Response> {
            return localVarFp.storageMediaList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageMetaDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaDelete(requestParameters: StorageApiStorageMetaDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.storageMetaDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageMetaListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaList(requestParameters: StorageApiStorageMetaListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StorageMetaList200Response> {
            return localVarFp.storageMetaList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageMetaPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaPartialUpdate(requestParameters: StorageApiStorageMetaPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<MediaMeta> {
            return localVarFp.storageMetaPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageApiStorageMetaUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageMetaUpdate(requestParameters: StorageApiStorageMetaUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<MediaMeta> {
            return localVarFp.storageMetaUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.storageTestRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Signed Upload URL Get Signed URL for Upload Media to temp path, attach to asset later.
         * @param {StorageApiStorageUploadCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCreate(requestParameters: StorageApiStorageUploadCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadObject> {
            return localVarFp.storageUploadCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for storageAlbumsList operation in StorageApi.
 * @export
 * @interface StorageApiStorageAlbumsListRequest
 */
export interface StorageApiStorageAlbumsListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StorageApiStorageAlbumsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StorageApiStorageAlbumsList
     */
    readonly offset?: number
}

/**
 * Request parameters for storageAlbumsRead operation in StorageApi.
 * @export
 * @interface StorageApiStorageAlbumsReadRequest
 */
export interface StorageApiStorageAlbumsReadRequest {
    /**
     * A unique integer value identifying this album media model.
     * @type {number}
     * @memberof StorageApiStorageAlbumsRead
     */
    readonly id: number
}

/**
 * Request parameters for storageAttachCreate operation in StorageApi.
 * @export
 * @interface StorageApiStorageAttachCreateRequest
 */
export interface StorageApiStorageAttachCreateRequest {
    /**
     * 
     * @type {AttachMedia}
     * @memberof StorageApiStorageAttachCreate
     */
    readonly data: AttachMedia
}

/**
 * Request parameters for storageMediaCategoryList operation in StorageApi.
 * @export
 * @interface StorageApiStorageMediaCategoryListRequest
 */
export interface StorageApiStorageMediaCategoryListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StorageApiStorageMediaCategoryList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StorageApiStorageMediaCategoryList
     */
    readonly offset?: number
}

/**
 * Request parameters for storageMediaList operation in StorageApi.
 * @export
 * @interface StorageApiStorageMediaListRequest
 */
export interface StorageApiStorageMediaListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StorageApiStorageMediaList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StorageApiStorageMediaList
     */
    readonly offset?: number
}

/**
 * Request parameters for storageMetaDelete operation in StorageApi.
 * @export
 * @interface StorageApiStorageMetaDeleteRequest
 */
export interface StorageApiStorageMetaDeleteRequest {
    /**
     * A unique integer value identifying this file media model.
     * @type {number}
     * @memberof StorageApiStorageMetaDelete
     */
    readonly id: number
}

/**
 * Request parameters for storageMetaList operation in StorageApi.
 * @export
 * @interface StorageApiStorageMetaListRequest
 */
export interface StorageApiStorageMetaListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StorageApiStorageMetaList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StorageApiStorageMetaList
     */
    readonly offset?: number
}

/**
 * Request parameters for storageMetaPartialUpdate operation in StorageApi.
 * @export
 * @interface StorageApiStorageMetaPartialUpdateRequest
 */
export interface StorageApiStorageMetaPartialUpdateRequest {
    /**
     * A unique integer value identifying this file media model.
     * @type {number}
     * @memberof StorageApiStorageMetaPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MediaMeta}
     * @memberof StorageApiStorageMetaPartialUpdate
     */
    readonly data: MediaMeta
}

/**
 * Request parameters for storageMetaUpdate operation in StorageApi.
 * @export
 * @interface StorageApiStorageMetaUpdateRequest
 */
export interface StorageApiStorageMetaUpdateRequest {
    /**
     * A unique integer value identifying this file media model.
     * @type {number}
     * @memberof StorageApiStorageMetaUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MediaMeta}
     * @memberof StorageApiStorageMetaUpdate
     */
    readonly data: MediaMeta
}

/**
 * Request parameters for storageUploadCreate operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadCreateRequest
 */
export interface StorageApiStorageUploadCreateRequest {
    /**
     * 
     * @type {UploadObject}
     * @memberof StorageApiStorageUploadCreate
     */
    readonly data: UploadObject
}

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @param {StorageApiStorageAlbumsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageAlbumsList(requestParameters: StorageApiStorageAlbumsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageAlbumsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageAlbumsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageAlbumsRead(requestParameters: StorageApiStorageAlbumsReadRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageAlbumsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finalize uploaded object and attach file to an asset. with media category
     * @param {StorageApiStorageAttachCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageAttachCreate(requestParameters: StorageApiStorageAttachCreateRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageAttachCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageMediaCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageMediaCategoryList(requestParameters: StorageApiStorageMediaCategoryListRequest = {}, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageMediaCategoryList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageMediaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageMediaList(requestParameters: StorageApiStorageMediaListRequest = {}, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageMediaList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageMetaDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageMetaDelete(requestParameters: StorageApiStorageMetaDeleteRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageMetaDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageMetaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageMetaList(requestParameters: StorageApiStorageMetaListRequest = {}, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageMetaList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageMetaPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageMetaPartialUpdate(requestParameters: StorageApiStorageMetaPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageMetaPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageMetaUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageMetaUpdate(requestParameters: StorageApiStorageMetaUpdateRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageMetaUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageTestRead(options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageTestRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Signed Upload URL Get Signed URL for Upload Media to temp path, attach to asset later.
     * @param {StorageApiStorageUploadCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadCreate(requestParameters: StorageApiStorageUploadCreateRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}

