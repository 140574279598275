import React, { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSThemeColor } from "@app/pages/erp/Rentals/RentalProductsList/_models.ts";

export interface ActionBtnProps {
  isIconbtn: boolean;
  action: () => void;
  title: string;
  icon: ReactNode;
  theme?: string | BSThemeColor;
  alertIcon?: string | ReactNode | null;
}

const ActionBtn = ({
  isIconbtn,
  action,
  title,
  icon,
  theme,
  alertIcon,
}: ActionBtnProps) => {
  return (
    <div>
      {!isIconbtn && (
        <div className="menu-item px-3  ">
          <div
            className={`menu-link px-3 text-hover-${theme} bg-hover-light-${theme}`}
            onClick={() => action()}
          >
            <div className="dropdown-item fw-semibold hstack gap-2 cursor-pointer position-relative">
              {icon}
              <span>
                {title} {alertIcon}
              </span>
            </div>
          </div>
        </div>
      )}
      {isIconbtn && (
        <OverlayTrigger
          placement="top"
          overlay={title ? <Tooltip>{title}</Tooltip> : <></>}
        >
          <button
            className={`btn btn-sm btn-icon btn-outline border-${theme} `}
            onClick={() => action()}
          >
            {icon}
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
};
export default ActionBtn;
