import React from "react";
import { task_status_variant } from "@Constants/APP_Constants.ts";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import ProfileImage from "@components/helpers/ProfleImage/ProfileImage.tsx";
import ClientUserCardSm from "../../../../../components/helpers/CrmHelpers/Ui/ClientUserCardSm";
import ProductCardSm from "../../../../../components/helpers/CrmHelpers/Ui/ProductCardSm";
import TableAlert from "../../../../../components/helpers/CustomAlerts/TableAlert";
import {
  dayMonYearDate,
  getFulldate,
  longDate,
  timeAgo,
} from "@/Utils/Shared/DateStringToDays.ts";
import { TatHelperFunction } from "@components/UI/TAT/TatHelperFun.ts";
import TaskCreateMOdel from "./ModelCreaateTask";
import { Table } from "react-bootstrap";
import CommentModel from "./CommentModel";
import { useQuery } from "react-query";
import {
  getComments,
  getStageUpdateInCR,
  getTasksByIDToken,
} from "../_request";
import { useAuth } from "@app/modules/auth";
import SeparatorY from "../../../../../components/helpers/Separators/SeparatorY";
import { CRStageColor, CRStageIcon } from "@Constants/CR_Constant";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";
import CopyButton from "@components/UI/Buttons/CopyButton.tsx";
import { TASKS_PATHS } from "@Constants/RoutingConstants/erp/RedirectRouteConstants.ts";
import RowCardsSkeleton from "@components/helpers/Skeletons/RowCardsSkeleton.tsx";
import TableSkeleton from "@components/helpers/Skeletons/TableSkeleton.tsx";
import TextLinesSkeleton from "@components/helpers/Skeletons/TextLinesSkeleton.tsx";
import ListSkeleton from "@components/helpers/Skeletons/ListSkeleton.tsx";
import HorizonatlTimelIne from "@components/helpers/HorizontalTimeLine/HorizonatlTimelIne.tsx";

const UiTickite = ({
  StageData,
  item,
  isTicketeLoading,
  isStageLoading,
  isStageErr,
  comments,
  TaskDetails,
  TaskLodonig,
  redirectToTaskView = (e) => {},
}) => {
  return (
    <div className={"card"}>
      <div className={"card-body"}>
        <div className={"vstack gap-6"}>
          {isTicketeLoading ? (
            <RowCardsSkeleton />
          ) : (
            <div className="row">
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-body p-6">
                    <div className="d-flex align-items-center gap-4">
                      <div className="btn btn-icon btn-primary">
                        {/*  todo iconn*/}
                      </div>
                      <div className="flex-grow-1 d-flex flex-column justify-content-center gap-1">
                        <div className={"d-flex gap-2"}>
                          {item?.title}{" "}
                          <CommonBadge
                            text={item?.current_stage?.stage}
                            theme={"sr_stage"}
                          />
                        </div>
                        <strong className="card-title fw-bold fs-7 m-0">
                          {item?.serial ? item?.serial : ""}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-body p-6">
                    <div className="d-flex align-items-center gap-4">
                      <div className="btn btn-icon btn-light-primary">
                        <ProfileImage
                          src={item?.connect_info?.avatar}
                          name={item?.connect_info?.name}
                          alt={item?.connect_info?.name}
                        />
                      </div>
                      <div className="flex-grow-1 d-flex flex-column justify-content-center gap-1">
                        <span className="card-title fw-bold fs-5 m-0">
                          {`${item?.connect_info?.name ?? ""}`}
                        </span>
                        <span className="">
                          {item?.connect_info?.slug ?? ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isStageLoading ? (
            <TableSkeleton col={5} row={5} lines={2} />
          ) : (
            <>
              {/*<div className="card h-100">*/}
              {/*  <div className="card-body p-6">*/}
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body p-6">
                      <ClientUserCardSm user={item?.consignee_details} />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <ProductCardSm
                    data={{
                      ...item?.product_details,
                      serial: item?.serial_number,
                    }}
                  />
                </div>
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </>
          )}
          {/*  Description  */}
          <div className="card">
            <div className="card-body p-6">
              {isStageLoading ? (
                <TextLinesSkeleton />
              ) : (
                <div className="">
                  <span className={"fw-bold"}>Description</span>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </div>
              )}
            </div>
          </div>

          {/*Internal*/}
          <div className="col-12 my-5">
            <div className="card">
              <div className="card-header align-items-center justify-content-between  d-flex bg-light">
                <div className="card-title">
                  <strong>Internal Tasks</strong>
                </div>
                <TaskCreateMOdel data={item} />
              </div>
            </div>
            <Table bordered striped responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Title</th>
                  <th>Connect</th>
                  <th>Date</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {!TaskLodonig &&
                  TaskDetails?.map((task, index) => {
                    return (
                      <tr
                        key={index}
                        onDoubleClick={() => redirectToTaskView(task?.id)}
                        className="cursor-pointer"
                      >
                        <td>
                          <div className="">
                            {task?.assigned_user?.first_name}
                          </div>
                          <span className="badge badge-sm bage-outline badge-secondary rounded-pill cursor-pointer">
                            <i className="bi bi-copy text-dark"></i> &nbsp; SPG-
                            {task?.id}
                          </span>
                          {/*<button type="button" className="btn btn-sm btn-outline  border-dark fw-bold"><i className="bi bi-copy text-dark"></i> SPG-{task?.id}</button>*/}
                        </td>
                        <td className={""}>
                          <div className={"vstack gap-2"}>
                            <div>
                              <span
                                className={`badge rounded-pill bg-${
                                  task_status_variant[task?.status_label]
                                } text-white`}
                              >
                                {task?.status_label}
                              </span>
                            </div>
                            <div>
                              <span className="badge rounded-pill bg-secondary">
                                {task?.department_name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>{task?.title}</span>
                        </td>
                        <td>{task?.connect_info?.name}</td>
                        <td>
                          <p className="m-0 p-0">
                            <b>Created At:</b>
                            {longDate(task?.created_at)}
                          </p>
                          <p className="m-0 p-0">
                            <b>Updated At:</b>
                            {longDate(task?.updated_at)}
                          </p>
                          <p className="m-0 p-0">
                            <strong className={"text-danger"}>
                              Deadline:{" "}
                            </strong>
                            {task?.deadline}
                          </p>
                        </td>
                        <td>{task?.priority_label}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/*comment*/}
          <div className="col-12">
            <div className="card rounded-0 border-0">
              <div className="card-header align-items-center fs-6 d-flex bg-light">
                <div>
                  <strong>Client Conversation </strong>
                </div>
                <div>
                  <CommentModel data={item} />
                </div>
              </div>
              <div>
                {comments?.length > 0 &&
                  comments?.map((comment) => {
                    return (
                      <div key={comment?.id}>
                        <div className="card ">
                          <div className="card-body p-6 ">
                            <div className="w-100 d-flex flex-stack mb-2">
                              <div className="d-flex align-items-center ">
                                <div className="symbol symbol-50px me-5">
                                  <ProfileImage
                                    src={comment?.user_info?.avatar}
                                    name={comment?.user_info?.display_name}
                                  />
                                </div>

                                <div className="d-flex flex-column fw-semibold fs-5 text-gray-600 text-gray-900">
                                  <div className="d-flex align-items-center">
                                    <span className="text-gray-800 fw-bold fs-5 me-3">
                                      {comment?.user_info?.display_name}
                                    </span>

                                    <span className="badge badge-light-danger"></span>
                                  </div>

                                  <span className="text-muted fw-semibold fs-6"></span>
                                </div>
                              </div>

                              <div className="m-0">
                                <button className="btn btn-color-gray-500 btn-active-color-primary p-0 fw-bold">
                                  {" "}
                                  {timeAgo(comment?.created_at)}
                                </button>
                              </div>
                            </div>

                            <p
                              className="fw-normal fs-5 text-gray-700 m-0 "
                              dangerouslySetInnerHTML={{
                                __html: comment?.comment,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UiTickite;

export const StageUI = ({ TickiteDetails }: { TickiteDetails: any }) => {
  const { auth } = useAuth();
  const {
    data: StageData,
    isLoading: isStageLoading,
    isError: isStageErr,
  } = useQuery<any, Error>(
    [" Cr_stage ", TickiteDetails?.id],
    () => getStageUpdateInCR(auth?.access, TickiteDetails?.id),
    {
      enabled: !!TickiteDetails?.id,
    }
  );

  return (
    <>
      {isStageLoading ? <ListSkeleton /> : ""}
      {isStageErr ? (
        <TableAlert message={"Error fetching stages. Please try again."} />
      ) : (
        <>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item rounded-0">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed text-dark accordion-indicator-center"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="d-flex w-100 justify-content-between">
                    {StageData?.data?.results.length && (
                      <div className="d-flex align-items-center gap-4">
                        <div className="flex-shrink-0">
                          <div
                            className={` h-45px w-45px rounded-circle btn-outline btn btn-icon border-${
                              CRStageColor[StageData?.data?.results[0]?.stage]
                            } btn-color-${
                              CRStageColor[StageData?.data?.results[0]?.stage]
                            }`}
                          >
                            <i
                              className={`${
                                CRStageIcon[StageData?.data?.results[0]?.stage]
                              } fs-2`}
                            ></i>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <strong className="">
                              {DecorateNameTags(
                                StageData?.data?.results[0]?.stage ?? ""
                              )}
                            </strong>
                            <div
                              className="d-block text-mute mt-1"
                              style={{
                                color: "gray !important",
                              }}
                            >
                              {getFulldate(
                                StageData?.data?.results[0]?.created_at ?? ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <HorizonatlTimelIne
                      items={TatHelperFunction(
                        TickiteDetails?.stage_tats_and_total?.stages,
                        "CR"
                      )}
                    />
                  </div>
                </button>
              </h2>

              <div
                id="collapseOne"
                className="accordion-collapse collapse "
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body ">
                  {StageData?.data?.results?.length > 0 &&
                    StageData?.data?.results?.map((stage, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className={`list-group-item w-100 ${
                              index === 0 ? "visually-hidden" : ""
                            }`}
                            style={{
                              filter:
                                index !== 0
                                  ? "brightness(80%) contrast(150%) grayscale(100%)"
                                  : "none",
                              opacity: index !== 0 ? "0.50" : "",
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center gap-4">
                                <div className="flex-shrink-0">
                                  <div
                                    className={`h-45px w-45px rounded-circle btn-outline btn btn-icon border-${
                                      CRStageColor[stage?.stage]
                                    } btn-color-${CRStageColor[stage?.stage]}`}
                                  >
                                    <i
                                      className={`${
                                        CRStageIcon[stage?.stage]
                                      } fs-2`}
                                    ></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className={"vstack gap-1"}>
                                    <strong className="fs-6">
                                      {DecorateNameTags(stage?.stage ?? "")}
                                    </strong>
                                    <div className="text-muted fs-7 fw-semibold">
                                      {getFulldate(stage?.created_at ?? "")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {index !== 0 &&
                          index !== StageData?.data?.results?.length - 1 ? (
                            <div
                              className={"h-40px my-2"}
                              style={{ marginInlineStart: "calc(45px / 2)" }}
                            >
                              <SeparatorY />
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const CrInternalTasks = ({
  TickiteDetails,
}: {
  TickiteDetails: any;
}) => {
  const redirectToTaskView = (taskId) => {
    const taskViewPath = `${TASKS_PATHS.VIEW}${taskId}`;
    window.open(taskViewPath);
  };

  const { auth } = useAuth();

  const { data: TaskDetails, isLoading: TaskLodonig } = useQuery<any, Error>(
    ["taskDetaisl", { TickiteDetails }],
    () => {
      return getTasksByIDToken(auth?.access, TickiteDetails?.id)
        .then((response: any) => {
          return response?.data?.results;
        })
        .catch((error) => {
          return error.response;
        });
    },
    {
      enabled: !!TickiteDetails,
    }
  );

  return (
    <div className="col-12 my-5">
      <div className="card">
        <div className="card-header align-items-center justify-content-between  d-flex bg-light">
          <div className="card-title">
            <strong>Internal Tasks</strong>
          </div>
          <TaskCreateMOdel data={TickiteDetails} />
        </div>
      </div>
      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Title</th>
            <th>Connect</th>
            <th>Date</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          {!TaskLodonig &&
            Array?.isArray(TaskDetails) &&
            TaskDetails?.map((task, index) => {
              return (
                <tr
                  key={index}
                  onDoubleClick={() => redirectToTaskView(task?.id)}
                  className="cursor-pointer"
                >
                  <td>
                    <div className="table-cell-col">
                      <div className="">{task?.assigned_user?.first_name}</div>
                      <CopyButton
                        text={`${window.location.origin}${TASKS_PATHS.VIEW}${task?.id}`}
                        title={`SPG-${task?.id}`}
                        isIcon={false}
                        isAction={false}
                      />
                    </div>
                  </td>
                  <td className={""}>
                    <div className={"table-cell-col"}>
                      <div>
                        <CommonBadge
                          text={task?.status_label ?? ""}
                          theme={"task_status"}
                        />
                      </div>
                      <div>
                        <CommonBadge
                          text={task?.department_name ?? ""}
                          fallback={"brand-secondary"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{task?.title}</span>
                  </td>
                  <td>{task?.connect_info?.name}</td>
                  <td>
                    <p className="m-0 p-0">
                      <b>Created At:</b>
                      {longDate(task?.created_at)}
                    </p>
                    <p className="m-0 p-0">
                      <b>Updated At:</b>
                      {longDate(task?.updated_at)}
                    </p>
                    <p className="m-0 p-0">
                      <strong className={"text-danger"}>Deadline: </strong>
                      {dayMonYearDate(task?.deadline)}
                    </p>
                  </td>
                  <td>{task?.priority_label}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export const CrCommentView = ({ TickiteDetails }) => {
  const { data: comments } = useQuery<any, Error>(
    ["taskComment", TickiteDetails?.id],
    () => {
      return getComments(TickiteDetails?.access, TickiteDetails?.id)
        .then((response: any) => {
          return response?.data?.results;
        })
        .catch((error) => {
          return error.response;
        });
    },
    {
      enabled: !!TickiteDetails,
    }
  );

  return (
    <div className="col-12">
      <div className="card rounded-0 border-0">
        <div className="card-header align-items-center fs-6 d-flex bg-light">
          <div>
            <strong>Client Conversation </strong>
          </div>
          <div>
            <CommentModel data={TickiteDetails} />
          </div>
        </div>
        <div>
          {comments?.length > 0 &&
            comments?.map((comment) => {
              return (
                <div key={comment?.id}>
                  <div className="card ">
                    <div className="card-body p-6 ">
                      <div className="w-100 d-flex flex-stack mb-2">
                        <div className="d-flex align-items-center ">
                          <div className="symbol symbol-50px me-5">
                            <ProfileImage
                              src={comment?.user_info?.avatar}
                              name={comment?.user_info?.display_name}
                            />
                          </div>

                          <div className="d-flex flex-column fw-semibold fs-5 text-gray-600 text-gray-900">
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bold fs-5 me-3">
                                {comment?.user_info?.display_name}
                              </span>

                              <span className="badge badge-light-danger"></span>
                            </div>

                            <span className="text-muted fw-semibold fs-6"></span>
                          </div>
                        </div>

                        <div className="m-0">
                          <button className="btn btn-color-gray-500 btn-active-color-primary p-0 fw-bold">
                            {" "}
                            {timeAgo(comment?.created_at)}
                          </button>
                        </div>
                      </div>

                      <p
                        className="fw-normal fs-5 text-gray-700 m-0 "
                        dangerouslySetInnerHTML={{
                          __html: comment?.comment,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export const CrComponetID = ({ item, isTickitLoading }) => {
  return (
    <>
      {isTickitLoading ? (
        <RowCardsSkeleton />
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body p-6">
                <div className="d-flex align-items-center gap-4">
                  <div className="btn btn-icon btn-brand-primary">
                    <i className="bi bi-bookmark-heart-fill fs-2x"></i>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center gap-1">
                    <div className={"d-flex gap-2"}>
                      {item?.title}{" "}
                      <CommonBadge
                        text={item?.current_stage?.stage}
                        theme={"sr_stage"}
                      />
                    </div>
                    <strong className="card-title fw-bold fs-7 m-0">
                      {item?.serial ? item?.serial : ""}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body p-6">
                <div className="d-flex align-items-center gap-4">
                  <div className="btn btn-icon">
                    <ProfileImage
                      src={item?.connect_info?.avatar}
                      name={item?.connect_info?.name}
                      alt={item?.connect_info?.name}
                    />
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center gap-1">
                    <span className="card-title fw-bold fs-5 m-0">
                      {`${item?.connect_info?.name ?? ""}`}
                    </span>
                    <span className="">{item?.connect_info?.slug ?? ""}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isTickitLoading ? (
        <TableSkeleton col={5} row={5} lines={2} />
      ) : (
        <>
          {/*<div className="card h-100">*/}
          {/*  <div className="card-body p-6">*/}
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body p-6">
                  <ClientUserCardSm user={item?.consignee_details} />
                </div>
              </div>
            </div>
            <div className="col">
              <ProductCardSm
                data={{
                  ...item?.product_details,
                  serial: item?.serial_number,
                }}
              />
            </div>
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </>
      )}
      {/*  Description  */}
      <div className="card">
        <div className="card-body p-6">
          {isTickitLoading ? (
            <TextLinesSkeleton />
          ) : (
            <div className="">
              <span className={"fw-bold"}>Description</span>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
