import React from "react";
import ExtendedProductPublicView from "@app/pages/erp/products/ExtendedProductComponents/ExtendedProductPublicView.tsx";
import { useQuery } from "react-query";
import { publicStockyApi } from "@/api/public";
import { useParams } from "react-router-dom";
import { GenericProduct } from "@/generated-api";
import BackgroundWrapper from "@components/UI/Wrappers/BackgroundWrapper.tsx";

function ExtendedProductViewPublicPage() {
  const { id } = useParams();
  const productId = id ? parseInt(id) : null;

  // Fetch Data
  const { data } = useQuery<GenericProduct, any>({
    queryKey: ["public_product_view", id],
    queryFn: () => {
      return publicStockyApi
        .stockyProductsRead({ id: productId! })
        .then((res) => res.data);
    },
    enabled: !!productId,
    retry: false,
  });

  // Error Redirection
  // useErrorRedirection(error?.response);

  return (
    <BackgroundWrapper>
      <div className={"container"}>
        <div className="d-flex flex-column justify-content-center">
          <div className="vstack my-10">
            <div className="card shadow">
              <div className="card-header">
                <h1 className="card-title fs-1">Product Details</h1>
              </div>
              <div className="card-body">
                <ExtendedProductPublicView data={data} height={"400px"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundWrapper>
  );
}

export default ExtendedProductViewPublicPage;
