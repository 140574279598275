export function getLimitAndOffset(urlString: string | null | undefined) {
  if (!urlString) {
    return { limit: null, offset: null };
  }

  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);

  const limit = params.get("limit") ? parseInt(params.get("limit")!) : 0;
  const offset = params.get("offset") ? parseInt(params.get("offset")!) : 0;

  return { limit, offset };
}

export type PaginatedResponse = {
  data:
    | {
        count: number;
        next: string | null | undefined;
        previous: string | null | undefined;
        results: any[];
      }
    | undefined;
};

export function getCurrentPage({ data }: PaginatedResponse) {
  if (!data) {
    return 0;
  }

  const { count, next, previous } = data;

  if (previous === null && next !== null) {
    return 0;
  }

  if (next === null && previous !== null) {
    const { offset } = getLimitAndOffset(previous);

    if (offset) {
      return Math.floor(count / offset);
    }
  }

  const { offset } = getLimitAndOffset(next);
  if (offset) {
    return Math.floor(count / offset);
  }

  return 0;
}
