import React from "react";
import CompanyImage from "@components/helpers/CompalyImage/CompanyImage";
import SupportPopover from "../../../../../../app/pages/crm/CRMPageWrapper/SupportPopover";
import { useAuth } from "../../../../../../app/modules/auth";

function CrmHeader() {
  const { currentUser } = useAuth();

  return (
    <div className="d-flex align-items-center w-100">
      <div className="flex-grow-1 d-flex flex-center">
        <CompanyImage
          width={50}
          alt={""}
          id={currentUser?.account?.connect?.company}
        />
      </div>
      <div className="">
        <SupportPopover
          placement={"bottom"}
          positionHeader={true}
          domain={currentUser?.account?.connect?.domain}
        />
      </div>
    </div>
  );
}

export default CrmHeader;
