import React from "react";

type SrMetricsDetailsProps = {
  data: any;
};

function SrMetricsDetails({ data }: SrMetricsDetailsProps) {
  return (
    <div className="card shadow-xs">
      <div className="card-header">
        <h5 className="card-title fw-bolder">Support Requests Today </h5>
      </div>
      <ul className="list-group list-group-flush mb-0 pb-0  ">
        <li className="list-group-item d-flex justify-content-between align-items-center border-bottom-0 px-9">
          <span className="">RAISED TODAY</span>
          <span className="fw-bold text-primary">
            {data?.client_request?.today?.total ?? 0}
          </span>
        </li>
        <hr className="m-0 p-0 border-bottom-brand-primary" />
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span className="fw-bold">Total Active CR</span>
        </li>
        {data?.client_request?.totals?.status?.length > 0 &&
          data?.client_request?.totals?.status?.map((e, index) => {
            return (
              <li
                className={`list-group-item d-flex justify-content-between align-items-center px-9 ${
                  index + 1 === data?.client_request?.totals.status?.length
                    ? "border-0"
                    : ""
                }`}
                key={index}
              >
                <span className="">{e?.status}</span>
                <span className="fw-bold text-primary">{e?.count}</span>
              </li>
            );
          })}
        <hr className="m-0 p-0 border-bottom-brand-primary" />
        {data?.client_request?.client_list?.map((e, index) => {
          return (
            <li
              className={`list-group-item d-flex justify-content-between align-items-center px-9`}
              key={index}
            >
              <span className="">{e?.connect__name}</span>
              <span className="fw-bold text-primary">{e?.count}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SrMetricsDetails;
