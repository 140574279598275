import React from "react";
import OperationMetricsDetails from "@app/pages/erp/DashboardHelpers/MetricsDetails/OperationMetricsDetails.tsx";
import LogisticsMetricsDetails from "@app/pages/erp/DashboardHelpers/MetricsDetails/LogisticsMetricsDetails.tsx";
import OrdersMetricsDetails from "@app/pages/erp/DashboardHelpers/MetricsDetails/OrdersMetricsDetails.tsx";
import SrMetricsDetails from "@app/pages/erp/DashboardHelpers/MetricsDetails/SrMetricsDetails.tsx";

type MetricsDetailsContainerProps = {
  metricsData: any;
  loading: boolean;
};

function MetricsDetailsContainer({
  metricsData,
  loading,
}: MetricsDetailsContainerProps): React.JSX.Element {
  return (
    <>
      <div className="row gy-5 gy-xxl-10 gx-xl-4 mb-8">
        {/* Operations */}
        <div className="col-sm-6 col-xxl-3">
          <OperationMetricsDetails data={metricsData} />
        </div>

        {/* Logistics */}
        <div className="col-sm-6 col-xxl-3">
          <LogisticsMetricsDetails data={metricsData} />
        </div>

        {/* Orders */}
        <div className="col-sm-6 col-xxl-3">
          <OrdersMetricsDetails data={metricsData} />
        </div>

        {/* Support Requests */}
        <div className="col-sm-6 col-xxl-3">
          <SrMetricsDetails data={metricsData} />
        </div>
      </div>
    </>
  );
}

export default MetricsDetailsContainer;
