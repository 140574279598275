import React, { ReactElement, ReactNode, useState } from "react";

type contentObjectValue = {
  id: string | number;
  label: string;
  content: ReactElement;
};

type contentObjectType = {
  [key: string]: contentObjectValue;
};

type NavigationCardProps = {
  contentObject: contentObjectType;
  Heading?: ReactNode;
};

function NavigationCard({
  contentObject,
  Heading,
}: NavigationCardProps): React.JSX.Element {
  const [currentTab, setCurrentTab] = useState(() => {
    return Object.keys(contentObject)[0];
  });

  // Methods
  function handleTabClick(key: string) {
    setCurrentTab(key);
  }

  return (
    <div className="card h-100 shadow-xs">
      <div className="card-header position-relative py-0 border-bottom-2">
        <ul
          className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3"
          role="tablist"
        >
          {Object.entries(contentObject).map(([key, value], index) => {
            return (
              <li
                className="nav-item p-0 ms-0 me-8"
                role="presentation"
                key={`${key}-${index}`}
              >
                <button
                  className={`nav-link btn btn-color-muted px-0 text-capitalize ${
                    key === currentTab ? "active" : ""
                  }`}
                  role="tab"
                  onClick={() => handleTabClick(key)}
                >
                  <span className="nav-text fw-semibold fs-4 mb-3">
                    {value.label}
                  </span>

                  <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                </button>
              </li>
            );
          })}
        </ul>
        <div className="justify-content-center align-self-center">
          {Heading}
        </div>
      </div>

      <div className="card-body">
        <div className="tab-content mb-2">
          {currentTab ? (
            <div
              className={"animate__animated animate__fadeIn"}
              key={contentObject?.[currentTab]?.id}
            >
              {contentObject?.[currentTab]?.content}
            </div>
          ) : (
            "Tab not selected"
          )}
        </div>
      </div>
    </div>
  );
}

export default NavigationCard;

NavigationCard.defaultProps = {
  contentObject: {
    one: {
      id: "one",
      label: "one",
      content: (
        <div className={``} role="tabpanel">
          one
        </div>
      ),
    },
    two: {
      id: "two",
      label: "two",
      content: (
        <div className={``} role="tabpanel">
          two
        </div>
      ),
    },
    three: {
      id: "three",
      label: "three",
      content: (
        <div className={``} role="tabpanel">
          three
        </div>
      ),
    },
    four: {
      id: "four",
      label: "four",
      content: (
        <div className={``} role="tabpanel">
          four
        </div>
      ),
    },
    five: {
      id: "five",
      label: "five",
      content: (
        <div className={``} role="tabpanel">
          five
        </div>
      ),
    },
    six: {
      id: "six",
      label: "six",
      content: (
        <div className={``} role="tabpanel">
          six
        </div>
      ),
    },
  },
};
