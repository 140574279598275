import React from "react";
import { Placeholder } from "react-bootstrap";

const CkEditorSkeleton = () => {
  return (
    <div className="card">
      <div className="border border-1 p-2">
        <Placeholder as="span" animation="glow">
          <Placeholder bg={"secondary"} className={"w-50"} />
        </Placeholder>
      </div>

      <div className="card-body min-h-100px"></div>
    </div>
  );
};
export default CkEditorSkeleton;
