import { useEffect } from "react";
interface useTitleInterface {
  title: string;
}

function useDocumentTitle({ title }: useTitleInterface) {
  useEffect(() => {
    document.title = title;
  }, [title]);
}
export default useDocumentTitle;
