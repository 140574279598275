import * as Yup from "yup";
import { ObjectSchema } from "yup";
export interface CreateTicketModel {
  description?: string | undefined | null;
  title?: string | undefined;
  serial_number?: string | undefined;
  email?: string | undefined;
  name?: string | undefined;
  number?: string | undefined;
  captcha?: string | undefined;
  consignee?: number | undefined | null;
  category?: number | undefined | null;
}

export const createTicketValidationSchema: ObjectSchema<any> =
  Yup.object().shape({
    description: Yup.string().nullable(),
    title: Yup.string().required("Subject is required"),
    serial_number: Yup.string().required("Serial Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Contact Email is required"),
    name: Yup.string().required("Contact Name is required"),
    number: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Contact Number is required"),
    captcha: Yup.string().required("Captcha is required"),
    consignee: Yup.number().nullable(),
    category: Yup.number().required("Category is required"),
  });

export const initialValues: CreateTicketModel = {
  description: null,
  title: "",
  serial_number: "",
  email: "",
  name: "",
  number: "",
  captcha: "",
  consignee: null,
  category: null,
};

export type selectionStatus = "NOT_ASSIGNED" | "ASSIGNED" | "UNSELECTED";
