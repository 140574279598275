import axios, { AxiosError, AxiosResponse } from "axios";
import { OrdersApiResponse } from "./_model";

import { buildURL } from "@/Utils/Shared/buildUrl.ts";
import * as Yup from "yup";

const API_URL = import.meta.env.VITE_APP_API_URL;

export const TICKET_CONSTANT = `${API_URL}/stocky/client_requests/`;
export const TICKET_DETALIS_CONSTANT = `${API_URL}/stocky/client_requests/`;
export const TICKET_COMMET = `${API_URL}/stocky/client_request/`;

const TICKET_STAGE_URL = `${API_URL}/stocky/client_requests/stage/`;

const TICKET_TASK_ID = `${API_URL}/stocky/tasks/`;
const COMMENT_TASK_ID = `${API_URL}/stocky/client_request/comments/?task_id=`;

const POST_COMMENT_URL = `${API_URL}/stocky/task/comments/`;
export const EXPORT_CLIENT_REQUESTS = `${API_URL}/stocky/client_requests/export/`;

export function getOrderByToken(token: string) {
  const url = `${TICKET_CONSTANT}`;

  return axios.get<OrdersApiResponse>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getTickite_with_filter_ByToken(
  token: string | undefined,
  quarry: any,
  connect: string
) {
  const url = buildURL(quarry, TICKET_CONSTANT);
  return axios
    .get<OrdersApiResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<OrdersApiResponse>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      throw error;
    });
}

export function raiseTicketUpdate(token: any, param: any, payload: any) {
  return axios.patch<any>(`${TICKET_CONSTANT}${param}/`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  department: Yup.number().required("Department is required"),
  serial: Yup.array().of(Yup.string()), // Replace with the actual type if possible
  connect: Yup.mixed().nullable(),
  description: Yup.string().required("Description is required"),
  category: Yup.string().required("Category is required"),
  client_info: Yup.object({
    name: Yup.string().required("Name is required"),
    number: Yup.string().required("Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  }),
});

export function createTicket(token: string, body: any) {
  const createTicketUrl = `${API_URL}/stocky/client_requests/`; // Adjust the URL according to your API

  return axios
    .post<OrdersApiResponse>(createTicketUrl, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response: AxiosResponse<OrdersApiResponse>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      handleAxiosError(error); // You can create a common error handling function
      throw error;
    });
}

// Common error handling function
function handleAxiosError(error: AxiosError) {
  if (error.response) {
    console.error("Response data:", error.response.data);
    console.error("Response status:", error.response.status);
    console.error("Response headers:", error.response.headers);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error during request setup:", error.message);
  }
  console.error("Error config:", error.config);
}

export const fetchTickite_details = (id: string | null | undefined) => {
  const url = `${TICKET_DETALIS_CONSTANT}${id}`;
  return axios
    .get<OrdersApiResponse>(url, {
      headers: {},
    })
    .then((response: AxiosResponse<OrdersApiResponse>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);
      throw error;
    });
};

export function getTasksByIDToken(token: any, id) {
  const url = `${TICKET_TASK_ID}?client_request=${id}`;

  return axios.get<OrdersApiResponse>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function patchClintrequest(token: any, id, obj) {
  const url = `${TICKET_DETALIS_CONSTANT}${id}/`;
  return axios.patch<OrdersApiResponse>(url, obj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getComments(token: any, id) {
  const url = `${COMMENT_TASK_ID}${id}`;
  return axios.get<OrdersApiResponse>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postComments(token: any, payload) {
  const url = `${TICKET_COMMET}comments/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postSubtaskComments(token: any, payload) {
  const url = `${POST_COMMENT_URL}`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getSubtaskComments(token: any, param: any) {
  const url = `${POST_COMMENT_URL}?task_id=${param}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const postStageUpdateInCR = (
  token: string | null | undefined,
  payload: any
) => {
  const url = `${TICKET_STAGE_URL}`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStageUpdateInCR = (
  token: string | null | undefined,
  id: any
) => {
  const url = `${TICKET_STAGE_URL}`;
  return axios.get(url, {
    headers: {
      // Authorization: `Bearer ${token}`,
    },
    params: { client_request: id },
  });
};
