import React from "react";

function OrderDisclaimer(): React.JSX.Element {
  return (
    <div className="bg-light card rounded-0 border-top-0">
      <div className="card-body p-8">
        <div className="">
          <h4 className={"fw-bolder"}>Disclaimer:</h4>
        </div>
        <div>
          <p>
            The order details contain confidential information pertaining to the
            business relationship between the Company and its clients. By
            accessing the link, you agree to respect the privacy and
            confidentiality of this information. You agree not to disclose,
            disseminate, or use the information contained within the order
            details for any purpose that is not explicitly authorized by the
            Company.
          </p>
          <p>
            Unauthorized use, reproduction, or distribution of the order details
            or any information contained therein is strictly prohibited and may
            be subject to legal action.
          </p>
          <p className={"m-0"}>
            The Company reserves the right to track, restrict or revoke access
            to the Order Details Link at any time and for any reason, at its
            sole discretion.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrderDisclaimer;
