import React from "react";
import { Placeholder } from "react-bootstrap";

function ListSkeleton({
  count = 3,
  flush = false,
}: {
  count?: number;
  flush?: boolean;
}): React.JSX.Element {
  return (
    <section className={"mb-6"}>
      {Array.from({ length: count }).map((elem, index) => {
        return (
          <div className="row mb-3" key={index}>
            <div className="col">
              <div className={`card ${flush ? "border-0" : ""} `}>
                <div className={"card-body p-6"}>
                  <Placeholder as="span" animation="glow" className={"me-4"}>
                    <Placeholder.Button
                      bg={"secondary"}
                      className={"btn btn-icon"}
                    />
                  </Placeholder>

                  <Placeholder as="span" animation="glow">
                    <Placeholder.Button bg={"secondary"} xs={4} />
                  </Placeholder>

                  <Placeholder
                    as="span"
                    animation="glow"
                    className={"float-end"}
                  >
                    <Placeholder.Button
                      bg={"secondary"}
                      className={"rounded-circle btn btn-icon ms-auto"}
                    />
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default ListSkeleton;
