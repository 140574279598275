import React from "react";
import BackgroundWrapper from "@components/UI/Wrappers/BackgroundWrapper.tsx";
import { getConnectionInformation } from "../../../Create/CreateTicket/_requests.ts";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import PublicAssignAssetForm from "./PublicAssignAssetForm.tsx";
import CommonPageHeader from "@components/helpers/CommonPageHeader/CommonPageHeader.tsx";
import SupportPopover from "../../../../crm/CRMPageWrapper/SupportPopover.tsx";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import ColumnFormSkeleton from "@components/helpers/Skeletons/SkeletonsByPage/ColumnFormSkeleton.tsx";

function AssignAsset(): React.JSX.Element {
  const { slug } = useParams();

  // Queries
  const {
    data: accountData,
    isFetching,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["assigned_asset"],
    queryFn: () => {
      return getConnectionInformation(slug).then((res) => res.data);
    },
    retry: false,
  });
  // @ts-ignore
  UseErrorRedirection(error?.response);

  return (
    <BackgroundWrapper>
      <div className="container">
        <div className="row align-items-start mt-10">
          <div className="col-12 col-md-10 offset-md-1">
            <CommonPageHeader
              id={accountData?.company}
              heading={accountData?.name}
            />

            <div className="card shadow-xs">
              <div className="card-header">
                <div className="d-flex flex-center w-100">
                  <div className="">
                    <h1 className="m-0">Assign Asset</h1>
                  </div>
                </div>
              </div>

              <div className="card-body">
                {isLoading || isFetching ? (
                  <>
                    <ColumnFormSkeleton />
                  </>
                ) : (
                  <>
                    <PublicAssignAssetForm accountData={accountData} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SupportPopover domain={accountData?.domain} />
    </BackgroundWrapper>
  );
}

export default AssignAsset;
