import axios from "axios";
import { orderStageChartParamsInterface } from "./OrderStageChart/_models";
import { orderTypeChartParamsInterface } from "./OrderTypeChart/_models";

const API_URL = import.meta.env.VITE_APP_API_URL;

const GET_ERP_ORDER_METRICS_STAGE = `${API_URL}/orders/metrics/erp/stage/`;
const GET_ERP_ORDER_METRICS_TYPE = `${API_URL}/orders/metrics/erp/type/`;

export function getErpOrderMetricsStage(
  token: string | null | undefined,
  params: orderStageChartParamsInterface
) {
  return axios.get(`${GET_ERP_ORDER_METRICS_STAGE}`, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getErpOrderMetricsType(
  token: string | null | undefined,
  params: orderTypeChartParamsInterface
) {
  return axios.get(`${GET_ERP_ORDER_METRICS_TYPE}`, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
