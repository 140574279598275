import React from "react";
import ChartDateRangePicker from "./ChartDateRangePicker";
import ApexChart from "@components/UI/Charts/ApexChart.tsx";
import { Props } from "react-apexcharts";

interface ChartWithStatsCardProps extends Props {
  onDateChange: (dates: Date[]) => void;
  initialDates?: [string, string];
  heading: string;
}

export default function ChartWithStatsCard({
  onDateChange,
  initialDates,
  heading,
  ...props
}: ChartWithStatsCardProps): React.JSX.Element {
  return (
    <>
      <div className="card shadow-sm h-100">
        <div className="card-header">
          <div className="card-toolbar">
            <h1 className="fs-1 m-0 card-title">{heading}</h1>
          </div>
        </div>

        <div className="card-body d-flex flex-column px-0">
          <div className="d-flex p-8 pt-0">
            <div className="ms-auto">
              <ChartDateRangePicker
                onChange={onDateChange}
                initialDates={initialDates}
              />
            </div>
          </div>

          <div
            className={`ps-4 pe-6`}
            style={{
              height: `${
                typeof props.height === "string"
                  ? props.height
                  : `${props.height}px`
              }`,
              minHeight: `${
                typeof props.height === "string"
                  ? props.height
                  : `${props.height}px`
              }`,
            }}
          >
            <ApexChart {...props} />
          </div>
        </div>
      </div>
    </>
  );
}
