import { WheelEvent } from "react";

export function handleNumberInputWheel(e: WheelEvent<HTMLInputElement>) {
  const target = e.currentTarget;
  if (target.type === "number" && target === document.activeElement) {
    target.blur();
    // console.log("blurred");
  }
  // console.log("wheeled");
}

export function handleNumberInputKeyDown(e) {
  if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "e") {
    e.preventDefault();
  }
}

export function normalizeInput(
  input: string | null | undefined
): string | null {
  if (!input) return null; // Handle null or undefined values

  // Remove escape characters and trim the input
  const normalized = input
    .replace(/[\r\n\t\v\f]/g, "") // Remove newline, carriage return, tab, vertical tab, and form feed
    .trim(); // Trim leading and trailing spaces

  // Return null if the string is empty after normalization
  return normalized === "" ? null : normalized;
}
