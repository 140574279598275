import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "@metronic/layout/MasterLayout.tsx";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { useAuth } from "../modules/auth";
import { DashboardWrapperCRM } from "../pages/dashboard/DashboardCRM";
import { SuspendedView } from "@components/helpers/Suspense/SuspendedView.tsx";
import { STOCKY_PRO_SCOPES } from "@Constants/APP_Constants.ts";

const PrivateRoutes = () => {
  const CrmPage = lazy(() => import("../pages/crm/CrmPage"));
  const ErpPage = lazy(() => import("../pages/erp/ErpPage"));
  const StockyProPage = lazy(() => import("../pages/stockyPro/StockyProPage"));

  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route
          path="dashboard"
          element={
            <>
              {currentUser?.scope === STOCKY_PRO_SCOPES.ERP && (
                <DashboardWrapper />
              )}
              {currentUser?.scope === STOCKY_PRO_SCOPES.CRM && (
                <DashboardWrapperCRM />
              )}
            </>
          }
        />
        {/* Lazy Modules */}
        <Route
          path="/crm/*"
          element={
            <SuspendedView>
              {currentUser?.scope === STOCKY_PRO_SCOPES.CRM ? (
                <CrmPage />
              ) : (
                <Navigate to={"/dashboard"} replace={true} />
              )}
            </SuspendedView>
          }
        />
        <Route
          path="/erp/*"
          element={
            <SuspendedView>
              {currentUser?.scope === STOCKY_PRO_SCOPES.ERP ? (
                <ErpPage />
              ) : (
                <Navigate to={"/dashboard"} replace={true} />
              )}
            </SuspendedView>
          }
        />
        <Route
          path={"/*"}
          element={
            <SuspendedView>
              {currentUser?.scope === STOCKY_PRO_SCOPES.STOCKY_PRO ? (
                <StockyProPage />
              ) : (
                <Navigate to={"/dashboard"} replace={true} />
              )}
            </SuspendedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
