import "./Utils/Sentry";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
import "flatpickr/dist/flatpickr.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider, setupAxios } from "@app/modules/auth";
import { initializeGoogleAnalytics } from "@/Utils/GoogleAnalytics/googleAnalytics.ts";
import { createQueryClient } from "@/reactQuery/reactQueryClient.ts";
import { disableReactQueryDevTools } from "@/reactQuery/reactQueryDevTools.ts";

setupAxios(axios);

const queryClient = createQueryClient();

const container = document.getElementById("root");

if (container) {
  // Disable react-query dev tools in production
  disableReactQueryDevTools();

  // Initialize Google Analytics
  initializeGoogleAnalytics();

  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
export { DYNAMIC_IMPORT_ERROR_MESSAGE } from "@Constants/APP_Constants.ts";
