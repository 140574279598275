import React from "react";
import { StockyIconProps } from "@components/UI/Icons/IconTypes.ts";

function IconPrint({ classNames, fontSize = "fs-2" }: StockyIconProps) {
  return (
    <>
      <i
        className={`ki-duotone ki-printer p-0 color-inherit ${classNames} ${fontSize}`}
      >
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
      </i>
    </>
  );
}

export default IconPrint;
