import { FilterObject } from "@components/UI/HOC/ListFilter/Filter.tsx";
import { StockyApiStockyProductsListRequest } from "@/generated-api/server/stocky-api.ts";

export type ProductListFilters = Partial<
  Record<keyof StockyApiStockyProductsListRequest, FilterObject>
>;

export const publicProductFiltersInitial: ProductListFilters = {
  serial: { key: "serial", label: "Serial", isVisible: false },
  item__id: { key: "item__id", label: "Item", isVisible: false },
  current_category: {
    key: "current_category",
    label: "Category",
    isVisible: false,
  },
  brand__id: { key: "brand__id", label: "Brand", isVisible: false },
  processor: { key: "processor", label: "Processor", isVisible: false },
  generation: { key: "generation", label: "Generation", isVisible: false },
};

export const publicProductListParamsInitial: StockyApiStockyProductsListRequest =
  {
    serial: undefined,
    item__id: undefined,
    current_category: undefined,
    brand__id: undefined,
    processor: undefined,
    generation: undefined,
  };
