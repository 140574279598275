import React, { useState } from "react";
import useDocumentTitle from "@Hooks/useDocumentTitle.tsx";
import SuccessCheck from "@components/UI/AnimatedIcons/SuccessCheck/SuccessCheck.tsx";
import StockyProSignUpForm from "@app/modules/auth/components/HelperComponents/StockyProSignUpForm.tsx";
import { Link } from "react-router-dom";

type StatusType = {
  isError: boolean;
  submitted: boolean;
};

function StockyProSignUp() {
  useDocumentTitle({ title: "Stocky Pro | Sign Up" });
  // local states
  const [status, setStatus] = useState<StatusType>({
    isError: false,
    submitted: false,
  });

  return (
    <>
      <div className="d-flex flex-column flex-center w-100 mb-10">
        <h1 className="m-0 text-capitalize fw-bolder">Sign Up</h1>
      </div>

      {status.submitted && !status.isError ? (
        <>
          <div className="vstack flex-center gap-8">
            <SuccessCheck />
            <div className="vstack gap-2">
              <h2 className={"m-0 text-center"}>Sign Up Successful!</h2>
              <p className="m-0 text-gray-500 text-center fs-4">
                You will be receiving an email with further instructions.
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          {status.submitted && status.isError ? (
            <div className="px-6 px-lg-0">
              <div className="alert alert-danger rounded-0 d-flex flex-center gap-2 my-10">
                <p className="m-0 text-center fs-6">
                  Something went wrong with Signing Up. Please try again!
                </p>
              </div>
            </div>
          ) : null}

          <div className="mb-10 px-lg-10">
            <StockyProSignUpForm setStatus={setStatus} />
          </div>

          <div className="text-gray-500 text-center fw-semibold fs-6">
            Already have an account?{" "}
            <Link to="/auth/login" className="link-primary fw-semibold">
              Login
            </Link>
          </div>
        </>
      )}
    </>
  );
}

export default StockyProSignUp;
