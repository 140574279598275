import React from "react";
import { useAuth } from "@app/modules/auth";

type MessageBoardProps = {
  genericMessage: string;
};

function MessageBoard({
  genericMessage,
}: MessageBoardProps): React.JSX.Element {
  const { currentUser } = useAuth();

  return (
    <div className={"card h-100 border-0 shadow-xs overflow-hidden"}>
      <div
        className="card-body p-10 bgi-no-repeat"
        style={{
          backgroundImage: "url('/media/meta/crm-hero.png')",
          backgroundSize: "80%",
          backgroundPosition: "175% 0%",
        }}
      >
        <div className="vstack gap-4">
          <div className="card-title">
            <h1 className="m-0">Welcome! {currentUser?.first_name}</h1>
          </div>

          <h1 className="fs-1 card-title font-semibold text-dark m-0">
            Your One-Stop Solution
            <br className={"d-3xl-none"} /> for Managing Assets.
          </h1>

          <p className="fs-5 fw-medium text-gray-700 m-0">
            As you log in, you are stepping into <br className={"d-3xl-none"} />{" "}
            a seamless digital environment designed to <br /> streamline every
            aspect of asset management.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MessageBoard;
