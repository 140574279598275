import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

function RequestSuccess({ clientResponse, handleNewRequestClick }) {
  function handleClick() {
    handleNewRequestClick();
  }

  return (
    <div className="card-body ">
      <div className=" d-flex justify-content-center align-items-center">
        <div>
          <div className="mb-4 text-center">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              className="text-success"
              width="100"
              height="100"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </div>
          <div className="text-center  ">
            <h1>Success</h1>
            <h3>Ticket ID: SPG- {clientResponse?.task_id}</h3>
            {/*<p>Track your request here</p>*/}
            <h3>
              <Link
                to={`/public/tickets/track/${clientResponse?.task_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Track your request here
              </Link>
            </h3>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col">
          <div className="card">
            <div className="card-body bg-light ">
              <Table bordered responsive className={"bg-light-subtle"}>
                <thead>
                  <tr className="align-middle text-center">
                    <th className="text-uppercase">title</th>
                    <th className="text-uppercase">serial</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={"align-middle text-center"}>
                    <td>{clientResponse?.title}</td>
                    <td>{clientResponse?.serial_number}</td>
                  </tr>
                </tbody>
              </Table>

              <div className="row">
                <div className="col">
                  <span className="fw-bolder">Description: </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: clientResponse?.description ?? "",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col text-center">
          <button className="btn btn-light-brand" onClick={handleClick}>
            New Request
          </button>
        </div>
      </div>
    </div>
  );
}

export default RequestSuccess;
