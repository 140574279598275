import React, { useState } from "react";
import ProfileImage from "@components/helpers/ProfleImage/ProfileImage.tsx";
import { ConsigneeViewModal } from "@app/pages/erp/Consignee/ConsigneeHelpers/ConsigneeViewModal.tsx";
import { UserReferences } from "@/generated-api";
const ClientUserCardSm = ({ user }) => {
  const [open, setOpen] = useState(false);

  // derived
  const referencePayload: UserReferences = {
    user_id: user?.id,
    account: user?.account,
  };

  return (
    <>
      <div className="row h-100">
        <div className="col">
          <div
            className="d-flex align-items-center gap-3 h-100"
            key={`${user?.auto_id} ${user?.name} `}
          >
            <div className="flex-shrink-0">
              {user?.avatar ? (
                <ProfileImage
                  size={30}
                  name={user?.name?.toUpperCase()}
                  src={user?.avatar}
                />
              ) : (
                <button
                  className="btn btn-sm btn-icon btn-secondary  btn-light-dark fs-1 "
                  onClick={() => setOpen(true)}
                  type={"button"}
                >
                  <span className={"fs-2x"}>
                    {user?.name?.slice(0, 1).toUpperCase()}
                  </span>
                </button>
              )}
            </div>
            <div className="flex-grow-1">
              <div className="d-flex flex-column gap-1">
                <div>
                  <span className="text-dark me-2 text-capitalize">
                    {`${user?.name ?? ""}`}{" "}
                  </span>
                  <span className="text-muted small">{`${
                    user?.auto_id ? "#" : ""
                  } ${user?.auto_id ?? ""}`}</span>
                </div>

                <div className="">
                  {user?.phone && (
                    <span className="text-mute small me-2">
                      <i className="bi bi-phone-fill"></i>&nbsp;
                      {`${user?.phone ?? ""} `}
                    </span>
                  )}

                  {user?.email && (
                    <span className="text-mute small">
                      <i className="bi bi-envelope-at-fill"></i>
                      &nbsp;
                      {user?.email ?? ""}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConsigneeViewModal
        user={user}
        open={open}
        setOpen={setOpen}
        referencePayload={referencePayload}
      />
    </>
  );
};

export default ClientUserCardSm;
