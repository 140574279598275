import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import IconPrint from "@components/UI/Icons/IconPrint.tsx";
import {
  generateDataURIFromImage,
  getCompanyImageSrc,
} from "@/Utils/images/imageHelpers.ts";
import { useQuery } from "react-query";
import pdfmake, { pdfMakeFonts } from "@/Utils/pdfmake";
import { toast } from "react-toastify";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import cloneDeep from "lodash/cloneDeep";

interface PdfGenerateButtonProps<T> {
  imageId?: number;
  generateFunction: ({
    data,
    imageData,
  }: {
    data: T;
    imageData: string;
  }) => Promise<TDocumentDefinitions>;
  data: T;
  isActionButton?: boolean;
  text?: string;
}

function PdfGenerateButton<T>({
  generateFunction,
  data,
  imageId,
  isActionButton = false,
  text = "Print",
}: PdfGenerateButtonProps<T>) {
  const [loading, setLoading] = useState(false);

  const imageSrc = getCompanyImageSrc(imageId);

  const {
    data: imageData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["get_image_data", imageSrc],
    queryFn: () => {
      return generateDataURIFromImage(imageSrc!).then((imageData) => imageData);
    },
    enabled: !!imageSrc,
  });

  async function handlePrintClick() {
    if (!isLoading && !isError && imageData) {
      setLoading(true);

      try {
        const dd = await generateFunction({ data, imageData });
        const ddCopy = cloneDeep(dd);
        pdfmake.createPdf(ddCopy, undefined, pdfMakeFonts).open();
      } catch (error) {
        toast.error("Unable to generate PDF");
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      {isActionButton ? (
        <>
          <div className="menu-item px-3">
            <div className="menu-link px-3" onClick={handlePrintClick}>
              <div className="d-flex flex-center gap-2">
                <IconPrint />
                {text}
              </div>
            </div>
          </div>
        </>
      ) : (
        <button
          type={"button"}
          className={"btn btn-light-brand-primary btn-flex gap-2"}
          onClick={handlePrintClick}
        >
          {loading ? (
            <>
              <Spinner size="sm" /> Please wait...
            </>
          ) : (
            <>
              <IconPrint />
              {text}
            </>
          )}
        </button>
      )}
    </>
  );
}

export default PdfGenerateButton;
