import React from "react";
import { GroupBase } from "react-select";

import CreatableSelect, { CreatableProps } from "react-select/creatable";
import {
  CityOptions,
  CityType,
} from "@components/helpers/CustomSelectors/CitySelector.tsx";

function CreatableCitySelector<
  IsMulti extends boolean = false,
  Group extends GroupBase<CityType> = GroupBase<CityType>
>({
  options = CityOptions,
  ...rest
}: CreatableProps<CityType, IsMulti, Group>): React.JSX.Element {
  return (
    <>
      <CreatableSelect
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        isSearchable
        isClearable
        openMenuOnFocus
        options={options}
        getOptionLabel={(option) => option?.name}
        getOptionValue={(option) => option?.id?.toString()}
        getNewOptionData={(inputValue) => {
          return {
            name: inputValue,
            id: inputValue,
            state: "",
            __isNew__: true,
          } as any;
        }}
        {...rest}
      />
    </>
  );
}

export default CreatableCitySelector;
