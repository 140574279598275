import React from "react";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

type OrdersMetricsDetailsProps = {
  data: any;
};

function OrdersMetricsDetails({
  data,
}: OrdersMetricsDetailsProps): React.JSX.Element {
  return (
    <div className="card shadow-xs">
      <div className="card-header">
        <h5 className="card-title fw-bolder">Orders Today </h5>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          <span className="fw-bold">Orders Total Active Stage</span>
        </li>
        {data?.order?.totals?.length > 0 &&
          data?.order?.totals?.map((e, index) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center px-9"
                key={index}
              >
                <span className="">{DecorateNameTags(e?.stage ?? "")}</span>
                <span className="fw-bold">{e?.count}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default OrdersMetricsDetails;
