import React from "react";
import { StockyIconProps } from "@components/UI/Icons/IconTypes.ts";

function IconLink({ classNames, fontSize = "fs-3" }: StockyIconProps) {
  return (
    <>
      <i
        className={`ki-duotone ki-fasten p-0 color-inherit ${classNames} ${fontSize}`}
      >
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    </>
  );
}

export default IconLink;
