import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import { playSound } from "@components/helpers/Sounds/PlaySound.ts";
import { getAccountData, postAssetVerification } from "../_requestes";
import CompanyImage from "@components/helpers/CompalyImage/CompanyImage";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorMessageBox } from "../../../erp/Create/Operations/components/UtilityOPSINCOM";
import SupportPopover from "../../CRMPageWrapper/SupportPopover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import AssetVerified from "./AssetVerified";
import { workPlaceOptions } from "./_constants";
import { assetVerificationSchema } from "./_models";
import AssetVerificationSuccess from "./AssetVerificationSuccess";
import { getPublicSerialInfo } from "@Hooks/_requestees.ts";
import { getConsigneeBySlugAndSerial } from "../../../erp/Create/CreateTicket/_requests";
import ClientInfoSelection from "../../../erp/Create/CreateTicket/Components/ClientInfoSelection";
import BackgroundWrapper from "../../../../../components/UI/Wrappers/BackgroundWrapper";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import SearchInput from "@components/UI/Inputs/SearchInput.tsx";
import useDocumentTitle from "@Hooks/useDocumentTitle.tsx";
import IconSend from "@components/UI/Icons/IconSend.tsx";
import FormSkeleton from "@components/helpers/Skeletons/SkeletonsByPage/FormSkeleton.tsx";

const AssetVerification = () => {
  useDocumentTitle({ title: "Asset Verification" });

  const [successScreen, setSuccessScreen] = useState<any>(null);
  const [ApiError, setApiError] = useState(null);
  const [serialData, setSerialData] = useState<any>({
    assignedData: null,
    productData: null,
    isError: false,
  });

  const param = useParams<any>();
  const initialValues: any = {
    first_name: "",
    mobile: null, // Assuming 0 is a placeholder value. Adjust as necessary.
    email: "",
    employee_id: null,
    work_location: null, // Set a default or keep empty if you want users to choose
    pincode: null, // Same assumption as `mobile`
    latitude: null,
    longitude: null,
    address: null,
    serial: null,
    product: null,
    slug: param?.slug,
    captcha: null,
  };

  const {
    data: AccountData,
    isError,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ["AssetVerificationAccount", param],
    () => {
      return getAccountData(param?.slug);
    },
    {
      retry: false, // default: true
    }
  );
  // @ts-ignore
  UseErrorRedirection(error?.response);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setFieldValue("latitude", position.coords.latitude);
          setFieldValue("longitude", position.coords.longitude);
        });
      } else {
        toast.error("Geolocation is not supported by this browser.");
      }
    };

    if (AccountData?.data?.track_location) {
      getLocation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, AccountData?.data?.track_location]);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: assetVerificationSchema,
    onSubmit: (values, action) => {
      postAssetVerification(values)
        .then((res) => {
          toast.success("Asset Verification submitted", { autoClose: 1500 });
          playSound("BaseNotify");
          action.resetForm();
          setSuccessScreen(res.data);
          setApiError(null);
        })
        .catch((err) => {
          toast.error("Error submitting verification", { autoClose: 1500 });
          playSound("SoftAlert");
          setApiError(err.response.data);
        })
        .finally(() => {
          action.setSubmitting(false);
        });
    },
  });

  const {
    refetch: getAssignedData,
    isLoading: isAssignedDataLoading,
    isFetching: isAssignedDataFetching,
  } = useQuery({
    queryKey: ["asset_verification_assigned", values?.serial],
    queryFn: () => {
      return getConsigneeBySlugAndSerial(
        AccountData?.data?.connect?.slug,
        values?.serial
      );
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setSerialData((prev) => ({
        ...prev,
        assignedData: data,
        isError: false,
      }));
    },
    onError: () => {
      setSerialData((prev) => ({
        ...prev,
        assignedData: null,
        isError: true,
      }));
    },
  });

  const {
    refetch: getProductData,
    isLoading: isProductDataLoading,
    isFetching: isProductDataFetching,
  } = useQuery({
    queryKey: ["asset_verification_product", values?.serial],
    queryFn: () => {
      return getPublicSerialInfo(values?.serial);
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setSerialData((prev) => ({
        ...prev,
        productData: data.data,
        isError: false,
      }));
      setFieldTouched("product", true);
      setFieldValue("product", data?.data?.computer?.item_name);
    },
    onError: () => {
      setSerialData((prev) => ({
        ...prev,
        productData: null,
        isError: true,
      }));
      setFieldTouched("product", false);
      setFieldValue("product", null);
    },
  });

  // Methods
  function updateClientInfo({
    name,
    number,
    email,
    consignee,
  }: {
    name: string | null;
    number: string | null;
    email: string | null;
    consignee: number | null;
  }) {
    setFieldValue("first_name", name).then(() => {
      if (name) {
        setFieldTouched("first_name", true);
      }
    });
    setFieldValue("mobile", number).then(() => {
      if (number) {
        setFieldTouched("mobile", true);
      }
    });
    setFieldValue("email", email).then(() => {
      if (email) {
        setFieldTouched("email", true);
      }
    });
    setFieldValue("consignee", consignee).then(() => {
      if (consignee) {
        setFieldTouched("consignee", true);
      }
    });
  }

  function handleSerialBlur() {
    setFieldTouched("serial", true);
    if (!(values?.serial && AccountData?.data?.connect?.slug)) {
      setSerialData((prev) => {
        return {
          ...prev,
          assignedData: null,
          productData: null,
        };
      });
      setFieldValue("product", null);
      setFieldValue("first_name", null);
      setFieldValue("mobile", null);
      setFieldValue("email", null);

      setFieldTouched("product", false);
      setFieldTouched("first_name", false);
      setFieldTouched("mobile", false);
      setFieldTouched("email", false);
    }
  }

  // Derived
  const isSerialDataLoading =
    isAssignedDataLoading ||
    isAssignedDataFetching ||
    isProductDataLoading ||
    isProductDataFetching;

  return (
    <BackgroundWrapper>
      <div className={"container"}>
        <div className="row align-items-center min-vh-100">
          <div className={"col-12 col-md-10 offset-md-1"}>
            <div className={"card shadow-sm my-6"}>
              <div className={"card-header"}>
                <div className="d-flex flex-center gap-4 w-100">
                  <CompanyImage
                    id={AccountData?.data?.connect?.company}
                    width={40}
                    alt={AccountData?.data?.connect?.company_name}
                  />
                  <h1 className="d-inline-block my-0">Asset Verification</h1>
                </div>
              </div>
              {isError ? (
                <>
                  <AssetVerified />
                </>
              ) : (
                <div className="row mb-3">
                  <div className="col">
                    {!successScreen && (
                      <>
                        <div className="card-body">
                          <div className="row mb-6">
                            <div className="col">
                              <h5 className="card-title text-capitalize">
                                {AccountData?.data?.connect?.name}
                              </h5>
                            </div>
                          </div>

                          {isLoading || isFetching ? (
                            <>
                              <FormSkeleton />
                            </>
                          ) : (
                            <form onSubmit={handleSubmit}>
                              <div
                                className={"card mb-6"}
                                style={{ backgroundColor: "#f7f7f7" }}
                              >
                                <div className={"card-body p-8"}>
                                  <div className="row row-gap-6">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor={"asset_verification_serial"}
                                        className="form-label fw-bold"
                                      >
                                        Serial{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <SearchInput
                                        id={"asset_verification_serial"}
                                        className="form-control form-control-sm"
                                        name="serial"
                                        onChange={handleChange}
                                        onBlur={() => {
                                          handleSerialBlur();
                                        }}
                                        value={values?.serial ?? ""}
                                        autoFocus
                                        handleSearch={() => {
                                          if (
                                            values?.serial &&
                                            AccountData?.data?.connect?.slug
                                          ) {
                                            getAssignedData();
                                            getProductData();
                                          }
                                        }}
                                        loading={isSerialDataLoading}
                                      />

                                      <div className="d-flex align-items-center gap-1 mt-2">
                                        <p className="m-0">
                                          How to find Serial ?
                                        </p>
                                        <div
                                          className={
                                            "d-flex align-items-center"
                                          }
                                        >
                                          <OverlayTrigger
                                            placement={"bottom"}
                                            overlay={
                                              <Tooltip>Visit Website</Tooltip>
                                            }
                                            delay={{ show: 500, hide: 0 }}
                                          >
                                            <a
                                              href={
                                                "https://spurge.rentals/how-to-find-your-computers-bios-serial-number-a-guide-for-windows-macos-and-linux-users/"
                                              }
                                              className={
                                                "btn btn-icon btn-sm btn-outline border-0 btn-color-primary"
                                              }
                                              target={"_blank"}
                                              rel={"noreferrer noopener"}
                                            >
                                              <i className="bi bi-globe2"></i>
                                            </a>
                                          </OverlayTrigger>
                                          <OverlayTrigger
                                            placement={"bottom"}
                                            overlay={
                                              <Tooltip>Watch Tutorial</Tooltip>
                                            }
                                            delay={{ show: 500, hide: 0 }}
                                          >
                                            <a
                                              href={
                                                "https://www.youtube.com/watch?v=lhHBPcrSg3I"
                                              }
                                              className={
                                                "btn btn-icon btn-sm btn-outline border-0 btn-color-danger"
                                              }
                                              target={"_blank"}
                                              rel={"noreferrer noopener"}
                                            >
                                              <i className="bi bi-youtube"></i>
                                            </a>
                                          </OverlayTrigger>
                                        </div>
                                      </div>

                                      {errors?.serial &&
                                        touched?.serial &&
                                        typeof errors.serial === "string" && (
                                          <div className="text-danger">
                                            {errors?.serial}
                                          </div>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                      <label
                                        htmlFor={"asset_verification_product"}
                                        className="form-label fw-bold"
                                      >
                                        Product{" "}
                                      </label>
                                      <input
                                        id={"asset_verification_product"}
                                        className="form-control form-control-sm"
                                        name="product"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.product ?? ""}
                                      />
                                      {errors?.product &&
                                        touched?.product &&
                                        typeof errors.product === "string" && (
                                          <div className="text-danger">
                                            {errors?.product}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {serialData.assignedData &&
                              !serialData.isError ? (
                                <div className={""}>
                                  <ClientInfoSelection
                                    serialData={serialData}
                                    updateClientInfo={updateClientInfo}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldTouched={setFieldTouched}
                                    fieldNames={{
                                      name: "first_name",
                                      number: "mobile",
                                      email: "email",
                                    }}
                                  />
                                </div>
                              ) : null}

                              <div className={"card mb-6"}>
                                <div className={"card-body"}>
                                  {!(
                                    serialData.assignedData &&
                                    !serialData.isError
                                  ) ? (
                                    <>
                                      <div className="row mb-6 row-gap-6">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor={
                                              "asset_verification_firstName"
                                            }
                                            className="form-label fw-bold"
                                          >
                                            Name{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            id={"asset_verification_firstName"}
                                            className="form-control form-control-sm"
                                            name="first_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.first_name ?? ""}
                                          />
                                          {errors.first_name &&
                                            touched.first_name &&
                                            typeof errors.first_name ===
                                              "string" && (
                                              <div className="text-danger">
                                                {errors?.first_name}
                                              </div>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                          <label
                                            htmlFor={
                                              "asset_verification_mobile"
                                            }
                                            className="form-label fw-bold"
                                          >
                                            Mobile{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            id={"asset_verification_mobile"}
                                            className="form-control form-control-sm"
                                            name="mobile"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={"number"}
                                            value={values?.mobile ?? ""}
                                          />
                                          {errors.mobile &&
                                            touched.mobile &&
                                            typeof errors.mobile ===
                                              "string" && (
                                              <div className="text-danger">
                                                {errors?.mobile}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="row mb-6 row-gap-6">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor={"asset_verification_email"}
                                            className="form-label fw-bold"
                                          >
                                            Email{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            id={"asset_verification_email"}
                                            className="form-control form-control-sm"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="email"
                                            value={values?.email ?? ""}
                                          />
                                          {errors.email &&
                                            touched.email &&
                                            typeof errors.email ===
                                              "string" && (
                                              <div className="text-danger">
                                                {errors?.email}
                                              </div>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                          <label
                                            htmlFor={
                                              "asset_verification_employeeId"
                                            }
                                            className="form-label fw-bold"
                                          >
                                            Employee ID{" "}
                                            <span className="text-muted">
                                              (optional)
                                            </span>
                                          </label>
                                          <input
                                            id={"asset_verification_employeeId"}
                                            className="form-control form-control-sm"
                                            name="employee_id"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.employee_id ?? ""}
                                          />
                                          {errors.employee_id &&
                                            touched.employee_id &&
                                            typeof errors.employee_id ===
                                              "string" && (
                                              <div className="text-danger">
                                                {errors?.employee_id}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}

                                  <div className="row mb-6 row-gap-6">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor={
                                          "asset_verification_workLocation"
                                        }
                                        className="form-label fw-bold"
                                      >
                                        Work Location{" "}
                                        <span className="text-danger">*</span>
                                      </label>

                                      <Select
                                        inputId={
                                          "asset_verification_workLocation"
                                        }
                                        options={workPlaceOptions}
                                        className="react-select-styled react-select-sm"
                                        classNamePrefix="react-select"
                                        placeholder={"Select Work Location"}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "work_location",
                                            e?.value
                                          )
                                        }
                                        value={
                                          workPlaceOptions?.filter(
                                            (e) =>
                                              e?.value === values?.work_location
                                          ) ?? ""
                                        }
                                        onBlur={() =>
                                          setFieldTouched("work_location", true)
                                        }
                                        openMenuOnFocus={true}
                                      />

                                      {errors.work_location &&
                                        touched.work_location &&
                                        typeof errors.work_location ===
                                          "string" && (
                                          <div className="text-danger">
                                            {errors?.work_location}
                                          </div>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                      <label
                                        htmlFor={"asset_verification_pin"}
                                        className="form-label fw-bold"
                                      >
                                        PIN Code{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        id={"asset_verification_pin"}
                                        className="form-control form-control-sm"
                                        name="pincode"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.pincode ?? ""}
                                        type={"number"}
                                        min={0}
                                      />
                                      {errors.pincode &&
                                        touched.pincode &&
                                        typeof errors.pincode === "string" && (
                                          <div className="text-danger">
                                            {errors?.pincode}
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col">
                                      <label
                                        htmlFor={"asset_verification_address"}
                                        className="form-label fw-bold"
                                      >
                                        Address{" "}
                                        <span className="text-muted">
                                          (optional)
                                        </span>
                                      </label>
                                      <textarea
                                        id={"asset_verification_address"}
                                        name="address"
                                        className="form-control form-control-sm"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.address ?? ""}
                                      />
                                      {errors.address &&
                                        touched.address &&
                                        typeof errors.address === "string" && (
                                          <div className="text-danger">
                                            {errors?.address}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*<hr className={"my-6"}/>*/}
                              {ApiError && (
                                <div className="mb-6">
                                  <ErrorMessageBox Data={ApiError} />
                                </div>
                              )}

                              <div className="row row-gap-6 position-relative">
                                <div
                                  className={
                                    "col-md-5 position-relative z-index-2"
                                  }
                                >
                                  <ReCAPTCHA
                                    sitekey={
                                      import.meta.env
                                        .VITE_APP_RECAPTCHA_SITE_KEY
                                        ? import.meta.env
                                            .VITE_APP_RECAPTCHA_SITE_KEY
                                        : "site key"
                                    }
                                    onChange={(value) => {
                                      setFieldValue("captcha", value);
                                    }}
                                  />

                                  {errors.captcha &&
                                    touched.captcha &&
                                    typeof errors.captcha === "string" && (
                                      <div className="text-danger">
                                        {errors?.captcha}
                                      </div>
                                    )}
                                </div>

                                <div
                                  className="col-12 text-end align-self-stretch
                                                    position-xl-absolute top-0 start-0 end-0 bottom-0 d-flex flex-center"
                                >
                                  <SubmitButton
                                    type="submit"
                                    disabled={isSubmitting || !values?.captcha}
                                    loading={isSubmitting}
                                    icon={<IconSend />}
                                  />
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </>
                    )}

                    {successScreen && (
                      <>
                        <AssetVerificationSuccess
                          successScreen={successScreen}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <SupportPopover domain={AccountData?.data?.connect?.domain} />
      </div>
    </BackgroundWrapper>
  );
};
export default AssetVerification;
