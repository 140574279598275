export const PROCESSORS = [
  { value: 0, label: "Core 2 Duo" },
  { value: 1, label: "Intel Core i3" },
  { value: 2, label: "Intel Core i5" },
  { value: 3, label: "Intel Core i7" },
  { value: 4, label: "Intel Core i9" },
  { value: 25, label: "AMP Processor" },
];

export const GENERATIONS = [
  { value: 1, label: "1st Generation" },
  { value: 2, label: "2nd Generation" },
  { value: 3, label: "3rd Generation" },
  { value: 4, label: "4th Generation" },
  { value: 5, label: "5th Generation" },
  { value: 6, label: "6th Generation" },
  { value: 7, label: "7th Generation" },
  { value: 8, label: "8th Generation" },
  { value: 9, label: "9th Generation" },
  { value: 10, label: "10th Generation" },
];

export const RateCategory = {
  RESELLER: 1,
  STAFF: 2,
  CUSTOMER: 3,
  CORPORATE: 4,
};
export const StockFilterList = {
  SERVICE_STOCK: "SERVICE_STOCK",
  FLOW_CAPTAIN_STOCK: "FLOW_CAPTAIN_STOCK",
  OPERATIONS_STOCK: "OPERATIONS_STOCK",
};
export const RAM = {
  GB_2: 2,
  GB_4: 4,
  GB_6: 6,
  GB_8: 8,
  GB_12: 12,
  GB_16: 16,
  GB_32: 32,
};
