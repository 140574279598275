import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import { useNavigate } from "react-router-dom";

const initialValues = {
  email: "",
  captcha: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  captcha: Yup.string().required("Captcha is required"),
});

export function ForgotPassword() {
  const navigate = useNavigate();

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [captChaKey, setCaptChaKey] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setHasErrors(undefined);
      setTimeout(() => {
        requestPassword(values.email, captChaKey)
          .then(({ data: { result } }) => {
            setHasErrors(false);
            formik.resetForm();
          })
          .catch(() => {
            setHasErrors(true);
            setStatus("The login detail is incorrect");
          })
          .finally(() => {
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  // Methods
  function handleCancelClick() {
    navigate("/auth/login");
  }

  return (
    <div className={"flex-column-fluid d-flex align-items-center px-lg-10"}>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-11">
          {/* begin::Title */}
          <div className="position-relative mb-3">
            <h1 className="text-dark fw-bolder m-0">Forgot Password ?</h1>
          </div>

          {/* end::Title */}
          {/* begin::Link */}
          <div className="text-gray-500 fw-semibold fs-6">
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold text-center">
              Sorry, looks like there are some errors detected, please try
              again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="card mb-10 rounded-3 shadow-xs">
            <div className={"card-body"}>
              <div className="text-success text-center">
                Password reset link has been sent. Please check your email.
              </div>
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          {/*<label*/}
          {/*  htmlFor={"forgot_password_email"}*/}
          {/*  className="form-label fw-bolder text-gray-900 fs-6"*/}
          {/*>*/}
          {/*  Email*/}
          {/*</label>*/}
          <input
            type="email"
            id={"forgot_password_email"}
            placeholder="Enter Email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className={"mb-8"}>
          <ReCAPTCHA
            sitekey={
              import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                ? import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                : "site key"
            }
            onChange={(value) => {
              setCaptChaKey(value);
              formik.setFieldValue("captcha", value);
            }}
          />
          {formik.errors.captcha && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.captcha}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center gap-4 pb-lg-0">
          <SubmitButton
            type="submit"
            variant={"brand-primary"}
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !(captChaKey?.length > 0)
            }
            loading={formik.isSubmitting}
          />
          <button
            type={"button"}
            className={"btn btn-light-brand-secondary"}
            disabled={formik.isSubmitting}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  );
}
