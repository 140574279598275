import React, { useEffect, useState } from "react";
// @ts-ignore
import SPURGELOGO from "@metronic/assets/CompanyName/SpurgeLogo.png";
// @ts-ignore
import RENTECHOGO from "@metronic/assets/CompanyName/renttechlogo.png";
// @ts-ignore
import SPURGEITLOGO from "@metronic/assets/CompanyName/SPUREIT.png";

function CompanyImage({ id, width, alt }) {
  const [imageSrc, setImageSrc] = useState(SPURGELOGO);

  useEffect(() => {
    if (id === 1) {
      setImageSrc(SPURGEITLOGO);
    } else if (id === 2) {
      setImageSrc(SPURGELOGO);
    } else if (id === 3) {
      setImageSrc(RENTECHOGO);
    }
  }, [id]);

  return (
    <>
      <img src={imageSrc} alt={alt} width={width} />
    </>
  );
}

export default CompanyImage;
