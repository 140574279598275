import axios from "axios";

const API_URL = import.meta.env.VITE_APP_API_URL;

const CRM_DASHBOARD_METRICS = `${API_URL}/core/crm/dashboard/metrics`;

export function getCrmDashboardMetrics(token: string | undefined) {
  return axios.get(`${CRM_DASHBOARD_METRICS}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
