export const ORDER_TYPE = {
  Sales: 1,
  Rental: 2,
  Service: 3,
  Logistics: 4,
};

export const NotifyType = {
  EMAIL: 1,
  SMS: 2,
  WHATSAPP: 3,
};

export const ORDER_CATEGORY_COLORS = {
  "NEW ORDER": "primary",
  REPLACEMENT: "warning",
  UPGRADATION: "success",
  RESEARCH: "info",
  RESEARCH_REPLACEMENT: "dark",
  default: "secondary",
};

export const RentalLockIn = {
  "No Lockin": 0,
  "Less Then 1 Month": 0,
  "ONE MONTH": 1,
  "THREE MONTH": 3,
  "SIX MONTH": 6,
  "1 Year": 12,
  "2 Year": 24,
  "3 Year": 36,
};

export const ORDER_STAGE = {
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  APPROVED: "APPROVED",
  HOLD: "HOLD",
  CANCELED: "CANCELED",
  OPS_PARTIAL_PROCESSED: "OPS_PARTIAL_PROCESSED",
  LOGISTICS_PARTIAL_DISPATCHED: "LOGISTICS_PARTIAL_DISPATCHED",
  OPERATIONS_PROCESSED: "OPERATIONS_PROCESSED",
  LOGISTICS_DISPATCHED: "LOGISTICS_DISPATCHED",
  COMPLETE: "COMPLETE",
};

export const OrderStageColor = {
  CREATED: "primary",
  APPROVED: "success",
  HOLD: "warning",
  WAITING_FOR_PAYMENT: "warning",
  CANCELED: "danger",
  OPERATIONS_PROCESSED: "primary",
  OPS_PARTIAL_PROCESSED: "dark",
  LOGISTICS_DISPATCHED: "info ",
  LOGISTICS_PARTIAL_DISPATCHED: "dark ",
  COMPLETE: "success",
};

export const orderStageIcons = {
  CREATED: "fa fa-brands fa-first-order",
  APPROVED: "bi bi-check-lg",
  HOLD: "fa fa-hand-paper",
  CANCELED: "bi bi-x-circle-fill",
  OPERATIONS_PROCESSED: "fa fa-inbox",
  OPS_PARTIAL_PROCESSED: "fa fa-hourglass-start",
  LOGISTICS_DISPATCHED: "fa fa-truck",
  LOGISTICS_PARTIAL_DISPATCHED: "fa fa-bicycle",
  COMPLETE: "fa fa-handshake",
  SERVICE_PROCESSED: "bi bi-wrench",
};

export const getOrderStageGradient = (operation) => {
  switch (operation) {
    case "CREATED":
      return "bg-primary bg-red";
    case "APPROVED":
      return "bg-success";
    case "HOLD":
      return "bg-warning";
    case "WAITING_FOR_PAYMENT":
      return "bg-danger";
    case "CANCELED":
      return "bg-secondary";
    case "OPERATIONS_PROCESSED":
      return "bg-info";
    case "OPS_PARTIAL_PROCESSED":
      return "bg-dark";
    case "LOGISTICS_PARTIAL_DISPATCHED":
      return "bg-light";
    case "LOGISTICS_DISPATCHED":
      return "bg-pink";
    case "COMPLETE":
      return "bg-info";
    default:
      return "secondary text-dark";
  }
};

export const itemTypes = {
  Laptop: "laptop",
  Phone: "phone",
  tab: "tab",
  accessory: "accessory",
  other: "other",
};

export const ServiceTypes = {
  part: "part",
  service: "service",
  other: "other",
};
export const Category = {
  "Windows Laptop": "Windows Laptop",
  "Macbook Air": "Macbook Air",
  "Macbook Pro": "Macbook Pro",
  Mobile: "Mobile",
  "Android Tab": "Android Tab",
  iPad: "iPad",
  "Gaming PC": "Gaming PC",
};

export const PaymentTerms = {
  "BEFORE DELIVERY": "BEFORE_DELIVERY",
  "ON DELIVERY": "ON_DELIVERY",
  "NET 7 DAYS": "NET_7_DAYS",
  "NET 15 DAYS": "NET_15_DAYS",
  "NET 30 DAYS": "NET_30_DAYS",
  EMI: "EMI",
};

export const ALL_STAGES_OF_ORDER = {
  CREATED: "CREATED",
  APPROVED: "APPROVED",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  HOLD: "HOLD",
  CANCELED: "CANCELED",
  OPS_PARTIAL_PROCESSED: "OPS_PARTIAL_PROCESSED",
  OPERATIONS_PROCESSED: "OPERATIONS_PROCESSED",
  LOGISTICS_PARTIAL_DISPATCHED: "LOGISTICS_PARTIAL_DISPATCHED",
  LOGISTICS_DISPATCHED: "LOGISTICS_DISPATCHED",
  COMPLETE: "COMPLETE",
};

export const getOrderStageBadgeClass = (operation) => {
  if (operation === "CREATED") return "primary";
  if (operation === "APPROVED") return "success ";
  if (operation === "HOLD") return "warning";
  if (operation === "WAITING_FOR_PAYMENT") return "warning";
  if (operation === "CANCELED") return "danger";
  if (operation === "OPERATIONS_PROCESSED") return "primary";
  if (operation === "OPS_PARTIAL_PROCESSED") return "dark";
  if (operation === "LOGISTICS_DISPATCHED") return "info ";
  if (operation === "LOGISTICS_PARTIAL_DISPATCHED") return "dark ";
  if (operation === "COMPLETE") return "success";
  else return "dark";
};

export const ORDER_STAGES_COLORS = {
  CREATED: "primary",
  APPROVED: "success",
  WAITING_FOR_PAYMENT: "warning",
  HOLD: "warning",
  CANCELED: "danger",
  OPS_PARTIAL_PROCESSED: "dark",
  OPERATIONS_PROCESSED: "primary",
  LOGISTICS_PARTIAL_DISPATCHED: "dark",
  LOGISTICS_DISPATCHED: "info",
  COMPLETE: "success",
  default: "dark",
};

export const getOrderStageIcon = (operation) => {
  if (operation === "CREATED") return " fa fa-brands fa-first-order";
  if (operation === "APPROVED") return "fa  fa-check";
  if (operation === "HOLD") return "fa  fa-hand-paper";
  if (operation === "WAITING_FOR_PAYMENT") return "bi bi-hourglass-split";
  if (operation === "CANCELED") return "fa  fa-times-circle";
  if (operation === "OPERATIONS_PROCESSED") return "fa  fa-inbox";
  if (operation === "OPS_PARTIAL_PROCESSED") return "fa  fa-hourglass-start";
  if (operation === "LOGISTICS_DISPATCHED") return "fa  fa-truck";
  if (operation === "LOGISTICS_PARTIAL_DISPATCHED") return "fa fa-bicycle";
  if (operation === "COMPLETE") return "fa fa-handshake";
  else return "bi bi-building-down";
};
export const OrderCategory = {
  NEW_ORDER: "NEW ORDER",
  REPLACEMENT: "REPLACEMENT",
  UPGRADATION: "UPGRADATION",
  RESEARCH: "RESEARCH",
  RESEARCH_REPLACEMENT: "RESEARCH_REPLACEMENT",
};

export const PoCategory = {
  RESEARCH: 0,
  PURCHASE: 1,
  SERVICE: 2,
  RENT: 3,
};
