import { createRedirectObject } from "@Constants/RoutingConstants/constants.ts";
import { crmModules } from "@Constants/RoutingConstants/crm/CrmModules.ts";

export const CRM_AR_PATHS = createRedirectObject({
  CREATE: `/crm/${crmModules.ar}/create`,
  LIST: `/crm/${crmModules.ar}/list`,
  VIEW: `/crm/${crmModules.ar}/`,
});

export const CRM_ASSETS_PATHS = createRedirectObject({
  CREATE: `/crm/${crmModules.assets}/create`,
  LIST: `/crm/${crmModules.assets}/list`,
});

export const CRM_ASSET_VERIFICATION_PATHS = createRedirectObject({
  CREATE: `/crm/${crmModules.assetVerification}/create`,
  LIST: `/crm/${crmModules.assetVerification}/list`,
  VIEW: `/crm/${crmModules.assetVerification}/sublist/`,
});

export const CRM_MEMBERS_PATHS = createRedirectObject({
  LIST: `/crm/${crmModules.members}/list`,
  VIEW: `/crm/${crmModules.members}/`,
});

export const CRM_LOGISTICS_PATHS = createRedirectObject({
  LIST: `/crm/${crmModules.logistics}/list`,
  VIEW: `/crm/${crmModules.logistics}/`,
  RATE: `/crm/${crmModules.logistics}/rate`,
});

export const CRM_MANAGE_ASSETS_PATHS = createRedirectObject({
  TRANSACT: `/crm/${crmModules.manageAssets}/transact`,
  LIST: `/crm/${crmModules.manageAssets}/list`,
});

export const CRM_ORDER_PATHS = createRedirectObject({
  LIST: `/crm/${crmModules.order}/list`,
  VIEW: `/crm/${crmModules.order}/`,
});

export const CRM_RENTALS_PATHS = createRedirectObject({
  SHEET: `/crm/${crmModules.rentals}/sheet`,
  VIEW: `/crm/${crmModules.rentals}/`,
  LIVE_RATE: `/crm/${crmModules.rentals}/rate-list`,
  ASSIGN: `/crm/${crmModules.rentals}/assign`,
  ASSIGNED: `/crm/${crmModules.rentals}/assigned`,
});

export const CRM_SUPPORT_REQUEST_PATHS = createRedirectObject({
  LIST: `/crm/${crmModules.supportRequest}/list`,
});

export const CRM_USER_PATHS = createRedirectObject({
  CREATE: `/crm/${crmModules.users}/create`,
  LIST: `/crm/${crmModules.users}/list`,
});

export const CRM_ONBOARDING_PATHS = createRedirectObject({
  WIZARD: `/crm/${crmModules.onboarding}/wizard`,
});
