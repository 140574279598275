// Rentals
export const RENT_REQUEST_STAGE = {
  CANCELED: "CANCELED",
  CREATED: "CREATED",
  BLOCKED: "BLOCKED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};
export const RENT_REQUEST_STAGE_COLORS = {
  CANCELED: "danger",
  CREATED: "primary",
  BLOCKED: "dark",
  IN_PROGRESS: "info",
  COMPLETED: "success",
  default: "secondary",
};
export const RENT_REQUEST_REQUESTS = {
  REPLACEMENT: "REPLACEMENT",
  RETURN: "RETURN",
};
export const RENTAL_REQUESTS_FULL_REQUEST_TYPES = {
  NEW_ORDER: "NEW ORDER",
  REPLACEMENT: "REPLACEMENT",
  RETURN: "RETURN",
};
export const RENTAL_REQUESTS_TYPES_COLORS = {
  NEW_ORDER: "primary",
  "NEW ORDER": "primary",
  REPLACEMENT: "warning",
  UPGRADE: "success",
  RETURN: "danger",
  default: "secondary",
};
export const RAR_STAGES_ICONS = {
  CANCELED: "bi bi-x-circle-fill",
  CREATED: "fa fa-brands fa-first-order",
  BLOCKED: "fa fa-hand-paper",
  IN_PROGRESS: "bi bi-clock-history",
  COMPLETED: "fa fa-handshake",
};
export const RentalStatus = {
  RENT: 1,
  REPLACEMENT: 2,
  RETURNED: 3,
  YET_TO_PICKUP: 4,
  SETTLED: 5,
};

export const RENT_SHEET_STATUS_COLORS = {
  RENT: "primary",
  REPLACEMENT: "warning",
  RETURNED: "dark",
  YET_TO_PICKUP: "info",
  SETTLED: "success",
  default: "secondary",
};
