import React, { ReactElement } from "react";

type rowObject = {
  icon?: ReactElement;
  label?: string | ReactElement | null;
  value?: string | number | ReactElement | null;
  isImportant?: boolean;
};

type detailsCardProps = {
  title: string | null;
  rowsArray: rowObject[] | null;
  cardClassNames?: string;
};

export default function DetailsCard({
  title,
  rowsArray,
  cardClassNames,
}: detailsCardProps): React.JSX.Element {
  return (
    <div
      className={`card card-flush flex-row-fluid h-100 ${
        cardClassNames ? cardClassNames : "border-gray-300"
      }`}
    >
      <div className="card-header bg-secondary-subtle px-6">
        <div className="card-title">
          <h2>{title}</h2>
        </div>
      </div>

      <div className="card-body p-6">
        <div className="mb-0 fs-6">
          <div className="vstack fw-semibold text-black">
            {rowsArray?.map((row, index) => {
              return (
                <div
                  key={`${index}-${row.label}`}
                  className={`hstack align-items-center justify-content-between py-5 text-capitalize ${
                    index === rowsArray?.length - 1
                      ? ""
                      : "border-bottom border-gray-300"
                  }`}
                >
                  {row.label ? (
                    <div className="">
                      <div
                        className={`d-flex align-items-center fs-6 ${
                          row.isImportant ? "fw-bold" : ""
                        }`}
                      >
                        {row.icon ? row.icon : null} {row.label}
                      </div>
                    </div>
                  ) : null}
                  <div className="fw-bold text-end">{row.value}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

DetailsCard.defaultProps = {
  title: "Order Details (#14534)",
  rowsArray: [
    {
      icon: (
        <i className="ki-duotone ki-calendar fs-2 me-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      ),
      label: "Date Added",
      value: "24/04/2024",
    },
    {
      icon: (
        <i className="ki-duotone ki-calendar fs-2 me-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      ),
      label: "Date Added",
      value: "24/04/2024",
    },
    {
      icon: (
        <i className="ki-duotone ki-calendar fs-2 me-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      ),
      label: "Date Added",
      value: "24/04/2024",
    },
  ],
};
