import React from "react";
import Select, { GroupBase, Props } from "react-select";
import City from "../../../Utils/CityAndStates/City.json";

export type CityType = {
  id: number;
  name: string;
  state: string;
};

export const CityOptions: CityType[] = City;

function CitySelector<
  IsMulti extends boolean = false,
  Group extends GroupBase<CityType> = GroupBase<CityType>
>({
  options = CityOptions,
  ...rest
}: Props<CityType, IsMulti, Group>): React.JSX.Element {
  return (
    <>
      <Select
        className="react-select-styled react-select-sm"
        classNamePrefix="react-select"
        isSearchable
        isClearable
        openMenuOnFocus
        options={options}
        getOptionLabel={(option) => option?.name}
        getOptionValue={(option) => option?.id?.toString()}
        {...rest}
      />
    </>
  );
}

export default CitySelector;
