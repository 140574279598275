/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Access } from '../model';
// @ts-ignore
import type { AclAccessList200Response } from '../model';
// @ts-ignore
import type { AclContentTypeList200Response } from '../model';
// @ts-ignore
import type { AclRolesList200Response } from '../model';
// @ts-ignore
import type { Role } from '../model';
// @ts-ignore
import type { RoleAccess } from '../model';
// @ts-ignore
import type { RoleContentTypeDeleteAllAccess } from '../model';
// @ts-ignore
import type { RoleContentTypeSetAllAccess } from '../model';
// @ts-ignore
import type { SetRole } from '../model';
/**
 * AclApi - axios parameter creator
 * @export
 */
export const AclApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Access} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclAccessCreate: async (data: Access, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aclAccessCreate', 'data', data)
            const localVarPath = `/acl/access/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [content_type] content_type
         * @param {string} [codename] codename
         * @param {string} [is_native] is_native
         * @param {string} [role] Role
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclAccessList: async (id?: string, content_type?: string, codename?: string, is_native?: string, role?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/access/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (content_type !== undefined) {
                localVarQueryParameter['content_type'] = content_type;
            }

            if (codename !== undefined) {
                localVarQueryParameter['codename'] = codename;
            }

            if (is_native !== undefined) {
                localVarQueryParameter['is_native'] = is_native;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclAccessRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aclAccessRead', 'id', id)
            const localVarPath = `/acl/access/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [content_type] content_type
         * @param {string} [role] Role
         * @param {AclContentTypeListSelectedPermissions} [selected_permissions] selected_permissions
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclContentTypeList: async (id?: string, content_type?: string, role?: string, selected_permissions?: AclContentTypeListSelectedPermissions, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/content_type/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (content_type !== undefined) {
                localVarQueryParameter['content_type'] = content_type;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (selected_permissions !== undefined) {
                localVarQueryParameter['selected_permissions'] = selected_permissions;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessCreate: async (data: RoleAccess, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aclRolesAccessCreate', 'data', data)
            const localVarPath = `/acl/roles/access/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aclRolesAccessDelete', 'id', id)
            const localVarPath = `/acl/roles/access/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aclRolesAccessRead', 'id', id)
            const localVarPath = `/acl/roles/access/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleContentTypeDeleteAllAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessRemoveCreate: async (data: RoleContentTypeDeleteAllAccess, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aclRolesAccessRemoveCreate', 'data', data)
            const localVarPath = `/acl/roles/access/remove/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleContentTypeSetAllAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessSetCreate: async (data: RoleContentTypeSetAllAccess, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aclRolesAccessSetCreate', 'data', data)
            const localVarPath = `/acl/roles/access/set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Role} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesCreate: async (data: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aclRolesCreate', 'data', data)
            const localVarPath = `/acl/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aclRolesDelete', 'id', id)
            const localVarPath = `/acl/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [slug] slug
         * @param {string} [account] account
         * @param {AclRolesListCategory} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesList: async (id?: string, slug?: string, account?: string, category?: AclRolesListCategory, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aclRolesRead', 'id', id)
            const localVarPath = `/acl/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetRole} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesSetCreate: async (data: SetRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aclRolesSetCreate', 'data', data)
            const localVarPath = `/acl/roles/set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AclApi - functional programming interface
 * @export
 */
export const AclApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AclApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Access} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclAccessCreate(data: Access, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclAccessCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclAccessCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [content_type] content_type
         * @param {string} [codename] codename
         * @param {string} [is_native] is_native
         * @param {string} [role] Role
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclAccessList(id?: string, content_type?: string, codename?: string, is_native?: string, role?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclAccessList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclAccessList(id, content_type, codename, is_native, role, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclAccessList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclAccessRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclAccessRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclAccessRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [content_type] content_type
         * @param {string} [role] Role
         * @param {AclContentTypeListSelectedPermissions} [selected_permissions] selected_permissions
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclContentTypeList(id?: string, content_type?: string, role?: string, selected_permissions?: AclContentTypeListSelectedPermissions, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclContentTypeList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclContentTypeList(id, content_type, role, selected_permissions, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclContentTypeList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RoleAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesAccessCreate(data: RoleAccess, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesAccessCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesAccessCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesAccessDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesAccessDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesAccessDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesAccessRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesAccessRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesAccessRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RoleContentTypeDeleteAllAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesAccessRemoveCreate(data: RoleContentTypeDeleteAllAccess, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleContentTypeDeleteAllAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesAccessRemoveCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesAccessRemoveCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RoleContentTypeSetAllAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesAccessSetCreate(data: RoleContentTypeSetAllAccess, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleContentTypeSetAllAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesAccessSetCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesAccessSetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Role} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesCreate(data: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [slug] slug
         * @param {string} [account] account
         * @param {AclRolesListCategory} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesList(id?: string, slug?: string, account?: string, category?: AclRolesListCategory, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclRolesList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesList(id, slug, account, category, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SetRole} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclRolesSetCreate(data: SetRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclRolesSetCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclRolesSetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aclTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AclApi.aclTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AclApi - factory interface
 * @export
 */
export const AclApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AclApiFp(configuration)
    return {
        /**
         * 
         * @param {AclApiAclAccessCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclAccessCreate(requestParameters: AclApiAclAccessCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Access> {
            return localVarFp.aclAccessCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclAccessListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclAccessList(requestParameters: AclApiAclAccessListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AclAccessList200Response> {
            return localVarFp.aclAccessList(requestParameters.id, requestParameters.content_type, requestParameters.codename, requestParameters.is_native, requestParameters.role, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclAccessReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclAccessRead(requestParameters: AclApiAclAccessReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Access> {
            return localVarFp.aclAccessRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclContentTypeListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclContentTypeList(requestParameters: AclApiAclContentTypeListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AclContentTypeList200Response> {
            return localVarFp.aclContentTypeList(requestParameters.id, requestParameters.content_type, requestParameters.role, requestParameters.selected_permissions, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesAccessCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessCreate(requestParameters: AclApiAclRolesAccessCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleAccess> {
            return localVarFp.aclRolesAccessCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesAccessDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessDelete(requestParameters: AclApiAclRolesAccessDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.aclRolesAccessDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesAccessReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessRead(requestParameters: AclApiAclRolesAccessReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleAccess> {
            return localVarFp.aclRolesAccessRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesAccessRemoveCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessRemoveCreate(requestParameters: AclApiAclRolesAccessRemoveCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleContentTypeDeleteAllAccess> {
            return localVarFp.aclRolesAccessRemoveCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesAccessSetCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesAccessSetCreate(requestParameters: AclApiAclRolesAccessSetCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleContentTypeSetAllAccess> {
            return localVarFp.aclRolesAccessSetCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesCreate(requestParameters: AclApiAclRolesCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Role> {
            return localVarFp.aclRolesCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesDelete(requestParameters: AclApiAclRolesDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.aclRolesDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesList(requestParameters: AclApiAclRolesListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AclRolesList200Response> {
            return localVarFp.aclRolesList(requestParameters.id, requestParameters.slug, requestParameters.account, requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesRead(requestParameters: AclApiAclRolesReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Role> {
            return localVarFp.aclRolesRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AclApiAclRolesSetCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclRolesSetCreate(requestParameters: AclApiAclRolesSetCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SetRole> {
            return localVarFp.aclRolesSetCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aclTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aclTestRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aclAccessCreate operation in AclApi.
 * @export
 * @interface AclApiAclAccessCreateRequest
 */
export interface AclApiAclAccessCreateRequest {
    /**
     * 
     * @type {Access}
     * @memberof AclApiAclAccessCreate
     */
    readonly data: Access
}

/**
 * Request parameters for aclAccessList operation in AclApi.
 * @export
 * @interface AclApiAclAccessListRequest
 */
export interface AclApiAclAccessListRequest {
    /**
     * id
     * @type {string}
     * @memberof AclApiAclAccessList
     */
    readonly id?: string

    /**
     * content_type
     * @type {string}
     * @memberof AclApiAclAccessList
     */
    readonly content_type?: string

    /**
     * codename
     * @type {string}
     * @memberof AclApiAclAccessList
     */
    readonly codename?: string

    /**
     * is_native
     * @type {string}
     * @memberof AclApiAclAccessList
     */
    readonly is_native?: string

    /**
     * Role
     * @type {string}
     * @memberof AclApiAclAccessList
     */
    readonly role?: string

    /**
     * A search term.
     * @type {string}
     * @memberof AclApiAclAccessList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AclApiAclAccessList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AclApiAclAccessList
     */
    readonly offset?: number
}

/**
 * Request parameters for aclAccessRead operation in AclApi.
 * @export
 * @interface AclApiAclAccessReadRequest
 */
export interface AclApiAclAccessReadRequest {
    /**
     * A unique integer value identifying this access.
     * @type {number}
     * @memberof AclApiAclAccessRead
     */
    readonly id: number
}

/**
 * Request parameters for aclContentTypeList operation in AclApi.
 * @export
 * @interface AclApiAclContentTypeListRequest
 */
export interface AclApiAclContentTypeListRequest {
    /**
     * id
     * @type {string}
     * @memberof AclApiAclContentTypeList
     */
    readonly id?: string

    /**
     * content_type
     * @type {string}
     * @memberof AclApiAclContentTypeList
     */
    readonly content_type?: string

    /**
     * Role
     * @type {string}
     * @memberof AclApiAclContentTypeList
     */
    readonly role?: string

    /**
     * selected_permissions
     * @type {'ALL' | 'PARTIAL' | 'NONE'}
     * @memberof AclApiAclContentTypeList
     */
    readonly selected_permissions?: AclContentTypeListSelectedPermissions

    /**
     * A search term.
     * @type {string}
     * @memberof AclApiAclContentTypeList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AclApiAclContentTypeList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AclApiAclContentTypeList
     */
    readonly offset?: number
}

/**
 * Request parameters for aclRolesAccessCreate operation in AclApi.
 * @export
 * @interface AclApiAclRolesAccessCreateRequest
 */
export interface AclApiAclRolesAccessCreateRequest {
    /**
     * 
     * @type {RoleAccess}
     * @memberof AclApiAclRolesAccessCreate
     */
    readonly data: RoleAccess
}

/**
 * Request parameters for aclRolesAccessDelete operation in AclApi.
 * @export
 * @interface AclApiAclRolesAccessDeleteRequest
 */
export interface AclApiAclRolesAccessDeleteRequest {
    /**
     * A unique integer value identifying this role access.
     * @type {number}
     * @memberof AclApiAclRolesAccessDelete
     */
    readonly id: number
}

/**
 * Request parameters for aclRolesAccessRead operation in AclApi.
 * @export
 * @interface AclApiAclRolesAccessReadRequest
 */
export interface AclApiAclRolesAccessReadRequest {
    /**
     * A unique integer value identifying this role access.
     * @type {number}
     * @memberof AclApiAclRolesAccessRead
     */
    readonly id: number
}

/**
 * Request parameters for aclRolesAccessRemoveCreate operation in AclApi.
 * @export
 * @interface AclApiAclRolesAccessRemoveCreateRequest
 */
export interface AclApiAclRolesAccessRemoveCreateRequest {
    /**
     * 
     * @type {RoleContentTypeDeleteAllAccess}
     * @memberof AclApiAclRolesAccessRemoveCreate
     */
    readonly data: RoleContentTypeDeleteAllAccess
}

/**
 * Request parameters for aclRolesAccessSetCreate operation in AclApi.
 * @export
 * @interface AclApiAclRolesAccessSetCreateRequest
 */
export interface AclApiAclRolesAccessSetCreateRequest {
    /**
     * 
     * @type {RoleContentTypeSetAllAccess}
     * @memberof AclApiAclRolesAccessSetCreate
     */
    readonly data: RoleContentTypeSetAllAccess
}

/**
 * Request parameters for aclRolesCreate operation in AclApi.
 * @export
 * @interface AclApiAclRolesCreateRequest
 */
export interface AclApiAclRolesCreateRequest {
    /**
     * 
     * @type {Role}
     * @memberof AclApiAclRolesCreate
     */
    readonly data: Role
}

/**
 * Request parameters for aclRolesDelete operation in AclApi.
 * @export
 * @interface AclApiAclRolesDeleteRequest
 */
export interface AclApiAclRolesDeleteRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AclApiAclRolesDelete
     */
    readonly id: number
}

/**
 * Request parameters for aclRolesList operation in AclApi.
 * @export
 * @interface AclApiAclRolesListRequest
 */
export interface AclApiAclRolesListRequest {
    /**
     * id
     * @type {string}
     * @memberof AclApiAclRolesList
     */
    readonly id?: string

    /**
     * slug
     * @type {string}
     * @memberof AclApiAclRolesList
     */
    readonly slug?: string

    /**
     * account
     * @type {string}
     * @memberof AclApiAclRolesList
     */
    readonly account?: string

    /**
     * category
     * @type {'ERP' | 'CLIENT'}
     * @memberof AclApiAclRolesList
     */
    readonly category?: AclRolesListCategory

    /**
     * A search term.
     * @type {string}
     * @memberof AclApiAclRolesList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AclApiAclRolesList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AclApiAclRolesList
     */
    readonly offset?: number
}

/**
 * Request parameters for aclRolesRead operation in AclApi.
 * @export
 * @interface AclApiAclRolesReadRequest
 */
export interface AclApiAclRolesReadRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AclApiAclRolesRead
     */
    readonly id: number
}

/**
 * Request parameters for aclRolesSetCreate operation in AclApi.
 * @export
 * @interface AclApiAclRolesSetCreateRequest
 */
export interface AclApiAclRolesSetCreateRequest {
    /**
     * 
     * @type {SetRole}
     * @memberof AclApiAclRolesSetCreate
     */
    readonly data: SetRole
}

/**
 * AclApi - object-oriented interface
 * @export
 * @class AclApi
 * @extends {BaseAPI}
 */
export class AclApi extends BaseAPI {
    /**
     * 
     * @param {AclApiAclAccessCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclAccessCreate(requestParameters: AclApiAclAccessCreateRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclAccessCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclAccessListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclAccessList(requestParameters: AclApiAclAccessListRequest = {}, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclAccessList(requestParameters.id, requestParameters.content_type, requestParameters.codename, requestParameters.is_native, requestParameters.role, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclAccessReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclAccessRead(requestParameters: AclApiAclAccessReadRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclAccessRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclContentTypeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclContentTypeList(requestParameters: AclApiAclContentTypeListRequest = {}, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclContentTypeList(requestParameters.id, requestParameters.content_type, requestParameters.role, requestParameters.selected_permissions, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesAccessCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesAccessCreate(requestParameters: AclApiAclRolesAccessCreateRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesAccessCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesAccessDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesAccessDelete(requestParameters: AclApiAclRolesAccessDeleteRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesAccessDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesAccessReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesAccessRead(requestParameters: AclApiAclRolesAccessReadRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesAccessRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesAccessRemoveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesAccessRemoveCreate(requestParameters: AclApiAclRolesAccessRemoveCreateRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesAccessRemoveCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesAccessSetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesAccessSetCreate(requestParameters: AclApiAclRolesAccessSetCreateRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesAccessSetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesCreate(requestParameters: AclApiAclRolesCreateRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesDelete(requestParameters: AclApiAclRolesDeleteRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesList(requestParameters: AclApiAclRolesListRequest = {}, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesList(requestParameters.id, requestParameters.slug, requestParameters.account, requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesRead(requestParameters: AclApiAclRolesReadRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AclApiAclRolesSetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclRolesSetCreate(requestParameters: AclApiAclRolesSetCreateRequest, options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclRolesSetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AclApi
     */
    public aclTestRead(options?: RawAxiosRequestConfig) {
        return AclApiFp(this.configuration).aclTestRead(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AclContentTypeListSelectedPermissions = {
    ALL: 'ALL',
    PARTIAL: 'PARTIAL',
    NONE: 'NONE'
} as const;
export type AclContentTypeListSelectedPermissions = typeof AclContentTypeListSelectedPermissions[keyof typeof AclContentTypeListSelectedPermissions];
/**
 * @export
 */
export const AclRolesListCategory = {
    ERP: 'ERP',
    CLIENT: 'CLIENT'
} as const;
export type AclRolesListCategory = typeof AclRolesListCategory[keyof typeof AclRolesListCategory];
