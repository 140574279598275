/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ActivateUser } from '../model';
// @ts-ignore
import type { LoginLog } from '../model';
// @ts-ignore
import type { RequestResetCredential } from '../model';
// @ts-ignore
import type { ResetCredential } from '../model';
// @ts-ignore
import type { SetAccountAdmin } from '../model';
// @ts-ignore
import type { SetCredential } from '../model';
// @ts-ignore
import type { StockySearchUserList200Response } from '../model';
// @ts-ignore
import type { TokenObtainPair } from '../model';
// @ts-ignore
import type { TokenRefresh } from '../model';
// @ts-ignore
import type { User } from '../model';
// @ts-ignore
import type { UsersLoginLogList200Response } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SetAccountAdmin} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountAdminCreate: async (data: SetAccountAdmin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersAccountAdminCreate', 'data', data)
            const localVarPath = `/users/account/admin/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestResetCredential} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountCredentialsRequestResetCreate: async (data: RequestResetCredential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersAccountCredentialsRequestResetCreate', 'data', data)
            const localVarPath = `/users/account/credentials/request/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetCredential} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountCredentialsResetCreate: async (data: ResetCredential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersAccountCredentialsResetCreate', 'data', data)
            const localVarPath = `/users/account/credentials/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountCredentialsResetRead: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('usersAccountCredentialsResetRead', 'key', key)
            const localVarPath = `/users/account/credentials/reset/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivateUser} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersActivateCreate: async (data: ActivateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersActivateCreate', 'data', data)
            const localVarPath = `/users/activate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersApiTokenRefreshCreate: async (data: TokenRefresh, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersApiTokenRefreshCreate', 'data', data)
            const localVarPath = `/users/api/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAuthCheckRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/auth/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreate: async (data: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersCreate', 'data', data)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetCredential} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCredentialsSetCreate: async (data: SetCredential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersCredentialsSetCreate', 'data', data)
            const localVarPath = `/users/credentials/set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] email
         * @param {string} [is_active] is_active
         * @param {string} [account] account
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList: async (email?: string, is_active?: string, account?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (is_active !== undefined) {
                localVarQueryParameter['is_active'] = is_active;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenObtainPair} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginCreate: async (data: TokenObtainPair, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersLoginCreate', 'data', data)
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [user] user
         * @param {UsersLoginLogListLoginType} [login_type] login_type
         * @param {string} [ip_address] ip_address
         * @param {string} [meta] meta
         * @param {string} [account] account
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginLogList: async (start_date?: string, end_date?: string, user?: string, login_type?: UsersLoginLogListLoginType, ip_address?: string, meta?: string, account?: string, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/login_log/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (login_type !== undefined) {
                localVarQueryParameter['login_type'] = login_type;
            }

            if (ip_address !== undefined) {
                localVarQueryParameter['ip_address'] = ip_address;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this login log.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginLogRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersLoginLogRead', 'id', id)
            const localVarPath = `/users/login_log/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPartialUpdate: async (id: number, data: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersPartialUpdate', 'data', data)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPermissionsRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersRead', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdate: async (id: number, data: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersUpdate', 'data', data)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SetAccountAdmin} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAccountAdminCreate(data: SetAccountAdmin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetAccountAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAccountAdminCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersAccountAdminCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RequestResetCredential} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAccountCredentialsRequestResetCreate(data: RequestResetCredential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestResetCredential>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAccountCredentialsRequestResetCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersAccountCredentialsRequestResetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ResetCredential} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAccountCredentialsResetCreate(data: ResetCredential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetCredential>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAccountCredentialsResetCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersAccountCredentialsResetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAccountCredentialsResetRead(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAccountCredentialsResetRead(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersAccountCredentialsResetRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ActivateUser} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersActivateCreate(data: ActivateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersActivateCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersActivateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersApiTokenRefreshCreate(data: TokenRefresh, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersApiTokenRefreshCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersApiTokenRefreshCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAuthCheckRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAuthCheckRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersAuthCheckRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreate(data: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SetCredential} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCredentialsSetCreate(data: SetCredential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetCredential>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCredentialsSetCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersCredentialsSetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] email
         * @param {string} [is_active] is_active
         * @param {string} [account] account
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersList(email?: string, is_active?: string, account?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySearchUserList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersList(email, is_active, account, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TokenObtainPair} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLoginCreate(data: TokenObtainPair, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLoginCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersLoginCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [user] user
         * @param {UsersLoginLogListLoginType} [login_type] login_type
         * @param {string} [ip_address] ip_address
         * @param {string} [meta] meta
         * @param {string} [account] account
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLoginLogList(start_date?: string, end_date?: string, user?: string, login_type?: UsersLoginLogListLoginType, ip_address?: string, meta?: string, account?: string, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersLoginLogList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLoginLogList(start_date, end_date, user, login_type, ip_address, meta, account, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersLoginLogList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this login log.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLoginLogRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLoginLogRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersLoginLogRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPartialUpdate(id: number, data: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPermissionsRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPermissionsRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPermissionsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdate(id: number, data: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UsersApiUsersAccountAdminCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountAdminCreate(requestParameters: UsersApiUsersAccountAdminCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SetAccountAdmin> {
            return localVarFp.usersAccountAdminCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersAccountCredentialsRequestResetCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountCredentialsRequestResetCreate(requestParameters: UsersApiUsersAccountCredentialsRequestResetCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RequestResetCredential> {
            return localVarFp.usersAccountCredentialsRequestResetCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersAccountCredentialsResetCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountCredentialsResetCreate(requestParameters: UsersApiUsersAccountCredentialsResetCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResetCredential> {
            return localVarFp.usersAccountCredentialsResetCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersAccountCredentialsResetReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAccountCredentialsResetRead(requestParameters: UsersApiUsersAccountCredentialsResetReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAccountCredentialsResetRead(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersActivateCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersActivateCreate(requestParameters: UsersApiUsersActivateCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActivateUser> {
            return localVarFp.usersActivateCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {UsersApiUsersApiTokenRefreshCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersApiTokenRefreshCreate(requestParameters: UsersApiUsersApiTokenRefreshCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenRefresh> {
            return localVarFp.usersApiTokenRefreshCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAuthCheckRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAuthCheckRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreate(requestParameters: UsersApiUsersCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersCredentialsSetCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCredentialsSetCreate(requestParameters: UsersApiUsersCredentialsSetCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SetCredential> {
            return localVarFp.usersCredentialsSetCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList(requestParameters: UsersApiUsersListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySearchUserList200Response> {
            return localVarFp.usersList(requestParameters.email, requestParameters.is_active, requestParameters.account, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersLoginCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginCreate(requestParameters: UsersApiUsersLoginCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenObtainPair> {
            return localVarFp.usersLoginCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersLoginLogListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginLogList(requestParameters: UsersApiUsersLoginLogListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UsersLoginLogList200Response> {
            return localVarFp.usersLoginLogList(requestParameters.start_date, requestParameters.end_date, requestParameters.user, requestParameters.login_type, requestParameters.ip_address, requestParameters.meta, requestParameters.account, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersLoginLogReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginLogRead(requestParameters: UsersApiUsersLoginLogReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginLog> {
            return localVarFp.usersLoginLogRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPartialUpdate(requestParameters: UsersApiUsersPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPermissionsRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersPermissionsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRead(requestParameters: UsersApiUsersReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUsersUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdate(requestParameters: UsersApiUsersUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersAccountAdminCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersAccountAdminCreateRequest
 */
export interface UsersApiUsersAccountAdminCreateRequest {
    /**
     * 
     * @type {SetAccountAdmin}
     * @memberof UsersApiUsersAccountAdminCreate
     */
    readonly data: SetAccountAdmin
}

/**
 * Request parameters for usersAccountCredentialsRequestResetCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersAccountCredentialsRequestResetCreateRequest
 */
export interface UsersApiUsersAccountCredentialsRequestResetCreateRequest {
    /**
     * 
     * @type {RequestResetCredential}
     * @memberof UsersApiUsersAccountCredentialsRequestResetCreate
     */
    readonly data: RequestResetCredential
}

/**
 * Request parameters for usersAccountCredentialsResetCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersAccountCredentialsResetCreateRequest
 */
export interface UsersApiUsersAccountCredentialsResetCreateRequest {
    /**
     * 
     * @type {ResetCredential}
     * @memberof UsersApiUsersAccountCredentialsResetCreate
     */
    readonly data: ResetCredential
}

/**
 * Request parameters for usersAccountCredentialsResetRead operation in UsersApi.
 * @export
 * @interface UsersApiUsersAccountCredentialsResetReadRequest
 */
export interface UsersApiUsersAccountCredentialsResetReadRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersAccountCredentialsResetRead
     */
    readonly key: string
}

/**
 * Request parameters for usersActivateCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersActivateCreateRequest
 */
export interface UsersApiUsersActivateCreateRequest {
    /**
     * 
     * @type {ActivateUser}
     * @memberof UsersApiUsersActivateCreate
     */
    readonly data: ActivateUser
}

/**
 * Request parameters for usersApiTokenRefreshCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersApiTokenRefreshCreateRequest
 */
export interface UsersApiUsersApiTokenRefreshCreateRequest {
    /**
     * 
     * @type {TokenRefresh}
     * @memberof UsersApiUsersApiTokenRefreshCreate
     */
    readonly data: TokenRefresh
}

/**
 * Request parameters for usersCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersCreateRequest
 */
export interface UsersApiUsersCreateRequest {
    /**
     * 
     * @type {User}
     * @memberof UsersApiUsersCreate
     */
    readonly data: User
}

/**
 * Request parameters for usersCredentialsSetCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersCredentialsSetCreateRequest
 */
export interface UsersApiUsersCredentialsSetCreateRequest {
    /**
     * 
     * @type {SetCredential}
     * @memberof UsersApiUsersCredentialsSetCreate
     */
    readonly data: SetCredential
}

/**
 * Request parameters for usersList operation in UsersApi.
 * @export
 * @interface UsersApiUsersListRequest
 */
export interface UsersApiUsersListRequest {
    /**
     * email
     * @type {string}
     * @memberof UsersApiUsersList
     */
    readonly email?: string

    /**
     * is_active
     * @type {string}
     * @memberof UsersApiUsersList
     */
    readonly is_active?: string

    /**
     * account
     * @type {string}
     * @memberof UsersApiUsersList
     */
    readonly account?: string

    /**
     * A search term.
     * @type {string}
     * @memberof UsersApiUsersList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof UsersApiUsersList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof UsersApiUsersList
     */
    readonly offset?: number
}

/**
 * Request parameters for usersLoginCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersLoginCreateRequest
 */
export interface UsersApiUsersLoginCreateRequest {
    /**
     * 
     * @type {TokenObtainPair}
     * @memberof UsersApiUsersLoginCreate
     */
    readonly data: TokenObtainPair
}

/**
 * Request parameters for usersLoginLogList operation in UsersApi.
 * @export
 * @interface UsersApiUsersLoginLogListRequest
 */
export interface UsersApiUsersLoginLogListRequest {
    /**
     * start_date
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly end_date?: string

    /**
     * user
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly user?: string

    /**
     * login_type
     * @type {'1' | '2' | '3'}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly login_type?: UsersLoginLogListLoginType

    /**
     * ip_address
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly ip_address?: string

    /**
     * meta
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly meta?: string

    /**
     * account
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly account?: string

    /**
     * Pagination
     * @type {string}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof UsersApiUsersLoginLogList
     */
    readonly offset?: number
}

/**
 * Request parameters for usersLoginLogRead operation in UsersApi.
 * @export
 * @interface UsersApiUsersLoginLogReadRequest
 */
export interface UsersApiUsersLoginLogReadRequest {
    /**
     * A unique integer value identifying this login log.
     * @type {number}
     * @memberof UsersApiUsersLoginLogRead
     */
    readonly id: number
}

/**
 * Request parameters for usersPartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersPartialUpdateRequest
 */
export interface UsersApiUsersPartialUpdateRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof UsersApiUsersPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {User}
     * @memberof UsersApiUsersPartialUpdate
     */
    readonly data: User
}

/**
 * Request parameters for usersRead operation in UsersApi.
 * @export
 * @interface UsersApiUsersReadRequest
 */
export interface UsersApiUsersReadRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof UsersApiUsersRead
     */
    readonly id: number
}

/**
 * Request parameters for usersUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUpdateRequest
 */
export interface UsersApiUsersUpdateRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof UsersApiUsersUpdate
     */
    readonly id: number

    /**
     * 
     * @type {User}
     * @memberof UsersApiUsersUpdate
     */
    readonly data: User
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiUsersAccountAdminCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAccountAdminCreate(requestParameters: UsersApiUsersAccountAdminCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAccountAdminCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersAccountCredentialsRequestResetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAccountCredentialsRequestResetCreate(requestParameters: UsersApiUsersAccountCredentialsRequestResetCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAccountCredentialsRequestResetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersAccountCredentialsResetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAccountCredentialsResetCreate(requestParameters: UsersApiUsersAccountCredentialsResetCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAccountCredentialsResetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersAccountCredentialsResetReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAccountCredentialsResetRead(requestParameters: UsersApiUsersAccountCredentialsResetReadRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAccountCredentialsResetRead(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersActivateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersActivateCreate(requestParameters: UsersApiUsersActivateCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersActivateCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {UsersApiUsersApiTokenRefreshCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersApiTokenRefreshCreate(requestParameters: UsersApiUsersApiTokenRefreshCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersApiTokenRefreshCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAuthCheckRead(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAuthCheckRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreate(requestParameters: UsersApiUsersCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersCredentialsSetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCredentialsSetCreate(requestParameters: UsersApiUsersCredentialsSetCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCredentialsSetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersList(requestParameters: UsersApiUsersListRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersList(requestParameters.email, requestParameters.is_active, requestParameters.account, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLoginCreate(requestParameters: UsersApiUsersLoginCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersLoginCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersLoginLogListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLoginLogList(requestParameters: UsersApiUsersLoginLogListRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersLoginLogList(requestParameters.start_date, requestParameters.end_date, requestParameters.user, requestParameters.login_type, requestParameters.ip_address, requestParameters.meta, requestParameters.account, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersLoginLogReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLoginLogRead(requestParameters: UsersApiUsersLoginLogReadRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersLoginLogRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPartialUpdate(requestParameters: UsersApiUsersPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPermissionsRead(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPermissionsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersRead(requestParameters: UsersApiUsersReadRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdate(requestParameters: UsersApiUsersUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UsersLoginLogListLoginType = {
    _1: '1',
    _2: '2',
    _3: '3'
} as const;
export type UsersLoginLogListLoginType = typeof UsersLoginLogListLoginType[keyof typeof UsersLoginLogListLoginType];
