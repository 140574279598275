import React from "react";
import { SidebarMenuItemWithSub } from "@metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub.tsx";
import { SidebarMenuItem } from "@metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem.tsx";
import {
  SP_CONSIGNEE_PATHS,
  SP_HELP_DESK_PATHS,
  SP_LIFE_CYCLE_PATHS,
  SP_PRODUCT_PATHS,
  SP_ROLES_PATHS,
  SP_USERS_PATHS,
} from "@Constants/RoutingConstants/stockyPro/SpRedirectRouteConstants.ts";

function StockyProSidebar() {
  return (
    <>
      <SidebarMenuItemWithSub
        to="/"
        icon="laptop"
        title="Products"
        permission={["user.create_product", "user.list_product"]}
      >
        <SidebarMenuItem
          to={SP_PRODUCT_PATHS.CREATE}
          hasBullet={true}
          title={"Create"}
          fontIcon="bi-app-indicator"
          permission="user.create_product"
        />

        <SidebarMenuItem
          to={SP_PRODUCT_PATHS.LIST}
          hasBullet={true}
          title={"List"}
          fontIcon="bi-app-indicator"
          permission="user.list_product"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/"
        icon="abstract-18"
        title="Life Cycle"
        permission={["user.create_product", "user.list_product"]}
      >
        <SidebarMenuItem
          to={SP_LIFE_CYCLE_PATHS.CREATE}
          hasBullet={true}
          title={"Create"}
          fontIcon="bi-app-indicator"
          permission="user.create_product"
        />

        <SidebarMenuItem
          to={SP_LIFE_CYCLE_PATHS.LIST}
          hasBullet={true}
          title={"List"}
          fontIcon="bi-app-indicator"
          permission="user.list_product"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to={SP_HELP_DESK_PATHS.LIST}
        icon={"question"}
        title={"Help Desk"}
        fontIcon="bi-app-indicator"
        permission="user.list_product"
      />

      <SidebarMenuItem
        to={SP_CONSIGNEE_PATHS.LIST}
        title="Consignee"
        icon={"bi bi-person-lines-fill"}
        fontIcon="bi bi-person-lines-fill"
        permission="user.view_tasks"
      />

      <SidebarMenuItemWithSub
        to="/"
        icon="bi bi-people-fill"
        title="User Management"
        permission={["user.create_product", "user.list_product"]}
      >
        <SidebarMenuItemWithSub
          to={"/users/"}
          title={"Users"}
          fontIcon="bi bi-person-fill-gear"
          icon={"user"}
          permission={[
            "user.create_product",
            "user.update_product",
            "user.list_product",
          ]}
        >
          <SidebarMenuItem
            to={SP_USERS_PATHS.CREATE}
            hasBullet={true}
            title={"Create"}
            fontIcon="bi-app-indicator"
            permission="user.create_product"
          />

          <SidebarMenuItem
            to={SP_USERS_PATHS.LIST}
            hasBullet={true}
            title={"List"}
            fontIcon="bi-app-indicator"
            permission="user.list_product"
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to={"/roles/"}
          title={"Roles"}
          fontIcon="bi bi-person-fill-gear"
          icon={"bi bi-person-fill-gear"}
          permission={[
            "user.create_product",
            "user.update_product",
            "user.list_product",
          ]}
        >
          <SidebarMenuItem
            to={SP_ROLES_PATHS.CREATE}
            hasBullet={true}
            title={"Create"}
            permission={"user.create_product"}
          />
          <SidebarMenuItem
            to={SP_ROLES_PATHS.MANAGE}
            hasBullet={true}
            title={"Manage"}
            permission={"user.update_product"}
          />
          <SidebarMenuItem
            to={SP_ROLES_PATHS.LIST}
            hasBullet={true}
            title={"List"}
            permission={"user.list_product"}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
    </>
  );
}

export default StockyProSidebar;
