import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import QuillTextEditor from "@components/helpers/ReactQuill/QuillTextEditor.tsx";
import { postComments } from "../_request";
import { toast, ToastContainer } from "react-toastify";
import { useQueryClient } from "react-query";
import MultiLayeredModalDialog from "@components/UI/MultiLayeredModalDialog.tsx";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";

const CommentModel = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const recaptcha = useRef();
  const queryClient = useQueryClient();

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const hadelsubmit = () => {
    const payload = {
      comment: comment,
      task: data.id,
    };
    // @ts-ignore
    const captchaValue = recaptcha?.current?.getValue();
    if (!captchaValue) {
      // @ts-ignore
      postComments(payload)
        .then(() => {
          toast.success("Comment posted successfully");
          handleModalClose();
          queryClient.invalidateQueries("taskComment");
        })
        .catch(() => {
          toast.error("Error posting comment");
        });
    }
  };

  return (
    <div className={"d-flex flex-column justify-content-center"}>
      <Button
        variant="btn btn-light-brand-primary btn-sm  "
        onClick={handleModalOpen}
      >
        Comment
      </Button>
      <ToastContainer />
      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered={true}
        enforceFocus={false}
      >
        <MultiLayeredModalDialog>
          <Modal.Header closeButton>
            <Modal.Title>Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"row mb-3"}>
              <div className="col">
                <QuillTextEditor
                  setterFunction={(val) => setComment(val)}
                  value={comment}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-end">
                <div className="d-flex gap-2 justify-content-center">
                  <SubmitButton
                    onClick={() => hadelsubmit()}
                    disabled={comment.length < 0}
                    text="Comment"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </MultiLayeredModalDialog>
      </Modal>
    </div>
  );
};

export default CommentModel;
