import axios from "axios";

import { buildURL } from "@/Utils/Shared/buildUrl.ts";
import { OrderUrlParams } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;
const ASSETTRAIL = `${API_URL}/orders/`;
const RETRIVE = `${API_URL}/orders/retrive/`;
const ORDER_ITEM_API: string = `${API_URL}/orders/sales/items/`;
const ORDER_ITEMS: string = `${API_URL}/orders/`;
const RENTAL_ORDER_ITEM_API: string = `${API_URL}/orders/`;
const PROCESSED_ITEMS_API: string = `${API_URL}/orders/processed/ops/items/`;
const SERVICE_ITEMS_API: string = `${API_URL}/orders/processed/service/items/`;

const ORDER_DETAILS_VIEW_API: string = `${API_URL}/orders/public/`;

const ORDER_STAGE_API: string = `${API_URL}/orders/stage/`;

const GET_NOTIFICATIONS_API: string = `${API_URL}/orders/notifications/`;
const GET_PO_NOTIFICATIONS_API: string = `${API_URL}/orders/purchase/notifications/`;

const GET_NOTIFY_DETAILS_API: string = `${API_URL}/orders/notify/`;
const GET_PO_NOTIFY_DETAILS_API: string = `${API_URL}/orders/purchase/notify/`;

const GET_LOGISTIC_ITEM_API: string = `${API_URL}/orders/processed/logistics/items/`;

export function getOrderByToken(token: any, params: OrderUrlParams) {
  const url = buildURL(params, ASSETTRAIL);
  return axios.get<any>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getOrderItems(token: any, params: any) {
  return axios.get<any>(`${ORDER_ITEM_API}?order=${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getProcessedOrderitems(token: any, params: any) {
  return axios
    .get(`${PROCESSED_ITEMS_API}${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
}

export function getServiceProcessedOrderitems(token: any, params: any) {
  return axios
    .get(`${SERVICE_ITEMS_API}${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
}

export function getOrderDetailsView(token: any, param: any) {
  return axios
    .get(`${ORDER_DETAILS_VIEW_API}${param}`, {
      headers: {
        // Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getOrderStage(token: any, param: any) {
  return axios.get(`${ORDER_STAGE_API}?order=${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getOrderRentalState(token: any, param: any, type = "rental") {
  return axios.get(`${ORDER_ITEMS}${type}/items/?order=${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getOrderItemDetails(token: any, param: any, type = "rental") {
  return axios.get(`${ORDER_ITEMS}${type}/items/?order=${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteOrderRentalState(token: any, id: any, type = "rental") {
  return axios.delete(`${RENTAL_ORDER_ITEM_API}${type}/items/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function postOrderStage(token: any, payload: any) {
  return axios.post(ORDER_STAGE_API, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getNotificationsData(token: any, param: any) {
  return axios.get(`${GET_NOTIFICATIONS_API}?order=${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getPurchaseNotificationsData(token: any, param: any) {
  return axios.get(`${GET_PO_NOTIFICATIONS_API}?order=${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getNotifyData(token: any, param: any) {
  console.log("getNotidata");
  return axios.get(`${GET_NOTIFY_DETAILS_API}${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function getPoNotifyData(token: any, id: any) {
  console.log("getPoNotidata");
  return axios.get(`${GET_PO_NOTIFY_DETAILS_API}${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postNotifyData(token: any, payload: any) {
  return axios.post(`${GET_NOTIFY_DETAILS_API}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postPoNotifyData(token: any, payload: any) {
  return axios.post(`${GET_PO_NOTIFY_DETAILS_API}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getLogisticItems(token: any, params: any) {
  return axios.get(`${GET_LOGISTIC_ITEM_API}${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function orderGettingDetails(token: any, param: any) {
  return axios.get(`${RETRIVE}${param}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getItemDetailsView(token: any, param: any) {
  return axios
    .get(`${ORDER_DETAILS_VIEW_API}${param}`, {
      headers: {
        // Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
}

const SEARCH_ORDER_API = `${API_URL}/orders/`;

export function searchOrderItem(token: any, id: any, urltype) {
  const url = `${SEARCH_ORDER_API}${urltype}/items/?order=${id}`;

  return axios
    .get<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      throw error;
    });
}
