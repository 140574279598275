import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  generateDataURIFromImage,
  getCompanyImageSrc,
} from "@/Utils/images/imageHelpers.ts";
import { publicOrdersApi } from "@/api/public";
import PdfGenerate from "@components/helpers/Pdfmake/PdfGenerate.tsx";
import { generatePoDocDefinition } from "@/Utils/pdfmake/generatePoPdfService.ts";
import CommonPageHeader from "@components/helpers/CommonPageHeader/CommonPageHeader.tsx";
import PdfLoader from "@components/helpers/Pdfmake/PdfLoader.tsx";
import TableAlert from "@components/helpers/CustomAlerts/TableAlert.tsx";
import useErrorRedirection from "@Hooks/useErrorRedirection.tsx";

function PrintPoPublicPage() {
  const { slug } = useParams();

  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ["get_public_po_details"],
    queryFn: () => {
      return publicOrdersApi
        .ordersPurchasePublicRead({ id: +slug! })
        .then((res) => res.data);
    },
    enabled: !!slug,
  });

  // @ts-ignore
  useErrorRedirection(error?.response);

  const imageSrc = getCompanyImageSrc(data?.company_info?.id);
  const productItems = data?.items;

  const {
    data: imageData,
    isLoading: isImageLoading,
    isError: isImageError,
  } = useQuery({
    queryKey: ["get_image_data_public_page", imageSrc],
    queryFn: () => {
      return generateDataURIFromImage(imageSrc!).then((imageData) => imageData);
    },
    enabled: !!imageSrc,
  });

  const ready =
    (!isLoading || !isFetching || !isImageLoading) &&
    (!isImageError || !isError) &&
    !!imageData &&
    !!data?.id &&
    !!productItems?.length;

  return (
    <div className={"container"}>
      <div className="my-10">
        <CommonPageHeader
          id={data?.company_info?.id}
          heading={`Purchase Order`}
        />
        {ready ? (
          <>
            <div className="">
              <PdfGenerate
                dd={generatePoDocDefinition({
                  order: data,
                  itemsList: productItems,
                  imageData: imageData,
                })}
              />
            </div>
          </>
        ) : isError ? (
          <>
            <TableAlert message={"Error generating PDF. Please try again."} />
          </>
        ) : (
          <PdfLoader />
        )}
      </div>
    </div>
  );
}

export default PrintPoPublicPage;
