import publicInstance from "@/api/public/axios.ts";
import { RentalsApi } from "@/generated-api/server/rentals-api.ts";
import { StockyApi } from "@/generated-api/server/stocky-api.ts";
import { OrdersApi } from "@/generated-api/server/orders-api.ts";

const publicRentalsApi = new RentalsApi(undefined, undefined, publicInstance);
const publicStockyApi = new StockyApi(undefined, undefined, publicInstance);
const publicOrdersApi = new OrdersApi(undefined, undefined, publicInstance);

export { publicRentalsApi, publicStockyApi, publicOrdersApi };
