export const buildURL = (
  params: {
    limit?: number | null | string;
    offset?: number | null | string;
  },
  baseUrl?: string | null
): string => {
  let queryParams = "";

  const addQueryParam = (
    paramName: string,
    paramValue: string | number | (string | string[] | null) | undefined
  ) => {
    if (
      paramValue !== undefined &&
      paramValue !== null &&
      paramValue !== "" &&
      paramValue !== ","
    ) {
      if (Array.isArray(paramValue)) {
        if (paramValue.length) {
          queryParams += `${paramName}=${paramValue
            .map((value) => encodeURIComponent(value))
            .join(",")}&`;
        }
      } else {
        queryParams += `${paramName}=${encodeURIComponent(paramValue)}&`;
      }
    }
  };
  for (const key in params) {
    addQueryParam(key, params[key]);
  }

  // Remove the trailing '&' character from queryParams, if present
  if (queryParams.endsWith("&")) {
    queryParams = queryParams.slice(0, -1);
  }

  if (baseUrl) {
    return `${baseUrl}?${queryParams}`;
  }
  return `?${queryParams}`;
};
