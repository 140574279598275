import axios from "axios";

const API_URL = import.meta.env.VITE_APP_API_URL;

const GET_ASSIGNED_ASSET_DATA = `${API_URL}/rentals/assigned/rental/serial`;
const POST_ASSIGNED_ASSET = `${API_URL}/client/asset/assign/`;
const POST_PUBLIC_ASSET_ASSIGN = `${API_URL}/client/public/asset/assign/`;

interface postAssignedAssetInterface {
  assigned: number | null;
  rental: number | null;
  note?: string | null;
}

interface postPublicAssignAssetInterface {
  connect_slug: string | null;
  assigned_info: {
    name: string | null;
    number: number | null;
    email: string | null;
  };
  rental: number | null;
  note: string | null;
}

export function getAssignedAssetData(
  slug: string | undefined,
  serial: string | undefined
) {
  return axios.get(`${GET_ASSIGNED_ASSET_DATA}/${slug}/${serial}`);
}

export function postAssignedAsset(payload: postAssignedAssetInterface) {
  return axios.post(`${POST_ASSIGNED_ASSET}`, payload);
}

export function postPublicAssetAssign(payload: postPublicAssignAssetInterface) {
  return axios.post(`${POST_PUBLIC_ASSET_ASSIGN}`, payload);
}
