import React, { ButtonHTMLAttributes, ReactElement } from "react";
import { Spinner } from "react-bootstrap";
import { ColorsAll } from "@components/UI/uiTypes.ts";

interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  text?: string;
  icon?: ReactElement;
  size?: "sm" | "lg";
  variant?: ColorsAll;
}

function SubmitButton({
  loading,
  text,
  icon,
  size,
  className,
  variant = "light-brand-primary",
  ...props
}: SubmitButtonProps): React.JSX.Element {
  return (
    <>
      <button
        className={`btn btn-${size} btn-${variant} d-flex flex-center gap-2 text-capitalize  ${className} `}
        {...props}
      >
        {loading ? (
          <>
            <Spinner size={"sm"} />
            Please wait...
          </>
        ) : (
          <>
            {icon}
            {text ?? "Submit"}
          </>
        )}
      </button>
    </>
  );
}

export default SubmitButton;
