import { Route, Routes } from "react-router-dom";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { AuthLayout } from "./AuthLayout";
import ResetPassword from "@app/modules/auth/components/ResetPassword.tsx";
import StockyProSignUp from "@app/modules/auth/components/StockyProSignUp.tsx";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route index element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path={"account/credentials/reset/:resetKey"}
        element={<ResetPassword />}
      />

      {/* Stocky Pro Sign up */}
      <Route path={"sign-up"} element={<StockyProSignUp />} />
    </Route>
  </Routes>
);

export { AuthPage };
