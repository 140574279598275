import React from "react";

type NoteAlertProps = {
  heading: string;
  note: string;
  borderClass: string;
};

function NoteAlert({ heading, note, borderClass }: NoteAlertProps) {
  return (
    <div className={`alert border ${borderClass} rounded-0 m-0 px-6`}>
      <div className={"vstack gap-2"}>
        <div className="fw-bold fs-5 d-flex align-items-center gap-2 text-black">
          <i className="ki-duotone ki-note-2 fs-1" style={{ color: "inherit" }}>
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </i>{" "}
          {heading}
        </div>
        <div
          className={"text-gray-800"}
          dangerouslySetInnerHTML={{
            __html: note ?? "",
          }}
        ></div>
      </div>
    </div>
  );
}

export default NoteAlert;

NoteAlert.defaultProps = {
  borderClass: "border-gray-300",
};
