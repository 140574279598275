import React, { useState } from "react";
import { useAuth } from "@app/modules/auth";
import QuillTextEditor from "@components/helpers/ReactQuill/QuillTextEditor.tsx";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  addClientRequestDetails,
  getConnectionInformation,
  getConsigneeBySlugAndSerial,
  getSerialData,
} from "./_requests";
import { useFormik } from "formik";
import { createTicketValidationSchema, initialValues } from "./_models";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { playSound } from "@components/helpers/Sounds/PlaySound.ts";
import SupportPopover from "../../../crm/CRMPageWrapper/SupportPopover";
import ClientInfoSelection from "./Components/ClientInfoSelection";
import RequestSuccess from "./Components/RequestSuccess";
import BackgroundWrapper from "../../../../../components/UI/Wrappers/BackgroundWrapper";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import SearchInput from "@components/UI/Inputs/SearchInput.tsx";
import FormSkeleton from "@components/helpers/Skeletons/SkeletonsByPage/FormSkeleton.tsx";
import { SUPPORT_REQUEST_CATEGORY } from "@Constants/CR_Constant";
import CategorySelection from "@app/pages/erp/Create/CreateTicket/Components/CategorySelection.tsx";
import StockyProLogo from "@components/helpers/CustomImage/StockyProLogo.tsx";

const CreateTicket = () => {
  const { auth } = useAuth();
  const { connectSlug } = useParams<any>();
  const [searchParams] = useSearchParams();
  const categoryKey = searchParams.get("category");

  const [clientResponse, setClientResponse] = useState<any>(null);
  const [captchaKey, setCaptchaKey] = useState("");
  const [serialData, setSerialData] = useState<any>({
    assignedData: null,
    productData: null,
    isError: false,
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      ...initialValues,
      category: SUPPORT_REQUEST_CATEGORY[categoryKey],
    },
    validationSchema: createTicketValidationSchema,
    onSubmit: (values, action) => {
      const data = {
        ...values,
        client_info: {
          name: values.name,
          number: values.number,
          email: values.email,
        },
        connect: connectInfo?.data?.id,
        captcha: captchaKey,
      };

      if (!values?.consignee) {
        delete data.consignee;
      }

      delete data.name;
      delete data.number;
      delete data.email;

      addClientRequestDetails(auth?.access, data)
        .then((res) => {
          setClientResponse(res?.data);
          toast.success("Ticket created", { autoClose: 1500 });
          playSound("BaseNotify");
          action.resetForm();
        })
        .catch(() => {
          toast.error("Error creating ticket", { autoClose: 1500 });
          playSound("Buzzer");
        })
        .finally(() => {
          action.setSubmitting(false);
        });
    },
  });

  // Queries
  const {
    data: connectInfo,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ["Connect-Info", connectSlug],
    () => {
      return getConnectionInformation(connectSlug).then((res) => {
        return res;
      });
    },
    { retry: false }
  );

  // @ts-ignore
  UseErrorRedirection(error?.response);

  // Query
  const {
    refetch: getAssignedData,
    isLoading: isAssignedDataLoading,
    isFetching: isAssignedDataFetching,
  } = useQuery({
    queryKey: ["raise_ticket_assigned", values?.serial_number],
    queryFn: () => {
      return getConsigneeBySlugAndSerial(
        connectInfo?.data?.slug,
        values?.serial_number
      );
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setSerialData((prev) => ({
        ...prev,
        assignedData: data,
        isError: false,
      }));
    },
    onError: () => {
      setSerialData((prev) => ({
        ...prev,
        assignedData: null,
        isError: true,
      }));
    },
  });

  const {
    refetch: getProductData,
    isLoading: isProductDataLoading,
    isFetching: isProductDataFetching,
  } = useQuery({
    queryKey: ["raise_ticket_product", values?.serial_number],
    queryFn: () => {
      return getSerialData(values?.serial_number!);
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setSerialData((prev) => ({
        ...prev,
        productData: data,
        isError: false,
      }));
    },
    onError: () => {
      setSerialData((prev) => ({
        ...prev,
        productData: null,
        isError: true,
      }));
    },
  });

  function updateClientInfo({
    name,
    number,
    email,
    consignee,
  }: {
    name: string | null;
    number: string | null;
    email: string | null;
    consignee: number | null;
  }) {
    setFieldValue("name", name).then(() => {
      if (name) {
        setFieldTouched("name", true);
      }
    });
    setFieldValue("number", number).then(() => {
      if (number) {
        setFieldTouched("number", true);
      }
    });
    setFieldValue("email", email).then(() => {
      if (email) {
        setFieldTouched("email", true);
      }
    });
    setFieldValue("consignee", consignee).then(() => {
      if (consignee) {
        setFieldTouched("consignee", true);
      }
    });
  }

  function handleNewRequestClick() {
    resetForm();
    setClientResponse(null);
    setCaptchaKey("");
    setSerialData({
      assignedData: null,
      productData: null,
      isError: false,
    });
  }

  // Derived
  const isSerialDataLoading =
    isAssignedDataLoading ||
    isAssignedDataFetching ||
    isProductDataLoading ||
    isProductDataFetching;

  return (
    <BackgroundWrapper>
      <div className="container">
        <div className={"row"}>
          <div className={"col-12 col-md-10 offset-md-1"}>
            <div className="d-flex flex-center gap-2 py-3 border-bottom border-gray-300 mb-3">
              <div className="">
                <StockyProLogo width={40} />
              </div>
              <h1 className="d-inline-block m-0">Create A Ticket</h1>
            </div>

            <div className="row mb-3">
              <div className="col">
                <div className="card shadow-xs">
                  {!clientResponse ? (
                    <div className="card-body">
                      {isLoading || isFetching ? (
                        <FormSkeleton />
                      ) : (
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-6 row-gap-6">
                            <div className="col-md-6">
                              <label
                                htmlFor={"public_create_ticket_subject"}
                                className="form-label fw-bold"
                              >
                                Subject
                              </label>
                              <input
                                id={"public_create_ticket_subject"}
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Subject"
                                name="title"
                                value={values?.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors?.title && touched?.title && (
                                <p className="text-danger m-0">
                                  {errors?.title}
                                </p>
                              )}
                            </div>

                            {/* Connect Card */}
                            <div className="col-md-6">
                              <div className={"card"}>
                                <div className={"card-body p-6"}>
                                  <div className="d-flex align-items-center">
                                    <p className="flex-shrink-0 btn btn-icon btn-brand-primary m-0">
                                      <i className="bi bi-hand-thumbs-up-fill fs-2"></i>
                                    </p>
                                    <div className="flex-grow-1 ms-3 d-flex flex-column">
                                      <span className="fw-bold">Connect</span>
                                      <span>{connectInfo?.data?.name}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={""}>
                            <CategorySelection
                              errors={errors}
                              touched={touched}
                              values={values}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>

                          <div className="row">
                            <div className="col-6 offset-3">
                              <div className="separator my-6"></div>
                            </div>
                          </div>

                          <div className="row row-gap-6 mb-6">
                            <div className="col-md-6">
                              <label
                                htmlFor={"public_create_ticket_serial"}
                                className="form-label fw-bold"
                              >
                                Serial Number
                              </label>
                              <SearchInput
                                id={"public_create_ticket_serial"}
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Serial Number"
                                name="serial_number"
                                value={values?.serial_number}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  if (
                                    !(
                                      values?.serial_number &&
                                      values?.serial_number?.length > 2
                                    )
                                  ) {
                                    setSerialData({
                                      assignedData: null,
                                      productData: null,
                                      isError: false,
                                    });
                                  }
                                }}
                                handleSearch={() => {
                                  if (
                                    values?.serial_number &&
                                    connectInfo?.data?.slug
                                  ) {
                                    getAssignedData();
                                    getProductData();
                                  }
                                }}
                                loading={isSerialDataLoading}
                              />
                              {errors?.serial_number &&
                                touched?.serial_number && (
                                  <p className="text-danger m-0">
                                    {errors?.serial_number}
                                  </p>
                                )}
                            </div>

                            {serialData.productData ? (
                              <div className="col-md-6">
                                <div className={"card"}>
                                  <div className="card-body p-6">
                                    <div className="vstack gap-1">
                                      <div className={"text-truncate"}>
                                        {
                                          serialData.productData?.computer
                                            ?.item_name
                                        }
                                      </div>
                                      <div
                                        className={"text-muted text-truncate"}
                                      >
                                        {
                                          serialData.productData?.computer
                                            ?.cpuversion
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>

                          {serialData.assignedData && !serialData.isError ? (
                            <div className={""}>
                              <ClientInfoSelection
                                serialData={serialData}
                                updateClientInfo={updateClientInfo}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldTouched={setFieldTouched}
                              />
                            </div>
                          ) : (
                            <>
                              <div className="row mb-6 row-gap-6">
                                <div className="col-md-6">
                                  <label
                                    htmlFor={"public_create_ticket_contactName"}
                                    className="form-label fw-bold"
                                  >
                                    Contact Name
                                  </label>
                                  <input
                                    id={"public_create_ticket_contactName"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder="Contact Name"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.name ?? ""}
                                  />
                                  {errors?.name && touched?.name && (
                                    <p className="text-danger m-0">
                                      {errors?.name}
                                    </p>
                                  )}
                                </div>

                                <div className="col-md-6">
                                  <label
                                    htmlFor={
                                      "public_create_ticket_contactNumber"
                                    }
                                    className="form-label fw-bold"
                                  >
                                    Contact Number
                                  </label>
                                  <input
                                    id={"public_create_ticket_contactNumber"}
                                    type="number"
                                    className="form-control form-control-sm"
                                    placeholder="Contact Number"
                                    name="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.number ?? ""}
                                  />
                                  {errors?.number && touched?.number && (
                                    <p className="text-danger m-0">
                                      {errors?.number}
                                    </p>
                                  )}
                                </div>

                                <div className="col-md-6">
                                  <label
                                    htmlFor={
                                      "public_create_ticket_contactEmail"
                                    }
                                    className="form-label fw-bold"
                                  >
                                    Contact Email
                                  </label>
                                  <input
                                    id={"public_create_ticket_contactEmail"}
                                    type="email"
                                    className="form-control form-control-sm"
                                    placeholder="Contact Email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.email ?? ""}
                                  />
                                  {errors?.email && touched?.email && (
                                    <p className="text-danger m-0">
                                      {errors?.email}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row mb-6">
                            <div className="col z-index-0">
                              <label className="form-label fw-bold">
                                Description
                              </label>
                              <div>
                                <QuillTextEditor
                                  setterFunction={(val) => {
                                    setFieldValue("description", val);
                                  }}
                                  value={values.description}
                                />
                              </div>
                              {errors?.description && touched?.description && (
                                <p className="text-danger m-0">
                                  {errors?.description}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="row row-gap-6 position-relative">
                            <div
                              className={"col-md-5 position-relative z-index-2"}
                            >
                              <ReCAPTCHA
                                sitekey={
                                  import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                                    ? import.meta.env
                                        .VITE_APP_RECAPTCHA_SITE_KEY
                                    : "site key"
                                }
                                onChange={(e) => {
                                  setCaptchaKey(e);
                                  setFieldValue("captcha", e);
                                }}
                              />
                              {touched?.captcha && errors?.captcha && (
                                <p className="text-danger m-0">
                                  {errors?.captcha}
                                </p>
                              )}
                            </div>

                            <div
                              className="col-12 text-end align-self-stretch
                            position-xl-absolute top-0 start-0 end-0 bottom-0 d-flex flex-center"
                            >
                              <SubmitButton
                                type="submit"
                                disabled={
                                  !(captchaKey?.length > 0) || isSubmitting
                                }
                                loading={isSubmitting}
                                icon={
                                  <i className="ki-duotone ki-send fs-3 color-inherit p-0">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                }
                              />
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  ) : (
                    <RequestSuccess
                      clientResponse={clientResponse}
                      handleNewRequestClick={handleNewRequestClick}
                    />
                  )}
                  <div className="row">
                    <div className={"col"}>
                      <div className="card-body bg-light">
                        <div className="">
                          <p className="fw-bold">Disclaimer: </p>
                          <p>
                            This contains confidential information pertaining to
                            the business relationship between the Company and
                            its clients. By accessing the link, you agree to
                            respect the privacy and confidentiality of this
                            information. You agree not to disclose, disseminate,
                            or use the information contained within the order
                            details for any purpose that is not explicitly
                            authorized by the Company.
                          </p>
                          <p>
                            Unauthorized use, reproduction, or distribution of
                            the order details or any information contained
                            therein is strictly prohibited and may be subject to
                            legal action.
                          </p>
                          <p>
                            The Company reserves the right to track, restrict or
                            revoke access to the Order Details Link at any time
                            and for any reason, at its sole discretion.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SupportPopover domain={connectInfo?.data?.domain} />
      </div>
    </BackgroundWrapper>
  );
};
export default CreateTicket;
