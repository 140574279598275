import React, { useState } from "react";
import { Accordion, Table } from "react-bootstrap";
import ProductImageCarousel from "@app/pages/erp/products/ExtendedProductComponents/ProductImageCarousel.tsx";

function ExtendedProductPublicView({ data, height }) {
  //   local states
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <div className="vstack gap-8">
        <div className="">
          <div className="row">
            <div className="col-8">
              <div className="vstack gap-2">
                <h2 className="m-0">{data?.item_details?.name}</h2>
                <h4 className={"m-0 w-fit"}>
                  <a
                    href={data?.link_to_official_site ?? "#"}
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                    className={
                      "d-flex align-items-center gap-2 hover-elevate-up"
                    }
                  >
                    View in Detail
                    <i className="ki-duotone ki-arrow-up-right fs-1 color-inherit">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-4">
              <div className="vstack gap-2">
                <h2 className="m-0 text-end">
                  {data?.brand_details?.name}{" "}
                  {data?.category_details?.display_name}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className={""}>
          <h2 className="m-0">Specifications</h2>
          <div className="separator border-gray-300 mt-2 mb-4"></div>
          <div className="row">
            <div className="col-8">
              <div className="card border-0">
                <Table bordered striped className={`m-0 border-gray-300`}>
                  <tbody>
                    <tr>
                      <th className={"col-1"}>cpu</th>
                      <td className={"col-4"}>
                        <div className="table-cell-col">
                          <div className={"fw-semibold"}>
                            {data?.computer?.cpuversion},{" "}
                            {data?.computer?.cpuman}
                          </div>
                          <div className="">
                            {data?.computer?.cpucores ? (
                              <span>{data?.computer?.cpucores} Cores</span>
                            ) : null}{" "}
                            {data?.computer?.cpucores &&
                            data?.computer?.cputhreads ? (
                              <span>& </span>
                            ) : null}
                            {data?.computer?.cputhreads ? (
                              <span>{data?.computer?.cputhreads} Threads</span>
                            ) : null}
                          </div>
                        </div>
                      </td>
                    </tr>

                    {data?.computer?.ram ? (
                      <tr>
                        <th>RAM</th>
                        <td>{data?.computer?.ram} GB</td>
                      </tr>
                    ) : null}

                    {data?.computer?.storage?.length > 0 ? (
                      <tr>
                        <th>storage</th>
                        <td>
                          {data?.computer?.storage?.map((item, index) => {
                            return (
                              <span key={index}>
                                {item?.hddsize}
                                {index !== data?.computer?.storage?.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                    ) : null}

                    {data?.computer?.graphics ? (
                      <tr>
                        <th>Graphic</th>
                        <td>{data?.computer?.graphics}</td>
                      </tr>
                    ) : null}

                    {data?.computer?.mbserial ? (
                      <tr>
                        <th>MB Serial</th>
                        <td>{data?.computer?.mbserial}</td>
                      </tr>
                    ) : null}

                    {data?.computer?.caseserial ? (
                      <tr>
                        <th>case serial</th>
                        <td>{data?.computer?.caseserial}</td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-4">
              <div className="card border-brand">
                <div className="card-header border-brand">
                  <h2 className={"card-title m-0"}>Current Status</h2>
                </div>
                <div className="card-body vstack gap-2">
                  {/*<p className="m-0 fw-semibold">SN#: {data?.serial}</p>*/}
                  {/*<CommonBadge text={data?.current_status?.status_label} />*/}
                  {/*<p className="m-0">{data?.current_status?.log}</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Accordion activeKey={!isCollapsed ? "0" : "-1"}>
          <Accordion.Item eventKey={"0"}>
            <Accordion.Button
              className={"fs-6 fw-semibold"}
              onClick={() => setIsCollapsed((prev) => !prev)}
            >
              Product Images
            </Accordion.Button>
            <Accordion.Body>
              {!isCollapsed ? (
                <ProductImageCarousel data={data} height={height} />
              ) : null}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default ExtendedProductPublicView;
