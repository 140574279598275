import React from "react";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

const CustomFilterBtn = ({
  action,
  size = "lg",
  isActive,
  label,
}: {
  action: Function;
  size?: "sm" | "lg" | "md";
  isActive: boolean;
  label: string;
}) => {
  return (
    <div
      className={`badge  badge-${
        isActive ? "brand-primary" : "secondary"
      } rounded-pill cursor-pointer badge-${size} `}
      onClick={() => action()}
    >
      {DecorateNameTags(label)}
    </div>
  );
};
export default CustomFilterBtn;
