import React from "react";
import { dayMonYearDate } from "@/Utils/Shared/DateStringToDays.ts";
import { Modal } from "react-bootstrap";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

const POCardView = ({ data }) => {
  return (
    <div className={"card"}>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <span className="d-block">
              {data?.po_number} | {data?.category_label} |{" "}
              <CommonBadge
                text={data?.current_stage?.stage ?? ""}
                theme={"odr_stage"}
              />
            </span>
            <span className="d-block">
              <span className={"fw-bold"}>Total Qty: </span>
              {data?.totals?.total_qty}
            </span>
            <span className="d-block">
              <span className={"fw-bold"}>Payment Terms :</span>{" "}
              {DecorateNameTags(data?.payment_terms)}
            </span>
          </div>
          <div className="col">
            <span className="d-block">
              <span className={"fw-bold"}>Expected Arrival: </span>
              {dayMonYearDate(data?.eta)}
            </span>
            <div dangerouslySetInnerHTML={{ __html: data?.note }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default POCardView;

export const ErrorMessageBox = ({ Data }) => {
  return (
    <>
      {typeof Data !== typeof "" &&
      Data &&
      !Array.isArray(Data) &&
      Object.keys(Data).length &&
      Object.keys(Data) ? (
        Object.keys(Data)?.map((fieldName, index) => (
          <div key={index} className="">
            <div className="alert alert-danger opacity-75 d-flex align-items-center p-5 m-1 rounded-0 ">
              <div className="d-flex flex-column">
                {Data[fieldName] &&
                Data[fieldName].length &&
                Array.isArray(Data[fieldName]) ? (
                  Data[fieldName]?.map((errorMessage, idx) => (
                    <div key={idx}>
                      {fieldName !== "non_field_errors" ? fieldName : ""} &nbsp;
                      &nbsp;
                      {typeof errorMessage === "string" ||
                      typeof errorMessage !== typeof {}
                        ? errorMessage
                        : JSON.stringify(errorMessage)}
                    </div>
                  ))
                ) : (
                  <div>{JSON.stringify(Data[fieldName])}</div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="alert alert-danger opacity-75 d-flex align-items-center p-5 m-0 rounded-0 ">
          {Array.isArray(Data) ? [Data].toString() : "Something is Wrong "}
        </div>
      )}
    </>
  );
};

export const LoadingModal = ({ state }) => {
  return (
    <Modal show={state}>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Modal>
  );
};
