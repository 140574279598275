import React from "react";
import { usePageData } from "../../../../core";

function ErpHeader() {
  const { pageTitle } = usePageData();

  return (
    <div className="d-flex align-items-center w-100">
      <div className="flex-grow-1 d-flex flex-center">
        <h2
          className={
            "page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          }
        >
          {pageTitle}
        </h2>
      </div>
    </div>
  );
}

export default ErpHeader;
