import React from "react";
import { Spinner } from "react-bootstrap";

type PdfLoaderProps = {
  message?: string;
};

function PdfLoader({
  message = "Generating PDF document... ",
}: PdfLoaderProps) {
  return (
    <>
      <div className="my-10 d-flex flex-center gap-4">
        <h2 className={"m-0"}>{message}</h2>
        <Spinner />
      </div>
    </>
  );
}

export default PdfLoader;
