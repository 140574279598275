import React from "react";
import {
  dayMonYearDate,
  getDayOfTheWeek,
  shortTime,
} from "@/Utils/Shared/DateStringToDays.ts";
import { TRACKING_STATUSES } from "@Constants/Logistic_Constants";
import TimelineItem from "@app/pages/erp/Logistics/TrackLogistics/Components/TimelineItem.tsx";
import { useParams } from "react-router-dom";

function TrackingDetailsMain({ data }) {
  const { slug } = useParams();

  // Derived
  const timeline =
    data?.timeline !== null && data?.timeline?.length > 0
      ? [...data?.timeline].reverse()
      : null;

  const DEFAULT_TIMELINE = [
    { id: 1, action: "Booked", updated_at: `${data?.date}T${data?.time}` },
    { id: 2, action: "In Transit", updated_at: `${data?.date}T${data?.time}` },
  ].sort((a, b) => b.id - a.id);

  const WITH_DELIVERED = [
    ...DEFAULT_TIMELINE,
    {
      id: 3,
      action: "Delivered",
      updated_at: data?.delivered?.updated_at,
    },
  ].sort((a, b) => b.id - a.id);

  const defaultTimeline =
    data?.delivered === null ? DEFAULT_TIMELINE : WITH_DELIVERED;

  return (
    <div className="card border-end-0 border-top-0 border-bottom-0 h-100 border-gray-300">
      <div className="card-body">
        <div className="d-flex flex-column flex-md-row gap-4 justify-content-between align-items-center px-4">
          <div className="vstack gap-2">
            <p className="m-0">Tracking No.</p>
            <p className={"m-0 fs-2 fw-bold"}>#{slug}</p>
          </div>

          <div className="vstack gap-2">
            <p className="m-0">Dispatch Date</p>
            <p className={"m-0 fs-2 fw-bold"}>{dayMonYearDate(data?.date)}</p>
          </div>

          <div className="vstack gap-2">
            <p className="m-0">Destination</p>
            <p className={"m-0 fs-2 fw-bold"}>{data?.city}</p>
          </div>
        </div>

        <div className="separator border-gray-300 my-6"></div>

        <div className="hstack justify-content-between align-items-center px-4">
          <div className="vstack gap-2">
            <p className="m-0">Your Order is</p>

            <p className="m-0 fw-bolder fs-3x text-brand">
              {data?.delivered === null
                ? TRACKING_STATUSES.IN_TRANSIT
                : TRACKING_STATUSES.DELIVERED}
            </p>

            {data?.delivered?.updated_at ? (
              <p className="m-0 fw-semibold">
                as on {dayMonYearDate(data?.delivered?.updated_at)}{" "}
                {shortTime(data?.delivered?.updated_at)},{" "}
                {getDayOfTheWeek(data?.delivered?.updated_at)}
              </p>
            ) : null}
          </div>
        </div>

        <div className="separator border-gray-300 my-6"></div>

        <div className="vstack gap-4 ps-4">
          <p className="m-0 fs-5 fw-bold text-gray-600">Tracking History</p>

          <div className="h-300px d-flex flex-column gap-8 overflow-y-auto">
            {timeline === null ? (
              <>
                {defaultTimeline.map((details, index) => {
                  return (
                    <TimelineItem
                      details={details}
                      index={index}
                      key={`${index}-${details?.id}`}
                    />
                  );
                })}
              </>
            ) : (
              timeline?.map((details, index) => {
                return (
                  <TimelineItem
                    details={details}
                    index={index}
                    key={`${index}-${details?.id}`}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingDetailsMain;
