export const getQuoteGreetMessage = (salutation, lead) => {
  return `<p>Dear <strong>${lead},</strong></p><p>${salutation},</p><p>Attached is the quote you requested. We trust it aligns with your needs and offers significant value.</p>`;
};

export const getQuoteGreetMessageForConnect = (salutation, lead) => {
  return `
    <p>Dear <strong>${lead},</strong></p>
    <p>${salutation},</p>
    <p>As valued partners, we're pleased to share the attached quote per your request. We believe it reflects the best solutions tailored for your needs, and we trust it offers significant value.</p>
    <p>If there are any adjustments or further clarifications needed, please do not hesitate to reach out.</p>
    <p>Thank you for your continued partnership.</p>
    `;
};

export const getQuoteRequestGreetMessage = (
  salutation,
  lead,
  senderName,
  companyName
) => {
  return `
        <p>Dear <strong>${lead},</strong></p>
        <p>${salutation}, I wanted to reach out personally. This is ${senderName} from ${companyName}.</p>
        <p>I've raised the request in our system and have looped in our team to ensure prompt attention to your needs<br/>
        We have received your quote request and are actively reviewing it. <br/>Once the quote is prepared, you'll be notified right here in this email thread.</p>
    `;
};

export const getQuoteRequestGreetMessageForConnect = (salutation, lead) => {
  return `
        <p>Dear <strong>${lead},</strong></p>
        <p>${salutation},</p>
        <p>I've raised your request into our system, and we will share the quote here as soon as possible.</p> 
        <p>We appreciate your trust in our services and look forward to assisting you further.</p>
        <p>Thank you for your continued partnership with us.</p>
    `;
};

export function getTermsandConditions() {
  return `
            <span>Terms &amp; Conditions:<br>
                Warranty Period: [] Months<br>
            </span>
            <strong><a target="_blank" href="https://spurge.rentals/sales-terms-and-conditions-spurge/">Sales-Terms-and-Conditions</a></strong>
        `;
}

export const TemplateName = {
  SALES_TERMS: "SALES_TERMS",
  RENTAL_TERMS: "RENTAL_TERMS",
};
export const quoteRequestTypeAsNumber = {
  SALES: 0,
  RENTAL: 1,
  SERVICE: 2,
  SALES_RENTAL: 3,
};

export const QR_REQUEST_TYPE_COLORS = {
  SALES: "success",
  RENTAL: "primary",
  SERVICE: "success",
  SALES_RENTAL: "primary",
};

export const UsageType = {
  OFFICE: 0,
  WORK_FROM_HOME: 1,
};
export const ProductType = {
  LAPTOP: 0,
  ACCESSORY: 1,
};
export const QuoteStages = {
  CREATED: "CREATED",
  QUOTE_SENT: "QUOTE_SENT",
  PENDING_CLIENT: "PENDING_CLIENT",
  REVISED_SENT: "REVISED_SENT",
  EXPIRED: "EXPIRED",
  CLIENT_APPROVED: "CLIENT APPROVED",
  HOLD: "HOLD",
  CANCELED: "CANCELED",
  REJECTED: "REJECTED",
  COMPLETE: "SERVED",
};

export const QUOTE_STAGE_COLORS = {
  CREATED: "primary",
  REVISED_SENT: "dark",
  QUOTE_SENT: "success",
  default: "info",
};

export const lead_stage = {
  INVALID: "INVALID",
  DISQUALIFIED: "DISQUALIFIED",
  FUTURE: "FUTURE",
  LEAD: "LEAD",
  PROSPECT: "PROSPECT",
  FOLLOWUP: "FOLLOWUP",
  OPPORTUNITY: "OPPORTUNITY",
  CUSTOMER: "CUSTOMER",
};

export const LEAD_STAGE_COLORS = {
  LEAD: "success",
  CUSTOMER: "success",
  INVALID: "danger",
  DISQUALIFIED: "dark",
  default: "info",
};
