import React, { useEffect, useState } from "react";
import { getLimitAndOffset } from "@components/UI/Pagination/PaginationHelpers.ts";

type UrlData = {
  limit: null | number;
  offset: null | number;
};

const urlDataInitial: UrlData = {
  limit: null,
  offset: null,
};

export interface CommonPaginationProps {
  count: number | undefined;
  prev: string | null | undefined;
  next: string | null | undefined;
  handlePaginationClick: (limit: number, offset: number) => void;
  maxPages?: number;
}

function CommonPagination({
  count,
  prev,
  next,
  handlePaginationClick,
  maxPages,
}: CommonPaginationProps): React.JSX.Element {
  const MAX_PAGES = maxPages ? maxPages : 5;

  // local states
  const [previousData, setPreviousData] = useState<UrlData>(urlDataInitial);
  const [nextData, setNextData] = useState<UrlData>(urlDataInitial);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxLimit, setMaxLimit] = useState(MAX_PAGES);

  // Effect to update previousData and nextData
  useEffect(() => {
    const resultPrevious = getLimitAndOffset(prev);
    const resultNext = getLimitAndOffset(next);

    setPreviousData({ ...resultPrevious });
    setNextData({ ...resultNext });
  }, [prev, next]);

  // Effect for current Page
  useEffect(() => {
    if (prev === null) {
      setCurrentPage(1);
      return;
    }

    if (next === null && count) {
      setCurrentPage(Math.ceil(count / previousData.limit!));
      return;
    }

    const page =
      nextData.offset && nextData.limit
        ? Math.floor(nextData.offset / nextData.limit)
        : 0;
    setCurrentPage(page);
  }, [prev, next, nextData, count, previousData.limit]);

  // Methods
  function pageNumbers() {
    const pageArray: number[] = [];
    for (let i = maxLimit; i > maxLimit - MAX_PAGES; i--) {
      pageArray.unshift(i);
    }

    return pageArray;
  }

  function handlePageNumberClick(e: React.MouseEvent<HTMLButtonElement>) {
    const pageNumber = parseInt(e.currentTarget.value);

    const queryLimit = nextData?.limit ? nextData?.limit : previousData.limit!;

    handlePaginationClick(queryLimit, (pageNumber - 1) * queryLimit);

    if (pageNumber === maxLimit - 1) {
      setMaxLimit((prev) => prev + 1);
    } else if (pageNumber === maxLimit) {
      setMaxLimit((prev) => prev + 2);
    }
    setCurrentPage(pageNumber);
  }

  function handleNextClick() {
    if (currentPage === maxLimit) {
      setMaxLimit((prev) => prev + 1);
    }
    handlePaginationClick(nextData.limit!, nextData.offset!);
  }

  function handlePreviousClick() {
    handlePaginationClick(previousData.limit!, previousData.offset!);
  }

  function handleFirstClick() {
    setMaxLimit(MAX_PAGES);
    handlePaginationClick(previousData.limit!, 0);
  }

  function handleLastClick() {
    setMaxLimit(totalPages);
    handlePaginationClick(nextData.limit!, (totalPages - 1) * nextData.limit!);
  }

  function handleJumpTenRightClick() {
    handlePaginationClick(nextData.limit!, (currentPage + 9) * nextData.limit!);
  }

  function handleJumpTenLeftClick() {
    handlePaginationClick(
      previousData.limit!,
      (currentPage - 11) * previousData.limit!
    );
  }

  // Derived Constants
  const totalPages = count
    ? nextData?.limit
      ? Math.ceil(count / nextData.limit)
      : previousData?.limit
      ? Math.ceil(count / previousData.limit)
      : 0
    : 0;
  const moreThanTenRight = totalPages - currentPage >= 10;
  const moreThanTenLeft = currentPage > 10;

  // Effect to set maxLimit
  useEffect(() => {
    if (currentPage < MAX_PAGES) {
      setMaxLimit(MAX_PAGES);
    } else if (currentPage === totalPages) {
      setMaxLimit(totalPages);
    } else {
      setMaxLimit(currentPage + 2);
    }
  }, [currentPage, totalPages]);

  return (
    <div className={"d-flex align-items-center gap-4"}>
      <ul className="pagination z-index-0">
        {/* first */}
        <li className={`page-item previous m-1 ${!prev ? "disabled" : ""}`}>
          <button
            type={"button"}
            className="page-link"
            onClick={handleFirstClick}
            disabled={!prev}
          >
            <i className="bi bi-chevron-bar-left color-inherit"></i>
          </button>
        </li>

        {/* Jump 10 left */}
        {moreThanTenLeft ? (
          <li className="page-item previous m-1">
            <button
              type={"button"}
              className="page-link"
              onClick={handleJumpTenLeftClick}
            >
              <i className="bi bi-chevron-double-left color-inherit p-0"></i>
            </button>
          </li>
        ) : null}

        {/* Previous */}
        <li className={`page-item previous m-1 ${!prev ? "disabled" : ""}`}>
          <button
            type={"button"}
            className="page-link"
            onClick={handlePreviousClick}
            disabled={!prev}
          >
            <i className="bi bi-chevron-left color-inherit p-0"></i>
          </button>
        </li>

        {pageNumbers().map((pageNumber, index) => {
          if (pageNumber > totalPages) {
            return null;
          }

          return (
            <li
              className={`page-item m-1 ${
                currentPage === pageNumber ? "active" : ""
              }`}
              key={`${pageNumber}-${index}`}
              id={`${pageNumber}-${index}`}
            >
              <button
                type={"button"}
                className="page-link"
                value={pageNumber}
                onClick={handlePageNumberClick}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}

        {/* Next */}
        <li className={`page-item previous m-1 ${!next ? "disabled" : ""}`}>
          <button
            type={"button"}
            className="page-link"
            onClick={handleNextClick}
            disabled={!next}
          >
            <i className="bi bi-chevron-right color-inherit p-0"></i>
          </button>
        </li>

        {/* Jump 10 right */}
        {moreThanTenRight ? (
          <li className="page-item previous m-1">
            <button
              type={"button"}
              className="page-link"
              onClick={handleJumpTenRightClick}
            >
              <i className="bi bi-chevron-double-right color-inherit p-0"></i>
            </button>
          </li>
        ) : null}

        {/* last */}
        <li className={`page-item previous m-1 ${!next ? "disabled" : ""}`}>
          <button
            type={"button"}
            className={`page-link`}
            onClick={handleLastClick}
            disabled={!next}
          >
            <i className="bi bi-chevron-bar-right color-inherit p-0"></i>
          </button>
        </li>
      </ul>

      <div className={"d-flex align-items-center gap-2"}>
        <span>
          {(prev === null && next === null) ||
          (prev === undefined && next === undefined) ? (
            count
          ) : prev === null ? (
            <>1 - {nextData.offset}</>
          ) : next === null ? (
            <>
              {previousData.offset! + previousData.limit! + 1} - {count}
            </>
          ) : (
            <>
              {(currentPage - 1) * nextData.limit! + 1} - {nextData.offset}
            </>
          )}
        </span>
        <span>/</span> <span>{count}</span>
      </div>
    </div>
  );
}

export default CommonPagination;
