import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MultiLayeredModalDialog from "../../UI/MultiLayeredModalDialog.tsx";

type CellRichTextWrapperProps = {
  richText: string;
};

function CellRichTextWrapper({
  richText,
}: CellRichTextWrapperProps): React.JSX.Element {
  const [open, setOpen] = useState(false);

  //   Methods
  function handleHide() {
    setOpen(false);
  }

  function handleShow() {
    setOpen(true);
  }

  return (
    <>
      <div className="wrap-rich-text text-gray-600 position-relative">
        <div
          className={""}
          dangerouslySetInnerHTML={{
            __html: richText ?? "",
          }}
        ></div>

        <div
          className="bg-light bg-opacity-75 text-hover-primary text-end fs-base fw-bolder pt-3 position-absolute bottom-0 start-0 end-0"
          role={"button"}
          onClick={handleShow}
        >
          View more
        </div>
      </div>

      <Modal show={open} onHide={handleHide} centered>
        <MultiLayeredModalDialog>
          <Modal.Header closeButton>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={""}
              dangerouslySetInnerHTML={{
                __html: richText ?? "",
              }}
            ></div>
          </Modal.Body>
        </MultiLayeredModalDialog>
      </Modal>
    </>
  );
}

export default CellRichTextWrapper;
