import React from "react";

type OperationMetricsDetailsProps = {
  data: any;
};

function OperationMetricsDetails({
  data,
}: OperationMetricsDetailsProps): React.JSX.Element {
  return (
    <div className="card shadow-xs">
      <div className="card-header">
        <h5 className="card-title fw-bolder">Operations</h5>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex justify-content-between align-items-center px-9 ">
          <span className="">IN OPERATIONS STOCK</span>
          <div>
            <span className="fw-bold text-primary">
              <i className="bi bi-inbox-fill text-primary"></i>{" "}
              {data?.ops?.ops_stock?.ops_stock}{" "}
            </span>
          </div>
        </li>
        {data?.ops?.ops_category_stock?.ops_category_stock.length > 0 &&
          data?.ops?.ops_category_stock?.ops_category_stock?.map((e, index) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center px-9"
                key={index}
              >
                <span className="badge bg-dark text-white rounded-pill badge-sm fw-bold">
                  {e?.categories__name}
                </span>
                <span className="fw-bold">{e?.count_of_category_name}</span>
              </li>
            );
          })}
        <li className="list-group-item d-flex justify-content-between align-items-center px-9">
          {/*@ts-ignore*/}
          <span className="">
            {data?.ops?.total_in_out?.use_type_counts &&
            data?.ops.total_in_out.use_type_counts.length > 0
              ? data?.ops?.total_in_out?.use_type_counts[0]?.use_type
              : ""}
          </span>
          <div>
            {/* @ts-ignore*/}
            <span className="fw-bold text-primary me-2  ">
              {" "}
              <i className="bi bi-caret-left-fill text-primary"></i>{" "}
              {data?.ops?.total_in_out?.use_type_counts &&
              data?.ops.total_in_out.use_type_counts.length > 0
                ? data?.ops?.total_in_out?.use_type_counts[0]?.total_in
                : ""}
            </span>
            {/* @ts-ignore*/}
            <span className="fw-bold text-success">
              {data?.ops?.total_in_out?.use_type_counts &&
              data?.ops.total_in_out.use_type_counts.length > 0
                ? data?.ops?.total_in_out?.use_type_counts[0]?.total_out
                : ""}{" "}
              <i className="bi bi-caret-right-fill text-success"></i>
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default OperationMetricsDetails;
