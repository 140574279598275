import React from "react";
import TableAlert from "@components/helpers/CustomAlerts/TableAlert.tsx";
import { Table } from "react-bootstrap";
import TableSkeleton from "@components/helpers/Skeletons/TableSkeleton.tsx";
import { useQuery } from "react-query";
import { clientApi } from "@/api/private";
import { UserReferences } from "@/generated-api";

type ConsigneeReferenceProps = {
  referencePayload: UserReferences;
  open: boolean;
};

function ConsigneeReference({
  referencePayload,
  open,
}: ConsigneeReferenceProps) {
  //   local states

  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ["consignee_view_user_references", referencePayload],
    queryFn: () => {
      return clientApi
        .clientUsersReferencesCreate({ data: referencePayload })
        .then((res) => res.data);
    },
    enabled: open && !!referencePayload?.user_id,
    retry: false,
  });

  return (
    <>
      {isError ? (
        <TableAlert />
      ) : (
        <Table
          className={`${isLoading || isFetching ? "visually-hidden" : ""}`}
          striped
          bordered
        >
          <thead>
            <tr>
              <th className={""}>Referenced</th>
              <th className={""}>Count</th>
            </tr>
          </thead>
          <tbody>
            {/* todo: Remove ts-ignore after api docs are updated */}
            {/* @ts-ignore */}
            {data?.summary?.references?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.model}</td>
                  <td>{item?.reference_count}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {isLoading || isFetching ? (
        <TableSkeleton col={2} lines={1} row={5} />
      ) : null}
    </>
  );
}

export default ConsigneeReference;
