import React from "react";
import { SUPPORT_REQUEST_CATEGORY } from "@Constants/CR_Constant";

function CategorySelection({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
}) {
  return (
    <>
      <p className="mb-4 form-label">Category</p>
      <div className={"d-flex align-items-center gap-6"}>
        {Object.entries(SUPPORT_REQUEST_CATEGORY).map(([key, value], index) => {
          return (
            <div
              className="form-check form-check-solid form-check-brand-primary"
              key={`${key}-${index}`}
            >
              <input
                className="form-check-input"
                type="radio"
                name="category"
                id={`category-${key}`}
                value={value}
                checked={values?.category?.toString() === value.toString()}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label
                className="form-label text-capitalize"
                htmlFor={`category-${key}`}
              >
                {key.toLowerCase()}
              </label>
            </div>
          );
        })}
      </div>
      {errors?.category && touched?.category ? (
        <p className={"m-0 text-danger"}>{errors?.category as string}</p>
      ) : null}
    </>
  );
}

export default CategorySelection;
