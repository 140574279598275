import * as yup from "yup";

export const assetAssignedSchema = yup.object().shape({
  assigned: yup.number().required("User is required"),
  rental: yup.number().required("Rental is required"),
  note: yup.string().nullable(),
});

export interface assignedAssetValues {
  serial?: string | null;
  assigned: number | null;
  rental: number | null;
}

export const publicAssetAssignedSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  number: yup
    .string()
    .matches(/^\d{10}$/, "Contact number must be exactly 10 digits")
    .required("Contact Number is required"),
  email: yup.string().required("Email is required"),
  connect_slug: yup.string().required("Slug is required"),
  rental: yup.number().required("Rental is required"),
  note: yup.string().nullable(),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  pincode: yup
    .string()
    .matches(/^\d{6}$/, "PIN Code must be exactly 6 digits")
    .required("PIN Code is required"),
  tag: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Tag is required"),
        category: yup
          .number()
          .min(3, "Category must be 3")
          .max(3, "Category must be 3")
          .required("Category is required"),
      })
    )
    .nullable(),
});

export const erpAssignAssetSchema = yup.object().shape({
  assigned: yup.number().required("User is required"),
  rental: yup.number().required("Rental is required"),
  note: yup.string().nullable(),
  account: yup.number().required("Account is required"),
});
