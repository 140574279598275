import axios, { AxiosError, AxiosResponse } from "axios";
import { FilterURLParams, ApiResponseProduct } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;

export const PRODUCT_CONSTANT = `${API_URL}/stocky/products/`;

const PRODUCT_CATEGORY_SEARCH_CONSTANT = `${API_URL}/stocky/search/category/`;

const PRODUCT_CATEGORY_CREATE = `${API_URL}/stocky/category`;

const PRODUCT_BRAND_CONSTANT = `${API_URL}/stocky/brand/`;

const PRODUCT_ITEM_CONSTANT = `${API_URL}/stocky/search/item/`;

const PRODUCT_ITEM_CREATE = `${API_URL}/stocky/item`;

const GENERATE_SERIAL_UUID = `${API_URL}/stocky/uuid/`;

const GET_PRODUCT_TAGS = `${API_URL}/stocky/search/tag/?category=1`;

const PRODUCT_RATE_CONSTANT = `${API_URL}/stocky/products/rates/`;

export const PRODUCTS_EXPORT_URL = `${API_URL}/stocky/export/products/`;

const GET_PRODUCT_BIOS_KEY_URL = `${API_URL}/stocky/products/bios/password`;

const GET_CLIENTTAGS_URL = `${API_URL}/client/tags`;

export const buildURL = (params: FilterURLParams): string => {
  const queryParams: string[] = [];

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      queryParams.push(`${key}=${params[key]}`);
    }
  }

  const queryString = queryParams.join("&");

  return queryString ? `?${queryString}` : "";
};

export const buildExportURL = (params: FilterURLParams): string => {
  const queryParams: string[] = [];

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      // Replace double quotes with an empty string
      const paramValue = String(params[key]).replace(/"/g, "");
      queryParams.push(`${key}=${encodeURIComponent(paramValue)}`);
    }
  }

  const queryString = queryParams.join("&");

  return queryString ? `?${queryString}` : "";
};

export function getOrder_with_filter_ByToken(token: string, quarry: string) {
  const url = `${PRODUCT_CONSTANT}${quarry}`;

  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        // The request was made and the server responded with a status code
        // other than 2xx (e.g., 404, 500).
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
}

export function getProductCategorys(token: string, searchterm) {
  const url = `${PRODUCT_CATEGORY_SEARCH_CONSTANT}?search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
}

export const getBrandDetails = (token) => {
  const url = `${PRODUCT_BRAND_CONSTANT}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      return response?.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        // The request was made and the server responded with a status code
        // other than 2xx (e.g., 404, 500).
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
};

export function getProductItem(token: string, searchterm) {
  const url = `${PRODUCT_ITEM_CONSTANT}?search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
}

export function getUuId(token: string) {
  const url = `${GENERATE_SERIAL_UUID}`;

  return axios.get<ApiResponseProduct>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getProductTag(token: string, searchterm) {
  const url = `${GET_PRODUCT_TAGS}&search=${searchterm}`;
  return axios
    .get<ApiResponseProduct>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<ApiResponseProduct>) => {
      // Handle successful response
      return response.data.results;
    })
    .catch((error: AxiosError) => {
      // Handle error
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received.
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.error("Error during request setup:", error.message);
      }
      console.error("Error config:", error.config);

      // Propagate the error to the calling code
      throw error;
    });
}

export const createCatogary = (token, payloadtext) => {
  const url = PRODUCT_CATEGORY_CREATE;
  const payload = {
    display_name: payloadtext,
  };
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createBrand = (token, payloadtext) => {
  const url = PRODUCT_BRAND_CONSTANT;
  const payload = {
    name: payloadtext,
  };
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createItem = (token, payloadtext) => {
  const url = PRODUCT_ITEM_CREATE;
  const payload = {
    name: payloadtext,
  };
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createTag = (token, payloadtext) => {
  const url = PRODUCT_ITEM_CREATE;
  const payload = {
    slug: payloadtext,
  };
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const createProduct = (token, payload) => {
  const url = PRODUCT_CONSTANT;

  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AactionPatchProduct = (token, payload, id) => {
  const url = `${PRODUCT_CONSTANT}${id}/`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AcionProductRate = (token, payload) => {
  const url = PRODUCT_RATE_CONSTANT;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ProductRetrive = (token, id) => {
  const url = `${PRODUCT_CONSTANT}${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function downloadExcelFile(token: any) {
  return axios.get<any>(PRODUCTS_EXPORT_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getProductBiosKey(token: any, productId) {
  return axios.get(`${GET_PRODUCT_BIOS_KEY_URL}/${productId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getClientTags(token, term, key) {
  return axios.get(GET_CLIENTTAGS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      search: term,
      category: key,
    },
  });
}
