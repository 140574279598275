import React, { useEffect, useRef } from "react";
import pdfmake, { pdfMakeFonts } from "@/Utils/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";

type PdfGenerateProps = {
  dd: TDocumentDefinitions;
  height?: string;
};

function PdfGenerate({ dd, height }: PdfGenerateProps) {
  const poFrameRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    pdfmake.createPdf(dd, undefined, pdfMakeFonts).getDataUrl((dataUrl) => {
      if (poFrameRef?.current) {
        poFrameRef.current.src = dataUrl;
      }
    });
  }, [dd]);

  return (
    <div className={"w-100"} style={{ height: `${height ? height : "80dvh"}` }}>
      <iframe ref={poFrameRef} className={"w-100 h-100"}></iframe>
    </div>
  );
}

export default PdfGenerate;
