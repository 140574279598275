/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
import { ILayout, useLayout } from "../../core";
import { useAuth } from "../../../../app/modules/auth";

const Footer = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();
  useEffect(() => {
    updateDOM(config);
  }, [config]);
  return (
    <>
      <div className="" style={{ color: "#999" }}>
        <span className="fw-semibold me-2 text-uppercase">
          {currentUser?.account?.connect?.company_display_name || "spurge"}
        </span>
        <span className="fw-semibold">
          <span className={"me-2"}>&copy;</span>
          {new Date().getFullYear().toString()}
        </span>
      </div>
    </>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add("data-kt-app-footer-fixed", "true");
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
  }
};

export { Footer };
