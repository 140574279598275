import React from "react";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "@app/modules/auth";
import {
  ACCOUNT_PATHS,
  AR_PATHS,
  ASSET_TRAIL_PATHS,
  ASSET_VERIFICATION_PATHS,
  ATTENDANCE_PATHS,
  CONNECT_PATHS,
  CONSIGNEE_PATHS,
  DC_PATHS,
  ENQUIRY_PATHS,
  GROUP_PATHS,
  LEAD_PATHS,
  LOGIN_LOG_PATHS,
  LOGISTICS_PATHS,
  MARKETPLACE_PATHS,
  OPERATIONS_PATHS,
  ORDER_PATHS,
  PICKUP_ATTEMPT_PATHS,
  PO_PATHS,
  PRODUCT_PATHS,
  QC_PATHS,
  QR_PATHS,
  REACHOUT_PATHS,
  REGISTRATIONS_PATHS,
  RENTAL_LOGISTIC_RATE_PATHS,
  RENTALS_PATHS,
  REPORTS_PATHS,
  ROLES_PATHS,
  SERVICE_PATHS,
  SERVICE_TRACKER_PATHS,
  SUPPORT_REQUEST_PATHS,
  TASKS_PATHS,
  USERS_PATHS,
  VAULT_PATHS,
  VISIT_PATHS,
} from "@Constants/RoutingConstants/erp/RedirectRouteConstants.ts";

const ErpSidebar = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <SidebarMenuItemWithSub
        to="/erp/product"
        icon={"bi bi-laptop"}
        title="Products"
        permission={["user.create_product", "user.list_product"]}
      >
        <SidebarMenuItem
          to={`${PRODUCT_PATHS.CREATE}`}
          hasBullet={true}
          title={"Create"}
          fontIcon="bi-app-indicator"
          permission="user.create_product"
        />

        <SidebarMenuItem
          to={`${PRODUCT_PATHS.LIST}`}
          hasBullet={true}
          title={"List"}
          fontIcon="bi-app-indicator"
          permission="user.list_product"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to={`/erp/`}
        icon={"bi bi-pen-fill"}
        title="Create"
        permission={[
          "user.list_ops",
          "user.list_qc",
          "user.list_flowcaptain",
          "user.list_sent_to_service",
          "user.create_service_tracker",
          "user.list_sent_to_service",
          "user.create_group",
        ]}
      >
        <SidebarMenuItem
          to={`${OPERATIONS_PATHS.CREATE}`}
          hasBullet={true}
          title={"Operations"}
          fontIcon="bi-app-indicator"
          permission="user.list_ops"
        />

        <SidebarMenuItem
          to={`${QC_PATHS.CREATE}`}
          hasBullet={true}
          title={"Quality Check"}
          fontIcon="bi-app-indicator"
          permission="user.list_qc"
        />
        <SidebarMenuItem
          to={`${SERVICE_PATHS.CREATE}`}
          hasBullet={true}
          title={"Services"}
          fontIcon="bi-app-indicator"
          permission="user.list_sent_to_service"
        />
        <SidebarMenuItem
          to={`${SERVICE_TRACKER_PATHS.CREATE}`}
          hasBullet={true}
          title={"Service Tracker"}
          fontIcon="bi-app-indicator"
          permission="user.create_service_tracker"
        />
        <SidebarMenuItem
          to={`${GROUP_PATHS.IMPORT}`}
          title="Group"
          hasBullet={true}
          permission="user.create_group"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-folder-fill"}
        title="Log Lists"
        permission={[
          "user.list_ops",
          "user.list_qc",
          "user.list_flowcaptain",
          "user.list_sent_to_service",
          "user.list_asset_trail",
          "user.list_service_tracker",
          "user.list_group",
        ]}
      >
        <SidebarMenuItem
          to={`${OPERATIONS_PATHS.LIST}`}
          hasBullet={true}
          title={"Operations"}
          fontIcon="bi-app-indicator"
          permission={"user.list_ops"}
        />
        <SidebarMenuItem
          to={QC_PATHS.LIST}
          hasBullet={true}
          title={"Quality Check"}
          fontIcon="bi-app-indicator"
          permission={"user.list_qc"}
        />

        <SidebarMenuItem
          to={SERVICE_PATHS.LIST}
          hasBullet={true}
          title={"Service"}
          fontIcon="bi-app-indicator"
          permission={"user.list_sent_to_service"}
        />

        <SidebarMenuItem
          to={ASSET_TRAIL_PATHS.LIST}
          hasBullet={true}
          title={"Asset Trail"}
          fontIcon="bi-app-indicator"
          permission={"user.list_asset_trail"}
        />
        <SidebarMenuItem
          to={SERVICE_TRACKER_PATHS.LIST}
          hasBullet={true}
          title={"Service Tracker"}
          fontIcon="bi-app-indicator"
          permission="user.list_service_tracker"
        />

        <SidebarMenuItem
          to={GROUP_PATHS.LIST}
          title="Group"
          hasBullet={true}
          permission="user.list_group"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-card-list"}
        title="Logistics"
        permission={[
          "user.list_dc",
          "user.create_dc",
          "user.list_logistics",
          "user.create_logistics",
        ]}
      >
        <SidebarMenuItem
          to={LOGISTICS_PATHS.CREATE}
          hasBullet={true}
          title="Create"
          fontIcon="bi-app-indicator"
          permission="user.create_logistics"
        />
        <SidebarMenuItem
          to={LOGISTICS_PATHS.LIST}
          hasBullet={true}
          title="List"
          fontIcon="bi-app-indicator"
          permission="user.list_logistics"
        />

        <SidebarMenuItem
          to={DC_PATHS.CREATE}
          hasBullet={true}
          title="DC Create"
          fontIcon="bi-app-indicator"
          permission="user.create_dc"
        />
        <SidebarMenuItem
          to={DC_PATHS.LIST}
          hasBullet={true}
          title="DC List"
          fontIcon="bi-app-indicator"
          permission="user.list_dc"
        />

        <SidebarMenuItem
          to={PICKUP_ATTEMPT_PATHS.LIST}
          hasBullet={true}
          title="Pickup Attempt List"
          fontIcon="bi-app-indicator"
          permission="user.list_logistics"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-x-diamond-fill"}
        title="Orders"
        permission={[
          "user.create_order",
          "user.list_order",
          "user.po_create",
          "user.po_list",
        ]}
      >
        <SidebarMenuItem
          to={ORDER_PATHS.CREATE}
          hasBullet={true}
          title="Create"
          fontIcon="bi-app-indicator"
          permission="user.create_order"
        />
        <SidebarMenuItem
          to={ORDER_PATHS.LIST}
          hasBullet={true}
          title="List"
          fontIcon="bi-app-indicator"
          permission="user.list_order"
        />
        <SidebarMenuItem
          to={PO_PATHS.CREATE}
          hasBullet={true}
          title="PO Create"
          fontIcon="bi-app-indicator"
          permission="user.po_create"
        />

        <SidebarMenuItem
          to={PO_PATHS.LIST}
          hasBullet={true}
          title="PO List"
          fontIcon="bi-app-indicator"
          permission="user.po_list"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to={AR_PATHS.LIST}
        title="Asset Request"
        icon={"bi bi-lightning-charge-fill text-gold"}
        fontIcon={"bi bi-lightning-charge-fill"}
        permission={"user.list_rental_asset_request"}
      />

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-caret-down-square-fill"}
        title="Rentals"
        permission={[
          "user.list_rentals",
          "user.view_rentals",
          "user.update_rentals",
          "user.create_rentals",
          "user.delete_connect",
          "user.list_asset_trail",
        ]}
      >
        <SidebarMenuItem
          to={RENTALS_PATHS.SHEET}
          hasBullet={true}
          title="Sheet"
          fontIcon="bi-app-indicator"
          permission="user.list_rentals"
        />
        <SidebarMenuItem
          to={RENTALS_PATHS.RATE}
          hasBullet={true}
          title="Rate"
          fontIcon="bi-app-indicator"
          permission="user.create_rentals"
        />
        <SidebarMenuItem
          to={RENTALS_PATHS.YIELD}
          hasBullet={true}
          title="Yield "
          fontIcon="bi-app-indicator"
          permission="user.list_rentals"
        />
        <SidebarMenuItem
          to={RENTALS_PATHS.PRODUCTS}
          hasBullet={true}
          title="Products"
          fontIcon="bi-app-indicator"
          permission="user.list_rentals"
        />
        <SidebarMenuItem
          to={RENTALS_PATHS.ASSIGN}
          title={"Assign"}
          hasBullet={true}
          fontIcon="bi-app-indicator"
          permission={"user.list_rentals"}
        />
        <SidebarMenuItem
          to={RENTALS_PATHS.ASSIGNED}
          title={"Assigned"}
          hasBullet={true}
          fontIcon="bi-app-indicator"
          permission={"user.list_rentals"}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        icon="bi bi-database-fill-check"
        title="Asset Verification"
        fontIcon="bi bi-database-fill-check"
        permission={[
          "user.asset_verification_create",
          "user.asset_verification_list",
        ]}
      >
        <SidebarMenuItem
          to={ASSET_VERIFICATION_PATHS.MASTER_CREATE}
          title="Create"
          hasBullet={true}
          permission="user.asset_verification_create"
        />
        <SidebarMenuItem
          to={ASSET_VERIFICATION_PATHS.MASTER_LIST}
          title="List"
          hasBullet={true}
          permission="user.asset_verification_list"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-link-45deg"}
        title="Connect"
        permission={[
          "user.create_connect",
          "user.list_connect",
          "user.list_logistics",
          "user.crate_logistics",
        ]}
      >
        <SidebarMenuItem
          to={CONNECT_PATHS.CREATE}
          hasBullet={true}
          title="Create"
          fontIcon="bi-app-indicator"
          permission="user.create_connect"
        />
        <SidebarMenuItem
          to={CONNECT_PATHS.LIST}
          hasBullet={true}
          title="List"
          fontIcon="bi-app-indicator"
          permission="user.list_connect"
        />

        <SidebarMenuItemWithSub
          to="/erp/rental-logistic-rate/"
          title="Logistics Rate"
          hasBullet={true}
          permission={["user.list_leads", "user.create_lead"]}
        >
          <SidebarMenuItem
            to={RENTAL_LOGISTIC_RATE_PATHS.CREATE}
            hasBullet={true}
            title="Create"
            fontIcon="bi-app-indicator"
            permission="user.create_logistics"
          />
          <SidebarMenuItem
            to={RENTAL_LOGISTIC_RATE_PATHS.LIST}
            hasBullet={true}
            title="List"
            fontIcon="bi-app-indicator"
            permission="user.list_logistics"
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to={SUPPORT_REQUEST_PATHS.LIST}
        icon={"bi bi-bookmark-heart-fill"}
        title="Support Request"
        permission={"user.list_support_request"}
      />

      <SidebarMenuItem
        to={REPORTS_PATHS.LIST}
        icon={"bi bi-file-earmark-text"}
        title="Reports"
        permission={"user.list_reports"}
      />

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-briefcase-fill"}
        title="B.D."
        permission={[
          "user.create_quote_request",
          "user.list_quote_request",
          "user.list_leads",
          "user.create_lead",
          "user.list_visits",
          "user.list_enquiry",
        ]}
      >
        <SidebarMenuItemWithSub
          to={"/erp/qr"}
          icon={"bi bi-bank2"}
          title="Quote Request"
          permission={["user.create_quote_request", "user.list_quote_request"]}
        >
          <SidebarMenuItem
            to={QR_PATHS.CREATE}
            hasBullet={true}
            title="Create"
            fontIcon="bi bi-file-earmark-text"
            permission="user.create_quote_request"
          />
          <SidebarMenuItem
            to={QR_PATHS.LIST}
            hasBullet={true}
            title="List"
            fontIcon="bi bi-file-earmark-text"
            permission="user.list_quote_request"
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to="/erp/lead/"
          title="Lead"
          fontIcon="bi-chat-left"
          icon="bi bi-hand-thumbs-up-fill"
          permission={["user.list_leads", "user.create_lead"]}
        >
          <SidebarMenuItem
            to={LEAD_PATHS.CREATE}
            title="Create"
            hasBullet={true}
            permission="user.create_lead"
          />
          <SidebarMenuItem
            to={LEAD_PATHS.LIST}
            title="List"
            hasBullet={true}
            permission="user.list_leads"
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to="/erp/visit"
          icon={"bi bi-briefcase-fill"}
          title="Visit"
          permission={["user.list_visits"]}
        >
          <SidebarMenuItem
            to={VISIT_PATHS.LIST}
            hasBullet={true}
            title="List"
            fontIcon="bi bi-briefcase-fill"
            permission="user.list_visits"
          />
          <SidebarMenuItem
            to={VISIT_PATHS.REPORT}
            hasBullet={true}
            title={"Report"}
            fontIcon="bi bi-briefcase-fill"
            permission="user.list_visits"
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItem
          to={ENQUIRY_PATHS.LIST}
          title="Enquiry"
          fontIcon="bi-chat-left"
          icon="bi bi-person-raised-hand"
          permission={"user.list_enquiry"}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/reachout"
        title="Reachout "
        fontIcon="bi bi-person-bounding-box"
        icon="bi bi-person-bounding-box"
        permission={["user.reachout_list", "reachout_create"]}
      >
        <SidebarMenuItem
          to={REACHOUT_PATHS.CREATE}
          title="Create"
          hasBullet={true}
          permission="user.reachout_create"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/vault/"
        title="Vault"
        fontIcon="bi-chat-left"
        icon="bi bi-key-fill"
        permission={["user.create_vault", "user.list_vault"]}
      >
        <SidebarMenuItem
          to={VAULT_PATHS.CREATE}
          title="Create"
          hasBullet={true}
          permission="user.create_vault"
        />
        <SidebarMenuItem
          to={VAULT_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission="user.list_vault"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="erp/marketplace"
        title="Market Place"
        fontIcon="bi bi-shop"
        icon="bi bi-shop"
        permission={[
          "user.delete_marketplace",
          "user.view_marketplace",
          "user.list_marketplace",
          "user.update_marketplace",
          "user.create_marketplace",
        ]}
      >
        <SidebarMenuItem
          to={MARKETPLACE_PATHS.CREATE}
          title="Create"
          hasBullet={true}
          permission="user.view_marketplace"
        />
        <SidebarMenuItem
          to={MARKETPLACE_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission="user.view_marketplace"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        icon={"bi bi-list-task"}
        fontIcon={"bi bi-list-task"}
        title="Tasks"
        permission={[
          "user.delete_tasks",
          "user.view_tasks",
          "user.list_tasks",
          "user.update_tasks",
          "user.create_tasks",
        ]}
      >
        <SidebarMenuItem
          to={TASKS_PATHS.CREATE}
          hasBullet={true}
          title="Create"
          fontIcon="bi bi-file-earmark-text"
          permission="user.create_tasks"
        />
        <SidebarMenuItem
          to={TASKS_PATHS.MANAGE}
          hasBullet={true}
          title="Manage"
          fontIcon="bi bi-file-earmark-text"
          permission="user.list_tasks"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/erp/"
        title="Account"
        fontIcon="bi bi-person-badge-fill"
        icon="bi bi-person-badge-fill"
        permission={["user.reachout_list", "reachout_create"]}
      >
        <SidebarMenuItem
          to={ACCOUNT_PATHS.LIST}
          title="List"
          hasBullet={true}
          fontIcon="bi-app-indicator"
          permission="user.view_tasks"
        />

        <SidebarMenuItem
          to={USERS_PATHS.CREATE}
          title="Create User"
          hasBullet={true}
          fontIcon="bi-app-indicator"
          permission="user.view_tasks"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to={CONSIGNEE_PATHS.LIST}
        title="Consignee"
        icon={"bi bi-person-lines-fill"}
        fontIcon="bi bi-person-lines-fill"
        permission="user.view_tasks"
      />

      {currentUser?.is_account_admin ? (
        <SidebarMenuItemWithSub
          to={"/erp/roles"}
          title={"Roles"}
          fontIcon="bi bi-person-fill-gear"
          icon={"bi bi-person-fill-gear"}
          permission="user.view_tasks"
        >
          <SidebarMenuItem
            to={ROLES_PATHS.CREATE}
            hasBullet={true}
            title={"Create"}
            permission={"user.view_tasks"}
          />
          <SidebarMenuItem
            to={ROLES_PATHS.MANAGE}
            hasBullet={true}
            title={"Manage"}
            permission={"user.view_tasks"}
          />
          <SidebarMenuItem
            to={ROLES_PATHS.LIST}
            hasBullet={true}
            title={"List"}
            permission={"user.view_tasks"}
          />
        </SidebarMenuItemWithSub>
      ) : null}

      <SidebarMenuItem
        to={REGISTRATIONS_PATHS.LIST}
        title="Registrations"
        icon="bi bi-box-arrow-in-left"
        fontIcon="bi bi-box-arrow-in-left"
        permission="user.view_tasks"
      />

      <SidebarMenuItemWithSub
        to="/erp/"
        title="Attendance"
        fontIcon="bi bi-person-bounding-box"
        icon="bi bi-people-fill"
        permission={["user.user_list", "user_list"]}
      >
        <SidebarMenuItem
          to={ATTENDANCE_PATHS.LIST}
          title="List"
          hasBullet={true}
          permission={"user.user_list"}
        />
        {currentUser?.is_account_admin && (
          <SidebarMenuItem
            to={LOGIN_LOG_PATHS.LIST}
            title="Login Log"
            hasBullet={true}
            permission={"user.user_list"}
          />
        )}
      </SidebarMenuItemWithSub>
    </>
  );
};

export { ErpSidebar };
