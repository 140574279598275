import React, { useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { DateFormat } from "@/Utils/Shared/DateStringToDays.ts";

interface ChartDateRangePickerProps {
  onChange: (dates: Date[]) => void;
  initialDates?: [string, string];
}

export default function ChartDateRangePicker({
  onChange,
  initialDates,
}: ChartDateRangePickerProps): React.JSX.Element {
  const [selectedDates, setSelectedDates] = useState(() => {
    if (
      initialDates &&
      moment(initialDates[0]).isValid() &&
      moment(initialDates[1]).isValid()
    ) {
      return [new Date(initialDates[0]), new Date(initialDates[1])];
    }

    return [
      new Date(
        moment().clone().subtract(1, "days").format(DateFormat.YYYY_MM_DD)
      ),
      new Date(),
    ];
  });
  const flatpickrRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  // Methods
  function handleClick() {
    if (flatpickrRef?.current && flatpickrRef?.current?.flatpickr) {
      flatpickrRef?.current?.flatpickr.open();
    }
  }

  function handleDateChange(dates) {
    setSelectedDates(dates);
    onChange(dates);
  }

  // Derived
  const dateOne = moment(selectedDates[0]).format(DateFormat.DD_MM_YYYY);
  const dateTwo = moment(selectedDates[1]).format(DateFormat.DD_MM_YYYY);

  return (
    <>
      <button
        type={"button"}
        className="btn btn-sm btn-light d-flex align-items-center px-4"
        onClick={handleClick}
        id={"chart_date_range_picker"}
        ref={buttonRef}
      >
        <div className="text-gray-600 fw-bold">
          {dateOne === dateTwo ? dateOne : `${dateOne} - ${dateTwo}`}
        </div>

        <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-2 me-0">
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
          <span className="path6"></span>
        </i>
      </button>
      <div className="">
        <Flatpickr
          ref={flatpickrRef}
          value={selectedDates}
          options={{
            mode: "range",
            dateFormat: "d-m-Y",
            position: "auto right",
            positionElement: buttonRef?.current,
            showMonths: 1,
            onClose: handleDateChange,
          }}
          className="visually-hidden"
        />
      </div>
    </>
  );
}
