import React, { useState } from "react";
import { copyToClipboard } from "@/Utils/Shared/ClipboardHelpers.ts";
import ActionBtn from "@components/helpers/ActionButton/ActionBtn.tsx";

type CopyButtonProps = {
  text: string;
  isIcon?: boolean;
  title?: string;
  isAction?: boolean;
  classNames?: string;
};

function CopyButton({
  text,
  isIcon = true,
  title = "",
  isAction = true,
  classNames,
}: CopyButtonProps): React.JSX.Element {
  let timer: ReturnType<typeof setTimeout>;

  //   Local states
  const [copied, setCopied] = useState(false);

  //   Methods
  function handleClick() {
    copyToClipboard(text);
    setCopied(true);

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  return (
    <>
      {isAction ? (
        <ActionBtn
          isIconbtn={isIcon}
          action={() => handleClick()}
          title={title}
          icon={
            <i
              className={`ki-${
                copied ? "duotone" : "outline"
              } ki-copy p-0 fs-6 color-inherit`}
            ></i>
          }
        />
      ) : (
        <div className="d-flex align-items-center gap-2 fs-base">
          <span className={` ${classNames}`}>{title}</span>
          <button
            type={"button"}
            className={"btn btn-icon btn-sm btn-secondary"}
            onClick={handleClick}
          >
            <i
              className={`ki-${
                copied ? "duotone" : "outline"
              } ki-copy p-0 fs-6 color-inherit`}
            ></i>
          </button>
        </div>
      )}
    </>
  );
}

export default CopyButton;
