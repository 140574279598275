import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

function DynamicImportFallback() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="d-flex flex-center gap-4">
        <h2 className={"m-0"}>Updating Stocky Pro. Please wait...</h2>
        <Spinner />
      </div>
    </>
  );
}

export default DynamicImportFallback;
