import React from "react";
import { useFilterContext } from "@components/UI/HOC/ListFilter/useFilterContext.tsx";
import { ProductListFilters } from "@app/pages/erp/products/PublicProduct/_constants.ts";
import CreatableSelect from "react-select/creatable";
import {
  AsyncBrandSelector,
  AsyncItemSelector,
} from "@components/helpers/CustomSelectors/AsyncSelectors/Selector's.tsx";
import Select from "react-select";
import {
  CategoryStatus as CategoriesDropdown,
  GENERATIONS,
  PROCESSORS,
} from "@app/pages/erp/products/_constants.ts";
import { StockyApiStockyProductsListRequest } from "@/generated-api/server/stocky-api.ts";

function PublicProductListFilters() {
  const { filters, handleParamChange } = useFilterContext<
    ProductListFilters,
    StockyApiStockyProductsListRequest
  >();

  return (
    <>
      {filters.serial?.isVisible && (
        <div className="col-3 ">
          <label htmlFor={"product_list_serial"} className="form-label fw-bold">
            {filters.serial?.label}
          </label>
          <CreatableSelect
            components={{ Menu: () => <></> }}
            className="react-select-styled react-select-sm"
            classNamePrefix="react-select"
            name="serial"
            inputId={"product_list_serial"}
            placeholder={"Serial"}
            options={[{ label: "", value: "" }]}
            isMulti
            onChange={(selectedTags) => {
              const uppercaseTags = selectedTags.map((tag) =>
                tag.value.toUpperCase()
              );
              handleParamChange(filters.serial?.key, uppercaseTags.join(","));
            }}
          />
        </div>
      )}

      {filters.item__id?.isVisible && (
        <div className="col-3 ">
          <label className="form-label fw-bold">
            {filters.item__id?.label}
          </label>
          <AsyncItemSelector
            setterFunction={(val: any) => {
              handleParamChange(filters.item__id?.key, val);
            }}
          />
        </div>
      )}

      {filters.current_category?.isVisible && (
        <div className="col-3 ">
          <label htmlFor={"current_category"} className="form-label fw-bold">
            {filters.current_category?.label}
          </label>
          <Select
            className="react-select-styled react-select-sm"
            classNamePrefix="react-select"
            options={CategoriesDropdown}
            isClearable={true}
            isSearchable={true}
            openMenuOnFocus={true}
            inputId="current_category"
            name="current_category"
            placeholder={"Select Category"}
            onChange={(selectedCategory) => {
              const val = selectedCategory ? selectedCategory.value : null;
              handleParamChange(filters.current_category?.key, val);
            }}
          />
        </div>
      )}

      {filters.brand__id?.isVisible && (
        <div className="col-3">
          <label className="form-label fw-bold">
            {filters.brand__id?.label}
          </label>
          <AsyncBrandSelector
            setterFunction={(val: any) => {
              handleParamChange(filters.brand__id?.key, val);
            }}
          />
        </div>
      )}

      {/* Processor */}
      {filters.processor?.isVisible && (
        <div className="col-3 ">
          <label htmlFor={"processor"} className="form-label fw-bold">
            {filters.processor?.label}
          </label>
          <Select
            className="react-select-styled react-select-sm"
            classNamePrefix="react-select"
            options={PROCESSORS}
            isClearable={true}
            isSearchable={true}
            openMenuOnFocus={true}
            inputId="processor"
            name="processor"
            placeholder={"Select Processor"}
            onChange={(selectedProcessor: any) => {
              const val = selectedProcessor ? selectedProcessor.value : null;
              handleParamChange(filters.processor?.key, val);
            }}
          />
        </div>
      )}

      {/* Generation */}
      {filters.generation?.isVisible && (
        <div className="col-3 ">
          <label htmlFor={"generation"} className="form-label fw-bold">
            {filters.generation?.label}
          </label>
          <Select
            className="react-select-styled react-select-sm"
            classNamePrefix="react-select"
            options={GENERATIONS}
            isClearable={true}
            isSearchable={true}
            openMenuOnFocus={true}
            inputId="generation"
            name="generation"
            placeholder={"Select Generation"}
            onChange={(selectedGeneration: any) => {
              const val = selectedGeneration ? selectedGeneration.value : null;
              handleParamChange(filters.generation?.key, val);
            }}
          />
        </div>
      )}
    </>
  );
}

export default PublicProductListFilters;
