import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getPermissionByToken, getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import ReCAPTCHA from "react-google-recaptcha";
import jwtDecode from "jwt-decode";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import { playSound } from "@components/helpers/Sounds/PlaySound.ts";
import * as Sentry from "@sentry/react";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  captcha: Yup.string().required("Captcha is required"),
});

const initialValues = {
  email: "",
  password: "",
  captcha: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const { saveAuth, setCurrentUser, setPermissions } = useAuth();

  // local states
  const [captChaKey, setCaptChaKey] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { data: auth } = await login(
          values.email,
          values.password,
          captChaKey
        );
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.access);
        const { data: UserPermissions } = await getPermissionByToken(
          auth.access
        );
        const decodedToken: { scope: string; is_account_admin?: boolean } =
          jwtDecode(auth.access);

        setCurrentUser(() => {
          return {
            ...user,
            scope: decodedToken?.scope,
            is_account_admin: decodedToken?.is_account_admin,
          };
        });

        // setCurrentUser(user)
        setPermissions(UserPermissions);

        // Set user to sentry
        Sentry.setUser({ id: user.id, username: user.username });
        playSound("BaseNotify");
      } catch (error) {
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        playSound("SoftAlert");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className={"flex-column-fluid d-flex align-items-center px-lg-10"}>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3"> Sign In</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Login options */}

        {/* end::Separator */}

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          {/*<label className="form-label fs-6 fw-bolder text-dark">Email</label>*/}
          <input
            placeholder="Email"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-3">
          {/*<label className="form-label fw-bolder text-dark fs-6">*/}
          {/*  Password*/}
          {/*</label>*/}
          <input
            type="password"
            placeholder={"Password"}
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          {/* begin::Link */}
          <Link to="/auth/forgot-password" className="link-primary">
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* ReCAPTCHA */}
        <div className={"mb-8"}>
          <ReCAPTCHA
            sitekey={
              import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                ? import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                : "site key"
            }
            onChange={(value) => {
              setCaptChaKey(value);
              formik.setFieldValue("captcha", value);
            }}
          />
          {formik.errors.captcha && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.captcha}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Action */}
        <div className="d-grid mb-8">
          <SubmitButton
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !(captChaKey?.length > 0)
            }
            text={"Sign In"}
            loading={formik.isSubmitting}
            variant="brand-primary"
          />
        </div>
        {/* end::Action */}

        {/*<div className="text-gray-500 text-center fw-semibold fs-6">*/}
        {/*  Not a Member yet?{" "}*/}
        {/*  <Link to="/auth/onboarding" className="link-primary fw-semibold">*/}
        {/*    Sign Up*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </form>
    </div>
  );
}
