import {
  CanvasLine,
  CanvasPolyline,
  CanvasRect,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import { email, phone } from "@/Utils/pdfmake/_constants.ts";
import { SlimConnect } from "@/generated-api";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import { getCSSVariableValue } from "@metronic/assets/ts/_utils";

const fill1 = getCSSVariableValue("--bs-brand-primary");
const fill2 = getCSSVariableValue("--bs-brand-light");
const rSkewHeading = getCSSVariableValue("--bs-brand");

const rTrapezoid: CanvasPolyline = {
  type: "polyline",
  lineColor: fill1,
  color: fill1,
  points: [
    { x: 0, y: 60 },
    { x: 10, y: 10 },
    { x: 240, y: 0 },
    { x: 250, y: 70 },
    { x: 0, y: 60 },
  ],
};

const lTrapezoid: CanvasPolyline = {
  type: "polyline",
  lineColor: fill1,
  color: fill1,
  points: [
    { x: 20, y: 0 },
    { x: 200, y: 10 },
    { x: 220, y: 70 },
    { x: 0, y: 80 },
    { x: 20, y: 0 },
  ],
};

const rectangle: CanvasRect = {
  type: "rect",
  x: 0,
  y: 0,
  w: 240,
  h: 60,
  lineColor: fill2,
  color: fill2,
};

const rSkewed1: CanvasPolyline = {
  type: "polyline",
  lineColor: fill2,
  color: fill2,
  points: [
    { x: 0, y: 0 },
    { x: 210, y: 0 },
    { x: 230, y: 70 },
    { x: 0, y: 70 },
    { x: 0, y: 0 },
  ],
};

const rSkewed2: CanvasPolyline = {
  type: "polyline",
  lineColor: fill2,
  color: fill2,
  points: [
    { x: 0, y: 0 },
    { x: 210, y: 0 },
    { x: 230, y: 70 },
    { x: 0, y: 70 },
    { x: 0, y: 0 },
  ],
};

const rSkewed3: CanvasPolyline = {
  type: "polyline",
  lineColor: fill2,
  color: fill2,
  points: [
    { x: 0, y: 0 },
    { x: 210, y: 0 },
    { x: 230, y: 70 },
    { x: 0, y: 70 },
    { x: 0, y: 0 },
  ],
};

const rSkewed4: CanvasPolyline = {
  type: "polyline",
  lineColor: fill2,
  color: fill2,
  points: [
    { x: 0, y: 0 },
    { x: 210, y: 0 },
    { x: 230, y: 70 },
    { x: 0, y: 70 },
    { x: 0, y: 0 },
  ],
};

const bLine: CanvasLine = {
  type: "line",
  x1: 240,
  x2: 500,
  y1: 20,
  y2: 20,
  lineColor: fill1,
  lineWidth: 2,
};

const border =
  "-------------------------------------------------------------------------------------------------------------------------------------------------------------------------";

interface GenerateApDD {
  imageData: string;
  data: SlimConnect;
}

export function generateApDocDefinition({ imageData, data }: GenerateApDD) {
  const companyName = data?.company_display_name ?? "";
  const link = `${window.location.origin}/ticket/create/${data?.slug ?? ""}`;
  const connectName = DecorateNameTags(data?.name ?? "");

  return Promise.resolve({
    info: {
      title: `Asset Particulars | ${connectName}`,
    },
    content: [
      {
        image: "company",
        fit: [50, 50],
        width: 50,
        absolutePosition: { x: 40, y: 25 },
      },
      { text: companyName, fontSize: 14, absolutePosition: { x: 100, y: 40 } },
      {
        canvas: [rTrapezoid],
        marginTop: 0,
        absolutePosition: { x: 300, y: 10 },
      },
      {
        text: "ASSET PARTICULARS",
        color: "#fff",
        fontSize: 20,
        absolutePosition: { x: 330, y: 35 },
      },
      {
        text: border,
        decoration: "underline",
        decorationColor: fill1,
        decorationStyle: "dashed",
        color: "#fff",
        style: "sectionGap",
        absolutePosition: { x: 40, y: 80 },
      },
      {
        stack: [
          { text: "Dear User,", style: ["normalPara", "bold"] },
          { text: "We hope you have received the laptop safely." },
          {
            text: "Request you to kindly confirm the serial number / model of your laptop to your Admin / HR over email.",
          },
        ],
        relativePosition: { x: 0, y: 50 },
      },
      {
        columnGap: 10,
        relativePosition: { x: 0, y: 130 },
        columns: [
          {
            width: "*",
            stack: [
              {
                text: "In case you have any complaint / hardware / functionality issue, you can raise a ticket by simply scanning the QR code.",
                style: ["bold"],
              },
              {
                text: link.split("").join("\u200b"),
                link: link,
                color: getCSSVariableValue("--bs-primary"),
                margin: [0, 20, 0, 20],
              },
              {
                ul: [
                  {
                    text: "While raising the ticket please make sure all the fields are mentioned along with the correct serial number.",
                    margin: [0, 0, 0, 10],
                  },
                  {
                    text: "We recommend preserving the packaging for safe returns.",
                    margin: [0, 0, 0, 20],
                  },
                ],
              },
            ],
          },
          {
            width: "*",
            qr: link,
            fit: 205,
            foreground: fill1,
            alignment: "center",
            marginTop: -15,
          },
        ],
      },
      {
        columnGap: 10,
        relativePosition: { x: 0, y: 340 },
        columns: [
          {
            width: "*",
            stack: [
              { text: "IMPORTANT NOTE", fontSize: 16, style: ["normalPara"] },
              {
                text: `The laptop ownership belongs to ${companyName}`,
                marginBottom: 20,
              },
              {
                canvas: [rectangle],
              },
              {
                relativePosition: { x: 20, y: -50 },
                stack: [
                  {
                    text: "It is given on a rental basis to",
                    style: ["normalPara"],
                  },
                  {
                    text: connectName,
                    style: ["normalPara", "bold"],
                    fontSize: 13,
                    color: rSkewHeading,
                  },
                ],
              },
              {
                text: [
                  `The laptop has to be handed off only to `,
                  { text: `${companyName} `, style: ["bold"] },
                  `or `,
                  { text: `${connectName} `, style: ["bold"] },
                  "notifying the concerned people over mail at the time of your exit or completion of Project.",
                ],
                marginTop: 20,
                style: ["normalPara"],
              },
              {
                text: "No sellout, transfer or adjustment against salary or deposit is permitted for the device to avoid legal actions.",
              },
            ],
          },
          {
            width: "*",
            columns: [
              {
                width: 20,
                stack: [
                  {
                    stack: [
                      {
                        text: "O",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "U",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "R",
                        marginBottom: 12,
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "S",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "E",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "R",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "V",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "I",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "C",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "E",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                      {
                        text: "S",
                        alignment: "center",
                        style: ["verticalText"],
                      },
                    ],
                  },
                ],
              },
              {
                width: "*",
                stack: [
                  {
                    canvas: [rSkewed1],
                    marginBottom: 10,
                  },
                  {
                    relativePosition: { x: 10, y: -75 },
                    stack: [
                      {
                        text: "Laptops on Rent",
                        style: ["bold"],
                        fontSize: 12,
                        marginBottom: 4,
                        color: rSkewHeading,
                      },
                      { text: "Choose from laptops, tablets," },
                      { text: "mobiles, monitors, TVs," },
                      { text: "and other Tech gear." },
                    ],
                  },
                  {
                    canvas: [rSkewed2],
                    marginBottom: 10,
                  },
                  {
                    relativePosition: { x: 10, y: -75 },
                    stack: [
                      {
                        text: "Service & Support",
                        style: ["bold"],
                        fontSize: 12,
                        marginBottom: 4,
                        color: rSkewHeading,
                      },
                      { text: "Service, Repairs, Diagnostics" },
                      { text: "and Performance boost," },
                      { text: "giving your laptop a new life!" },
                    ],
                  },
                  {
                    canvas: [rSkewed3],
                    marginBottom: 10,
                  },
                  {
                    relativePosition: { x: 10, y: -75 },
                    stack: [
                      {
                        text: "Laptops for Sale",
                        style: ["bold"],
                        fontSize: 12,
                        marginBottom: 4,
                        color: rSkewHeading,
                      },
                      { text: "Secure the best deal" },
                      { text: "and comprehensive support for" },
                      { text: "your new laptop purchases." },
                    ],
                  },
                  {
                    canvas: [rSkewed4],
                    marginBottom: 10,
                  },
                  {
                    relativePosition: { x: 10, y: -75 },
                    stack: [
                      {
                        text: "PAN India Logistics",
                        style: ["bold"],
                        fontSize: 12,
                        marginBottom: 4,
                        color: rSkewHeading,
                      },
                      { text: "Door-to-door service across" },
                      { text: "all PIN codes in India. Track" },
                      { text: "easily and enjoy flexible billing." },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        stack: [
          {
            canvas: [lTrapezoid],
            absolutePosition: { x: 40, y: 740 },
          },
          {
            absolutePosition: { x: 70, y: 760 },
            stack: [
              {
                columnGap: 5,
                columns: [
                  { svg: phone(), color: "#fff", width: 10 },
                  { text: "+91 783 783 8585", color: "#fff", width: "auto" },
                ],
                style: ["normalPara"],
              },
              {
                columnGap: 5,
                columns: [
                  { svg: email(), color: "#fff", width: 10, marginTop: 2 },
                  {
                    text: `support@${data?.domain}`,
                    color: "#fff",
                    width: "auto",
                  },
                ],
              },
            ],
          },
          {
            canvas: [bLine],
            absolutePosition: { x: 0, y: 740 },
          },
          {
            stack: [
              {
                text: "677, 1st Floor, 16th main, 6th A Cross Rd,",
                style: ["italics"],
              },
              {
                text: "Koramangala, Bengaluru, Karnataka 560034",
                style: ["italics"],
              },
            ],
            absolutePosition: { x: 290, y: 765 },
          },
          {
            image: "company",
            fit: [50, 50],
            width: 50,
            absolutePosition: { x: 510, y: 745 },
          },
        ],
      },
    ],
    defaultStyle: {
      columnGap: 20,
      margin: [20, 20, 20, 20],
      fontSize: 11,
      color: "#333",
    },
    images: {
      company: imageData,
    },
    styles: {
      tableHeader: {
        bold: true,
        color: "white",
        fillColor: "#555",
        noWrap: true,
      },
      header: {
        bold: true,
        fontSize: 12,
      },
      sectionGap: {
        marginBottom: 16,
      },
      normalPara: {
        marginBottom: 10,
      },
      muted: {
        color: "#999",
      },
      small: { fontSize: 9 },
      bold: { bold: true },
      italics: { italics: true },
      tablePara: {
        marginBottom: 4,
      },
      tableFooter: {
        fontSize: 12,
        bold: true,
      },
      verticalText: {
        fontSize: 20,
        color: fill1,
      },
    },
    pageSize: "A4",
    pageMargins: 40,
    // pageBreakBefore: function (currentNode, followingNodesOnPage) {
    //   return (
    //     currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
    //   );
    // },
    //   footer: (currentPage, pageCount) => {
    //     return {
    //       stack: [
    //         {
    //           text: `${currentPage} / ${pageCount}`,
    //           absolutePosition: { x: 555, y: 2 },
    //         },
    //       ],
    //     };
    //   },
  } as TDocumentDefinitions);
}
