import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "@metronic/layout/core";
import { useAuth } from "@app/modules/auth";
import { getMaticInformation } from "./_requestes";
import { useQuery } from "react-query";
import ErpDashboardChartContainer from "../erp/DashboardHelpers/Chart/ErpDashboardChartContainer";
import MetricsRow from "../erp/DashboardHelpers/Metrics/MetricsRow";
import DashboardNavCard from "../erp/DashboardHelpers/NavCard/DashboardNavCard";
import MetricsDetailsContainer from "@app/pages/erp/DashboardHelpers/MetricsDetails/MetricsDetailsContainer.tsx";
import ErpLoginChart from "@app/pages/erp/DashboardHelpers/Chart/ErpLoginChart.tsx";

const DashboardPage: FC = () => {
  const { auth, currentUser } = useAuth();
  const {
    data: metrics,
    isLoading,
    isFetching,
  } = useQuery("metrics", () => getMaticInformation(auth?.access));

  return (
    <>
      <div className={"container-fluid"}>
        {/* New Metrics Cards */}
        <MetricsRow
          data={metrics?.data}
          loading={isLoading || isFetching || !metrics?.data}
        />
        {/* Metrics Details Cards */}
        <MetricsDetailsContainer
          metricsData={metrics?.data}
          loading={isLoading || isFetching || !metrics?.data}
        />

        {currentUser?.is_account_admin ? (
          <div className="mb-8">
            <ErpLoginChart />
          </div>
        ) : null}

        {/* Rental Chart */}
        <div className="mb-8">
          <ErpDashboardChartContainer />
        </div>

        {/* More Charts */}
        <div className="row mb-8">
          <div className="col-12">
            <DashboardNavCard />
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
