import { toast } from "react-toastify";
import { playSound } from "@components/helpers/Sounds/PlaySound.ts";

export const copyToClipboard = (path: string) => {
  navigator.clipboard
    .writeText(path)
    .then(() => {
      toast.info("Copied!", { autoClose: 1000 });
      playSound("BaseNotify");
    })
    .catch((err) => {
      console.error("Could not copy text: ", err);
      playSound("Delete");
    });
};
