export const erpModules = {
  product: "product",
  operations: "operations",
  qc: "qc",
  service: "service",
  assetTrail: "asset-trail",
  serviceTracker: "service-tracker",
  group: "group",
  logistics: "logistics",
  dc: "dc",
  pickupAttempt: "pickup-attempt",
  order: "order",
  po: "po",
  pi: "pi",
  ar: "ar",
  rentals: "rentals",
  assetVerification: "asset-verification",
  connect: "connect",
  rentalLogisticRate: "rental-logistic-rate",
  supportRequest: "support-request",
  reports: "reports",
  qr: "qr",
  lead: "lead",
  visit: "visit",
  enquiry: "enquiry",
  reachout: "reachout",
  vault: "vault",
  marketplace: "marketplace",
  tasks: "tasks",
  account: "account",
  users: "users",
  consignee: "consignee",
  registrations: "registrations",
  roles: "roles",
  attendance: "attendance",
  loginLog: "login-log",
  whitelistIp: "whitelist-ip",
};
