import React, { createContext } from "react";

export interface FilterContextInterface<F, U> {
  filters: F;
  urlParams: U;
  setUrlParams: React.Dispatch<React.SetStateAction<U>>;
  handleParamChange: (key: string | undefined, value: any) => void;
  handleDateRangeChange: (
    startDate: string | null,
    endDate: string | null
  ) => void;
  handlePaginationClick: (limit: number, offset: number) => void;
}

const FilterContext = createContext<
  FilterContextInterface<any, any> | undefined
>(undefined);

interface FilterContextProviderProps<F, U> {
  value: FilterContextInterface<F, U>;
  children: React.ReactNode;
}

function FilterContextProvider<F, U>({
  value,
  children,
}: FilterContextProviderProps<F, U>) {
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}

export { FilterContextProvider, FilterContext };
