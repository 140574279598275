import React from "react";
import { useThemeMode } from "@metronic/partials";
import DefaultFallbackCard from "@components/helpers/ErrorBoundary/DefaultFallbackCard.tsx";

import { DYNAMIC_IMPORT_ERROR_MESSAGE } from "@Constants/APP_Constants.ts";

type ErrorFallbackProps = {
  error?: Error;
};

function ErrorFallback({ error }: ErrorFallbackProps) {
  const { mode } = useThemeMode();

  const isDynamicImportError = Boolean(
    error?.message?.includes(DYNAMIC_IMPORT_ERROR_MESSAGE)
  );

  return (
    <div
      className={
        "bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat w-100 h-100"
      }
      style={{
        backgroundImage:
          mode === "light"
            ? "url('/media/auth/bg5.jpg')"
            : mode === "dark"
            ? "url('/media/auth/bg5-dark.jpg')"
            : "url('/media/auth/bg5.jpg')",
      }}
    >
      <div className="d-flex flex-center h-100">
        <DefaultFallbackCard isDynamicImportError={isDynamicImportError} />
      </div>
    </div>
  );
}

export default ErrorFallback;
