import React from "react";
import Chart from "@metronic/assets/lottie-assets/GraphLoader.json";
import LazyLottie from "@components/helpers/CustomAlerts/Lottie/LazyLottie.tsx";

function LottieChart(): React.JSX.Element {
  return (
    <>
      <LazyLottie animationData={Chart} />
    </>
  );
}

export default LottieChart;
