import React from "react";
import { useAuth } from "@app/modules/auth";
import { useQuery } from "react-query";
import { getDailyMonthlyStatsByAccountId } from "../../../dashboard/_requestes";
import ChartLoader from "../../Reports/ReportsHelper/MonthlyStatsReport/ChartLoader";
import RentalDailyMonthChart from "./RentalDailyMonthChart";

const SPURGE_ID = 1;

function ErpDashboardChartContainer(): React.JSX.Element {
  const { auth } = useAuth();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["erp_dashboard_stats"],
    queryFn: () =>
      getDailyMonthlyStatsByAccountId(auth?.access, SPURGE_ID).then(
        (res) => res.data
      ),
    retry: false,
    cacheTime: 1,
  });

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">
          <h1 className="card-title fs-1 m-0">Rental Statistics</h1>
        </div>
        <div className="card-body">
          {isLoading || isFetching ? (
            <ChartLoader />
          ) : (
            <RentalDailyMonthChart chartData={data} title={``} />
          )}
        </div>
      </div>
    </>
  );
}

export default ErpDashboardChartContainer;
