import React, { lazy, Suspense } from "react";
import { ApexOptions } from "apexcharts";
import { Props } from "react-apexcharts/types/react-apexcharts";
import LottieChart from "../../helpers/CustomAlerts/Lottie/LottieChart.tsx";

const Chart = lazy(() =>
  import(/* webpackChunkName: "ApexChartsChunk" */ "react-apexcharts").then(
    (module) => {
      return { default: module.default };
    }
  )
);

export interface ApexChartOptions extends ApexOptions {}

function ApexChart(props: Props): React.JSX.Element {
  return (
    <>
      <Suspense fallback={<LottieChart />}>
        <Chart {...props} />
      </Suspense>
    </>
  );
}

export default ApexChart;
