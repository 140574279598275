import React from "react";
import SuccessCheck from "@components/UI/AnimatedIcons/SuccessCheck/SuccessCheck.tsx";

export function TableSuccess({
  message,
}: {
  message: string;
}): React.JSX.Element {
  return (
    <>
      <div className="alert bg-light-brand border-brand p-8 mb-0">
        <div className="d-flex flex-column flex-center gap-5 text-brand-dark">
          <SuccessCheck />
          <p className="m-0 fs-1 fw-semibold text-center">{message}</p>
        </div>
      </div>
    </>
  );
}

TableSuccess.defaultProps = {
  message: "Success",
};
