import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
interface Props {
  children: ReactNode;
}
const MultiLayeredModalDialog: React.FC<Props> = ({ children }) => {
  return (
    <Modal.Dialog
      style={{ boxShadow: "0 0 10px 10000px #00000088 ", borderRadius: "20px" }}
      className="m-0 rounded-5 "
    >
      {children}
    </Modal.Dialog>
  );
};
export default MultiLayeredModalDialog;
