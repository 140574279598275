import { useContext } from "react";
import {
  FilterContext,
  FilterContextInterface,
} from "@components/UI/HOC/ListFilter/FilterContext.tsx";

export function useFilterContext<F, U>() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error("useFilterContext must be used within a GenericProvider");
  }

  return context as FilterContextInterface<F, U>;
}
