import axios from "axios";

import { buildURL } from "@/Utils/Shared/buildUrl.ts";
import { TickiteParams } from "./_models";
import { OrdersApiResponse } from "../Client_requests/_model";
import { TICKET_COMMET } from "../Client_requests/_request";

const API_URL = import.meta.env.VITE_APP_API_URL;
const TCKIETS = `${API_URL}/stocky/client_requests/`;
const COMMENTS = `${API_URL}/stocky/client_request/comments/?task_id=`;

export function getTickitByCaptcha(params: TickiteParams) {
  const url = buildURL(params, TCKIETS);
  return axios.get<any>(url, {
    headers: {},
  });
}

export function getTickitBytaskid(taskid: string | undefined | number) {
  const url = `${TCKIETS}${taskid}/`;
  return axios.get<any>(url, {
    headers: {},
  });
}

export function getComments(id) {
  const url = `${COMMENTS}${id}`;
  return axios.get<OrdersApiResponse>(url, {
    headers: {},
  });
}

export function postComments(payload) {
  const url = `${TICKET_COMMET}comments/`;
  return axios.post(url, payload, {
    headers: {},
  });
}
