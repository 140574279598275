import { createRedirectObject } from "@Constants/RoutingConstants/constants.ts";
import { erpModules } from "@Constants/RoutingConstants/erp/Modules.ts";

export const PRODUCT_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.product}/create`,
  LIST: `/erp/${erpModules.product}/list`,
});

export const OPERATIONS_PATHS = createRedirectObject({
  VIEW: `/erp/${erpModules.operations}/`,
  LIST: `/erp/${erpModules.operations}/list`,
  CREATE: `/erp/${erpModules.operations}/create`,
});

export const QC_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.qc}/create`,
  LIST: `/erp/${erpModules.qc}/list`,
});

export const SERVICE_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.service}/create`,
  LIST: `/erp/${erpModules.service}/list`,
});

export const SERVICE_TRACKER_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.serviceTracker}/create`,
  LIST: `/erp/${erpModules.serviceTracker}/list`,
});

export const GROUP_PATHS = createRedirectObject({
  IMPORT: `/erp/${erpModules.group}/import`,
  LIST: `/erp/${erpModules.group}/list`,
});

export const ASSET_TRAIL_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.assetTrail}/list`,
});

export const LOGISTICS_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.logistics}/create`,
  LIST: `/erp/${erpModules.logistics}/list`,
  VIEW: `/erp/${erpModules.logistics}/`,
});

export const DC_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.dc}/create`,
  LIST: `/erp/${erpModules.dc}/list`,
});

export const PICKUP_ATTEMPT_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.pickupAttempt}/list`,
});

export const ORDER_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.order}/create`,
  LIST: `/erp/${erpModules.order}/list`,
  VIEW: `/erp/${erpModules.order}/`,
});

export const PO_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.po}/create`,
  LIST: `/erp/${erpModules.po}/list`,
  VIEW: `/erp/${erpModules.po}/`,
});

export const PI_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.pi}/create`,
  LIST: `/erp/${erpModules.pi}/list`,
  VIEW: `/erp/${erpModules.pi}/`,
});

export const AR_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.ar}/list`,
  VIEW: `/erp/${erpModules.ar}/`,
});

export const RENTALS_PATHS = createRedirectObject({
  VIEW: `/erp/${erpModules.rentals}/`,
  SHEET: `/erp/${erpModules.rentals}/sheet`,
  RATE: `/erp/${erpModules.rentals}/rate-list`,
  YIELD: `/erp/${erpModules.rentals}/yield-list`,
  PRODUCTS: `/erp/${erpModules.rentals}/product-list`,
  ASSIGN: `/erp/${erpModules.rentals}/assign`,
  ASSIGNED: `/erp/${erpModules.rentals}/assigned`,
  HISTORY: `/erp/${erpModules.rentals}/history/`,
});

export const ASSET_VERIFICATION_PATHS = createRedirectObject({
  MASTER_CREATE: `/erp/${erpModules.assetVerification}/create`,
  MASTER_LIST: `/erp/${erpModules.assetVerification}/list`,
  SUBLIST: `/erp/${erpModules.assetVerification}/`,
  SUBLIST_VIEW: `/erp/${erpModules.assetVerification}/`,
});

export const CONNECT_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.connect}/create`,
  LIST: `/erp/${erpModules.connect}/list`,
});

export const RENTAL_LOGISTIC_RATE_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.rentalLogisticRate}/create`,
  LIST: `/erp/${erpModules.rentalLogisticRate}/list`,
});

export const SUPPORT_REQUEST_PATHS = createRedirectObject({
  VIEW: `/erp/${erpModules.supportRequest}/`,
  LIST: `/erp/${erpModules.supportRequest}/list`,
});

export const REPORTS_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.reports}/list`,
});

export const QR_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.qr}/create`,
  LIST: `/erp/${erpModules.qr}/list`,
});

export const LEAD_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.lead}/create`,
  LIST: `/erp/${erpModules.lead}/list`,
  VIEW: `/erp/${erpModules.lead}/`,
});

export const VISIT_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.visit}/list`,
  REPORT: `/erp/${erpModules.visit}/report`,
  VIEW: `/erp/${erpModules.visit}/:id`,
});

export const ENQUIRY_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.enquiry}/list`,
  VIEW: `/erp/${erpModules.enquiry}/`,
});

export const REACHOUT_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.reachout}/create`,
});

export const VAULT_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.vault}/create`,
  LIST: `/erp/${erpModules.vault}/list`,
});

export const MARKETPLACE_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.marketplace}/create`,
  LIST: `/erp/${erpModules.marketplace}/list`,
  VIEW: `/erp/${erpModules.marketplace}/`,
});

export const TASKS_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.tasks}/create`,
  MANAGE: `/erp/${erpModules.tasks}/manage`,
  VIEW: `/erp/${erpModules.tasks}/`,
});

export const ACCOUNT_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.account}/list`,
});

export const USERS_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.users}/create`,
  LIST: `/erp/${erpModules.users}/`,
  PROFILE: `/erp/${erpModules.users}/profile/`,
});

export const CONSIGNEE_PATHS = createRedirectObject({
  VIEW: `/erp/${erpModules.consignee}/`,
  LIST: `/erp/${erpModules.consignee}/list`,
  HISTORY: `/erp/${erpModules.consignee}/history/`,
});

export const ROLES_PATHS = createRedirectObject({
  CREATE: `/erp/${erpModules.roles}/create`,
  MANAGE: `/erp/${erpModules.roles}/manage`,
  LIST: `/erp/${erpModules.roles}/list`,
});

export const REGISTRATIONS_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.registrations}/list`,
  VIEW: `/erp/${erpModules.registrations}/`,
});

export const ATTENDANCE_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.attendance}/list`,
});

export const LOGIN_LOG_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.loginLog}/list`,
});

export const WHITELIST_IP_PATHS = createRedirectObject({
  LIST: `/erp/${erpModules.whitelistIp}/list`,
  ADD: `/erp/${erpModules.whitelistIp}/add`,
});
