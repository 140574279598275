import { FC, Suspense } from "react";
import { WithChildren } from "@metronic/helpers";
import { getCSSVariableValue } from "@metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";

export const SuspendedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-brand-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
