import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import { PasswordMeterComponent } from "../../../../../_metronic/assets/ts/components";
import { useFormik } from "formik";
import { submitNewPassword } from "../../core/_requests";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";

const initialValues = {
  password: "",
  confirmPassword: "",
  captcha: "",
};

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
  captcha: Yup.string().required("Captcha is required"),
});

const InitialSubmitState = {
  error: false,
  success: false,
};

const ResetPasswordForm = ({ slug, setSubmitState }) => {
  // Local states
  const [captChaKey, setCaptChaKey] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitState(InitialSubmitState);
      setTimeout(() => {
        submitNewPassword(slug, values.password, captChaKey)
          .then(({ data }) => {
            formik.resetForm();
            setSubmitState((prevState) => ({ ...prevState, success: true }));
          })
          .catch(() => {
            setSubmitState((prevState) => ({ ...prevState, error: true }));
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  // Effects
  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      {/* password */}
      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className={"mb-3"}>
          {/*<label className="form-label fs-6 fw-bolder text-dark">*/}
          {/*  New Password*/}
          {/*</label>*/}
          <input
            placeholder="New Password"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
            type="password"
            autoComplete="off"
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Meter */}
        <div
          className="d-flex align-items-center mb-3"
          data-kt-password-meter-control="highlight"
        >
          <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
          <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
          <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
          <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
        </div>
        {/* end::Meter */}
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers &amp; symbols.
        </div>
      </div>

      {/* confirm new password */}
      <div className="fv-row mb-8">
        {/*<label className="form-label fs-6 fw-bolder text-dark">*/}
        {/*  Confirm New Password*/}
        {/*</label>*/}
        <input
          placeholder="Confirm New Password"
          {...formik.getFieldProps("confirmPassword")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              "is-valid":
                formik.touched.confirmPassword &&
                !formik.errors.confirmPassword,
            }
          )}
          type="text"
          name="confirmPassword"
          autoComplete="off"
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      {/* recaptcha */}
      <div className={"mb-8"}>
        <ReCAPTCHA
          sitekey={
            import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
              ? import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
              : "site key"
          }
          onChange={(value) => {
            setCaptChaKey(value);
            formik.setFieldValue("captcha", value);
          }}
        />
        {formik.errors.captcha && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.captcha}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-grid pb-lg-0">
        <SubmitButton
          type="submit"
          variant={"brand-primary"}
          disabled={
            formik.isSubmitting || !formik.isValid || !(captChaKey?.length > 0)
          }
          loading={formik.isSubmitting}
        />
      </div>
    </form>
  );
};

export default ResetPasswordForm;
