import { ReactNode, Component } from "react";
import * as Sentry from "@sentry/react";

import { DYNAMIC_IMPORT_ERROR_MESSAGE } from "@Constants/APP_Constants.ts";

interface ErrorBoundaryProps {
  fallback: ReactNode | ((error: Error) => ReactNode);
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    if (!error?.message?.includes(DYNAMIC_IMPORT_ERROR_MESSAGE)) {
      Sentry.captureException(error, {
        extra: {
          componentStack: info.componentStack,
        },
      });
    }

    // Update state with the error and component stack
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI with error and component stack
      const { fallback } = this.props;
      if (typeof fallback === "function") {
        return fallback(this.state.error as Error);
      }
      return fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
