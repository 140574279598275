import React from "react";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";

const ProductCardSm = ({ data }) => {
  return (
    <div className="card">
      <div className="card-body p-6">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <div className="btn  btn-icon bg-light-secondary cursor-default ">
              <i className="ki-duotone ki-monitor-mobile fs-2x text-dark  ">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          <div className="flex-grow-1 gap-1 ms-3">
            <div className="fw-bold d-flex gap-1">
              SN# : {data?.serial}{" "}
              <CommonBadge
                text={data?.status_label}
                theme={"rental_sheet_status"}
              />
            </div>
            <div className="text-truncate">{data?.model?.slice(0, 41)}</div>
            <div className="">{data?.config}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSm;

export const ProductUiSm = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="flex-grow-1 d-flex flex-column gap-1">
        <div className="d-flex gap-2">
          SN#: {data?.serial}{" "}
          <CommonBadge
            text={data?.status_label}
            theme={"rental_sheet_status"}
          />
        </div>
        <div className="text-truncate">{data?.model?.slice(0, 41)}</div>
        <div className="">{data?.config}</div>
      </div>
    </div>
  );
};
