import React, { ReactNode, useEffect, useState } from "react";
import { LOCK_KEY } from "@/api/private/axios.ts";
import CountdownTimer from "@components/helpers/Countdown/CountdownTimer.tsx";
import { useThemeMode } from "@metronic/partials";
import SupportPopover from "@app/pages/crm/CRMPageWrapper/SupportPopover.tsx";
import { useAuth } from "@app/modules/auth";

function AppLock({ children }: { children: ReactNode }) {
  const { mode } = useThemeMode();
  const { currentUser } = useAuth();

  const [lock, setLock] = useState<null | number>(null);
  const [lockTime, setLockTime] = useState<null | number>(null);

  useEffect(() => {
    const lockData = localStorage.getItem(LOCK_KEY);
    if (lockData) {
      const data = JSON.parse(lockData);
      setLock(data);
      setLockTime(Date.now());
    }
  }, []);

  // on Reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (lockTime && lock) {
        const timeElapsed = Math.floor((Date.now() - lockTime) / 1000);
        const remaining = lock - timeElapsed > 0 ? lock - timeElapsed : 0;
        remaining && localStorage.setItem(LOCK_KEY, JSON.stringify(remaining));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [lockTime, lock]);

  if (lock) {
    return (
      <>
        <div
          className={
            "bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat w-100 h-100"
          }
          style={{
            backgroundImage:
              mode === "light"
                ? "url('/media/auth/bg5.jpg')"
                : mode === "dark"
                ? "url('/media/auth/bg5-dark.jpg')"
                : "url('/media/auth/bg5.jpg')",
          }}
        >
          <div className="d-flex flex-center h-100">
            <div className="card">
              <div className="card-body d-flex flex-column gap-4 p-20 mw-500px mw-xl-700px">
                <div className="d-flex flex-center">
                  <CountdownTimer
                    initial={lock}
                    onComplete={() => {
                      setLock(null);
                      localStorage.removeItem(LOCK_KEY);
                    }}
                  />
                </div>
                <img
                  src={
                    mode === "light"
                      ? "/media/illustrations/unitedpalms-1/5.png"
                      : mode === "dark"
                      ? "/media/illustrations/unitedpalms-1/5-dark.png"
                      : "/media/illustrations/unitedpalms-1/5.png"
                  }
                  alt={"Error loading page"}
                  width={400}
                  height={400}
                  className={"object-fit-cover mx-auto"}
                />

                <div className="vstack gap-2">
                  <p className="m-0 fs-2 fw-semibold text-center">
                    We are experiencing increased traffic from you device.
                  </p>
                  <div className="d-flex flex-center gap-4">
                    <p className="m-0 fs-2 fw-semibold text-center">
                      Please wait.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SupportPopover domain={currentUser?.account?.connect?.domain} />
        </div>
      </>
    );
  }

  return <>{children}</>;
}

export default AppLock;
