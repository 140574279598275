import React, { useEffect } from "react";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import clsx from "clsx";
import { StatusType } from "../OnboardingSignUp.tsx";
import SubmitButton from "@components/UI/Buttons/SubmitButton.tsx";
import { PasswordMeterComponent } from "@metronic/assets/ts/components";

interface onboardingSignUpInterface {
  account: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: number | string;
  captcha: string;
  password: string;
  confirmPassword?: string;
}

const onboardingSignUpSchema = yup.object().shape({
  account: yup.string().required("Account name is required"),
  email: yup
    .string()
    .email("Enter a Valid Email")
    .required("Email is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().nullable(),
  mobile: yup
    .string()
    .matches(/[0-9]+/, "Mobile number cannot contain letters")
    .max(10, "Mobile number cannot exceed 10 digits")
    .min(10, "Mobile number must be 10 digits")
    .required("Mobile number is Required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
  captcha: yup.string().required("Captcha is required"),
});

const onboardingSignUpInitial: onboardingSignUpInterface = {
  account: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  captcha: "",
  password: "",
  confirmPassword: "",
};

type OnboardingSignUpFormProps = {
  setStatus: React.Dispatch<React.SetStateAction<StatusType>>;
};

function StockyProSignUpForm({
  setStatus,
}: OnboardingSignUpFormProps): React.JSX.Element {
  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  // formik
  const formik = useFormik({
    initialValues: onboardingSignUpInitial,
    validationSchema: onboardingSignUpSchema,
    onSubmit: (values, { setSubmitting }) => {
      values.mobile = values.mobile ? +values.mobile : values.mobile;

      const payload = { ...values };
      delete payload.confirmPassword;

      setSubmitting(false);

      // postOnboardingSignUp({ payload: values })
      //   .then(() => {
      //     setStatus((prev) => {
      //       return { ...prev, submitted: true, isError: false };
      //     });
      //   })
      //   .catch(() => {
      //     setStatus((prev) => {
      //       return { ...prev, submitted: true, isError: true };
      //     });
      //   })
      //   .finally(() => {
      //     setSubmitting(false);
      //   });
    },
  });

  return (
    <div className={""}>
      <form onSubmit={formik.handleSubmit} className={"vstack gap-8"}>
        <div className={""}>
          <div className="row gy-6">
            <div className="col-md-6">
              <input
                type="text"
                {...formik.getFieldProps("first_name")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                      formik.touched.first_name && formik.errors.first_name,
                  },
                  {
                    "is-valid":
                      formik.touched.first_name && !formik.errors.first_name,
                  }
                )}
                id={"onboarding_sign_up_first_name"}
                placeholder="First Name"
                autoComplete="off"
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.first_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6">
              <input
                type="text"
                {...formik.getFieldProps("last_name")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                      formik.touched.last_name && formik.errors.last_name,
                  },
                  {
                    "is-valid":
                      formik.touched.last_name && !formik.errors.last_name,
                  }
                )}
                id={"onboarding_sign_up_last_name"}
                placeholder="Last Name"
                autoComplete="off"
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.last_name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={""}>
          <input
            type="text"
            {...formik.getFieldProps("account")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.account && formik.errors.account,
              },
              {
                "is-valid": formik.touched.account && !formik.errors.account,
              }
            )}
            id={"onboarding_sign_up_account"}
            placeholder="Account Name"
            autoComplete="off"
          />
          {formik.touched.account && formik.errors.account && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.account}</span>
              </div>
            </div>
          )}
        </div>

        <div className={""}>
          <input
            type="email"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.email && formik.errors.email,
              },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            id={"onboarding_sign_up_email"}
            placeholder="Email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className={""}>
          <input
            type="tel"
            {...formik.getFieldProps("mobile")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.mobile && formik.errors.mobile,
              },
              {
                "is-valid": formik.touched.mobile && !formik.errors.mobile,
              }
            )}
            minLength={10}
            maxLength={10}
            id={"onboarding_sign_up_mobile"}
            placeholder="Mobile"
            autoComplete="off"
          />
          {formik.touched.mobile && formik.errors.mobile && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.mobile}</span>
              </div>
            </div>
          )}
        </div>

        <div className={""} data-kt-password-meter="true">
          <div className={"d-flex flex-column gap-2"}>
            <input
              type="password"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
              id={"onboarding_sign_up_password"}
              placeholder="Password"
              autoComplete="off"
            />
            <div
              className="d-flex align-items-center"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className={""}>
          <input
            type="text"
            {...formik.getFieldProps("confirmPassword")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid":
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword,
              },
              {
                "is-valid":
                  formik.touched.confirmPassword &&
                  !formik.errors.confirmPassword,
              }
            )}
            id={"onboarding_sign_up_confirmPassword"}
            placeholder="Confirm Password"
            autoComplete="off"
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>

        <div className={""}>
          <ReCAPTCHA
            sitekey={
              import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                ? import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                : "site key"
            }
            onChange={(value) => {
              formik.setFieldValue("captcha", value);
            }}
          />
          {formik.errors.captcha && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.captcha}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-grid ">
          <SubmitButton
            type={"submit"}
            variant={"brand-primary"}
            disabled={formik.isSubmitting || !formik.values.captcha}
            loading={formik.isSubmitting}
            text={"Sign Up"}
          />
        </div>
      </form>
    </div>
  );
}

export default StockyProSignUpForm;
