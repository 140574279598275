import React from "react";
import { Link } from "react-router-dom";
import { useThemeMode } from "@metronic/partials";
import DynamicImportFallback from "@components/helpers/ErrorBoundary/DynamicImportFallback.tsx";

type DefaultFallbackCardProps = {
  isDynamicImportError?: boolean;
};

function DefaultFallbackCard({
  isDynamicImportError,
}: DefaultFallbackCardProps) {
  const { mode } = useThemeMode();

  return (
    <div className="card">
      <div className="card-body d-flex flex-column gap-8 p-20 mw-500px mw-xl-700px">
        <img
          src={
            mode === "light"
              ? "/media/illustrations/unitedpalms-1/9.png"
              : mode === "dark"
              ? "/media/illustrations/unitedpalms-1/9-dark.png"
              : "/media/illustrations/unitedpalms-1/9.png"
          }
          alt={"Error loading page"}
          width={400}
          height={400}
          className={"object-fit-cover mx-auto"}
        />

        {isDynamicImportError ? (
          <>
            <DynamicImportFallback />
          </>
        ) : (
          <>
            <div className="vstack gap-4">
              <p className="m-0 fs-2 fw-semibold text-center">
                Oops! There was an error when loading page.
              </p>
              <p className="m-0 fs-4 fw-semibold text-muted text-center">
                Our team has been notified! Please reload the page to see if it
                goes away.
              </p>
            </div>

            <div className="d-flex flex-center gap-4">
              <Link to={"/dashboard"} className={"btn btn-light-brand"}>
                Go Home
              </Link>
              <button
                type={"button"}
                onClick={() => {
                  window.location.reload();
                }}
                className={"btn btn-light-brand-primary"}
              >
                Reload
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DefaultFallbackCard;
