import React, { useState } from "react";
import { useQuery } from "react-query";
import { usersApi } from "@/api/private";
import { LoginLog } from "@/generated-api";
import ChartWithStatsCard from "@components/UI/Cards/ChartWithStatsCard/ChartWithStatsCard.tsx";
import { getErpLoginChartCategories } from "@/Utils/DashboardHelpers/erpDashboardHelpers.tsx";
import { ApexChartOptions } from "@components/UI/Charts/ApexChart.tsx";
import { chartColors } from "@components/UI/Cards/ChartWithStatsCard/_constants.ts";
import moment from "moment";
import ChartLoader from "@app/pages/erp/Reports/ReportsHelper/MonthlyStatsReport/ChartLoader.tsx";
import { DateFormat } from "@/Utils/Shared/DateStringToDays.ts";
import { UsersApiUsersLoginLogListRequest } from "@/generated-api/server/users-api.ts";

function ErpLoginChart() {
  const [urlParams, setUrlParams] = useState<UsersApiUsersLoginLogListRequest>({
    start_date: moment().format(DateFormat.YYYY_MM_DD),
    end_date: moment().format(DateFormat.YYYY_MM_DD),
    pagination: "false",
  });

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["login_log_chart", urlParams],
    queryFn: () => {
      return usersApi.usersLoginLogList(urlParams).then((res) => res.data);
    },
    retry: false,
  });

  const loginData = data as unknown as LoginLog[] | undefined;

  function handleDateChange(dates: Date[]) {
    const startDate = moment(dates[0]).toISOString(true);
    const endDate = moment(dates[1]).toISOString(true);

    setUrlParams((prev) => {
      return { ...prev, start_date: startDate, end_date: endDate };
    });
  }

  // Constants
  const chartData = getErpLoginChartCategories({ data: loginData });

  const seriesScatter:
    | ApexAxisChartSeries
    | ApexNonAxisChartSeries
    | undefined = chartData
    ? Object.entries(chartData).map(([key, value]) => {
        return {
          name: key,
          data: value?.map((entry) => {
            return {
              x: moment
                .utc(entry.created_at as string)
                .local()
                .toDate()
                .getTime(),
              y: moment
                .utc(entry.created_at as string)
                .local()
                .format("HH.mm"),
            };
          }),
        };
      })
    : undefined;

  const options: ApexChartOptions = {
    chart: {
      height: 400,
      type: "scatter",
    },
    xaxis: {
      type: "datetime",
      tooltip: { enabled: false },
      axisBorder: {
        show: false,
      },
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {},
    grid: {
      strokeDashArray: 6,
    },
    colors: chartColors,
    tooltip: {
      y: {
        formatter(val: number): string {
          return val.toFixed(2).toString().replace(".", ":");
        },
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "var(--bs-brand-primary)",
        fontSize: "20px",
      },
    },
  };

  return (
    <div>
      {isFetching || isLoading ? (
        <>
          <div className="card shadow-sm min-h-525px">
            <div className="card-body d-flex flex-center">
              <ChartLoader />
            </div>
          </div>
        </>
      ) : (
        <ChartWithStatsCard
          onDateChange={handleDateChange}
          initialDates={[urlParams.start_date!, urlParams.end_date!]}
          heading={"Login Chart"}
          height={400}
          options={options}
          series={seriesScatter}
          type="scatter"
        />
      )}
    </div>
  );
}

export default ErpLoginChart;
