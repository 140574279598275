export const formatToIndianRupee = (amount: number): string => {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const formattedAmount = formatter.format(amount);

  // Removing ".00" from the formatted amount
  const formattedAmountWithoutDecimal = formattedAmount.replace(/\.00$/, "");

  // Adding ₹ symbol before the formatted amount without decimal
  return `${formattedAmountWithoutDecimal}`;
};

export const ToInr = (amount: number): string => {
  const roundedAmount = Math.round(amount);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const formattedAmount = formatter.format(roundedAmount);

  // Adding ₹ symbol before the formatted amount
  return `${formattedAmount}`;
};

export const IndianRupeeFormat = (
  number: number | string | null | undefined
): string => {
  if (number !== null && number !== undefined) {
    if (typeof number === "number" || !isNaN(Number(number))) {
      return Number(number).toLocaleString("en-IN");
    } else {
      return ""; // Handle invalid number strings here if needed
    }
  } else {
    return "";
  }
};
