import React, { useState, useEffect } from "react";

interface PaginationProps {
  totalCount: number | undefined;
  nextPageLink?: string | null;
  prevPageLink?: string | null;
  updateState: any;
  urlParam: any;
  classNames?: string;
  itemsPerPage?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  nextPageLink,
  prevPageLink,
  updateState,
  urlParam,
  classNames,
  itemsPerPage = 15,
}) => {
  // const itemsPerPage = 15;
  const totalPages =
    totalCount && totalCount < itemsPerPage
      ? 1
      : (totalCount && Math.ceil(totalCount / itemsPerPage)) ?? 1;
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const page = urlParam.offset
      ? Math.floor(urlParam.offset / itemsPerPage)
      : 0;
    setCurrentPage(page);
  }, [totalCount, urlParam, setCurrentPage, itemsPerPage]);

  const handleClick = (page: number) => {
    if (page <= totalPages) {
      setCurrentPage(page);
      const newOffset = page * itemsPerPage;

      if (newOffset > 0 && newOffset >= itemsPerPage) {
        updateState((prev: any) => ({
          ...prev,
          offset: newOffset,
          limit: itemsPerPage,
        }));
      } else {
        updateState((prev: any) => ({
          ...prev,
          offset: 0,
          limit: itemsPerPage,
        }));
      }
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers: React.ReactElement[] = [];
    const maxDisplayedPages = Math.min(totalPages ?? 1, 5);
    // const maxDisplayedPages = 10; // Number of pages to display in the pagination

    // Calculate the range of pages to display based on the current page
    let startPage = Math.max(
      1,
      currentPage - Math.floor(maxDisplayedPages / 2)
    );
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages);

    for (let i = startPage; i <= endPage; i++) {
      if (currentPage === 0 && i === 1) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item cursor-pointer  ${
              currentPage === 0 ? "active-page" : ""
            }`}
          >
            <p
              className={`page-link border-1 bg-hover-dark text-hover-white  ${
                currentPage === i - 1
                  ? "bg-gray-700 text-white"
                  : "border-gray-700"
              } cursor-pointer m-0`}
              onClick={() => handleClick(0)}
            >
              {i}
            </p>
          </li>
        );
      } else {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item cursor-pointer  ${
              currentPage === i ? "active-page" : ""
            }`}
          >
            <p
              className={`page-link border-1 bg-hover-dark text-hover-white ${
                currentPage === i - 1
                  ? "bg-gray-700 text-white"
                  : "border-gray-700"
              } cursor-pointer m-0`}
              onClick={() => {
                handleClick(i - 1);
              }}
            >
              {i}
            </p>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <ul className={`pagination   ${classNames}  `}>
      <li
        className={` paginate_button page-item ${
          currentPage === 0 ? "disabled cursor-default" : "cursor-pointer"
        }`}
      >
        <p
          className={`page-link bg-hover-dark text-hover-white m-0 ${
            currentPage === 0 ? "disabled" : ""
          } border-1 border-gray-700`}
          onClick={() => handleClick(0)}
        >
          {/*<i className="bi bi-skip-start-btn-fill text-dark"></i>*/}

          <i className="bi bi-chevron-bar-left   text-dark fw-bold "></i>
        </p>
      </li>
      {totalPages > 10 && (
        <li
          className={` page-item ${
            currentPage < 10 ? "disabled" : ""
          } cursor-pointer`}
        >
          <p
            className="page-link bg-hover-dark text-hover-white border-1 border-gray-700 cursor-pointer m-0"
            onClick={() => handleClick(currentPage - 10)}
          >
            <i className="bi bi-chevron-double-left   text-dark fw-bold"></i>
            {/*<i className="bi bi-skip-backward-fill text-dark"></i>*/}
          </p>
        </li>
      )}
      <li
        className={`page-item ${
          currentPage > 0 ? "" : "disabled"
        } cursor-pointer`}
      >
        <p
          className="page-link bg-hover-dark text-hover-white border-1 border-gray-700 cursor-pointer m-0"
          tabIndex={-1}
          aria-disabled={!prevPageLink}
          onClick={() => handleClick(currentPage - 1)}
        >
          {/*<i className="bi bi-skip-start-fill text-dark cursor-pointer"></i>*/}

          <i className="bi bi-chevron-left    text-dark fw-bold"></i>
        </p>
      </li>

      {renderPageNumbers()}
      <li
        className={`page-item ${
          currentPage + 1 < totalPages ? "" : "disabled"
        } cursor-pointer`}
      >
        <p
          className="page-link bg-hover-dark text-hover-white border-1 border-gray-700 cursor-pointer m-0"
          onClick={() => {
            handleClick(currentPage + 1);
          }}
        >
          {/*<i className="bi bi-skip-end-fill text-dark"></i>*/}
          <i className="bi bi-chevron-right    text-dark fw-bold"></i>
        </p>
      </li>
      {totalPages > 10 && (
        <li
          className={`page-item ${
            currentPage >= totalPages - 10 ? "disabled" : ""
          } cursor-pointer`}
        >
          <p
            className="page-link bg-hover-dark text-hover-white border-1 border-gray-700 cursor-pointer m-0"
            onClick={() => handleClick(currentPage + 10)}
          >
            {/*<i className="bi bi-skip-forward-fill text-dark"></i>*/}
            <i className="bi bi-chevron-double-right   text-dark fw-bold"></i>
          </p>
        </li>
      )}
      <li
        className={`page-item ${
          currentPage === totalPages - 1 ? "disabled" : ""
        } `}
      >
        <p
          className={`page-link bg-hover-dark text-hover-white ${
            currentPage === 0 ? "disabled" : ""
          }  border-1 border-gray-700 cursor-pointer m-0`}
          onClick={() => handleClick(totalPages - 1)}
        >
          {/*<i className="bi bi-skip-end-btn-fill text-dark"></i>*/}
          <i className="bi bi-chevron-bar-right     text-dark fw-bold"></i>
        </p>
      </li>
    </ul>
  );
};

export default Pagination;
