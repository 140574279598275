import { OrderStageColor } from "../../../Constants/Order_Constants";
import { ToMins } from "../../../Utils/Shared/DateStringToDays.ts";
import { CRStageColor } from "../../../Constants/CR_Constant";
import { RENT_REQUEST_STAGE_COLORS } from "../../../Constants/Rental_Constants";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

interface InputObject {
  start_stage: string;
  end_stage: string;
  tat: string;
}

interface OutputObject {
  color: string;
  label: string | null;
  ToolTip?: string | null;
}

const parardimeOBJ = {
  ODR: OrderStageColor,
  RAR: RENT_REQUEST_STAGE_COLORS,
  CR: CRStageColor,
};

const ObjToColor = (obj, key) => {
  return Object.keys(obj).includes(key) ? obj[key] : "dark";
};

export const TatHelperFunction = (
  inputArray: InputObject[],
  paradimeKey: "ODR" | "RAR" | "CR"
): OutputObject[] | {} => {
  if (inputArray?.length) {
    const convertedArray: OutputObject[] = [];

    inputArray.forEach((obj) => {
      const newObj: OutputObject = {
        color: ObjToColor(parardimeOBJ[paradimeKey], obj?.start_stage),
        ToolTip: DecorateNameTags(obj?.start_stage),
        label: ToMins(obj?.tat),
      };
      convertedArray.push(newObj);
    });

    // Add an additional object with the end_stage color
    const lastObj: OutputObject = {
      color: ObjToColor(
        parardimeOBJ[paradimeKey],
        inputArray[inputArray?.length - 1].end_stage
      ),
      label: "", // You can set this to null or any other value as needed
      ToolTip: DecorateNameTags(inputArray[inputArray?.length - 1]?.end_stage),
    };
    convertedArray.push(lastObj);

    return convertedArray;
  }
  return [];
};
