import React from "react";
import OrderStageChartContainer from "./OrderStageChart/OrderStageChartContainer";
import NavigationCard from "../../../../../components/UI/Cards/NavigationCard";
import OrderTypeChartContainer from "./OrderTypeChart/OrderTypeChartContainer";

const dashboardNavCardContent = {
  order_stage: {
    id: "order_stage",
    label: "Order Stage",
    content: <OrderStageChartContainer />,
  },
  order_type: {
    id: "order_type",
    label: "Order Type",
    content: <OrderTypeChartContainer />,
  },
};

function DashboardNavCard(): React.JSX.Element {
  return (
    <>
      <NavigationCard contentObject={dashboardNavCardContent} />
    </>
  );
}

export default DashboardNavCard;
