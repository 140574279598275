import React from "react";
import { Placeholder } from "react-bootstrap";

function FormSkeleton(): React.JSX.Element {
  return (
    <div>
      <Placeholder as="div" animation="glow" className={"mb-6"}>
        <Placeholder.Button bg={"secondary"} className={"btn btn-lg col-5"} />
      </Placeholder>
      <div className="card mb-6">
        <div className="card-body p-8">
          <div className="row row-cols-md-2">
            <div className="col">
              <Placeholder as="div" animation="glow" className={""}>
                <Placeholder
                  as={"span"}
                  className={"col-4 form-label bg-secondary"}
                />
                <div className={"d-grid"}>
                  <Placeholder.Button
                    bg={"secondary"}
                    className={"btn btn-lg"}
                  />
                </div>
              </Placeholder>
            </div>
            <div className="col">
              <Placeholder as="div" animation="glow" className={""}>
                <Placeholder
                  as={"span"}
                  className={"col-4 form-label bg-secondary"}
                />
                <div className={"d-grid"}>
                  <Placeholder.Button
                    bg={"secondary"}
                    className={"btn btn-lg"}
                  />
                </div>
              </Placeholder>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body p-8">
          {Array.from({ length: 5 }).map((elem, index) => {
            return (
              <div className="row row-cols-md-2 mb-8" key={index}>
                <div className="col">
                  <Placeholder as="div" animation="glow" className={""}>
                    <Placeholder
                      as={"span"}
                      className={"col-4 form-label bg-secondary"}
                    />
                    <div className={"d-grid"}>
                      <Placeholder.Button
                        bg={"secondary"}
                        className={"btn btn-lg"}
                      />
                    </div>
                  </Placeholder>
                </div>
                <div className="col">
                  <Placeholder as="div" animation="glow" className={""}>
                    <Placeholder
                      as={"span"}
                      className={"col-4 form-label bg-secondary"}
                    />
                    <div className={"d-grid"}>
                      <Placeholder.Button
                        bg={"secondary"}
                        className={"btn btn-lg"}
                      />
                    </div>
                  </Placeholder>
                </div>
              </div>
            );
          })}

          <Placeholder as="div" animation="glow" className={"text-center"}>
            <Placeholder.Button
              bg={"secondary"}
              className={"btn btn-lg col-4"}
            />
          </Placeholder>
        </div>
      </div>
    </div>
  );
}

export default FormSkeleton;
