import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { checkIsActive, KTIcon, WithChildren } from "@metronic/helpers";
import { useLayout } from "../../../core";
import { useAuth } from "@app/modules/auth";

import { useACLHook } from "@Hooks/useACLHook.tsx";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  permission?: any;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  permission,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const { permissions: Allowed } = useAuth(); // Extracting permissions from useAuth hook
  const permissions = Array.isArray(permission)
    ? permission
    : [permission ?? ""]; // Ensuring permissions is an array
  const hasPermission = useACLHook(permissions, Allowed); // Using permissions and Allowed in useACLHook
  const finalPermission = permissions.length > 0 ? hasPermission : true; // Setting finalPermission based on whether permissions array is non-empty

  return finalPermission ? (
    <div
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion")}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            <KTIcon iconName={icon} className="fs-2" />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3 ", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export { SidebarMenuItemWithSub };
