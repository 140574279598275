import React from "react";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import moment from "moment";
import ApexChart, {
  ApexChartOptions,
} from "../../../../../components/UI/Charts/ApexChart";

function RentalDailyMonthChart({
  chartData,
  title,
  showToolbar,
}): React.JSX.Element {
  const options: ApexChartOptions = {
    chart: {
      toolbar: {
        show: showToolbar,
      },
      type: "area",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: chartData
        ? Object.keys(chartData).map((key) => moment(key).format("MMM-YYYY"))
        : [],
      tooltip: { enabled: false },
      labels: {
        offsetX: 1,
        rotate: -70,
        style: {
          fontSize: "0.9rem",
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    legend: { itemMargin: { horizontal: 20, vertical: 20 }, position: "top" },
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "1.5rem",
        color: "var(--bs-primary)",
      },
      offsetY: 20,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "var(--bs-primary)",
        fontSize: "20px",
      },
    },
  };

  const series = chartData
    ? [
        {
          name: DecorateNameTags("RENT"),
          data: Object.values(chartData).map((value: any) =>
            value?.["RENT"] ? value?.["RENT"] : 0
          ),
        },
        {
          name: DecorateNameTags("REPLACEMENT"),
          data: Object.values(chartData).map((value: any) =>
            value?.["REPLACEMENT"] ? value?.["REPLACEMENT"] : 0
          ),
        },
        {
          name: DecorateNameTags("RETURNED"),
          data: Object.values(chartData).map((value: any) =>
            value?.["RETURNED"] ? value?.["RETURNED"] : 0
          ),
        },
        {
          name: DecorateNameTags("YET_TO_PICKUP"),
          data: Object.values(chartData).map((value: any) =>
            value?.["YET_TO_PICKUP"] ? value?.["YET_TO_PICKUP"] : 0
          ),
        },
      ]
    : [];

  return (
    <ApexChart options={options} series={series} type="area" height="500" />
  );
}

export default RentalDailyMonthChart;

RentalDailyMonthChart.defaultProps = {
  title: "Monthly Stats",
  showToolbar: true,
};
