export const chartColors = [
  "#0072ff",
  "#00d68f",
  "#ffd166",
  "#ff6b6b",
  "#ff9f1a",
  "#7a6fff",
  "#f76df3",
  "#00e0ff",
  "#ff579f",
  "#41d6c3",
];
