import * as yup from "yup";
import { workPlaceOptions } from "./_constants";
import * as Yup from "yup";

export const assetVerificationSchema = yup.object({
  first_name: yup.string().required("First Name Is Required").min(1).max(255),
  mobile: yup
    .string()
    .max(10, "Mobile number cannot exceed 10 digits")
    .min(10, "Mobile number must be 10 digits")
    .required("Mobile number is Required"),
  email: yup.string().email().required("Email Is Required ").min(1).max(255),
  employee_id: yup.string().nullable().min(1).max(255),
  work_location: yup
    .string()
    .required("Work Location Is Required")
    .oneOf([
      workPlaceOptions[0]?.value,
      workPlaceOptions[1]?.value,
      workPlaceOptions[2]?.value,
    ]),
  pincode: yup
    .string()
    .required("Pincode Is Required")
    .max(6, "Pincode cannot exceed 6 digits")
    .min(6, "Pincode must be 6 digits"),
  latitude: yup.string().nullable(),
  longitude: yup.string().nullable(),
  address: yup
    .string()
    .nullable()
    .min(1)
    .max(1000, "Address cannot exceed 1000 characters"),
  serial: yup.string().required("Serial Number Is Required").min(1).max(255),
  product: yup.string().required("Product is Required").min(1).max(255),
  captcha: yup.string().required(" Captcha Is Required"),
  consignee: Yup.number().nullable(),
});
