import React from "react";
import { Placeholder, Table } from "react-bootstrap";

export type TableSkeletonProps = {
  row?: number;
  col?: number;
  lines?: number;
};

function TableSkeleton({
  row = 3,
  col = 3,
  lines = 1,
}: TableSkeletonProps): React.JSX.Element {
  return (
    <section className={"mb-6"}>
      <div className="row">
        <div className="col">
          <Table bordered>
            <thead>
              <tr>
                {Array.from({ length: col }).map((elem, index) => (
                  <th key={index}>
                    <Placeholder as="span" animation="glow">
                      <Placeholder bg={"secondary"} className={"col-12"} />
                    </Placeholder>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: row - 1 }).map((elem, index) => {
                return (
                  <tr key={index}>
                    {Array.from({ length: col }).map((elem, index) => (
                      <td key={index}>
                        <Placeholder as="span" animation="glow">
                          {Array.from({
                            length: Math.floor(Math.random() * lines) + 1,
                          }).map((elem, index) => (
                            <Placeholder
                              bg={"secondary"}
                              className={"col-12"}
                              key={index}
                            />
                          ))}
                        </Placeholder>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
}

export default TableSkeleton;
